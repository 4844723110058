import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { createSubjectAPI } from 'src/api/MastersApi'
import RoutingUtil from 'src/utils/RoutingUtil'

const SubjectCreate = () => {

const history = useHistory()    
const params = useParams()
const [subjectName, setSubjectName] = useState('')
const [formData, setFormData] = useState('')

const handleSubmit = async(e) => {
       e.preventDefault()
       if(params.id){
        //
       }else {
        const createSub = await createSubjectAPI(formData)
        if(createSub){
            history.push(RoutingUtil.subjectMasterUrl())
        }
       } 
}

const handleChange = (e) => {
    const data = e.target.value;
    setSubjectName(data)
    const subject = data.replace(/[^a-zA-Z0-9, ]/g, "");
    setFormData({...formData, subject: subject});
}


    return (
        <div className='row mt-4 p-3'>
            <div className='col-md-12'>
                <p className='ms-2'>
                    <span className='mdi mdi-arrow-left-bold me-2' style={{fontSize:'1rem', cursor:'pointer',border:'1px solid black',color:'white', background:'black'}} onClick={() => history.push(RoutingUtil.subjectMasterUrl())}></span>
                    Create New Subject</p>
            </div>
            <div className='col-md-12'>
                <p className='ms-2'>Subject Name</p>
                <input className='form-control' type='text' value={subjectName} placeholder='Enter Subject Name'  style={{width:'30%'}} onChange={(e) => handleChange(e)}/>
                <button className='btnbluen1 mt-4 mb-4' onClick={(e) => handleSubmit(e)}
                disabled={subjectName === ''}
                >Save Subject</button>
            </div> 
        </div>
    )
}

export default SubjectCreate 