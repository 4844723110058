import BrowsersReport from "./browsersReport";
import DevicesReport from "./devicesReport";
import { LastRow } from "./styles";


const DivicesList=({browserData,devicesData})=>(
    <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2">
                        <span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                          Devices 
                      </div> 
                  </div>
                  <div className="hdh1bg">
                     <div className="col-md-6">
                      <BrowsersReport browserData={browserData} /> 
                     </div>
                    <div className="col-md-6">
                       <DevicesReport devicesData={devicesData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

)
export default DivicesList;