import React from 'react'
import { useParams } from 'react-router-dom'
import { MakeSlugToTitle } from 'src/utils/CommonUtils'
import AddQuestionForm from './AddQuestionForm'
import { getSingleFaqCategory } from 'src/api/FAQApi'
import { useEffect } from 'react'
import { useState } from 'react'
import QuestionList from './QuestionList'

const AddFaqQuestion = () => {

const params = useParams()
const [quesArr, setQuesArr] = useState([])

useEffect(() => {
getAllFaqQuestions()
},[])


const getAllFaqQuestions = async() => {
    const {data} = await getSingleFaqCategory(params.id)
    setQuesArr(data.data.faq_content)
}

    return (
        <div className='row p-4'>
            <div className='col-md-12'>
                <h2>Create Faq Category</h2>
            </div>
            <div className='col-md-12 mt-4'>
                <div className='row'>
                    <div className='col-md-6'>
                        <p>Add Faq Question - {MakeSlugToTitle(params.categorySlug)}</p>
                        <hr />
                        <AddQuestionForm quesArr={quesArr}/>
                    </div>
                    <div className='col-md-6'>
                    <p>All Faq Question - {MakeSlugToTitle(params.categorySlug)}</p>
                        <hr />
                     <QuestionList quesArr={quesArr}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddFaqQuestion