import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AUTH_ACTIONS } from 'src/containers/auth-container/actions/AuthActions';
import { EnquiryDefaultProps } from 'src/containers/enquiry-container/EnquiryPropTypes';
import { ENQUIRY_ACTIONS } from '../actions/EnquiryActions';

const initialState = () => ({
    currentRecord: { ...EnquiryDefaultProps },
    resume_writing:{
        search:{
            data:[],
            totalPending:0,
            totalNotConverted:0,
            totalConverted:0
        },
        currentPage:0,
        pages:0,
        count:0,       

    },
    mentor_assistant:{
        search:{
            data:[],
            totalPending:0,
            totalNotConverted:0,
            totalConverted:0
        },
        currentPage:0,
        pages:0,
        count:0,        
    },
    SOP:{
        search:{
            data:[],
            totalPending:0,
            totalNotConverted:0,
            totalConverted:0
        },
        currentPage:0,
        pages:0,
        count:0,       
    },
    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        // SEARCH
        case ENQUIRY_ACTIONS.ENQUIRY_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ENQUIRY_ACTIONS.ENQUIRY_SEARCH_SUCCESS: {
            const {searchType,data} = action.data;
            let newState = {...state};
            switch(searchType){
             case 'resume':
                newState.resume_writing.search={
                    ...newState.resume_writing.search,
                    ...data
                }
                break;
                case 'mentor':
                newState.mentor_assistant.search={
                    ...newState.mentor_assistant.search,
                    ...data
                }
                break;
                case 'SOP':
                newState.SOP.search={
                    ...newState.SOP.search,
                    ...data
                }
                break;
                default:
                    alert('invalide type')
                break;
            }
            return {
                // ...state,
                // search: action.data,
                ...newState,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ENQUIRY_ACTIONS.ENQUIRY_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // UPSERT
        case ENQUIRY_ACTIONS.ENQUIRY_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ENQUIRY_ACTIONS.ENQUIRY_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ENQUIRY_ACTIONS.ENQUIRY_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // RESET
        case ENQUIRY_ACTIONS.ENQUIRY_RESET: {
            return {
                ...state,
                currentRecord: { ...EnquiryDefaultProps },
            };
        }

        // LOGOUT
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...initialState(),
            };

        default:
            return state;
    }
};
