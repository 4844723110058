import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import MenuBandageComponent from './MenuBandageComponent';
import LinkComponent from '../LinkComponent';

const MenuLinkComponent = ({
    faIcon,
    title,
    link,
    bandage
}) => 
{

useEffect(() => {
    const listItems = document.getElementsByClassName('nav-item');

    for (let i = 0; i < listItems.length; i++) {
      listItems[i].addEventListener('click', function() {
        for (let j = 0; j < listItems.length; j++) {
          listItems[j].classList.remove('active');
        }
        this.classList.add('active');
      });
    }
},[])

const handleClick =  () => {
const listItems = document.getElementsByClassName('nav-item');

for (let i = 0; i < listItems.length; i++) {
  listItems[i].addEventListener('click', function() {
    for (let j = 0; j < listItems.length; j++) {
      listItems[j].classList.remove('active');
    }
    this.classList.add('active');
  });
}
}

    return (
        <li className={`nav-item list-item`} onClick={handleClick}>
            <LinkComponent to={link} className="nav-link">
                {faIcon ? <i className={`mdi ${faIcon} mdi-24px menu-icon`} /> : null}
                <span className="menu-title">{title}</span>
                {bandage ? (
                    <MenuBandageComponent
                        title={bandage.title}
                        faIcon={bandage.faIcon}
                    />
                ) : null}
            </LinkComponent>
        </li>
    );
}


MenuLinkComponent.defaultProps = {
    bandage: {
        title: '',
        faIcon: '',
    },
    faIcon: '',
    link: null,
    title: '',
};

MenuLinkComponent.propTypes = {
    bandage: PropTypes.shape({
        faIcon: PropTypes.string,
        title: PropTypes.string,
    }),
    faIcon: PropTypes.string,
    link: PropTypes.string,
    title: PropTypes.string,
};

export default MenuLinkComponent;
