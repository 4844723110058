const OnlineTutoring=(props)=>{
    const {
        onlineTutoring = []
    } = props;
    return(
        <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 lightblue-icon me-2"><span><img src="/assets/images/home-imgs/online-icon.svg" className="img-fluid" alt=""/></span></div>
                      Online Tutoring Sessions </div>
                     
                  </div>
                  <div className="d-flex justify-content-between align-items-center totdetaX2">
                    <div className="">
                      <h3>Total Delivered</h3>
                    </div>
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center txnetotal"> <span className="numcount">0</span>
                          <div className="pendcp text-green ms-3"><span>0% <i className="mdi mdi-plus mdi-24px"></i> </span> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sessaTxt">
                    <div className="sessaTxt1 inpress">
                      <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Session in Progress
                      </div>
                      <div className="sessaTxt3">0</div>
                    </div>
                    <div className="sessaTxt1">
                      <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Upcoming  Sessions
                      </div>
                      <div className="sessaTxt3">0</div>
                    </div>
                    <div className="sessaTxt1">
                      <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-red"></i> Cancelled Sessions
                      </div>
                      <div className="sessaTxt3">0</div>
                    </div>
                    <div className="sessaTxt1">
                      <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Unpaid Sessions
                      </div>
                      <div className="sessaTxt3">0</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}
export default OnlineTutoring