
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { useEffect, useRef } from "react";
import { useState } from "react";
// import { uploadAdapterPlugin } from "./UploadAdaptor";


const FormikMathTypeCKEditor=({ onChange, editorLoaded, name, value })=>{
    const [editorContent, setEditorContent] = useState("");

      // REDUX
      const dispatch = useDispatch();
      const {
      token,
      } = useSelector((state) => state.AuthReducer, shallowEqual);
    const editorRef = useRef();
    const { CKEditor, ClassicEditor } = editorRef.current || {};

    useEffect(() => {
      editorRef.current = {
        CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
        ClassicEditor: require("@ckeditor/ckeditor5-build-classic")
      };
    }, []);
  
     return (
     <>
     {editorLoaded ? (
          <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: {
              items: [
                "MathType",
                "ChemType",
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "imageUpload",
                "mediaEmbed",
                "insertTable",
                "blockQuote",
                "undo",
                "redo",
              ],
            },
          }}
          data={
            value && value
              ? value
              : "<p></p>"
          }
          name={name}
          onReady={(editor) => {
            editor?.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                "500px",
                editor.editing.view.document.getRoot()
              );
            });
            // uploadAdapterPlugin(editor,token);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            // setFieldValue("blog", data1);
            onChange(data);
          }}
        />
        ) : (
          <div>Editor loading</div>
        )}
     </>
     );
}
export  default FormikMathTypeCKEditor;