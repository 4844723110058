import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isInViewport } from 'src/utils/DomUtils';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'src/utils/ReactHooksUtil';

const CloseableMessageComponent = memo(({
    message,
    onClose,
    bringToViewPortWhenMessageChange,
}) => {
    const ref = useRef();
    useEffect(() => {
        if (bringToViewPortWhenMessageChange) {
            if (ref.current && !isInViewport(ref.current)) {
                ref.current.scrollIntoView();
            }
        }
    }, [
        bringToViewPortWhenMessageChange,
    ]);

    const location = useLocation();

    usePrevious({
        pathname: location.pathname,
    }, (prevState) => {
        if (prevState.pathname !== location.pathname) {
            onClose();
        }
    });

    return (
        <div
            ref={ref}
            className="section-content padding-y pb-0"
        >
            <div className="container ">
                <div
                    className="alert alert-warning mb-0 shadow"
                    role="alert"
                >
                    {message}
                    <button
                        onClick={onClose}
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        style={{float: 'right'}}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
    );
});

CloseableMessageComponent.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    message: PropTypes.any,
    onClose: PropTypes.func,
    bringToViewPortWhenMessageChange: PropTypes.bool,
};

CloseableMessageComponent.defaultProps = {
    message: null,
    onClose: null,
    bringToViewPortWhenMessageChange: false,
};

export default CloseableMessageComponent;
