import { REQUEST_STATUS } from "src/constants/CommonConstants";
import { SOS_ACTIONS } from "../actions/SOSActions";

const initialState = () => ({
    currentRecord:{
        userId:'',
        userName:'',
        email:'',
        phone:'',
        deviceType:'',
        country:'',
        gender:'',
        registrationDate:'',
        isSubscrbed:false
    },
    search: {
        data: [],
        groupCounter:{totalSOS:0, totalSOSByStudent:0,totalSOSByTutor:0},
        currentPage: 0,
        pages: 0,
        total: 0
    },
    searchReqStatus: null,
})

export default (state = initialState(), action) => {
    switch(action.type){
        //SEARCH
        case SOS_ACTIONS.SOS_SEARCH:{
            return {
                ...state,
                searchReqStatus:REQUEST_STATUS.PENDING
            }}
            case SOS_ACTIONS.SOS_SEARCH_SUCCESS: {
                return {
                    ...state,
                    
                    search: {
                        data:action.data.SosData,
                        groupCounter:{
                            totalSOS:action.data.totalSOS,
                            totalSOSByStudent:action.data.totalSOSByStudent,
                             totalSOSByTutor:action.data.totalSOSByTutor
                        },
                        total:action.data.total

                    },
                    searchReqStatus: REQUEST_STATUS.SUCCESS,
                };
            }
            case SOS_ACTIONS.SOS_SEARCH_ERROR: {
                return {
                    ...state,
                    searchReqStatus: REQUEST_STATUS.ERROR,
                };
            }
            default:
            return state;
    }
}