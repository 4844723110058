 export const WHATSAPP_ACTIONS = {
    WHATSAPP_SEARCH:'WHATSAPP_SEARCH',
    WHATSAPP_SEARCH_SUCCESS:'WHATSAPP_SEARCH_SUCCESS',
    WHATSAPP_SEARCH_ERROR:'WHATSAPP_SEARCH_ERROR',

    WHATSAPP_UPSERT:'WHATSAPP_UPSERT',
    WHATSAPP_UPSERT_SUCCESS: 'WHATSAPP_UPSERT_SUCCESS',
    WHATSAPP_UPSERT_ERROR: 'WHATSAPP_UPSERT_ERROR',

    WHATSAPP_EDIT: 'WHATSAPP_EDIT',

    WHATSAPP_RESET: 'WHATSAPP_RESET',
 }

 export const whatsappActionsSearchAction = (payload) => ({
    type: WHATSAPP_ACTIONS.WHATSAPP_SEARCH,
    ...payload,
});


 export const whatsappActionsUpsertAction = (payload) => ({
    type: WHATSAPP_ACTIONS.WHATSAPP_UPSERT,
    ...payload,
});

export const whatsappEditAction = (payload) => ({
    type: WHATSAPP_ACTIONS.WHATSAPP_EDIT,
    ...payload,
});

export const whatsappResetAction = () => ({
    type: WHATSAPP_ACTIONS.WHATSAPP_RESET,
});

export default {
    WHATSAPP_ACTIONS,
    whatsappActionsUpsertAction,
    whatsappActionsSearchAction,
    whatsappEditAction,
    whatsappResetAction
};