import { Field, ErrorMessage } from 'formik';
import React from 'react' 
import Select from 'react-select';
import { useState } from 'react';


const MultiSelect = ({
    field,
    form,
    options,
    isMulti = false,
    values,
    idx,
    placeholder = 'Select',
}) => {
    function onChange(option) {
        form.setFieldValue(
            field.name,
            option ? (option).map((item) => item.value) : [],
        );
      option =   option.map((item) => ({name:item.value,id:item.id}))
        values.SubjectExpertise[idx].sub_subject = option
    }

    const getValue = () => {
        if (options) {
            return isMulti 
                ? options.filter((option) => field?.value?.indexOf(option?.value) >= 0)
                : options.find((option) => option?.value === field?.value);
        } 
        else {
            return isMulti ? [] : ('');
        }
    };

    if (!isMulti) {
        return (
            <Select
            className="react-select-container"
            classNamePrefix="react-select"
            name={field.name}
            value={getValue()}
            onChange={onChange}
            options={options}
            isMulti={true}
            placeholder={placeholder}
        />
        )
    } else {
        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name={field.name}
                value={getValue()}
                onChange={onChange}
                options={options}
                isMulti={true}
                placeholder={placeholder}
            />
        )
    }
}

const ExpertiseDropdown = (props) => {
  return (
    <Field 
    name="multiSelectCustom"
    id="multiSelectCustom"
    placeholder="Select Sub Subject"
    isMulti={true}
    component={MultiSelect}
    {...props}
    />
  )
}

export default ExpertiseDropdown