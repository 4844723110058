import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AUTH_ACTIONS } from 'src/containers/auth-container/actions/AuthActions';
import { DASHBOARD_ACTIONS } from '../actions/DashboardActions';
import {
    registerStudentDefaultProps,    
    subscriptionPlanWiseDefaultProps,
    revenueOverviewDefaultProps,
    onboardedExpertTutorDefaultProps,
    countryWiseCountDefaultProps,
    genderWiseCountDefaultProps,
    SOSDefaultProps,    
    bookQnaDefaultProps,
    countryWiseUserDefaultProps,
    } from 'src/containers/dashboard-container/DashboardPropTypes';

const initialState = () => ({
    registerStudent: {...registerStudentDefaultProps},
    countryWiseUser:{...countryWiseUserDefaultProps},
    subscriptionPlanWise: {...subscriptionPlanWiseDefaultProps},
    revenueOverview: {...revenueOverviewDefaultProps},
    onboardedExpertTutor: {...onboardedExpertTutorDefaultProps},
    countryWiseCount: {...countryWiseCountDefaultProps},
    genderWiseCount: {...genderWiseCountDefaultProps},
    SOSSection: {...SOSDefaultProps},
    bookQnaSection: {...bookQnaDefaultProps},
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        // SEARCH
        case DASHBOARD_ACTIONS.DASHBOARD_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case DASHBOARD_ACTIONS.DASHBOARD_SEARCH_SUCCESS: {
            const extractData = {...action.data};
            console.log('extract data',extractData)
            const monthly = action?.data?.subscriptionPlanWise?.filter((item) => item._id === 'Monthly')
            const quarterly = action?.data?.subscriptionPlanWise?.filter((item) => item._id === 'Quarterly')
            const annually = action?.data?.subscriptionPlanWise?.filter((item) => item._id === 'Annualy')
            return {
                ...state,
                ...extractData,
                subscriptionPlanWise:[

                    monthly?.length ? monthly[0] : 
                    {
                        _id:'Monthly',
                        subscriptions:[
                            {
                                "status": "active",
                                "totalSubscriptions": 0,
                                "totalRevenue":0
                            },
                            {
                                "status": "cancelled",
                                "totalSubscriptions": 0,
                                "totalRevenue": 0
                            }
                        ],
                        totalSubscriptions:0,
                        totalRevenue:0
                    },
                    ,quarterly?.length ? quarterly[0] : 
                    {
                        _id:'Quarterly',
                        subscriptions:[    {
                            "status": "active",
                            "totalSubscriptions": 0,
                            "totalRevenue":0
                        },
                        {
                            "status": "cancelled",
                            "totalSubscriptions": 0,
                            "totalRevenue": 0
                        }],
                        totalSubscriptions:0,
                        totalRevenue:0
                    },
                    ,annually?.length ? annually[0] : 
                    {
                        _id:'Annually',
                        subscriptions:[    {
                            "status": "active",
                            "totalSubscriptions": 0,
                            "totalRevenue":0
                        },
                        {
                            "status": "cancelled",
                            "totalSubscriptions": 0,
                            "totalRevenue": 0
                        }],
                        totalSubscriptions:0,
                        totalRevenue:0
                    },

                ],
                // subscriptionPlanWise:{
                //     Monthly:monthly.length ? monthly[0] : 0,
                //     Quarterly:quarterly.length ? quarterly[0] : 0,
                //     Annually:annually.length ? annually[0] : 0
                // },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case DASHBOARD_ACTIONS.DASHBOARD_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        // RESET
        case DASHBOARD_ACTIONS.DASHBOARD_RESET: {
            return {
                ...state,
                // currentRecord: { ...DashboardDefaultProps },
            };
        }

        // LOGOUT
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...initialState(),
            };

        default:
            return state;
    }
};
