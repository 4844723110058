import SubjectDropDown from "src/business-components/SubjectDropDown";
import { assignExpert, getBackendExpert, getSubjects, getSubSubject } from 'src/api/Assignment';
import { useQuery } from "react-query";
import { useContext, useEffect, useState,useRef } from "react";

const InternalExpertModal=({selectedAssignment})=>{
   const [subject, setSubject] = useState();
   const [subjectIdVal, SetSubjectIdVal] = useState();
   const [subSubjectIdVal, setSubSubjectIdVal] = useState();
   const [formData, setFormData] = useState({});
   const [expertList,setExpertList]  = useState([]);
   const [assignAmt,setAssignmentAmt] = useState(0);

   const modalCloseRef = useRef(null);



   const {
      data: subjects,
      isLoading: subjectsIsLoading,
      error: subjectsError,
    } = useQuery(["subjects"], () => getSubjects(), { staleTime: Infinity }); //only called when session would be present
    const {
      data: subsubjects,
      isLoading: subsubjectsIsLoading,
      error: subsubjectsError,
    } = useQuery([subject], () => getSubSubject(subject), {
      staleTime: Infinity,
      enabled: !!subject,
    }); //only called when subject would be present

   const getSelectedSubject = (e) => {
      const subjectId =
        e.target.options[e.target.selectedIndex].dataset.subjectid;
      setFormData({
        ...formData,
        subject: e.target.value,
        subject_id: subjectId, 
      });
      setSubject(e.target.value);
      SetSubjectIdVal(subjectId);
    };
    const selectSubSubject = (e) => {
      const subSubjectId =
        e.target.options[e.target.selectedIndex].dataset.subsubjectid;
      setFormData({
        ...formData,
        sub_subject: e.target.value,
        sub_subject_id: subSubjectId,
      });
      setSubSubjectIdVal(subSubjectId)
    };

    const getExpertList =async({filter})=>
    {
       const experts =  await getBackendExpert(filter);
       setExpertList(experts.data.Experts);
    }

    useEffect(()=>{
      if(selectedAssignment?.subject_id?._id)
      {
         const subjectId = (subjectIdVal)?subjectIdVal:selectedAssignment.subject_id._id;
         const subSubjectId = (subSubjectIdVal)?subSubjectIdVal:selectedAssignment.sub_subject_id._id;
         const filter= {subjectId,subSubjectId};
         getExpertList({filter});
      }

    },[selectedAssignment,subjectIdVal,subSubjectIdVal])


    const assignBackenExpert=async(expertId)=>{
            const updatePayload = {tutor_id:expertId,assignAmt}
            const data = {id:selectedAssignment._id,updatePayload}
            const assigned = await assignExpert(data);
            if(assigned) modalCloseRef.current.click();
            
    }

    return (
   <div className="modal fade sman21-modal" id="assignaexpert-Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
               <div className="modal-header">
                  <h5 className="modal-title">Assign a Expert</h5>
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"  ref={modalCloseRef}><i className="mdi mdi-close"></i></button>
               </div>
               <div className="modal-body">
                  <div className="bgstrt">
                     <div className="">
                        <h3>Assignment  Experts</h3>
                     </div>
                     <div className="scscm21 scscm21-step3">
                        {/* <div className="">
                           <select className="form-select">
                              <option>Select Category</option>
                           </select>
                        </div> */}
                        <div className="">
                           {/* <SubjectDropDown name="subject"/> */}
                           <select
                           type="text"
                           className="form-select"
                           onChange={getSelectedSubject}
                           required
                           >
                           <option value="999">Select Subject</option>
                           {subjects &&
                           subjects.data.map((item, key) => {
                           return (
                           <option
                              value={item.subject}
                              key={key}
                              data-subjectid={item._id}
                           >
                               {item.subject}
                           </option>
                           );
                           })}
                           </select>
                        </div>
                        <div className="">
                        <select
                           type="text"
                           className="form-select"
                           onChange={selectSubSubject} 
                        >
                        <option>Sub Subject</option>
                        {subsubjects &&
                        // console.log('subs is',subsubjects.data.data)
                        subsubjects.data.data.map((item, key) => {
                              return (
                                 <option
                                    value={item.sub_subject}
                                    key={key}
                                    data-subsubjectid={item._id}
                                 >
                                    {item.sub_subject}
                                 </option>
                              );
                        })
                        }
                        </select>
                        </div>
                     </div>
                     {/* <div className="searchBar">
                        <input type="search" placeholder="" className="form-control"/>
                        <button><i className="mdi mdi-magnify"></i></button>
                     </div> */}

                     {
                        expertList.map((tutor,index)=>{

                           const subSubjects = tutor?.education[0]?.subsubject.map((subSubject,index)=>{
                              return subSubject.name;
                           })
                           const subject = tutor?.education[0]?.subject.map((Subject,index)=>{
                              return Subject.name;
                           })
                            
                           return (
                                       <div className="bgNew-white snpn-1 bsupdhad">
                                 <div className="d-flex align-items-start expertNew4 expertNewXvz">
                                    <div className="expertFild expertFildbgn1">
                                       <div className="d-flex align-items-center">
                                          <div className="d-flex justify-content-between align-items-start">
                                             <div className="expertFildImg">
                                                <span className="img"><img src="/assets/images/super-admin/man-img.png" className="img-fluid" alt=""/></span>
                                                <div className="expert_Id">
                                                   Expert ID : {tutor.id}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="expertFildSub">
                                             <div className="d-flex justify-content-between align-items-start">
                                                <div className="expertFildsbu1">
                                                   {/* <p>PHD in Math</p> */}
                                                   <h2>{tutor.name}</h2>
                                                </div>
                                             </div>
                                             <p><span>Expertise:</span>{subSubjects?.toString()}</p>
                                             <ul>
                                                <li><span><img src="/assets/images/super-admin/language-icon.svg" className="img-fluid" alt=""/></span>
                                                  {subject?.toString()}
                                                </li>
                                                <li><span><img src="/assets/images/super-admin/location-icon.svg" className="img-fluid" alt=""/></span>Living in {tutor.address}</li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="yuhdg321c">
                                       <div className="yearofexbg1">
                                          <div className="d-flex align-items-center yearofexbg">
                                             <span className="icon"><img src="/assets/images/super-admin/yearofex.svg" className="img-fluid" alt=""/></span>
                                             <div className="yearofextext sgbboldc">
                                                <h4><span>{tutor.Tenure} months+</span>  Tenure with us</h4>
                                             </div>
                                          </div>
                                          {/* <div className="d-flex align-items-center yearofexbg">
                                             <span className="icon"><img src="/assets/images/super-admin/assignmt-icon.svg" className="img-fluid" alt=""/></span>
                                             <div className="yearofextext">
                                                <h4><span>1353</span> assignments </h4>
                                                <p>Deliver with in deadline</p>
                                             </div>
                                          </div> */}
                                       </div>
                                       {/* <div className="d-flex justify-content-between align-items-center cwopage-1">
                                          Current Working  on Projects  <span>4</span>
                                       </div> */}
                                    </div>
                                 </div>
                                 <div className="sndbv21 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-start maiorNumbr">
                                       <div className="maior1">
                                       {tutor.Email}  
                                          <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                       </div>
                                       <div className="maior2">
                                          <div className="">{tutor.Contact}</div>
                                          <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center yuhdg321c">
                                       <div className="text-center intamunctmbg">
                                          <label>Enter Amount </label>
                                          <div className="input-group mb-3 intamunctm">
                                             <span className="input-group-text">$</span>
                                             <input type="text" className="form-control" name="amount" placeholder="55" aria-label="" onChange={(e)=>setAssignmentAmt(e.target.value)}/>
                                          </div>
                                       </div>
                                       <div className="">
                                          <button className="btnbluen1" onClick={()=>{assignBackenExpert(tutor._id)}}>Assign </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           )
                        })
                     } 
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default InternalExpertModal;