import React from "react";
import Pagination from "src/components/Pagination";
import moment from "moment";
const ErrorLog=(props)=>{
   const {
      search:{data},
      search,
      activeTab,
      setPageNo,
      pageNo
     } = props;

     const downloadFile = (flag, item) => {
      if (flag) {
        let data = [];
        if (item && item.invalidQuestions.length > 0) {
          item.invalidQuestions.map((que, index) => {
            data.push(["Issue in Question", que]);
          });
        }
        if (item && item.subjectIssue.length > 0) {
          item.subjectIssue.map((que, index) => {
            data.push(["Issue in Subject or Subsubject", que]);
          });
        }
      //   data;
        let csvContent =
          "data:text/csv;charset=utf-8," +
          data.map((e) => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "BulkQuestionIssues.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        link.remove();
      }
    };


   return (
    <div className={`tab-pane fade ${(activeTab=="errorLog")?"show active":""}`} id="pills-pending" role="tabpanel" aria-labelledby="pills-pending-tab">
    <div className="card-body ps-0 pe-0">
       <div className="table-responsive">
          <table className="table table-center datatablefnts datatable">
             <thead>
                  <tr>
                     <th>Created Date</th>
                     <th>File Name</th>
                     <th>Saved Count</th>
                     <th>Status</th>
                  </tr>
             </thead>
             <tbody> 
              {data.map((item, index) => {
                let status = "";
                let status2 = "";
                let msg = "";
                status = item?.invalidQuestions?.length;
                status2 = item?.subjectIssue?.length;
                if (status > 0) {
                  msg = status + " invalid Questions, ";
                }
                if (status2 > 0) {
                  msg = status2 + " Questions have invalid Subjects";
                }
                return (
                  <tr key={index}>
                    <td>{moment(item.created_at).format("YYYY-DD-MM")}</td>
                    <td>{item.fileName}</td>
                    <td>{item.count}</td>
                    <td
                      style={{
                        color: `${msg != "" ? "red" : "green"}`,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        downloadFile(msg != "", item);
                      }}
                    >
                      {msg != "" ? msg : "Done"}
                    </td>
                  </tr>
                );
              })}
             </tbody>
          </table>
       </div>
       {data && data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>} 
    </div>
 </div>
   )
}
export default ErrorLog;