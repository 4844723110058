import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const QuestionPropTypes = PropTypes.shape({
    _id: PropTypes.string,
    subjectId: PropTypes.string,
    subSubjectId: PropTypes.string,
    question: PropTypes.string,
    completeanswer: PropTypes.string,
    link: PropTypes.string,
    scoDetails: PropTypes.any,
});

export const QuestionDefaultProps = {
    subjectId: '',
    subSubjectId: '',
    question: '',
    completeanswer: '', 
    scoDetails:{
        metaTitle: '',
        metaKeywords: '',
        metaDescription: '',
        isIndex: false,
        isApprovedSCO: false
    },
    _id: undefined,
};

export const QuestionYupSchema = Yup.object().shape({
    // subjectId: Yup.string().required('Required'),
    // subSubjectId: Yup.string().required('Required'),
    
});

export default {
    QuestionDefaultProps,
    QuestionPropTypes,
    QuestionYupSchema,
};
