/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest, getRequest, putRequest } from 'src/utils/RequestUtil';


export const expertSearchAPI = function (data) {
    const url = `${config.baseUrl}dashboard/experts/all/${data.expertType}`;
    return postRequest({
        url,
        data,
    });
};

export const expertEditSearchAPI = function (data) {
    const url = `${config.baseUrl}dashboard/experts/all/${data.expertType}`;
    return postRequest({
        url,
        data,
    });
};

export const expertCountAPI = function (data) {
    const url = `${config.baseUrl}dashboard/experts/count-all`;
    return getRequest({
        url,
        data,
    });
};

export const expertCreateAPI = function (data,param) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) { 
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}dashboard/expert/save/${param}`;
    return postRequest({
        url,
        data: fd,                                                                                   
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const pendingExpertUpdateApi = function (id, updateType,expertType) {
    console.log('payload is',updateType,expertType)
    const url = `${config.baseUrl}dashboard/expert/update/${id}`;
    return putRequest({
        url,
        data: {updateType,expertType},
        expertType:expertType
    });
};

export const removeExpertApi = function (id, expertType) {
    console.log('payload is',id,expertType)
    // const url = `${config.baseUrl}dashboard/expert/delete/${id}`;
    // return postRequest({
    //     url,
    //     data: {expertType},
    //     expertType:expertType
    // });
};

export const expertUpdateAPI = function ({
    id,
    updateType
}) {
    console.log('update api data is',id,updateType)
   const url = `${config.baseUrl}dashboard/expert/update/${id}`;
    return putRequest({
        url,
        data: {updateType,expertType:'tutor'},
        expertType:'tutor'
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // },
    });
};