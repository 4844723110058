import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AUTH_ACTIONS } from 'src/containers/auth-container/actions/AuthActions';
import { BookDefaultProps } from 'src/containers/book-container/BookPropTypes';
import { BOOK_ACTIONS } from '../actions/BookActions';

const initialState = () => ({
    currentRecord: {...BookDefaultProps},
    BookCount:
    {
    authoringPending:0,
    authoringRejected:0,
    authoringApproved:0,
    scoPending:0,
    scoRejected:0,
    scoApproved:0
   },
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        // SEARCH
        case BOOK_ACTIONS.BOOK_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case BOOK_ACTIONS.BOOK_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case BOOK_ACTIONS.BOOK_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

          // QUESTION SEARCH
          case BOOK_ACTIONS.CHAPTER_LOG_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
          }

          case BOOK_ACTIONS.CHAPTER_LOG_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case BOOK_ACTIONS.CHAPTER_LOG_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        //BOOK LOG SEARCH
        case BOOK_ACTIONS.BOOK_LOG_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
          }

          case BOOK_ACTIONS.BOOK_LOG_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case BOOK_ACTIONS.BOOK_LOG_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // UPSERT
        case BOOK_ACTIONS.BOOK_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case BOOK_ACTIONS.BOOK_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case BOOK_ACTIONS.BOOK_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // ADD FAQ

        case BOOK_ACTIONS.ADD_FAQ:{
            return {
                ...state,
                currentRecord:action.data,
                upsertReqStatus:REQUEST_STATUS.SUCCESS
            }
        }

        // RESET
        case BOOK_ACTIONS.BOOK_RESET: {
            return {
                ...state,
                currentRecord: { ...BookDefaultProps },
            };
        }

        // LOGOUT
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...initialState(),
            };

        default:
            return state;
    }
};
