const MiscellaneousQuestion=()=>{
    return (
            <>
              <div className="content-wrapper">
                  <div className="page-header">
                     <div className="">
                        <h4 className="page-title"> Others Question
                        </h4>
                        <div className="top-breadcrumb">
                           <nav aria-label="breadcrumb">
                              <ol className="breadcrumb ps-0">
                                 <li className="breadcrumb-item"><a href="all-subject.php">Books</a></li>
                                 <li className="breadcrumb-item"><a href="all-questions.php">Live Books</a></li>
                                 <li className="breadcrumb-item active" aria-current="page">Edit Book</li>
                              </ol>
                           </nav>
                        </div>
                     </div>
                     <div className="">
                        <button className="btnbluen1 plwhit d-flex align-items-center" onClick="location.href='#'"><i className="mdi mdi-close-circle mdi-18px me-1 text-blue"></i> Solution report</button>
                     </div>
                  </div>
                  <div className="row">
					  <div className="col-md-12">
					  <div className="bgStpte1">
					  <div className="card">
						  <div className="card-body">
						  <div className="txtUllistbg">
						  <div className="txtUlauQ">
							  <h2>458455</h2>
							  <p><i className="mdi mdi-help-circle "></i> All Upload Questions</p>
							  </div>
							  <div className="txtUllist">
							  <ul>
								  <li><div className=""><i className="mdi mdi-help-circle"></i> Text Book Solutions</div> <span>25978</span></li>
								  <li><div className=""><i className="mdi mdi-help-circle"></i> Question And Answer</div> <span>45528</span></li>
								  </ul>
							  </div>
						  </div>
						  </div> 
						  </div>
						  <div className="card">
						  <div className="card-body">
						  <div className="txtUllistbg">
						  <div className="txtUlauQ2">
							  <h2>458455</h2>
							  <p>Total ISBN</p>
							  </div>
							  <div className="txtUllist2">
							  <ul>
								  <li><div className="">25848</div> <span>Total ISBN13</span></li>
								  <li><div className="">565654</div> <span>Total ISBN10</span></li>
								  </ul>
							  </div>
						  </div>
						  </div> 
						  </div>
					  </div>
					  
                     <div className="col-md-12">
						 <div className="card shadow mtcustom3">
							 <div className="card-body">
                        <div className="tabsnrstl1 tabsnrstl-g2">
							 <div className="col-md-12">
                              <div className="scscm21 scscm21-step2 mtcustom4">
                                 <div className="searchBar">
                                    <input type="search" placeholder="Search Book by ISBN 13" className="form-control"/>
                                    <button><i className="mdi mdi-magnify"></i></button>
                                 </div>
                                 <div className="d-flex align-items-center justify-content-end datewfiltr">
                                    <div className="me-2"><button className="flitrbtn" data-bs-toggle="modal" data-bs-target="#filter-Modal"><i className="mdi mdi-filter-outline mdi-24px"></i></button></div>

                                    <div className="ms-2"><button className="flitrbtn active"><i className="mdi mdi-filter-variant mdi-24px"></i></button></div>
                                 </div>
                              </div>
                           </div>
                           <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                              <li className="nav-item" role="presentation">
                                 <div className="nav-link active" id="pills-tab1" data-bs-toggle="pill" data-bs-target="#pills-1" type="button" role="tab" aria-controls="pills-1" aria-selected="true">
                                    <div className="stretch-card asigntabnew1">
                                       <div className="card-img-holder">
                                          <div className="card-body text-center"> 
                                             <h2 className="mt-0">Text Book Solutions </h2>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <div className="nav-link" id="pills-tab2" data-bs-toggle="pill" data-bs-target="#pills-2" type="button" role="tab" aria-controls="pills-2" aria-selected="false">
                                    <div className="stretch-card asigntabnew1">
                                       <div className="card-img-holder">
                                          <div className="card-body text-center"> 
                                             <h2 className="mt-0">Question And Answer </h2>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li> 
                           </ul> 
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                           <div className="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-tab1">
                              <div className="col-12 grid-margin  mt-4">  
                                       <div className="">
                                            <div className="table-responsive">
                                              <table className="table table-center datatablefnts datatable">
                                             <thead>
                                                <tr>
                                                   <th>S. No   </th>
                                                   <th>Uploaded Date</th>  
                                                   <th>ISBN   </th>
                                                   <th>ISBN Type </th> 
                                                   <th>Book Name  </th> 	
                                                   <th>Edition  </th>
                                                   <th>Subject </th> 	
                                                   <th>Sub Subject </th> 	
                                                   <th>Chield Subjects </th> 
                                                   <th> Author  </th>
                                                   <th> Total Questions </th>
                                                   <th> Action</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td><span className="sNo">1</span> </td>
                                                   <td>  15 October 2022   </td>
                                                   <td>  9781337395281   </td>
                                                   <td>  13     </td>
                                                   <td> <div className="nambtntx"><a href="#">MindTap Finance, 2 terms (12 m</a></div>     </td>
                                                   <td> 15th Edition      </td>
                                                   <td> business     </td>
                                                   <td> Accounting       </td>
                                                   <td> Accounting Equation          </td>
                                                   <td> Jimmy         </td>
                                                   <td> 3224       </td> 
													<td> 
														<div className="ocial-Btn">
														<div className="dropdown">
															<button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																<i className="mdi mdi-dots-vertical"></i>
															</button>
															<ul className="dropdown-menu dropdown-menu-end">
																<li><a className="dropdown-item" href="#">Edit</a></li>
																<li><a className="dropdown-item" href="#">Remove</a></li>
															</ul>
														</div>
														</div>
													</td>
												 </tr>
												 <tr>
                                                   <td><span className="sNo">2</span> </td>
                                                   <td>  15 October 2022   </td>
                                                   <td>  9781337395281   </td>
                                                   <td>  13     </td>
                                                   <td> <div className="nambtntx"><a href="#">MindTap Finance, 2 terms (12 m</a></div>     </td>
                                                   <td> 15th Edition      </td>
                                                   <td> business     </td>
                                                   <td> Accounting       </td>
                                                   <td> Accounting Equation          </td>
                                                   <td> Jimmy         </td>
                                                   <td> 3224       </td> 
													<td> 
														<div className="ocial-Btn">
														<div className="dropdown">
															<button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																<i className="mdi mdi-dots-vertical"></i>
															</button>
															<ul className="dropdown-menu dropdown-menu-end">
																<li><a className="dropdown-item" href="#">Edit</a></li>
																<li><a className="dropdown-item" href="#">Remove</a></li>
															</ul>
														</div>
														</div>
													</td>
												 </tr>
												 <tr>
                                                   <td><span className="sNo">3</span> </td>
                                                   <td>  15 October 2022   </td>
                                                   <td>  9781337395281   </td>
                                                   <td>  13     </td>
                                                   <td> <div className="nambtntx"><a href="#">MindTap Finance, 2 terms (12 m</a></div>     </td>
                                                   <td> 15th Edition      </td>
                                                   <td> business     </td>
                                                   <td> Accounting       </td>
                                                   <td> Accounting Equation          </td>
                                                   <td> Jimmy         </td>
                                                   <td> 3224       </td> 
													<td> 
														<div className="ocial-Btn">
														<div className="dropdown">
															<button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																<i className="mdi mdi-dots-vertical"></i>
															</button>
															<ul className="dropdown-menu dropdown-menu-end">
																<li><a className="dropdown-item" href="#">Edit</a></li>
																<li><a className="dropdown-item" href="#">Remove</a></li>
															</ul>
														</div>
														</div>
													</td>
												 </tr>
                                                 
                                                 
                                                 
                                             </tbody>
                                          </table>
                                          </div>
                                       </div> 
                              </div> 
                           </div>
                           <div className="tab-pane fade" id="pills-2" role="tabpanel" aria-labelledby="pills-tab2"> 
							    <div className="col-12 grid-margin  mt-4">  
                                     
									<div className="">
                                            <div className="table-responsive">
                                              <table className="table table-center datatablefnts datatable">
                                             <thead>
                                                <tr>
                                                   <th>S. No   </th>
                                                   <th>Uploaded Date</th>   
                                                   <th>ISBN Type </th>   
                                                   <th>Subject </th> 	
                                                   <th>Sub Subject </th> 	
                                                   <th>Chield Subjects </th> 
                                                   <th> Author  </th>
                                                   <th> Total Questions </th>
                                                   <th> Action</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td><span className="sNo">1</span> </td>
                                                   <td>  15 October 2022   </td> 
                                                   <td>  13     </td>  
                                                   <td> business     </td>
                                                   <td> Accounting       </td>
                                                   <td> Accounting Equation          </td>
                                                   <td> Jimmy         </td>
                                                   <td> 3224       </td> 
													<td> 
														<div className="ocial-Btn">
														<div className="dropdown">
															<button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																<i className="mdi mdi-dots-vertical"></i>
															</button>
															<ul className="dropdown-menu dropdown-menu-end">
																<li><a className="dropdown-item" href="#">Edit</a></li>
																<li><a className="dropdown-item" href="#">Remove</a></li>
															</ul>
														</div>
														</div>
													</td>
												 </tr>
												 <tr>
                                                   <td><span className="sNo">2</span> </td>
                                                   <td>  15 October 2022   </td> 
                                                   <td>  13     </td>  
                                                   <td> business     </td>
                                                   <td> Accounting       </td>
                                                   <td> Accounting Equation          </td>
                                                   <td> Jimmy         </td>
                                                   <td> 3224       </td> 
													<td> 
														<div className="ocial-Btn">
														<div className="dropdown">
															<button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																<i className="mdi mdi-dots-vertical"></i>
															</button>
															<ul className="dropdown-menu dropdown-menu-end">
																<li><a className="dropdown-item" href="#">Edit</a></li>
																<li><a className="dropdown-item" href="#">Remove</a></li>
															</ul>
														</div>
														</div>
													</td>
												 </tr>
												 <tr>
                                                   <td><span className="sNo">3</span> </td>
                                                   <td>  15 October 2022   </td> 
                                                   <td>  13     </td>  
                                                   <td> business     </td>
                                                   <td> Accounting       </td>
                                                   <td> Accounting Equation          </td>
                                                   <td> Jimmy         </td>
                                                   <td> 3224       </td> 
													<td> 
														<div className="ocial-Btn">
														<div className="dropdown">
															<button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																<i className="mdi mdi-dots-vertical"></i>
															</button>
															<ul className="dropdown-menu dropdown-menu-end">
																<li><a className="dropdown-item" href="#">Edit</a></li>
																<li><a className="dropdown-item" href="#">Remove</a></li>
															</ul>
														</div>
														</div>
													</td>
												 </tr> 
                                             </tbody>
                                          </table>
                                          </div>
                                       </div> 
                              </div>
                           </div> 
                        </div>
                     </div>
                     </div>
                     </div>
                  </div>
               </div> 
            </div>
            </>
    )
}

export default MiscellaneousQuestion;