import React, {useEffect, useState} from 'react'
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput';
import FormikFileDirect from 'src/components/formik/FormikFileDirect';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import FormikTextArea from 'src/components/formik/FormikTextArea';
import { whatsappResetAction, whatsappActionsUpsertAction } from './actions/WhatsappActions';


const Render = (props) => {
    const {
        initialValues,
        setFieldValue,
        values,
        // errors,
        // touched,
        onSubmit,
        isSubmitting,
        onSearch,
        onClear,
        resetForm,
    } = props;

  const { userId } = useSelector((state) => state.AuthReducer) 
useEffect(() =>{
    if(userId !== undefined && userId !== ''){
        values.createdBy = userId
    }
},[values])

const handelFile=async(e)=>{
    const file = e.target.files[0];
    // const img64 =await convertBase64(file)
    // setImagePre(img64)
    values.csvFile=file
  }
  const handelImagePreview=async(e)=>{
    const file = e.target.files[0]; 
    values.image=file
  }

    return (
        <Form>
                            <div className="row"> 
                                    <div className="col-md-2 form-group">
                                       <label> Attachment </label>
                                        <FormikFileDirect 
                                            type="file" 
                                            name="csvFile"
                                            accept="csv/*"
                                            className="form-control  enchilada"
                                            onChange={(e)=>{handelFile(e)}} 
                                            // value={values.csvFile}
                                        />
                                    </div>
                                    <div className="col-md-2 form-group">
                                       <label> Image </label>
                                        <FormikFileDirect 
                                            type="file" 
                                            name="image"
                                            accept="image/jpeg, image/png"
                                            className="form-control  enchilada"
                                            onChange={(e)=>{handelImagePreview(e)}} 
                                            // value={values.csvFile}
                                        />
                                    </div>
                                    <div className="col-md-8 form-group">
                                    <label>SMS Template</label>
                                   <FormikTextArea 
                                    name="message"
                                    placeholder="Enter your sms template"
                                    rows="20"
                                    className="form-control"
                                    value={values.message}
                                />
                                    </div> 
                                 </div>
                        <div className="text-center"> <button type="submit" className="btn btn-gradient-primary me-2 mb-5" onClick={() => onSubmit(values)}>Post</button></div>
                             
        </Form>
    )
}

const SenderForm=({setPostedAnnouncement,postedAnnouncement})=>{

    const dispatch = useDispatch();
    // const history = useHistory();
    
const {currentRecord} = useSelector((state) => state.AnnouncementReducer)
    
    const onSubmit = useCallback(( 
       values,
       formikUtils,
    ) => {
       dispatch(whatsappActionsUpsertAction(values));
       setPostedAnnouncement((prev) => !prev)
    }, [
       dispatch
    ]);
    
    
    const onSearch = () => {
        //
    }

    const onClear = () => {
        //
    }

    // const onSearch = useCallback(() => {
    //    history.push(RoutingUtil.blogSearch());
    // }, [
    //    history,
    // ]);
    // const onClear = useCallback(() => {
    //     dispatch(blogResetAction());
    // }, [dispatch]);
    
    
         return(
                <Formik
                    onSubmit={onSubmit}
                    onClear={onClear}
                    onSearch={onSearch}
                    initialValues={currentRecord}
                    // validationSchema={BlogYupSchema}
                    enableReinitialize
                > 
                    {(formikProps) => (
                        <Render
                            onClear={onClear}
                            onSearch={onSearch}
                            onSubmit={onSubmit}
                            {...formikProps}
                        />
                    )}
                </Formik>
         )
    }
    export default SenderForm