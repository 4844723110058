import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import './MainContainer.scss';
import { Route, Switch, useLocation } from 'react-router-dom';
import WelcomeComponent from 'src/components/welcome-page/WelcomeComponent';
import DashboardContainer from '../dashboard-container';
import GoogleAnalyticsContainer from '../google-analytics-container';
import HeaderContainer from '../header-container/HeaderContainer';
import NavigationContainer from '../navigation-container/NavigationContainer';
import FooterComponent from 'src/components/FooterComponent';

import ExpertContainer from "src/containers/expert-container"
import AssginmentContainer from "src/containers/assignment-request-container"
import SubscriptionContainer from '../subscription-container'
import StudentContainer from '../student-container'
import TutorContainer from '../tutor-container'
import SessionContainer from '../session-container'
import SOSContainer from '../SOS-container'
import AnnouncementContainer from '../announcement-container'
import WhatsappContainer from '../whatsapp-container'
import BlogContainer from '../blog-container'
import BookContainer from '../book-container'
import EnquiryContainer from '../enquiry-container'
import QuestionContainer from '../question-container'
import PaymentAndInvoice from '../payments-invoices-container'
import RatingAndReview from '../rating-reviews-container'
import CreateExpertForm from '../expert-container/CreateExpertForm';
import CreateBackendExpertForm from '../expert-container/CreateBackendExpertForm';
import CreateOnlineTutorForm from '../tutor-container/CreateOnlineTutorForm';
import MasterContainer from '../master-container/index';
import FaqContainer from '../faq-container/index';
import CreateFaqCategory from '../faq-container/CreateFaq';
import RoleModuleContainer from '../role-module-container/index';


function MainContainer() {
    const location = useLocation();

    const [
        sideBarState,
        setSideBarState,
    ] = useState(false);

    const closeSideBar = useCallback(() => {
        setSideBarState(false);
    }, [
        setSideBarState,
    ]);

    const openSideBar = useCallback(() => {
        setSideBarState(true);
    }, [
        setSideBarState,
    ]);

    // close side bar on route change
    useEffect(() => {
        closeSideBar();
    }, [
        location,
        closeSideBar,
    ]);

    return (
        <>
          <div className="container-scroller">
         {/* <?php include 'include/header-nav.php'; ?> */}
         <HeaderContainer/>
         <div className="container-fluid page-body-wrapper">
            <NavigationContainer
            closeSideBar={closeSideBar}
            openSideBar={openSideBar}
            />
            <div className="main-panel">
            <Switch>
                    <Route path="/" exact  component={DashboardContainer}/>
                    <Route path="/whatsapp"  component={WhatsappContainer}/>
                    <Route path="/google-analytics"  component={GoogleAnalyticsContainer}/>
                    <Route path="/subscriptions"  component={SubscriptionContainer}/>
                    <Route path="/students"  component={StudentContainer}/>
                    <Route path="/tutors"  component={TutorContainer}/>
                    <Route path="/sessions"  component={SessionContainer}/>
                    <Route path="/sos"  component={SOSContainer}/>
                    <Route path="/announcements"  component={AnnouncementContainer}/>
                    <Route path="/blogs"  component={BlogContainer}/>
                    <Route path="/payments-invoice"  component={PaymentAndInvoice}/>
                    <Route path="/rating-reviews"  component={RatingAndReview}/> 
                    <Route path="/experts"  component={ExpertContainer}/>
                    <Route path="/create-expert"  component={CreateExpertForm}/>
                    <Route path="/create-backend-expert"  component={CreateBackendExpertForm}/>
                    <Route path='/create-online-tutor' component={CreateOnlineTutorForm}/>
                    <Route path="/assignment-request"  component={AssginmentContainer}/>
                    <Route path="/books"  component={BookContainer}/>
                    <Route path="/enquiries"  component={EnquiryContainer}/>
                    <Route path="/questions"  component={QuestionContainer}/>
                    <Route path="/masters"  component={MasterContainer}/>
                    <Route path = "/faq" component={FaqContainer} />
                    <Route path = '/role-modules' component={RoleModuleContainer} />
                    {/* <Route path = "/manage-faq-category" component={CreateFaqCategory} /> */}
            </Switch>
               <FooterComponent/>
            </div>
         </div>
      </div>
        </>
    );
}

export default MainContainer;
