import React from 'react'
import { whatsappEditAction } from './actions/WhatsappActions'
import Pagination from 'src/components/Pagination'

const Categories = [{text:'Delevered',value:true},{text:'Not Delevered',value:false}]
const SenderList = ({tableData,pageNo,setPageNo,total,handleFilter}) => {
    return (
        <>
               <div className="card shadow">
                                                      <div className="card-body"> 
                                                         <div className="psttabblog"> 
                                                            <h4 className="card-title">All SMS Log</h4> 
                                                         <div className="psttabblog1">
                                                            <select className="form-select"
                                                            onChange={(e) => {
                                                                  handleFilter({'isNumberOnWhatsapp':e.target.value})   
                                                                  setPageNo(0)
                                                            }}
                                                            >
                                                            <option value=''>Select Category </option>
                                                            {
                                                               Categories.map((categs,index) => {
                                                                  return (
                                                                     <option value={categs.value} key={index}>{categs.text}</option>
                                                                  )
                                                               })
                                                            }
                                                            </select>  
                                                          
					  </div>
				 </div>
			
				 <div className="postablnewx"> 
                              <div className="table-responsive">
								   <table className="table datatablefnts datatable">
                                    <thead>
                                       <tr>
                                          <th>S.no </th>
                                          <th>Name </th>
                                          <th>Mobile No. </th>
                                          <th>Valid No. </th>  
                                          {/* <th>Template </th>    */}
                                          <th>Send Timestamp</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData.map((data,idx)=>{
                                                return (
                                                <tr key={data._id}>
                                                      <td><span className="sNo">{idx+1 + pageNo*10}</span> </td> 
                                                      <td>{data.name}</td>
                                                      <td>{data.mobile}</td>
                                                      <td>{(data.isNumberOnWhatsapp)?'Yes':'No'}</td>   
                                                      {/* <td> <div className="">{data.description.substring(20)}...</div> </td>   */}
                                                      <td>{data.create_at}</td>
                                                 </tr>
                                                )
                                            })
                                        }
                                       
                                    </tbody>
                                 </table> 
                              </div>
                              <Pagination setPageNo={setPageNo} pageNo={pageNo} total={total}/>
							   </div>
                     </div>
                     </div>
        </>
    )
}

export default SenderList