import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik'; 
import { useEffect } from 'react';


const SubComponent = ({
    field,
    form,
    items,
    name,
    onChange,
    placeholder,
    idx,
    values,
    ...rest
}) => {



    return (
        <select
        {...field}
        {...rest}
        value={field.value === undefined || field.value === null ? '' : field.value}
        onChange={(e) => {
            form.setFieldValue(field.name, e.target.value);
            // eslint-disable-next-line no-unused-expressions
            onChange && onChange(e.target.value);
            values.SubjectExpertise[idx].name = e.target.value
        }}
       style={{height:'60%'}}
    >
        <option
            key="-1"
            value=""
            label={`${(placeholder)?placeholder:'Choose...'}`}
        >
            Choose...
        </option>
        {
            Object.values(items).map(({
                subject,
                _id
            }) => (
                <option
                    key={_id}
                    value={subject}
                    label={subject}
                >
                    {subject}
                </option>
            ))
        }
    </select>
    )
}



const SingleSubjectDropdown = (props) => {
    return (
        <Field
        {...props}
        component={SubComponent}
    />
    )
}

export default SingleSubjectDropdown