import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { tutorEditAction } from './actions/TutorActions';
import { expertUpdateAPI } from 'src/api/ExpertApi';
import Pagination from 'src/components/Pagination';

const TutorList = ({tabledata,pageNo,setPageNo,total}) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [tutorStatus, setTutorStatus] = useState(true)
    const editClick = (record) => {
        if (tutorEditAction) {
           dispatch(tutorEditAction( {where:{_id:record._id},
              expertType:'tutor'}));
        }
        history.push('/create-online-tutor')
    }

    const handleStatusChange = async(id,e) => {
const res = await expertUpdateAPI({id:id,updateType:{status:e}})
if(res){
   history.push('/tutors')
}
    }


    return (
        <>
         <div className="table-responsive">
                                          <table className="table table-center datatablefnts datatable">
                                             <thead>
                                                <tr>

                                                   <th>S. No   </th>
                                                   <th>Tutor Id</th>
                                                   <th>User Name  </th>
                                                   <th>Email ID </th>
                                                   <th>Phone Number </th>
                                                   <th>Master Subject </th>
                                                   <th>Expert Areas  </th>
                                                   <th>Gender</th>
                                                   <th>Country </th>
                                                   <th> Onboarding Date</th>
                                                   {/* <th>Performance Chart </th> */}
                                                   <th>Total Sessions Taken </th>
                                                   <th>Total Sessions Rejected</th>
                                                   <th>Total Sessions Cancelled </th>
                                                   <th>Edit</th>
                                                   <th>Action</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {
                                                    tabledata.map((data,idx) => {
                                                        return(
                                                            <tr key={idx}>
                                                            <td><span className="sNo">{idx+1}</span> </td>
                                                            <td>{data?.id}    </td>
                                                            <td>
                                                               <div className="nambtntx">{data.name}</div>
                                                            </td>
                                                            <td>
                                                               {data.email}
                                                               <div className="messagebn1">
                                                               <a href={`mailto:${data.email}`} target='_blank'>
                                                                <button><i className="mdi mdi-email-outline"></i> Message</button>
                                                                </a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                               <div className="">{data.Contact}</div>
                                                               <div className="callphnwhaaap">
                                                               <a href={`https://api.whatsapp.com/send?phone=${data.Contact}`} title="Share on whatsapp" target="_blank">        
                                                                <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> </a>
                                                                <a href={`https://api.whatsapp.com/send?phone=${data.Contact}`} title="Share on whatsapp" target="_blank">        
                                                                <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> 
                                                                </a>
                                                                </div>
                                                            </td>
                                                            {
                                                                data?.SubjectExpertise?.length > 0 && data.SubjectExpertise.map((item) => {
                                                                    return (
                                                                        <td key={item.name}>
                                                                            {item.name}
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                            <td className='content-limited'> 
                                                            {
                                                               
                                                               data?.SubjectExpertise?.map((sub, idx)=> {
                                                                  // sub?.sub_subject?.map((ssub)=> {
                                                                     
                                                                     return (
                                                                        <div className="bsvcx1" key={idx}>{sub.sub_subject.map((val, idx) =>{
                                                                           return(
                                                                              <React.Fragment key={idx}>
                                                                              {val.name} &nbsp;
                                                                              </React.Fragment>
                                                                           )
                                                                        })}</div>
                                                                     )
                                                               })
                                                            }
                                                            </td>
                                                            <td>{data.gender} </td>
                                                            <td>
                                                               <div className="countryc1 text-bold">{data.country} </div>
                                                            </td>
                                                            <td>{data.createdAt}  </td>
                                                            {/* <td>
                                                               <div className=""><button className="virenbtn1">View Chart</button></div>
                                                            </td> */}
                                                            <td>
                                                               <div className="text-bold">$0</div>
                                                            </td>
                                                            <td>
                                                               <div className="text-bold">$0</div>
                                                            </td>
                                                            <td>
                                                               <div className="text-bold">$0</div>
                                                            </td>
                                                            <td>
                                                                <button className="virenbtn1" 
                                                                onClick={()=>editClick(data, '/create-expert','expert','expert')}
                                                                >
                                                                Edit
                                                                </button>
                                                                </td>
                                                            <td>
                                                                <select onChange={(e) => handleStatusChange(data._id,e.target.value)}
                                                                defaultValue={data.status}
                                                               // value={data.status}
                                                               defaultChecked={data.status}
                                                                >
                                                                    <option value={true}>Active</option>
                                                                    <option value={false}>Inactive</option>
                                                                </select>
                                                            </td>
                                                         </tr>
                                                        )
                                                    })
                                                }
                                             </tbody>
                                          </table>
                                       </div>
                                       <Pagination 
                                       pageNo={pageNo}
                                       setPageNo={setPageNo}
                                       total={total}
                                       />
        </>
    )
}

export default TutorList