import React, { useState } from 'react'
import { countries } from "countries-list"
import { TableContents } from 'src/containers/student-container/TabsConfiguration'
import { useEffect } from 'react'
import { getDateFromTimestamp } from 'src/utils/CommonUtils'
import Pagination from './Pagination'
import { useSelector } from 'react-redux'

const genderArr = ['Male', 'Female', 'Others']
const subscriptionTypeArr = ['All', 'Monthly', 'Annual']
const isSubscribedArr = ['Yes', 'No']
const StudentContainerTabPane = ({ loading,activeSelectedTab, tableData, setFilter, handleFilterChange, pageNo, setPageNo, total,previousDayWebCount,previousDayAppCount }) => {
   const [gender, setGender] = useState('')
   const [country, setCountry] = useState('')
   const [deviceType, setDeviceType] = useState('')
   const [SubscriptionType, setSubscriptionType] = useState('')
   const [isSubscribed, setIsSubscribed] = useState('')
   const [refundStatus, setRefundStatus] = useState('')
   const [category, setCategory] = useState('')
   const [subject, setSubject] = useState('')
   const [tableEntries, setTableEntries] = useState([])
   const [appUser, setAppUser] = useState(0)
   const [textSearch, setTextSearch] = useState('')
   const { search } = useSelector((state) => state.StudentReducer)


   const handleInputTextSearch = (e) => {
      setTextSearch(e.target.value)
         handleFilterChange({Contact:e.target.value,Email:e.target.value})
   }

   const convertArrayOfObjectsToCSV = () => {
      const csvRows = [];

  // Get the headers from the first object
  const headers = ['Subscribe','_id','Name','Email','Contact','Gender','created_at', 'Country']

  // Push the headers as the first row
  console.log('array is',Object.keys(tableData[0]))
  csvRows.push(headers.join(','));

  // Iterate over the data and convert each object to a row
  for (const obj of tableData) {
    const values = headers.map(header => 
      {
         // console.log('header is',header,obj[header])
         if(header === '_id' || header === 'Name' || header === 'Email'|| header === 'Contact' || header==='Subscribe' ){
      return obj[header]}
      }
      );
    csvRows.push(values.join(','));
  }

  // Join all the rows with newline character
  return csvRows.join('\n');
   }

   function downloadCSV(data, filename) {
      const csvData = convertArrayOfObjectsToCSV(data);
    
      // Create a Blob object for the CSV data
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    
      // Create a temporary anchor element
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Set the URL for the download attribute
        const csvURL = URL.createObjectURL(blob);
        link.setAttribute('href', csvURL);
        link.setAttribute('download', 'student_overview');
    
        // Append the link to the body and click it programmatically
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    

   return (
      <>
      <button onClick={downloadCSV} className='btnbluen1 mt-2 mb-0'>Export to CSV</button>
         <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id={`pills-${activeSelectedTab.id}`} role="tabpanel" aria-labelledby={`pills-tab${activeSelectedTab.id}`}>
               <div className="col-12 grid-margin  mt-4">
                  <div className="card">
                     <div className="card-body ps-0 pe-0">
                        {/* <div className="dateinydt">
                           <div className="row">
                              <div className='col-md-12 mb-4'>
                                 <input type='text' value={textSearch} onChange={(e) => 
                                 
                                    handleInputTextSearch(e)} style={{width:'30%',float:'right'}} className='form-control' placeholder='Search by contact or email'/>
                              </div>
                              <div className="col-md-12">
                                 <div className="scscm21 scscm21-step5">
                                    <div className="">
                                       <select className="form-select" onChange={(e) => {
                                          setGender(e.target.value)
                                          handleFilterChange({gender:e.target.value})
                                       }}>
                                          <option>Gender</option>
                                          {genderArr.map((gen) => {
                                             return (
                                                <option value={gen} key={gen}>{gen}</option>
                                             )
                                          })}
                                       </select>
                                    </div>
                                    <div className="">
                                       <select className="form-select" onChange={(e) => {
                                          setCountry(e.target.value)
                                          handleFilterChange({Country:e.target.value})
                                       }
                                       }>
                                          <option>Select Country</option>
                                          {Object.values(countries).map((country) => {
                                             return (
                                                <option value={country.name} key={country.name}>{country.name}</option>
                                             )
                                          })}
                                       </select>
                                    </div>
                                    {activeSelectedTab.tabName !== 'Student reviews' && (
                                       <div className="">
                                          <select className="form-select" onChange={(e) => {
                                             setDeviceType(e.target.value)
                                             handleFilterChange({ fcm_token: e.target.value === 'web' ? null : {'$ne' : null} })
                                          }
                                          }>
                                             <option>Select Web</option>
                                             <option value="All">All</option>
                                             <option value="web">Web</option>
                                             <option value="app">App</option>
                                          </select>
                                       </div>
                                    )}
                                    {
                                       activeSelectedTab.tabName !== 'Non-Subscribed' && activeSelectedTab.tabName !== 'Student reviews' && (
                                          <div className="">
                                             <select className="form-select" onChange={(e) => 
                                                {
                                                   setSubscriptionType(e.target.value)
                                                   handleFilterChange({'transactions.type':e.target.value})
                                                }
                                                }>
                                                <option>Subscribed Type</option>
                                                {
                                                   subscriptionTypeArr.map((subs) => {
                                                      return (
                                                         <option value={subs} key={subs}>{subs}</option>
                                                      )
                                                   })
                                                }
                                             </select>
                                          </div>
                                       )
                                    }

                                    {
                                       activeSelectedTab.tabName === 'Cancelled Subscription' && (
                                          <select className="form-select" onChange={(e) => 
                                          {
                                             setSubscriptionType(e.target.value)
                                          }
                                          }>
                                             <option>Refund Status</option>
                                             <option value="Refunded">Refunded</option>
                                             <option value="Pending">Pending</option>
                                          </select>
                                       )
                                    }

                                    {
                                       activeSelectedTab.tabName === 'Cancelled Subscription' && (
                                          <select className="form-select" onChange={(e) => {
                                             setSubscriptionType(e.target.value)
                                             handleFilterChange({ 'transactions.plan': e.target.value })
                                          }
                                          }>
                                             <option>Subscription Tenure</option>
                                             <option value="Monthly">1 Month</option>
                                             <option value="Quarterly">3 Months</option>
                                             <option value="Yearly">12 Months</option>
                                          </select>
                                       )
                                    }

                                    {
                                       activeSelectedTab.tabName === 'Student reviews' && (
                                          <select className="form-select" onChange={(e) => setSubscriptionType(e.target.value)}>
                                             <option>Rating</option>
                                             <option value={2}>2</option>
                                             <option value={3}>3</option>
                                             <option value={4}>4</option>
                                             <option value={5}>5</option>
                                          </select>
                                       )
                                    }


                                    {activeSelectedTab.tabName === 'Registered' && (
                                       <div className="">
                                          <select className="form-select" onChange={(e) => {
                                             setIsSubscribed(e.target.value)
                                             handleFilterChange({ Subscribe: e.target.value === 'true' ? true : false })
                                          }
                                          }>
                                             <option>Is Subscribed ?</option>
                                             <option value="true">Yes</option>
                                             <option value="false">No</option>
                                          </select>
                                       </div>
                                    )}
                                 </div>
                                 {activeSelectedTab.tabName === 'Registered' && (
                                    <div className="stdntoorNewbg">
                                       <div className="stdntoorNew">
                                          <div className="txnewatot txnewatot1">All Registered Student  </div>
                                          <div className="txneWebApp">
                                             <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">Web  {search.allTimeWebRegistered}</div>
                                                <div className="d-flex align-items-center">App  {search.allTimeAppRegistered}</div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="stdntoorNew">
                                          <div className="txnewatot">New Registered Student  </div>
                                          <div className="txnewatotHrs">Last 24 hours </div>
                                          <div className="">
                                             <div className="d-flex justify-content-between align-items-center txneWebApp">
                                                <div className="d-flex align-items-center">Web  {previousDayWebCount}</div>
                                                <div className="d-flex align-items-center">App  {previousDayAppCount} </div>
                                             </div>
                                          </div>
                                       </div>

                                    </div>
                                 )}

                              </div>
                           </div>
                        </div> */}
                        {
                         tableData.length ===0 ? <p>Loading...</p> : 
                           (
                              <div className="table-responsive">
                           <table className="table table-center datatablefnts datatable">
                              <thead>
                                 <tr>
                                    <th>S. No   </th>
                                    {TableContents[activeSelectedTab.tabName].tableHeaders.map((heads) => {
                                       return (
                                          <th>{heads.label}</th>
                                       )
                                    })}
                                 </tr>
                              </thead>
                              <tbody>
                                 {tableData.map((data, idx) => {
                                    return (
                                       <tr key={idx}>
                                          <td>{idx + 1}</td>
                                          {TableContents[activeSelectedTab.tabName].tableHeaders.map((heads) => {
                                             return (
                                                <>
                                                   {
                                                      heads.label === 'Registration Date' && (
                                                         <td>{getDateFromTimestamp(data.created_at)
                                                         }</td>
                                                      )
                                                   }
                                                   {/* {
                                                      heads.label === 'User ID' && (
                                                         <td>{Math.floor(100000 + Math.random() * 900000)}</td>
                                                      )
                                                   } */}
                                                   {heads.value === 'fcm_token' ? (
                                                      <td>{data.fcm_token === null ? 'Web' : 'App'}</td>
                                                   ) : (
                                                      <td style={{ display: heads.label === 'Registration Date' && 'none' }}>{data[heads.value]}
                                                         {heads.value === 'Email' && (
                                                            <div className="messagebn1">
                                                               <a href={`mailto:${data.Email}`}><button><i className="mdi mdi-email-outline" target="_blank"></i> Message</button></a>
                                                            </div>
                                                         )}
                                                         {heads.value === 'Contact' && (
                                                            <>
                                                               <div className="callphnwhaaap">
                                                                  <a href={`https://api.whatsapp.com/send?phone=${data.Contact}`} title="Share on whatsapp" target="_blank">         <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button>
                                                                     <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button></a>
                                                               </div>
                                                            </>
                                                         )}

                                                         {
                                                            heads.label === 'Subscription Date' && (
                                                               <td>{getDateFromTimestamp(data.transactions?.[0]?.SubscribeDate)
                                                               }</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'Cancel Reason' && (
                                                               <td>{data.transactions?.[0]?.reason}</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'Subscription Type' && (
                                                               <td>{data.transactions?.[0]?.plan}</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'Start Date' && (
                                                               <td>{getDateFromTimestamp(data.transactions?.[0]?.SubscribeDate)}</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'End Date' && (
                                                               <td>{getDateFromTimestamp(data.transactions?.[0]?.SubscribeDate)}</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'Cancel Date' && (
                                                               <td>{getDateFromTimestamp(data.transactions?.[0]?.CancelledDate)}</td>
                                                            )
                                                         }
                                                      </td>
                                                   )}

                                                </>
                                             )
                                          })}
                                       </tr>

                                    )
                                 })}
                              </tbody>
                           </table>
                           {tableData && tableData?.length > 0 && <Pagination total={total} data={tableData} setPageNo={setPageNo} pageNo={pageNo} />}

                        </div>
                           )
                        }
                        
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </>
   )
}


export default StudentContainerTabPane