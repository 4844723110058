import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { ReducerSyncWithStorage } from 'src/utils/ReducerSyncWithStorage';
import { AUTH_ACTIONS } from '../actions/AuthActions';

const initialState = {
    isLoggedIn: false,
    token: '',
    loginConfirmationStatus: null,
    userId: '',
    personalInfo: {},
    userType: '',
    profilePicUrl: '',
    userScopes: [],
};

const resetState = {
    otpSentStatus: null,
    loginConfirmationStatus: null,
};

const AuthReducer =  (state = initialState, action) => {
    let newState = {
        ...state,
        ...resetState,
    };

    switch (action.type) {
        case AUTH_ACTIONS.LOGIN:
            return {
                ...newState,
                loginConfirmationStatus: REQUEST_STATUS.PENDING,
            };
        // CONFIRM OTP
        case AUTH_ACTIONS.LOGIN_SUCCESS: {
            newState = {
                ...newState,
                loginConfirmationStatus: REQUEST_STATUS.SUCCESS,
                ...action.payload,
                isLoggedIn: !!action.payload.token,
            };
            break;
        }
        case AUTH_ACTIONS.LOGIN_ERROR: {
            newState = {
                ...newState,
                loginConfirmationStatus: REQUEST_STATUS.ERROR,
            };
            break;
        }

        // SCOPES
        case AUTH_ACTIONS.FETCH_PERMISSION_SCOPES_SUCCESS: {
            newState = {
                ...newState,
                ...action.payload,
            };
            break;
        }

        // LOGOUT
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }

    return newState;
};

export default ReducerSyncWithStorage(AuthReducer, {
    keysToBeSync: [
        'isLoggedIn',
        'token',
        'userId',
        'personalInfo',
        'userType',
        'profilePicUrl',
    ],
    initialState,
    storageKeyName: 'AuthReducer',
    storage: localStorage,
});
