import React, {useState} from "react"
import { TabDetails } from "./TabsConfiguration";
import DateRangeCalendar from "src/components/DateRangeCalendar";
import SessionContainerTabPane from "src/components/SessionContainerTabPane";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionSearchAction } from "./actions/SessionActions";
import { getSessionsCount } from "src/api/SOSApi";

const SessionContainer=()=>{
   const dispatch = useDispatch()
   var today = new Date();
   today.setUTCHours(0, 0, 0, 0); 
   var tomorrow = new Date(today);
   tomorrow.setDate(tomorrow.getDate() + 1);
   const {search} = useSelector((state) => state.SessionReducer)
   const [activeSelectedTab, setActiveSelectedTab] = useState({ tabName: 'Unpaid Sessions', id: 1 })
   const [dateFilter, setDateFilter] = useState({created_at:{ "$gte": today.toISOString(),"$lt": tomorrow.toISOString()}})
   const [filter, setFilter] = useState({payment_status:'unpaid',session_status:'pending',created_at:{ "$gte": today.toISOString(),"$lt": tomorrow.toISOString()}})
   const [groupCounter, setGroupCounter] = useState({
      'Unpaid Sessions':0,
      'Upcoming Sessions':0, 
      'Cancelled Sessions':0, 
      'Session History':0, 
      'Session in Progress':0
   })
   const [pageNo, setPageNo] = useState(0)
   const [tableData, setTableData] = useState([])

   useEffect(() => {
      getAllSessions()
   },[filter,pageNo])

   const getAllSessions = () => {
      dispatch(sessionSearchAction({
         where:filter,
         currentPage:pageNo,
         pageType:'Session',
         autopopulate:true
      }))
   }

   useEffect(() => {
    const {data} = search
    setTableData(data)
   //  setGroupCounter(groupCounter)
   }, [search])

   useEffect(() => {
      getAllGroupCounts()
   },[dateFilter])


   const getAllGroupCounts  = async() => {
      const res= await getSessionsCount('session-count',{where:dateFilter})
      setGroupCounter(res.data.data)
   }

 const handleFilterChange = (payload) => {
  if( 'is_attended_by_tutor' in payload){
      delete filter['schedule.start']
   }else if ('is_attended_by_student' in payload){
      delete filter['schedule.start']
   }else {
      delete filter['schedule.start']
      delete filter['is_attended_by_student']
      delete filter['is_attended_by_tutor']
   }         setFilter({...filter, ...payload})
 }  

   const handleTabClick = (tabDetails) => {
      if(tabDetails.heading === 'Upcoming Sessions'){
          setActiveSelectedTab({tabName:'Upcoming Sessions',id:tabDetails.id})
          delete filter['schedule.start']
          delete filter['is_attended_by_student']
          delete filter['is_attended_by_tutor']
          handleFilterChange({payment_status:'paid-full', session_status:'approved'})
         dispatch(sessionSearchAction({
            where:{...filter,payment_status:'paid-full', session_status:'approved'},
            currentPage:pageNo,
            pageType:'Session',
            autopopulate:true
         }))
      }else if(tabDetails.heading === 'Cancelled Sessions'){
           setActiveSelectedTab({tabName:'Cancelled Sessions',id:tabDetails.id})
           delete filter['schedule.start']
           delete filter['is_attended_by_student']
           delete filter['is_attended_by_tutor']
           handleFilterChange({session_status:'cancelled',payment_status:'paid-full'})
         dispatch(sessionSearchAction({
            where:{...filter.created_at, session_status:'cancelled',payment_status:'paid-full'},
            currentPage:pageNo,
            pageType:'Session',
            autopopulate:true
         }))
      }else if(tabDetails.heading === 'Session History'){
         delete filter['schedule.start']
           setActiveSelectedTab({tabName:'Session History',id:tabDetails.id})
           delete filter['schedule.start']
           handleFilterChange({is_attended_by_student:true,is_attended_by_tutor:true,payment_status:'paid-full',session_status:'approved'})
         dispatch(sessionSearchAction({
            where:{...filter.created_at,is_attended_by_student:true,is_attended_by_tutor:true,payment_status:'paid-full',session_status:'approved' },
            currentPage:pageNo,
            pageType:'Session',
            autopopulate:true
         }))
      } else if (tabDetails.heading === 'Session in Progress'){
         delete filter['is_attended_by_student']
         delete filter['is_attended_by_tutor']
           setActiveSelectedTab({tabName:'Session in Progress',id:tabDetails.id})
           handleFilterChange({payment_status:'paid-full',session_status:'approved'})
         dispatch(sessionSearchAction({
            where:{...filter.created_at, 'schedule.start':new Date(), payment_status:'paid-full',session_status:'approved'},
            currentPage:pageNo,
            pageType:'Session',
            autopopulate:true
         }))
      } else if (tabDetails.heading === 'Unpaid Sessions'){
           setActiveSelectedTab({tabName:'Unpaid Sessions',id:tabDetails.id})
           delete filter['schedule.start']
           delete filter['is_attended_by_student']
           delete filter['is_attended_by_tutor']
           handleFilterChange({payment_status:'unpaid',session_status:'pending'})
         dispatch(sessionSearchAction({
            where:{...filter.created_at, payment_status:'unpaid',session_status:'pending'},
            currentPage:pageNo,
            pageType:'Session',
            autopopulate:true
         }))
      }
   }

   const handleDateRangefilter = (payload) => {
      setDateFilter(payload)
      setFilter({...filter,...payload})
   }


    return(
        <>
        <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2" >
                        <i className="mdi mdi-home"></i>
                        </span> Sessions overview 
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="card shadow">
                           <div className="card-body">
                              <DateRangeCalendar onSelect={handleDateRangefilter}/>
                              <div className="tabsnrstl1 tabsnrstl-m5">
                                 <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {
                                       TabDetails.map((tab, idx) => {
                                          return (
                                             <li className="nav-item" role="presentation" onClick={() => handleTabClick(tab)}>
                                             <div className={`nav-link ${activeSelectedTab.tabName === tab.heading && 'active'}`} id={`pills-tab${idx+1}`} data-bs-toggle="pill" data-bs-target={`#pills-${idx+1}`} type="button" role="tab" aria-controls={`pills-${idx+1}`} aria-selected="true">
                                                <div className="stretch-card asigntabnew1">
                                                   <div className="card-img-holder">
                                                      <div className="card-body">
                                                         <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className={`asigntabIcon icon${idx+1}`}>
                                                            <img src={`/assets/images/super-admin/home/${tab.icon}.svg`} className="img-fluid img-icon" alt="" /> 
                                                            <i className="mdi mdi-plus"></i></span> <span className="valutext">{groupCounter[tab.heading]}</span>
                                                         </h4>
                                                         <h6 className="card-text">Total</h6>
                                                         <h2 className="mt-0">{tab.heading}</h2>
                                                         <div className="pricdlrm1">${groupCounter[tab.heading]*20}</div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </li>
                                          )
                                       })
                                    }
                                 </ul>
                              </div>
                              <SessionContainerTabPane activeSelectedTab={activeSelectedTab} tableData={tableData}
                               pageNo={pageNo}
                               setPageNo={setPageNo}
                               total={search.total}
                               handleFilterChange={handleFilterChange}
                              />
                              {/* <div className="tab-content" id="pills-tabContent">
                                 <div className="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-tab1">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                             <div className="dateinydt">
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="scscm21 scscm21-step5">
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Service Type</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Subject</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Start  Time </option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Master subject</option>
                                                            </select>
                                                         </div> 
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Gender</option>
                                                            </select>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Assignment  overview/Pending Assessment </h4>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>Session ID </th>  
                                                         <th>Student ID </th>  
                                                         <th>Student Name </th>   
                                                         <th>Student Email Id</th>     
                                                         <th>Student Contact N. </th>    
                                                         <th>Date  </th>   
                                                         <th>Preferred Slot </th>     
                                                         <th>Tutor Timezone</th>     
                                                         <th>Student Timezone </th>    
                                                         <th>Gender </th>        
                                                         <th>Country </th>    
                                                         <th>Tutor ID   </th> 
                                                         <th>Tutor Name   </th> 
                                                         <th>Tutor Email Id  </th>   
                                                         <th>Tutor Contact N.  </th>   
                                                         <th>Master Subject   </th>
                                                         <th>Sub Topic  </th>
                                                         <th>Service Type  </th> 
                                                         <th>Pending Amount</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td>
                                                         <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>
                                                                
                                                         <td>
                                                            <div className="text-bold">$15</div>
                                                         </td>  
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td>
                                                         <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>
                                                                
                                                         <td>
                                                            <div className="text-bold">$15</div>
                                                         </td>  
                                                      </tr> 
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="pills-2" role="tabpanel" aria-labelledby="pills-tab2">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                                <div className="dateinydt">
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="scscm21 scscm21-step5">
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Service Type</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Subject</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Start  Time </option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Master subject</option>
                                                            </select>
                                                         </div> 
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Gender</option>
                                                            </select>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Assignment  overview/Pending Assessment </h4>
                                                         </div>
                                                         <div className="sltbsxb1">
                                                            <div className="button-group">
                                                               <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                                               <ul className="dropdown-menu dropdown-menu-end">
                                                                  <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>Session ID </th>  
                                                         <th>Student ID </th>  
                                                         <th>Student Name </th>   
                                                         <th>Student Email Id</th>     
                                                         <th>Student Contact N. </th>    
                                                         <th>Date  </th>   
                                                         <th>Preferred Slot </th>     
                                                         <th>Tutor Timezone</th>     
                                                         <th>Student Timezone </th>    
                                                         <th>Gender </th>        
                                                         <th>Country </th>    
                                                         <th>Tutor ID   </th> 
                                                         <th>Tutor Name   </th> 
                                                         <th>Tutor Email Id  </th>   
                                                         <th>Tutor Contact N.  </th>   
                                                         <th>Master Subject   </th>
                                                         <th>Sub Topic  </th>
                                                         <th>Service Type  </th>  
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td>
                                                         <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>
                                                                
                                                           
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td>
                                                         <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>
                                                                
                                                      </tr> 
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="pills-3" role="tabpanel" aria-labelledby="pills-tab3">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                               <div className="dateinydt">
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="scscm21 scscm21-step5">
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Service Type</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Subject</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Start  Time </option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Master subject</option>
                                                            </select>
                                                         </div> 
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Gender</option>
                                                            </select>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Assignment  overview/Pending Assessment </h4>
                                                         </div>
                                                         <div className="sltbsxb1">
                                                            <div className="button-group">
                                                               <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                                               <ul className="dropdown-menu dropdown-menu-end">
                                                                  <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>Session ID </th>  
                                                         <th>Student ID </th>  
                                                         <th>Student Name </th>   
                                                         <th>Student Email Id</th>     
                                                         <th>Student Contact N. </th>    
                                                         <th>Date  </th>   
                                                         <th>Preferred Slot </th>     
                                                         <th>Tutor Timezone</th>     
                                                         <th>Student Timezone </th>    
                                                         <th>Gender </th>        
                                                         <th>Country </th>    
                                                         <th>Tutor ID   </th> 
                                                         <th>Tutor Name   </th> 
                                                         <th>Tutor Email Id  </th>   
                                                         <th>Tutor Contact N.  </th>   
                                                         <th>Master Subject   </th>
                                                         <th>Sub Topic  </th>
                                                         <th>Service Type  </th> 
                                                         <th>Cancelled by</th>
                                                         <th>Reasons</th>
                                                         <th>Book Another Session </th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td>
                                                         <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>    
                                                         <td>
                                                            <div className="">Tutor</div>
                                                         </td>
														  <td>
                                                          <div className=""><button className="btnbluen1" >View </button> </div>
                                                         </td>
														  <td>
                                                          <div className=""><button className="btnbluen1 bg-green" >Book Another Session </button> </div>
                                                         </td>
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td>
                                                         <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>    
                                                         <td>
                                                            <div className="">Tutor</div>
                                                         </td>
														  <td>
                                                          <div className=""><button className="btnbluen1" >View </button> </div>
                                                         </td>
														  <td>
                                                          <div className=""><button className="btnbluen1 bg-green" >Book Another Session </button> </div>
                                                         </td>
                                                      </tr>
													     
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="pills-4" role="tabpanel" aria-labelledby="pills-tab4">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                               <div className="dateinydt">
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="scscm21 scscm21-step5">
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Service Type</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Subject</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Start  Time </option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Master subject</option>
                                                            </select>
                                                         </div> 
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Gender</option>
                                                            </select>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Assignment  overview/Pending Assessment </h4>
                                                         </div>
                                                         <div className="sltbsxb1">
                                                            <div className="button-group">
                                                               <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                                               <ul className="dropdown-menu dropdown-menu-end">
                                                                  <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>Session ID </th>  
                                                         <th>Student ID </th>  
                                                         <th>Student Name </th>   
                                                         <th>Student Email Id</th>     
                                                         <th>Student Contact N. </th> 
                                                         <th>Gender </th> 
														   <th>Country </th>
														   <th>Subject   </th>
                                                         <th>Sub Subject  </th>
														  <th>Service Type  </th>
                                                         <th>Date  </th>   
                                                         <th>Preferred Slot </th>     
                                                         <th>Tutor Timezone</th>     
                                                         <th>Student Timezone </th>     
                                                         <th>Tutor ID   </th> 
                                                         <th>Tutor Name   </th> 
                                                         <th>Tutor Email Id  </th>   
                                                         <th>Tutor Contact N.  </th>
                                                         <th>Master Subject </th>
                                                         <th>Sub Topic </th> 
                                                         <th>Session Duration</th>
                                                         <th>Session Rating</th>
                                                         <th>Session Feedback</th>
                                                         <th>Session Recording </th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>Male</td>
                                                         <td><div className="countryc1 text-bold">(+1) USA</div></td> 
														  <td>Math</td>
														  <td>Algebra </td>
														  <td>Take a Doubt Session </td>
														  <td>05 August 2022 </td>
														  <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>T2540 </td> 
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">2h 25m</div></td>    
                                                         
														  <td>
                                                            <div className="starratntx">
																<div className="text-bold">2.0</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>
                                                         </td>
														  <td>
                                                          <div className=""><button className="btnbluen1">View </button> </div>
                                                         </td>
														  <td>
                                                          <div className=""><button className="btnbluen1 playbtn-circle"><i className="mdi mdi-play"></i> </button> </div>
                                                         </td>
                                                      </tr>
													     
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="pills-5" role="tabpanel" aria-labelledby="pills-tab5">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                                <div className="dateinydt">
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="scscm21 scscm21-step5">
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Service Type</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Select Subject</option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Start  Time </option>
                                                            </select>
                                                         </div>
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Master subject</option>
                                                            </select>
                                                         </div> 
                                                         <div className="">
                                                            <select className="form-select">
                                                               <option>Gender</option>
                                                            </select>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Assignment  overview/Pending Assessment </h4>
                                                         </div>
                                                         <div className="sltbsxb1">
                                                            <div className="button-group">
                                                               <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                                               <ul className="dropdown-menu dropdown-menu-end">
                                                                  <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                                  <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>Session ID </th>  
                                                         <th>Student ID </th>  
                                                         <th>Student Name </th>   
                                                         <th>Student Email Id</th>     
                                                         <th>Student Contact N. </th>    
                                                         <th>Date  </th>        
                                                         <th>Gender </th>        
                                                         <th>Country </th>    
                                                         <th>Tutor ID   </th> 
                                                         <th>Tutor Name   </th> 
                                                         <th>Tutor Email Id  </th>   
                                                         <th>Tutor Contact N.  </th>   
                                                         <th>Master Subject   </th>
                                                         <th>Sub Topic  </th>
                                                         <th>Service Type  </th>
														  <th>Start Time </th>     
                                                         <th>Tutor Timezone </th>     
                                                         <th>Student Timezone </th>
														  <th>Session Duration</th>
														  <th>Join Session</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td> 
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>
                                                             <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>   
                                                         <td>
                                                            <div className="">1 h 10m 40s</div>
                                                         </td>
														   <td>
                                                            <div className=""><button className="btnbluen1">Join Session  </button> </div>
                                                         </td>
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td> 
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>
                                                             <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>   
                                                         <td>
                                                            <div className="">1 h 10m 40s</div>
                                                         </td>
														   <td>
                                                            <div className=""><button className="btnbluen1">Join Session  </button> </div>
                                                         </td>
                                                      </tr>
													     
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                  
                                  
                              </div> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
        </>
    )
}

export default SessionContainer;