import React, { useState, useEffect } from "react";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  ChartWrapper,
  ReportWrapper,
  ChartTitle,
  Subtitle,
} from "./styles"; 
 
import { formatDate } from "./utils"; 
// import React from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DayVisitsReport = (props) => {
  const {
    sessionPerDay ={}, 
} = props; 

  const INITIAL_STATE = {
    labels: [],
    values: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);  
  const [average, setAverage] = useState(0);

  const displayResults = (response) => {
    const queryResult = response.reports[0].data.rows;
    const total = response.reports[0].data.totals[0].values[0];
    setAverage(parseInt(total / response.reports[0].data.rowCount));
    let labels = [];
    let values = [];
    queryResult.forEach((row) => {
      labels.push(formatDate(row.dimensions[0]));
      values.push(row.metrics[0].values[0]);
    });
    setReportData({
      ...reportData,
      labels,
      values,
    });
  };

  const data = {
    labels: reportData.labels,
    datasets: [
      {
        label: `${props.title} per day`,
        fill: false,
        lineTension: 0.3,
        borderColor: 'rgb(255, 99, 132)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#375751",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: reportData.values,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 7,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  useEffect(() => { 
    if(sessionPerDay?.reports?.length>0)
      displayResults(sessionPerDay)
  }, []);

  return (
    <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2">
                        <span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                        {`${props.title} per day`} </div>
                  </div>
                  <div className="hdh1bg">
                      <ReportWrapper> 
                          <Subtitle>{`Average - ${average} ${props.title}`}</Subtitle> 
                          {reportData && (
                            <ChartWrapper>
                              <Line data={data} options={options} width={100} height={250} />
                            </ChartWrapper>
                          )}
                        </ReportWrapper>
                    {/* <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center">
                        <div className="hdhomX3 lightblue-icon me-2">
                         


                        </div>
                        Tutors onboarded </div>
                      <div className="hdh1No">4584</div>
                    </div>
                    <div className="hdhomX1 hdh1">
                      <div className="hdhomX2 d-flex align-items-center">
                        <div className="hdhomX3 lightblue-icon me-2"><span><img src="/assets/images/home-imgs/expert-icon.svg" className="img-fluid" alt=""/></span></div>
                        Expert's onboarded </div>
                      <div className="hdh1No">4584</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
    
  );
};

export default DayVisitsReport;
