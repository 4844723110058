import React from 'react'

const BulkUploadTabPane = ({tableData, activeSelectedTab, uploadOperation}) => {
    return (
      <>
      {
         uploadOperation === 'Books Upload Chapters' ? (
            <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id={`pills-${activeSelectedTab.tabPaneId}`} role="tabpanel" aria-labelledby={`pills-${activeSelectedTab.tabPaneId}-tab`}>
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">Book ISBN </th>
                              <th>Chapter Name</th>
                              <th>Problem Number </th>
                              <th>Status</th>
                           </tr>
                        </thead>
                        <tbody>
                         {
                             tableData.map((data, idx) => {
                                 return (
                                     <tr>
                                     <td><span className="sNo">{idx+1}</span> </td>
                                     <td>{data.created_at}    </td>
                                     <td>
                                        <div className=" text-start">{data.book_isbn}</div>
                                     </td>
                                     <td>{data.chapter_name}</td>
                                     <td>{data.problem_no}   </td>
                                     <td style={{color:'red'}}>{data.question === '' ? 'Question not uploaded' : 'Chapter name not Uploaded'} </td>
                                  </tr> 
                                 )
                             })
                         }
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            {/* <div className="tab-pane fade" id="pills-uploaded" role="tabpanel" aria-labelledby="pills-uploaded-tab">
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID</th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              <th>Attachment </th>
                              <th className="text-start">Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td><span className="sNo">1</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">2</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">3</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">4</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="pills-error" role="tabpanel" aria-labelledby="pills-error-tab">
               <div className="card-body ps-0 pe-0">
                  <table className="table table-center datatablefnts datatable">
                     <thead>
                        <tr>
                           <th>S. No   </th>
                           <th>Created Date  </th>
                           <th className="text-start">ISBN ID</th>
                           <th>Subject </th>
                           <th>Sub Subject</th>
                           <th>Attachment </th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td><span className="sNo">1</span> </td>
                           <td>2022-17-09    </td>
                           <td>
                              <div className="text-start">ISBN13: 9781337395281</div>
                           </td>
                           <td>Business   </td>
                           <td>Account </td>
                           <td>
                              <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                           </td>
                           <td className="text-center">
                              <div className="text-center"><button className="btnbluen1"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                           </td>
                        </tr>
                        <tr>
                           <td><span className="sNo">2</span> </td>
                           <td>2022-17-09    </td>
                           <td>
                              <div className="text-start">ISBN13: 9781337395281</div>
                           </td>
                           <td>Business   </td>
                           <td>Account </td>
                           <td>
                              <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                           </td>
                           <td className="text-center">
                              <div className="text-center"><button className="btnbluen1"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                           </td>
                        </tr>
                        <tr>
                           <td><span className="sNo">3</span> </td>
                           <td>2022-17-09    </td>
                           <td>
                              <div className="text-start">ISBN13: 9781337395281</div>
                           </td>
                           <td>Business   </td>
                           <td>Account </td>
                           <td>
                              <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                           </td>
                           <td className="text-center">
                              <div className="text-center"><button className="btnbluen1"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                           </td>
                        </tr>
                        <tr>
                           <td><span className="sNo">4</span> </td>
                           <td>2022-17-09    </td>
                           <td>
                              <div className="text-start">ISBN13: 9781337395281</div>
                           </td>
                           <td>Business   </td>
                           <td>Account </td>
                           <td>
                              <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                           </td>
                           <td className="text-center">
                              <div className="text-center"><button className="btnbluen1"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
            <div className="tab-pane fade" id="pills-approved" role="tabpanel" aria-labelledby="pills-approved-tab">
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID</th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              <th>Attachment </th>
                              <th className="text-start">Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td><span className="sNo">1</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">2</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">3</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">4</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="pills-draft" role="tabpanel" aria-labelledby="pills-draft-tab">
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID</th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              <th>Attachment </th>
                              <th className="text-start">Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td><span className="sNo">1</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">2</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">3</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">4</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="pills-pending" role="tabpanel" aria-labelledby="pills-pending-tab">
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID</th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              <th>Attachment </th>
                              <th>Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td><span className="sNo">1</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className="text-yellow">Pending</div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">2</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className="text-yellow">Pending</div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">3</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className="text-yellow">Pending</div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div> */}
         </div>
         ) : (
            <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id={`pills-${activeSelectedTab.tabPaneId}`} role="tabpanel" aria-labelledby={`pills-${activeSelectedTab.tabPaneId}-tab`}>
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID </th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              {
                                 activeSelectedTab.Header !== 'Error' && (
                                    <>
                                    <th className="text-start">Authoring Status</th>
                                    <th className="text-start">SEO Status</th>
                                    </>
                                 )
                              }
                              {
                                 activeSelectedTab.Header === 'Error' && (
                                    <th>Status</th>
                                 )
                              }
                           </tr>
                        </thead>
                        <tbody>
                         {
                             tableData.map((data, idx) => {
                                 return (
                                     <tr>
                                     <td><span className="sNo">{idx+1}</span> </td>
                                     <td>{data.created_at}    </td>
                                     <td>
                                        <div className=" text-start">{data.ISBN13}</div>
                                     </td>
                                     <td>{data.subject_name}   </td>
                                     <td>{data.sub_subject_name} </td> 
                                     {
                                       activeSelectedTab.Header !== 'Error' && (
                                          <>
                                             <td>{data.authoringStatus} </td>
                                     <td>{data.seoStatus === null ? '--' : data.seoStatus}</td>
                                          </>
                                       )
                                     }
                                     {
                                       activeSelectedTab.Header === 'Error' && (
                                          <td style={{color:'red'}}>
                                             {
                                             data.Author1 === '' || data.Author2 === '' || data.Author3 === '' && 'Missing Author Details.'
                                          }
                                          {
                                             data.Description === '' && 'Missing book description.'
                                          }
                                          {
                                             data.subject_name === '' || data.subject_id === '' || data.sub_subject_name === '' || data.sub_subject_id === ''
                                             && 'Subject or Sub-Subject details missing'
                                          }
                                          </td>
                                       )
                                     }
                                  </tr> 
                                 )
                             })
                         }
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            {/* <div className="tab-pane fade" id="pills-uploaded" role="tabpanel" aria-labelledby="pills-uploaded-tab">
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID</th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              <th>Attachment </th>
                              <th className="text-start">Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td><span className="sNo">1</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">2</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">3</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">4</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="pills-error" role="tabpanel" aria-labelledby="pills-error-tab">
               <div className="card-body ps-0 pe-0">
                  <table className="table table-center datatablefnts datatable">
                     <thead>
                        <tr>
                           <th>S. No   </th>
                           <th>Created Date  </th>
                           <th className="text-start">ISBN ID</th>
                           <th>Subject </th>
                           <th>Sub Subject</th>
                           <th>Attachment </th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td><span className="sNo">1</span> </td>
                           <td>2022-17-09    </td>
                           <td>
                              <div className="text-start">ISBN13: 9781337395281</div>
                           </td>
                           <td>Business   </td>
                           <td>Account </td>
                           <td>
                              <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                           </td>
                           <td className="text-center">
                              <div className="text-center"><button className="btnbluen1"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                           </td>
                        </tr>
                        <tr>
                           <td><span className="sNo">2</span> </td>
                           <td>2022-17-09    </td>
                           <td>
                              <div className="text-start">ISBN13: 9781337395281</div>
                           </td>
                           <td>Business   </td>
                           <td>Account </td>
                           <td>
                              <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                           </td>
                           <td className="text-center">
                              <div className="text-center"><button className="btnbluen1"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                           </td>
                        </tr>
                        <tr>
                           <td><span className="sNo">3</span> </td>
                           <td>2022-17-09    </td>
                           <td>
                              <div className="text-start">ISBN13: 9781337395281</div>
                           </td>
                           <td>Business   </td>
                           <td>Account </td>
                           <td>
                              <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                           </td>
                           <td className="text-center">
                              <div className="text-center"><button className="btnbluen1"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                           </td>
                        </tr>
                        <tr>
                           <td><span className="sNo">4</span> </td>
                           <td>2022-17-09    </td>
                           <td>
                              <div className="text-start">ISBN13: 9781337395281</div>
                           </td>
                           <td>Business   </td>
                           <td>Account </td>
                           <td>
                              <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                           </td>
                           <td className="text-center">
                              <div className="text-center"><button className="btnbluen1"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
            <div className="tab-pane fade" id="pills-approved" role="tabpanel" aria-labelledby="pills-approved-tab">
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID</th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              <th>Attachment </th>
                              <th className="text-start">Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td><span className="sNo">1</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">2</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">3</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">4</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="pills-draft" role="tabpanel" aria-labelledby="pills-draft-tab">
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID</th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              <th>Attachment </th>
                              <th className="text-start">Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td><span className="sNo">1</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">2</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">3</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">4</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className=""><button className="btnbluen1 d-flex align-items-center"><i className="mdi mdi-arrow-up-bold-circle mdi-24px me-1"></i> Re-Upload</button> </div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="pills-pending" role="tabpanel" aria-labelledby="pills-pending-tab">
               <div className="card-body ps-0 pe-0">
                  <div className="table-responsive">
                     <table className="table table-center datatablefnts datatable">
                        <thead>
                           <tr>
                              <th>S. No   </th>
                              <th>Created Date  </th>
                              <th className="text-start">ISBN ID</th>
                              <th>Subject </th>
                              <th>Sub Subject</th>
                              <th>Attachment </th>
                              <th>Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td><span className="sNo">1</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className="text-yellow">Pending</div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">2</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className="text-yellow">Pending</div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                           <tr>
                              <td><span className="sNo">3</span> </td>
                              <td>2022-17-09    </td>
                              <td>
                                 <div className="text-start">ISBN13: 9781337395281</div>
                              </td>
                              <td>Business   </td>
                              <td>Account </td>
                              <td>
                                 <div className=""><button className="btn-down"><i className="mdi mdi-arrow-down mdi-24px"></i></button></div>
                              </td>
                              <td>
                                 <div className="text-yellow">Pending</div>
                              </td>
                              <td>
                                 <div className="text-bold"><button className="btnbluen1 plwhit">Edit</button> </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div> */}
         </div>
         )
      }
      </>
    )
}

export default BulkUploadTabPane