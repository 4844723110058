import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { createRoleMaster, getSingleRoleView, updateRoleMaster } from 'src/api/MastersApi'
import RoutingUtil from 'src/utils/RoutingUtil'

const RoleCreate = () => {

const history = useHistory()    
const params = useParams()
const [ roleName, setRoleName ] = useState('')    
const [ roleDescription, setRoleDescription ] = useState('')
const [ roleNumber, setRoleNumber ] = useState(0)
const formDataUpload = {}

useEffect(() => {
    if(params.id){
        getSingleRole()
    }
},[])

const getSingleRole = async () => {
    const { data } = await getSingleRoleView(params.id)
    setRoleName(data.data.name)
    setRoleDescription(data.data.description)
    setRoleNumber(data.data.role)
}

const handleSubmit = async() => {
    formDataUpload['name'] = roleName
    formDataUpload['description'] = roleDescription
    formDataUpload['role'] = roleNumber
    if(params.id){
        const updateRole = await updateRoleMaster(formDataUpload, params.id)
        if(updateRole){
            history.push(RoutingUtil.roleMasterUrl())
        }
    }else {
        const saveRole = await createRoleMaster(formDataUpload)
        if(saveRole){
            history.push(RoutingUtil.roleMasterUrl())
        }
    }
}

    return (
        <div className='row p-4'>
             <div className='col-md-12'>
                <div className='row mt-2'>
                    <label>Role Name</label>
                    <div className='col-md-12'>
                        <input type='text' value={roleName} onChange = {(e) => setRoleName(e.target.value)} name="name" placeholder='Enter role Name' autoComplete='off' className='form-control' style={{width:'30%'}}/>
                    </div>
                    <label className='mt-3'>Role Description</label>
                    <div className='col-md-12'>
                        <input type='email' name="description" value={roleDescription} onChange = {(e) => setRoleDescription(e.target.value)} placeholder='Enter role description' autoComplete='off' className='form-control' style={{width:'30%'}}/>
                    </div>
                    <label className='mt-3'>Role No</label>
                    <div className='col-md-12'>
                        <input type='number' name="role" value={roleNumber} onChange = {(e) => setRoleNumber(e.target.value)} placeholder='Enter role no' autoComplete='off' className='form-control' style={{width:'30%'}}/>
                    </div>
                    <div className='col-md-12 mt-5'>
                        <button className='btnbluen1' onClick={handleSubmit}>Save Role</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoleCreate