export const TabDetails = [
    {
        id:1,
        heading:'Backend',
        icon:'/assets/images/experts/bexperts-icon.svg'
    },
    {
        id:2,
        heading:'Frontend',
        icon:'/assets/images/experts/fexperts-icon.svg'
    },
    {
        id:3,
        heading:'Pending Frontend',
        icon:'/assets/images/experts/pexperts-icon.svg'
    },
    {
        id:4,
        heading:'Draft',
        icon:'/assets/images/experts/dexperts-icon.svg'
    },
]

export const TableContents = {
    Backend:{
        tableHeaders:[
            {
                label:'Expert ID',
                value:'id'
            },
            {
                label:'Expert Name',
                value:'name'
            },
            {
                label:'Expert Email Id',
                value:'email'
            },
            {
                label:'Contact Number',
                value:'Contact'
            },
            {
                label:'Subject',
                value:''
            },
            {
                label:'Sub Subject',
                value:''
            },
            // {
            //     label:'Service Type',
            //     value:''
            // },
            // {
            //     label:'Expertise',
            //     value:''
            // },
            // {
            //     label:'Language',
            //     value:'language'
            // },
            {
                label:'Gender',
                value:'gender'
            },
            // {
            //     label:'Country',
            //     value:''
            // },
            // {
            //     label:'Completed Assignment',
            //     value:'0'
            // },
            // {
            //     label:`Student's Rating/Feedback`,
            //     value:''
            // },
            {
                label:'Revenue Generated',
                value:'0'
            },
            {
                label:'Amount Received',
                value:'0'
            },
            // {
            // label:'Flag Status',
            // value:''
            // },
            // {
            //     label:'Performance Chart',
            //     value:''
            // },
            {
                label:'Total Active Hours',
                value:'0'
            },
            {
                label:'Onboarding Date',
                value:'createdAt'
            },
            {
                label:'Edit',
                value:''
            }
        ]
    },
    Frontend:{
        tableHeaders:[
            {
                label:'Expert ID',
                value:'id'
            },
            {
                label:'Expert Name',
                value:'expert_name'
            },
            {
                label:'Subjects',
                value:''
            },
            {
                label:'Sub Subject',
                value:''
            },
            {
                label:'Service Type',
                value:''
            },
            // {
            //     label:'Expertise',
            //     value:''
            // },
            {
                label:'Language',
                value:''
            },
            {
                label:'Gender',
                value:''
            },
            {
                label:'Country',
                value:''
            },
            {
                label:'Revenue Generated',
                value:'0'
            },
            {
                label:`Student's Rating/Feedback`,
                value:'0'
            },
            {
                label:'Revenue Generated',
                value:'0'
            },
            {
                label:'Amount Received',
                value:'0'
            },
            {
            label:'Created Date',
            value:''
            },
            {
                label:'Edit',
                value:''
            },
            // {
            //     label:'Popularity Badge',
            //     value:''
            // }
        ]
    },
    'Pending Frontend':{
        tableHeaders:[
            {
                label:'Expert ID',
                value:'id'
            },
            {
                label:'Expert Name',
                value:'name'
            },
            {
                label:'Expert Type',
                value:'type'
            },
            {
                label:'Expert Email Id',
                value:'email'
            },
            {
                label:'Subject',
                value:''
            },
            {
                label:'Sub Subject',
                value:''
            },
            {
                label:'Service Type',
                value:''
            },
            {
                label:'Expertise',
                value:''
            },
            {
                label:'Language',
                value:''
            },
            {
                label:'Gender',
                value:''
            },
            {
                label:`Student's Rating/Feedback`,
                value:''
            },
        //   {
        //     label:'Edit',
        //     value:''
        //   },
        //   {
        //     label:'Preview',
        //     value:''
        //   },
          {
            label:'Action',
            value:''
          }
        ]
    },
    Draft:{
        tableHeaders:[
            {
                label:'Expert ID',
                value:'id'
            },
            {
                label:'Expert Name',
                value:'expert_name'
            },
            {
                label:'Expert Type',
                value:'type'
            },
            {
                label:'Expert Email Id',
                value:''
            },
            {
                label:'Subject',
                value:''
            },
            {
                label:'Sub Subject',
                value:''
            },
            {
                label:'Service Type',
                value:''
            },
            {
                label:'Expertise',
                value:''
            },
            {
                label:'Language',
                value:''
            },
            {
                label:'Gender',
                value:''
            },
            {
                label:`Student's Rating/Feedback`,
                value:''
            },
          {
            label:'Edit',
            value:''
          },
          {
            label:'Preview',
            value:'editPreview'
          },
          {
            label:'Draft Action',
            value:''
          }
        ]
    }
}