import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useState } from "react";
import { questionCountAction, questionSearchAction } from "./actions/QuestionActions";
import { useEffect } from "react";
import { QuestionTabs, REQUEST_STATUS } from "src/constants/CommonConstants";
import AllQuestionList from "./AllQuestionList";
import PendingQuestionList from "./PendingQuestionList";
import AnsweredQuestionsList from "./AnsweredQuestionsList";
import RejectedQuestionsList from "./RejectedQuestionsList";
import Loader from "src/components/Loader";
import DateRangeCalendar from "src/components/DateRangeCalendar";

const Tabs=({activeTab,setActiveTab,tab,questionCount})=>{
   const totalVal = questionCount[tab.countKeyOther];
return (
         <li className="nav-item" role="presentation">
            <div className={`nav-link ${(activeTab==tab.key)?'active':''}`} id="pills-tab1" data-bs-toggle="pill" data-bs-target="#pills-1" type="button" role="tab" aria-controls="pills-1" aria-selected="true">
               <div className="stretch-card asigntabnew1">
                  <div className="card-img-holder" onClick={()=>{setActiveTab(tab.key)}}>
                     <div className="card-body text-center">
                        <h4 className="font-weight-normal mb-3 d-flex align-items-center justify-content-center"><span className="valutext ms-0">{totalVal}</span>
                        </h4>
                        <h2 className="mt-0">{tab.value}</h2>
                     </div>
                  </div>
               </div>
            </div>
         </li>
)
}
const OtherQuestion=()=>{
   const dispatch = useDispatch();
   const {
      questionCount:{askQuestion},
      questionCount:{isLoaded},
      search,
      searchReqStatus
     } = useSelector((state) => state.QuestionReducer, shallowEqual);

     const [questionCount, setQuestionCount] = useState(askQuestion);
     const [isLoadedLocal, setIsLoadedLocal] = useState(true);
     const [activeTab,setActiveTab] = useState("All");
     const[pageNo,setPageNo] = useState(0);
     const [filterState,setFilterState]=useState({});

     const getQuestionCount=async()=>{
      setIsLoadedLocal(false)
      if(!isLoaded)
      dispatch(questionCountAction());

      const where = getFilterValue();
      dispatch(questionSearchAction({
         where,
         currentPage: pageNo,
         autopopulate:true
       })); 
    }
    useEffect(()=>{
      if(askQuestion && searchReqStatus===REQUEST_STATUS.SUCCESS) {
         setQuestionCount(askQuestion)
         setIsLoadedLocal(true)
      }
      else
      {
         setIsLoadedLocal(false) 
      }
    },[askQuestion])
   
    useEffect(()=>{
      getQuestionCount();
    },[activeTab,pageNo,filterState])

   const getFilterValue=()=>{
      // let filter={type: { $ne: "ASK50"}};  
      let filter={...filterState,type: { $ne: "ASK50"}};    
         if(activeTab=="Pending")Object.assign(filter,{$or:[{flag:"assigned"},{flag:"pending"}] })
         else if(activeTab=="Answered") Object.assign(filter,{flag:"answered"})         
         else if(activeTab=="Rejected") Object.assign(filter,{flag:"rejected"})
      return filter;
   }
   const handelFilterChange=(payload)=> {
      setFilterState({...filterState,...payload})
   }

   
    return (
            <>
             <div className="content-wrapper">
                  <div className="page-header">
                     <div className="">
                        <h4 className="page-title"> Others Question
                        </h4>
                        <div className="top-breadcrumb">
                           <nav aria-label="breadcrumb">
                              <ol className="breadcrumb ps-0">
                                 <li className="breadcrumb-item"><a href="#">All Questions</a></li>
                                 <li className="breadcrumb-item"><a href="#">Others Question </a></li>
                                 <li className="breadcrumb-item active" aria-current="page">QA</li>
                              </ol>
                           </nav>
                        </div>
                     </div>
                     <div className="">
                        <button className="btnbluen1 plwhit d-flex align-items-center" onClick={()=>"javascript:void(0)"}><i className="mdi mdi-close-circle mdi-18px me-1 text-blue"></i> Solution report</button>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="tabsnrstl1 tabsnrstl-m4">
                           <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            {
                                 QuestionTabs.map((tab,index)=>(
                                    <Tabs 
                                       key={index}
                                       activeTab={activeTab}
                                       setActiveTab={setActiveTab}
                                       tab={tab}
                                       questionCount={questionCount}
                                    />
                                 ))
                              }                               
                           </ul>
                           <div className="col-md-12">
                           <DateRangeCalendar onSelect={handelFilterChange} isSeperate={true}/>
                              {/* <div className="scscm21 scscm21-step2 mtcustom4">
                                 <div className="searchBar">
                                    <input type="search" placeholder="Search Book by ISBN 13" className="form-control"/>
                                    <button><i className="mdi mdi-magnify"></i></button>
                                 </div>
                                 <div className="d-flex align-items-center justify-content-end datewfiltr">
                                    <div className="me-2"><button className="flitrbtn" data-bs-toggle="modal" data-bs-target="#filter-Modal"><i className="mdi mdi-filter-outline mdi-24px"></i></button></div>
                                    <div className="text-start">
                                       <div className="daterangepkr"> <span><i className="mdi mdi-calendar-check"></i></span>
                                          <input type="text" id="demo-13" placeholder="start date - end date" className="form-control form-control-sm"/>
                                       </div>
                                    </div>
                                    <div className="ms-2"><button className="flitrbtn active"><i className="mdi mdi-filter-variant mdi-24px"></i></button></div>
                                 </div>
                              </div> */}
                           </div>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                        {
                              (searchReqStatus!==REQUEST_STATUS.PENDING) ?
                           <>
                           <AllQuestionList 
                              activeTab={activeTab} 
                              setPageNo={setPageNo} 
                              pageNo={pageNo}
                              search={search}
                              />
                              <PendingQuestionList 
                              activeTab={activeTab}
                              setPageNo={setPageNo} 
                              pageNo={pageNo}
                              search={search}
                              />
                              <AnsweredQuestionsList
                              activeTab={activeTab}
                              setPageNo={setPageNo} 
                              pageNo={pageNo}
                              search={search}
                              />
                              <RejectedQuestionsList 
                              activeTab={activeTab}
                              setPageNo={setPageNo} 
                              pageNo={pageNo}
                              search={search}
                              />
                              </>:<Loader />
                             }
                        </div>
                     </div>
                  </div>
               </div>
            </>
    )
}

export default OtherQuestion;