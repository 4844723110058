import { REQUEST_STATUS } from "src/constants/CommonConstants";
import {TUTOR_ACTIONS} from '../actions/TutorActions'

const initialState = () => ({
    currentRecord:{
        name:'',
        email:'',
        Contact:'',
        country:'',
        gender:'',
        img_path:'',
        aboutDetails:'',
        journeyDetails:'',
        services:[],
        Subject:[],
        SubjectId:'',
        subSubject:[],
        subSubjectObj:[],
        subsArr:[],
        SubjectExpertise:[
            { name:'',
             id:'',
             sub_subject:[]}
         ],
         role:'online tutor',
         isDraft:true,
        status:true,
        approve:true,
        created_at:new Date(),
        updated_at:new Date()
    },
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        total: 0,
    },
    searchReqStatus: null,
    upsertReqStatus: null,
})


export default (state = initialState(), action) => {
    switch (action.type) {
        //SEARCH
        case TUTOR_ACTIONS.TUTOR_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING
            }
        }
        case TUTOR_ACTIONS.TUTOR_SEARCH_SUCCESS: {
            return {
                ...state,

                search: {
                    data: action.data,
                    total: action.total,
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case TUTOR_ACTIONS.TUTOR_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        // case EXPERT_ACTIONS.EXPERT_COUNT: {
        //     return {
        //         ...state,
        //         expertCounts: {
        //             Backend: action?.expertCounts?.backendExperts,
        //             Frontend: action?.expertCounts?.frontendExperts,
        //             'Pending Frontend': action?.expertCounts?.pendingExperts,
        //             Draft: action?.expertCounts?.draftExperts
        //         }
        //     };
        // }


        // UPSERT
        case TUTOR_ACTIONS.TUTOR_UPSERT: {
            console.log('tutor update running.',state)
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case TUTOR_ACTIONS.TUTOR_UPSERT_SUCCESS: {
            console.log('tutor update running.',action.state)
                return {
                    ...state,
                    currentRecord: action.data,
                    upsertReqStatus: REQUEST_STATUS.SUCCESS,
                };
        }
        case TUTOR_ACTIONS.TUTOR_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

         // RESET
         case TUTOR_ACTIONS.TUTOR_RESET: {
            return {
                ...state,
                currentRecord: {    name:'',
                email:'',
                Contact:'',
                country:'',
                gender:'',
                img_path:'',
                services:[],
                Subject:[],
                SubjectId:'',
                subSubject:[],
                subSubjectObj:[],
                subsArr:[],
                SubjectExpertise:[
                    { name:'',
                     id:'',
                     sub_subject:[]}
                 ],
                 role:'online tutor',
                 isDraft:false,
                status:true,
                approve:true, },
            };
        }
        default:
            return state;
    }
}

