
const ManageColumn=()=>{
 return(
    <>
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                        <div className="">
                        <h4 className="card-title">Assignment  overview/Pending Assessment </h4>
                        </div>
                        <div className="sltbsxb1">
                        <div className="button-group">
                            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                                <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox" /> <span className="checkmark"></span> </label> </a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
 )
}
export default ManageColumn;