const SOS=(props)=>{
   return (
    <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      SOS </div>
                    <div className="viebtnv1"><a href="#">View Details</a></div>
                  </div>
                  <div className="hdh1bg hdhg2">
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center"> Total SOS </div>
                      <div className="hdh1No">0</div>
                    </div>
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center"> Total Pending </div>
                      <div className="hdh1No">0</div>
                    </div>
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center"> Total Resolved </div>
                      <div className="hdh1No">0</div>
                    </div>
                  </div>
                  <div className="sostxcbg1">
                    <div className="sostxcbg fstsostxcbg">
                      <div className="sostxc1">Total SOS by Students <span>0</span></div>
                      <div className="sostxc2">Still Pending SOS <span>0</span></div>
                      <div className="sostxc2">Resolved SOS <span>0</span></div>
                    </div>
                    <div className="sostxcbg lastsostxcbg">
                      <div className="sostxc1">Total SOS by Tutors <span>0</span></div>
                      <div className="sostxc2">Still Pending SOS <span>0</span></div>
                      <div className="sostxc2">Resolved SOS <span>0</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
   )
}
export default SOS