import React from 'react';
import FormikSearchDropdown from 'src/components/formik/FormikSearchDropdown/FormikSearchDropdown';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import PropTypes from 'prop-types';
import { getSubSubjectById } from 'src/api/Assignment';

const SuSubjectDropDown = ({
    value, name, subjectId, readOnly,onChange,
}) => (
    <FormikSearchDropdown
        name={name}
        placeholder="Select Sub Subject"
        displayKeyPath={[
            'sub_subject',
        ]}
        valueKeyPath="_id"
        defaultValueFetchByKeyPath="_id"
        whereClauseKeysPaths={[
            'sub_subject',
        ]}
        api={getSubSubjectById}
        apiCallAddonData={{
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
        }}
        value={value}
        whereConditions={{ subject_id:subjectId }}
        onChange={onChange} 
        disabled={!subjectId || readOnly}
    />
);

SuSubjectDropDown.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    subjectId: PropTypes.string,
    readOnly: PropTypes.bool,
};

SuSubjectDropDown.defaultProps = {
    // eslint-disable-next-line react/forbid-prop-types
    subjectId: null,
    value: '',
    readOnly: false,
};

export default SuSubjectDropDown;
