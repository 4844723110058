import { Field, ErrorMessage } from 'formik';
import React from 'react' 
import Select from 'react-select';
import { useState } from 'react';
import { useEffect } from 'react';


const MultiSelect = ({
    field,
    form,
    options,
    isMulti = false,
    values,
    idx,
    placeholder = 'Select',
}) => {

const [dropdownOptions, setDropdownOptions] = useState([])

useEffect(()=>{
    if(options.length > 0){
        let option = options.map((item) => ({value:item.subject,id:item._id,label:item.subject}))
        setDropdownOptions(option)
    }
},[options])


    function onChange(option) {
        form.setFieldValue(
            field.name,
            option ? (option).map((item) => item.value) : [],
        );
      option =   option.map((item) => ({name:item.value,id:item.id}))
        values.education[idx].subject = option
    }

    const getValue = () => {
        if (dropdownOptions) {
            return isMulti 
                ? dropdownOptions.filter((option) => field?.value?.indexOf(option?.value) >= 0)
                : dropdownOptions.find((option) => option?.value === field?.value);
        } 
        else {
            return isMulti ? [] : ('');
        }
    };

    if (!isMulti) {
        return (
            <Select
            className="react-select-container"
            classNamePrefix="react-select"
            name={field.name}
            value={getValue()}
            onChange={onChange}
            options={dropdownOptions}
            isMulti={true}
            placeholder={placeholder}
        />
        )
    } else {
        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name={field.name}
                value={getValue()}
                onChange={onChange}
                options={dropdownOptions}
                isMulti={true}
                placeholder={placeholder}
            />
        )
    }
}

const MultiSubjectDropdown = (props) => {
  return (
    <Field 
    name="multiSubSelectCustom"
    id="multiSubSelectCustom"
    placeholder="Select Subject"
    isMulti={true}
    component={MultiSelect}
    {...props}
    />
  )
}

export default MultiSubjectDropdown