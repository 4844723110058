import React, {useState, useEffect} from 'react'
import SeoBreadCrumbSubjectQA from './SeoBreadCrumbSubjectQA'
import { getSingleSubjectAPI, updateQASeoSubject } from 'src/api/MastersApi'
import { getSubjectMasterData } from 'src/api/Assignment'
import { useHistory, useParams } from 'react-router-dom'


const UpdateSubjectSeoQA = () => {

const params = useParams()
const history = useHistory()

const [formData, setFormData] = useState("")   
const [error, setError] = useState('') 
const [metaTitle, setMetaTitle] = useState('')
const [metaDescription, setMetaDescription] = useState("")
const [metaKeywords, setMetaKeywords] = useState('')

useEffect(() => {
getSingleSubject()
}, [])

const getSingleSubject = async () => {
    const { data } = await getSingleSubjectAPI(params.id)
    setMetaDescription(data.data.qa_seo_details.meta_description)
    setMetaTitle(data.data.qa_seo_details.meta_title)
    setMetaKeywords(data.data.qa_seo_details.meta_keywords)
}

const handleSubmit = async () => {

if(formData === ''){
    setError('Please Fill all required fields.')
}
 else if(formData['meta_title'] === undefined || formData['meta_title'] === ''){
    setError('Please Fill Meta Title.')
}
else if(formData['meta_description'] === undefined || formData['meta_description'] === ''){
    setError('Please Fill Meta Description.')
}
else if(formData['meta_keywords'] === undefined || formData['meta_keywords'] === ''){
    setError('Please Fill Meta Keywords.')
}
else {
const updateSeo = await updateQASeoSubject(formData,params.id)
if(updateSeo){
    history.push('/masters/subject')
}
}
}

const handleChange = (e) => {
const data = e.target.value
const trimValue = data.replace(/[^a-zA-Z0-9, ]/g, "");
setFormData({...formData,[e.target.name]:trimValue})
}

    return (
        <div>
            <SeoBreadCrumbSubjectQA />
            <div className='form-group p-4 row'>
                <div className='col-md-8 mt-2'>
                <label>Meta Title</label>
                <input type='text' className='form-control' name='meta_title' placeholder='Meta Title' onChange={(e) => handleChange(e)}
                defaultValue={metaTitle}
                />
                </div>
                <div className='col-md-8 mt-4'>
                <label>Meta Description</label>
                <input type = 'text' className='form-control' name='meta_description' placeholder='Meta Description' onChange={(e) => handleChange(e)}
                defaultValue={metaDescription}
                />
                </div>
                <div className='col-md-8 mt-4'>
                <label>Meta Keywords</label>
                <input type='text' className='form-control' name='meta_keywords' placeholder='Meta Keywords' onChange={(e) => handleChange(e)}
                defaultValue={metaKeywords}
                />
                </div>
                <div className='col-md-8 mt-3'>
                    {
                        error !== '' && (
                            <p className='text-danger'>{error}</p>
                        )
                    }
                <button className='btnbluen1' style={{width:'20%'}} onClick={handleSubmit}>Update Seo</button>
                </div>
            </div>
        </div>
    )
}

export default UpdateSubjectSeoQA