import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_DP } from 'src/constants/CommonConstants';
import SideBarMenuComponent from './SideBarMenuComponent';
import NavigationHeaderComponent from './NavigationHeaderComponent';
import NavigationBrandComponent from './NavigationBrandComponent';
import NavigationSearchComponent from './NavigationSearchComponent';
import NavigationFooterComponent from './NavigationFooterComponent';

const NavigationComponent = ({
    closeSideBar,
    heading,
    menus,
    logout,
    personalInfo,
    userType,
    profilePicUrl,
}) => (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
         
            {/* <NavigationBrandComponent
                heading={heading}
                closeSideBar={closeSideBar}
            /> */}
            {/* <NavigationHeaderComponent
                firstName={personalInfo.firstName}
                lastName={personalInfo.lastName}
                role={userType}
                status="Online"
                dp={profilePicUrl || DEFAULT_DP}
            /> */}
            {/* sidebar-header  */}
            {/* <NavigationSearchComponent /> */}
            {/* sidebar-search  */}
            <SideBarMenuComponent menus={menus} />
            {/* sidebar-menu  */}
        
        {/* sidebar-content  */}
        {/* <NavigationFooterComponent
            logout={logout}
        /> */}
    </nav>
);

NavigationComponent.propTypes = {
    closeSideBar: PropTypes.func.isRequired,
    heading: PropTypes.string.isRequired, // eslint-disable-next-line react/forbid-prop-types
    logout: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    menus: PropTypes.array.isRequired,
    personalInfo: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }).isRequired,
    userType: PropTypes.string.isRequired,
    profilePicUrl: PropTypes.string,
};

NavigationComponent.defaultProps = {
    profilePicUrl: 'https://raw.githubusercontent.com/azouaoui-med/pro-sidebar-template/gh-pages/src/img/user.jpg',
};

export default NavigationComponent;
