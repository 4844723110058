import { useState } from "react";
import { titleCase } from "src/libs/common";

const CountryWiseRegisterStudent = (props) => {
  const [isFullView,setIsFullView] = useState(false)
  const { countryWiseUser = {} } = props;
  const userCountry = countryWiseUser?.total_user?.[0]?.total_user || [];

  // Divide the userCountry array into smaller arrays of length 10
  const columns = userCountry.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 7);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <div className="col-md-12 mtops">
      <div className="card shadow">
        <div className="card-body">
          <div className="books-pan1bg">
            <div className="hdhomX1">
              <div className="hdhomX2 d-flex align-items-center">
                <div className="hdhomX3 yellow-icon me-2">
                  <span>
                    <i className="mdi mdi-account-multiple mdi-24px"></i>
                  </span>
                </div>
                Country Wise Register Student{" "}
              </div>
              <div className="viebtnv1"> 
                {
                  !isFullView?
                  <a href="javascript:void(0)" onClick={()=>{setIsFullView(true)}}>View More</a>:
                  <a href="javascript:void(0)" onClick={()=>{setIsFullView(false)}}>Minimize</a>
              }
              </div>
              
            </div>
            <div className="row">
              {columns.map((column, index) => {
                if(index>2 && !isFullView) return;
                else
             return (
                <div className="col-md-4" key={index}>
                  <div className="uncunrybg">
                    <ul>
                      {column.map((user, index) => (
                        <li key={index}>
                          <div className="uncunry1">{titleCase(user.country)}</div>
                          <div className="uncunry-progss">
                            <div className="progress">
                              <div
                                className="progress-bar bg-gradient-success"
                                role="progressbar"
                                style={{
                                  width: user.percentage.toFixed(0) + "%",
                                }}
                                aria-valuenow="23.54"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <div className="prsg">
                              {user.percentage.toFixed(0)}%
                            </div>
                          </div>
                          <div className="uncunryNo2">{user.count}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )
              }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountryWiseRegisterStudent