import React from 'react'
import SessionFilters from 'src/containers/session-container/SessionFilters'
import Pagination from './Pagination'

const SessionContainerTabPane = ({activeSelectedTab, tableData, pageNo, setPageNo, total,handleFilterChange}) => {
    return(
        <div className="tab-content" id="pills-tabContent">
             <div className="tab-pane fade show active" id={`pills-${activeSelectedTab.id}`} role="tabpanel" aria-labelledby={`pills-tab${activeSelectedTab.id}`}>
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                             <div className="dateinydt">
                                              <SessionFilters handleFilterChange={handleFilterChange}/>
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Session  overview </h4>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>Session ID </th>  
                                                         <th>Student ID </th>  
                                                         <th>Student Name </th>   
                                                         <th>Student Email Id</th>     
                                                         <th>Student Contact N. </th>    
                                                         <th>Date  </th>   
                                                         <th>Preferred Slot </th>  
                                                         <th>Gender </th>        
                                                         <th>Country </th>    
                                                         <th>Tutor ID   </th> 
                                                         <th>Tutor Name   </th> 
                                                         <th>Tutor Email Id  </th>   
                                                         <th>Tutor Contact N.  </th>   
                                                         <th>Master Subject   </th>
                                                         <th>Sub Topic  </th>
                                                         <th>Service Type  </th> 
                                                         {
                                                            activeSelectedTab.tabName === 'Cancelled Sessions' && (
                                                               <th>Cancelled by</th>
                                                            )
                                                         }
                                                                                                                  {
                                                            activeSelectedTab.tabName === 'Cancelled Sessions' && (
                                                               <th>Reasons</th>
                                                            )
                                                         }
                                                         {
                                                                     activeSelectedTab.tabName === 'Unpaid Sessions' && (
                                                                        <th>Pending Amount</th>
                                                                     )
                                                                  }
                                                                     {
                                                                     activeSelectedTab.tabName === 'Session History' && (
                                                                        <th>Session Rating</th>
                                                                     )
                                                                  }
                                                                   {
                                                                     activeSelectedTab.tabName === 'Session History' && (
                                                                        <th>Session Feedback</th>
                                                                     )
                                                                  }
                                                                   {
                                                                     activeSelectedTab.tabName === 'Session History' && (
                                                                        <th>Session Recording</th>
                                                                     )
                                                                  }
                                                                  {
                                                                       activeSelectedTab.tabName === 'Session in Progress' && (
                                                                        <th>Join Session</th>
                                                                     )
                                                                  }
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      {
                                                         tableData.map((data, idx) => {
                                                            return (
                                                               <tr>
                                                                  <td>{idx+1}</td>
                                                                  <td>{data.sessionId}</td>
                                                                  <td>{data.student_id?.id}</td>
                                                                  <td>{data.student_id?.Name}</td>
                                                                  <td>{data.student_id?.Email}
                                                                  <div className="messagebn1">
                                                                  <a href={`mailto:${data.student_id?.Email}`}><button><i className="mdi mdi-email-outline" target="_blank"></i> Message</button></a>
                                                                 </div>
                                                                  </td>
                                                                  <td>
                                                                  <div className="">{data.student_id?.Contact}</div>
                                                            <div className="callphnwhaaap">
                                                            <a href={`https://api.whatsapp.com/send?phone=${data.student_id?.Contact}`} title="Share on whatsapp" target="_blank">         <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button>
                                                                     <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button></a>
                                                            </div>
                                                                  </td>
                                                                  <td>{data.created_at}</td>
                                                                  <td>{data.schedule.start}</td>
                                                                  <td>{data.student_id.Gender}</td>
                                                                  <td>{data.student_id.Country}</td>
                                                                  <td>{data.tutor_id.id}</td>
                                                                  <td>{data.tutor_id.name}</td>
                                                                  <td>{data.tutor_id.email}
                                                                  <div className="messagebn1">
                                                                  <a href={`mailto:${data.tutor_id.email}`}><button><i className="mdi mdi-email-outline" target="_blank"></i> Message</button></a>
                                                                 </div>
                                                                  </td>
                                                                  <td>
                                                                  <div className="">{data.tutor_id.Contact}</div>
                                                            <div className="callphnwhaaap">
                                                            <a href={`https://api.whatsapp.com/send?phone=${data.tutor_id.Contact}`} title="Share on whatsapp" target="_blank">         <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button>
                                                                     <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button></a>
                                                            </div>
                                                                  </td>
                                                                  <td>{data.tutor_id.SubjectExpertise[0].name}</td>
                                                                  <td>{data.expertise}</td>
                                                                  <td>{data.service_type}</td>
                                                                  {
                                                            activeSelectedTab.tabName === 'Cancelled Sessions' && (
                                                               <th>Tutor</th>
                                                            )
                                                         }
                                                         {
                                                            activeSelectedTab.tabName === 'Cancelled Sessions' && (
                                                               <th>{data.cancel_reason ? data.cancel_reason : '--'}</th>
                                                            )
                                                         }
                                                                  {
                                                                     activeSelectedTab.tabName === 'Unpaid Sessions' && (
                                                                        <td>$20</td>
                                                                     )
                                                                  }
                                                                  {
                                                                     activeSelectedTab.tabName === 'Session History' && (
                                                                        <td>{data.reviews.rating}</td>
                                                                     )
                                                                  }
                                                                   {
                                                                     activeSelectedTab.tabName === 'Session History' && (
                                                                        <td>{data.reviews.comment}</td>
                                                                     )
                                                                  }
                                                                   {
                                                                     activeSelectedTab.tabName === 'Session History' && (
                                                                        <td>
                                                                           <div class=""><button class="btnbluen1 playbtn-circle"><i class="mdi mdi-play"></i> </button> </div>
                                                                        </td>
                                                                     )
                                                                  }
                                                                   {
                                                                       activeSelectedTab.tabName === 'Session in Progress' && (
                                                                        <td>
                                                                        <div class=""><button class="btnbluen1">Join Session  </button> </div>
                                                                     </td>
                                                                     )
                                                                  }
                                                               </tr>
                                                            )
                                                         })
                                                      }
                                                       
                                                      {/* <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td>
                                                         <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>
                                                                
                                                         <td>
                                                            <div className="text-bold">$15</div>
                                                         </td>  
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>004586    </td>
                                                         <td>T2540    </td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
                                                         <td>05 August 2022    </td>
                                                         <td>10:00 PM </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														   <td>
															 <div className="dtimgtm">
                                                            <div className="text-bold mb-2">05 August 2022 09:30 AM</div>
                                                            USA (GMT-5) </div>  
                                                         </td>
														  <td>Male </td>
                                                         <td>
                                                            <div className="countryc1 text-bold">(+1) USA </div>
                                                         </td>
                                                         <td>T2540   </td>
                                                         <td><div className="nambtntx"><a href="#">Leonardo Payne </a></div>   </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com  
                                                            <div className="messagebn1"><button><i className="mdi mdi-email-outline"></i> Message</button></div>
                                                         </td>
                                                         <td>
                                                            <div className="">+91 7985298548</div>
                                                            <div className="callphnwhaaap"><button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button> </div>
                                                         </td>
														  <td><div className="">Math</div></td>
														  <td><div className="">Algebra</div></td>
														  <td><div className="">Take a Doubt Session</div></td>
                                                                
                                                         <td>
                                                            <div className="text-bold">$15</div>
                                                         </td>  
                                                      </tr>  */}
                                                   </tbody>
                                                </table>
                                                {tableData && tableData?.length > 0 && <Pagination total={total} data={tableData} setPageNo={setPageNo} pageNo={pageNo} />}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
            </div>
    )
}

export default SessionContainerTabPane