import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const getAllChats = function (data) {
    const url = `${config.webUrl}chat/get-all-assistance-messages`;
    // const url = 'http://localhost:8000/web/v1/chat/get-all-assistance-messages'
    return postRequest({
        url,
        data
    });
};

export default async function saveChat(data){
    const url = `${config.webUrl}chat/save-mentor-message`;
    // const url = 'http://localhost:8000/web/v1/chat/save-mentor-message'
    return postRequest({
        url,
        data
    });
}

export  async function saveFiles(data){
    const url = `${config.webUrl}chat/save-files`;
    // const url = 'http://localhost:8000/web/v1/chat/save-files'
    return postRequest({
        url,
        data
    });
}