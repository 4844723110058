// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***** Loading Bar *****/
#nprogress {
  pointer-events: all;
  z-index: 99999;
  border: none;
  margin: 0px;
  padding: 0px;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6); }

#nprogress .bar {
  background: #c31432;
  /* fallback for old browsers */
  background: linear-gradient(to right, #240b36, #c31432);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 7px !important; }
  @media screen and (max-width: 750) {
    #nprogress .bar {
      height: 4px; } }

#nprogress .peg {
  box-shadow: 0 0 10px #0ee639, 0 0 5px #1ecb52 !important; }

#nprogress .spinner-icon {
  border-top-color: #3b3bd8 !important;
  border-left-color: blue !important; }
`, "",{"version":3,"sources":["webpack://./src/containers/alert-container/AlertContainer.scss"],"names":[],"mappings":"AAAA,wBAAA;AACA;EACE,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,WAAW;EACX,YAAY;EAGZ,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,eAAe;EACf,oCAAoC,EAAA;;AAGtC;EACE,mBAAmB;EAAE,8BAAA;EACrB,uDAIC;EAAE,qEAAA;EAEH,sBAAsB,EAAA;EACtB;IATF;MAUI,WAAW,EAAA,EAEd;;AAED;EACE,wDAA0E,EAAA;;AAG5E;EACE,oCAA6C;EAC7C,kCAAkC,EAAA","sourcesContent":["/***** Loading Bar *****/\r\n#nprogress {\r\n  pointer-events: all;\r\n  z-index: 99999;\r\n  border: none;\r\n  margin: 0px;\r\n  padding: 0px;\r\n  // width: 100%;\r\n  // height: 100%;\r\n  top: 0px;\r\n  left: 0px;\r\n  cursor: wait;\r\n  position: fixed;\r\n  background-color: rgba(0, 0, 0, 0.6);\r\n}\r\n\r\n#nprogress .bar {\r\n  background: #c31432; /* fallback for old browsers */\r\n  background: linear-gradient(\r\n    to right,\r\n    #240b36,\r\n    #c31432\r\n  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */\r\n\r\n  height: 7px !important;\r\n  @media screen and (max-width: 750) {\r\n    height: 4px;\r\n  }\r\n}\r\n\r\n#nprogress .peg {\r\n  box-shadow: 0 0 10px rgb(14, 230, 57), 0 0 5px rgb(30, 203, 82) !important;\r\n}\r\n\r\n#nprogress .spinner-icon {\r\n  border-top-color: rgb(59, 59, 216) !important;\r\n  border-left-color: blue !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
