import React from 'react'

const BidExpertModal =  ({subjectId, subSubjectId, bidTutors}) =>{
    // const getBidExperts = () => {

    // }
    return (
        <>
         <div className="modal fade sman21-modal" id="biddingdetails-Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
               <div className="modal-header border-0"> 
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="mdi mdi-close"></i></button>
               </div>
               <div className="modal-body pt-0">
                  <div className="bgstrt">
                     <div className="bgstrtvb1 d-flex justify-content-between align-items-center">
                        <h3>Bidding Details</h3>
                        {/* <div className="lowtohigh"><button className="active">Low to High </button><button className="">High to Low </button><button className="">Recent</button></div> */}
                     </div>
                     {
                        bidTutors.length > 0 ? bidTutors.map((bidTut) => {
                            return (
                                <div className="bgNew-white snpn-1 bsupdhad">
                                <div className="d-flex align-items-start expertNew4 expertNewXvz">
                                   <div className="expertFild expertFildbgn1">
                                      <div className="d-flex align-items-center">
                                         <div className="d-flex justify-content-between align-items-start">
                                            <div className="expertFildImg">
                                               <span className="img"><img src="assets/images/super-admin/man-img.png" className="img-fluid" alt=""/></span>
                                               <div className="expert_Id">
                                                  Expert ID : {bidTut?.tutor_id?.id}
                                               </div>
                                            </div>
                                         </div>
                                         <div className="expertFildSub">
                                            <div className="d-flex justify-content-between align-items-start">
                                               <div className="expertFildsbu1">
                                                  {/* <p>PHD in Math</p> */}
                                                  <h2>{bidTut?.tutor_id?.name}</h2>
                                               </div>
                                            </div>
                                            <p><span>Expertise:</span> {
                                                bidTut?.tutor_id?.education[0].subsubject.map((subs) => {
                                                    return (
                                                        <>
                                                        {subs.name}, 
                                                        </>
                                                    )
                                                })
                                            }</p>
                                            <ul>
                                               {/* <li><span><img src="assets/images/super-admin/language-icon.svg" className="img-fluid" alt=""/></span>English, Arabic, Chinese, Hebrew</li> */}
                                               <li><span><img src="assets/images/super-admin/location-icon.svg" className="img-fluid" alt=""/></span>Living in {bidTut?.tutor_id?.address}</li>
                                            </ul>
                                         </div>
                                      </div>
                                   </div>
                                   <div className="yuhdg321c">
                                      <div className="yearofexbg1">
                                         <div className="d-flex align-items-center yearofexbg">
                                            <span className="icon"><img src="assets/images/super-admin/yearofex.svg" className="img-fluid" alt="" /></span>
                                            <div className="yearofextext sgbboldc">
                                               <h4>
                                                {/* <span>3 months+</span>  */}
                                                 Tenure with us</h4>
                                            </div>
                                         </div>
                                         <div className="d-flex align-items-center yearofexbg">
                                            <span className="icon"><img src="assets/images/super-admin/assignmt-icon.svg" className="img-fluid" alt="" /></span>
                                            <div className="yearofextext">
                                               <h4>
                                                {/* <span>1353</span> */}
                                                 assignments </h4>
                                               <p>Deliver with in deadline</p>
                                            </div>
                                         </div>
                                      </div>
                                      {/* <div className="d-flex justify-content-between align-items-center cwopage-1 cwopage-2">
                                         Current Working  on Projects  <span>4</span>
                                      </div> */}
                                      <div className="d-flex justify-content-between align-items-center">
                                         <div className="text-center intamunctmbg intamunctmbg1 d-flex align-items-center">
                                            <div className="bdingPrvbcn1 d-flex align-items-center">
                                               Bidding Price 
                                               <div className="price"><span>$</span>{bidTut?.bid_price}</div>
                                            </div>
                                         </div>
                                         {/* <div className="">
                                            <button className="btnbluen1">Assign </button>
                                         </div> */}
                                      </div>
                                   </div>
                                </div>
                             </div>
                            )
                        }) : (
                            <div className="bgNew-white snpn-1 bsupdhad">
                                No bids to show currently.
                            </div>
                        )
                     }
                     {/* <div className="bgNew-white snpn-1 bsupdhad" style={{border:'1px solid red'}}>
                        <div className="d-flex align-items-start expertNew4 expertNewXvz">
                           <div className="expertFild expertFildbgn1">
                              <div className="d-flex align-items-center">
                                 <div className="d-flex justify-content-between align-items-start">
                                    <div className="expertFildImg">
                                       <span className="img"><img src="assets/images/super-admin/man-img.png" className="img-fluid" alt=""/></span>
                                       <div className="expert_Id">
                                          Expert ID : 8568
                                       </div>
                                    </div>
                                 </div>
                                 <div className="expertFildSub">
                                    <div className="d-flex justify-content-between align-items-start">
                                       <div className="expertFildsbu1">
                                          <p>PHD in Math</p>
                                          <h2>Mistie Monge</h2>
                                       </div>
                                    </div>
                                    <p><span>Expertise:</span> Calculus, statistics, <a href="#">15+ more</a></p>
                                    <ul>
                                       <li><span><img src="assets/images/super-admin/language-icon.svg" className="img-fluid" alt=""/></span>English, Arabic, Chinese, Hebrew</li>
                                       <li><span><img src="assets/images/super-admin/location-icon.svg" className="img-fluid" alt=""/></span>Living in Klerksdorp, South Africa (12:44 UTC+02:00)</li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           <div className="yuhdg321c">
                              <div className="yearofexbg1">
                                 <div className="d-flex align-items-center yearofexbg">
                                    <span className="icon"><img src="assets/images/super-admin/yearofex.svg" className="img-fluid" alt="" /></span>
                                    <div className="yearofextext sgbboldc">
                                       <h4><span>3 months+</span>  Tenure with us</h4>
                                    </div>
                                 </div>
                                 <div className="d-flex align-items-center yearofexbg">
                                    <span className="icon"><img src="assets/images/super-admin/assignmt-icon.svg" className="img-fluid" alt="" /></span>
                                    <div className="yearofextext">
                                       <h4><span>1353</span> assignments </h4>
                                       <p>Deliver with in deadline</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center cwopage-1 cwopage-2">
                                 Current Working  on Projects  <span>4</span>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                 <div className="text-center intamunctmbg intamunctmbg1 d-flex align-items-center">
                                    <div className="bdingPrvbcn1 d-flex align-items-center">
                                       Bidding Price 
                                       <div className="price"><span>$</span>15</div>
                                    </div>
                                 </div>
                                 <div className="">
                                    <button className="btnbluen1">Assign </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="bgNew-white snpn-1 bsupdhad">
                        <div className="d-flex align-items-start expertNew4 expertNewXvz">
                           <div className="expertFild expertFildbgn1">
                              <div className="d-flex align-items-center">
                                 <div className="d-flex justify-content-between align-items-start">
                                    <div className="expertFildImg">
                                       <span className="img"><img src="assets/images/super-admin/man-img.png" className="img-fluid" alt="" /></span>
                                       <div className="expert_Id">
                                          Expert ID : 8568
                                       </div>
                                    </div>
                                 </div>
                                 <div className="expertFildSub">
                                    <div className="d-flex justify-content-between align-items-start">
                                       <div className="expertFildsbu1">
                                          <p>PHD in Math</p>
                                          <h2>Mistie Monge</h2>
                                       </div>
                                    </div>
                                    <p><span>Expertise:</span> Calculus, statistics, <a href="#">15+ more</a></p>
                                    <ul>
                                       <li><span><img src="assets/images/super-admin/language-icon.svg" className="img-fluid" alt=""/></span>English, Arabic, Chinese, Hebrew</li>
                                       <li><span><img src="assets/images/super-admin/location-icon.svg" className="img-fluid" alt=""/></span>Living in Klerksdorp, South Africa (12:44 UTC+02:00)</li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           <div className="yuhdg321c">
                              <div className="yearofexbg1">
                                 <div className="d-flex align-items-center yearofexbg">
                                    <span className="icon"><img src="assets/images/super-admin/yearofex.svg" className="img-fluid" alt=""/></span>
                                    <div className="yearofextext sgbboldc">
                                       <h4><span>3 months+</span>  Tenure with us</h4>
                                    </div>
                                 </div>
                                 <div className="d-flex align-items-center yearofexbg">
                                    <span className="icon"><img src="assets/images/super-admin/assignmt-icon.svg" className="img-fluid" alt=""/></span>
                                    <div className="yearofextext">
                                       <h4><span>1353</span> assignments </h4>
                                       <p>Deliver with in deadline</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center cwopage-1 cwopage-2">
                                 Current Working  on Projects  <span>4</span>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                 <div className="text-center intamunctmbg intamunctmbg1 d-flex align-items-center">
                                    <div className="bdingPrvbcn1 d-flex align-items-center">
                                       Bidding Price 
                                       <div className="price"><span>$</span>15</div>
                                    </div>
                                 </div>
                                 <div className="">
                                    <button className="btnbluen1">Assign </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="bgNew-white snpn-1 bsupdhad">
                        <div className="d-flex align-items-start expertNew4 expertNewXvz">
                           <div className="expertFild expertFildbgn1">
                              <div className="d-flex align-items-center">
                                 <div className="d-flex justify-content-between align-items-start">
                                    <div className="expertFildImg">
                                       <span className="img"><img src="assets/images/super-admin/man-img.png" className="img-fluid" alt=""/></span>
                                       <div className="expert_Id">
                                          Expert ID : 8568
                                       </div>
                                    </div>
                                 </div>
                                 <div className="expertFildSub">
                                    <div className="d-flex justify-content-between align-items-start">
                                       <div className="expertFildsbu1">
                                          <p>PHD in Math</p>
                                          <h2>Mistie Monge</h2>
                                       </div>
                                    </div>
                                    <p><span>Expertise:</span> Calculus, statistics, <a href="#">15+ more</a></p>
                                    <ul>
                                       <li><span><img src="assets/images/super-admin/language-icon.svg" className="img-fluid" alt=""/></span>English, Arabic, Chinese, Hebrew</li>
                                       <li><span><img src="assets/images/super-admin/location-icon.svg" className="img-fluid" alt=""/></span>Living in Klerksdorp, South Africa (12:44 UTC+02:00)</li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           <div className="yuhdg321c">
                              <div className="yearofexbg1">
                                 <div className="d-flex align-items-center yearofexbg">
                                    <span className="icon"><img src="assets/images/super-admin/yearofex.svg" className="img-fluid" alt=""/></span>
                                    <div className="yearofextext sgbboldc">
                                       <h4><span>3 months+</span>  Tenure with us</h4>
                                    </div>
                                 </div>
                                 <div className="d-flex align-items-center yearofexbg">
                                    <span className="icon"><img src="assets/images/super-admin/assignmt-icon.svg" className="img-fluid" alt=""/></span>
                                    <div className="yearofextext">
                                       <h4><span>1353</span> assignments </h4>
                                       <p>Deliver with in deadline</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center cwopage-1 cwopage-2">
                                 Current Working  on Projects  <span>4</span>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                 <div className="text-center intamunctmbg intamunctmbg1 d-flex align-items-center">
                                    <div className="bdingPrvbcn1 d-flex align-items-center">
                                       Bidding Price 
                                       <div className="price"><span>$</span>15</div>
                                    </div>
                                 </div>
                                 <div className="">
                                    <button className="btnbluen1">Assign </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
        </>
    )
}

export default BidExpertModal