import React, {useEffect, useState} from 'react'
import SEOBreadCrumb from './SEOBreadCrumb'
import * as Yup from 'yup'
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput';
import FormikTextArea from 'src/components/formik/FormikTextArea';
import { useCallback } from 'react';
import { BookYupSchema } from './BookPropTypes';
import { useDispatch, useSelector } from 'react-redux';
import { addBookFaqAPI } from 'src/api/BookApi';
import { useLocation, useParams } from 'react-router-dom';

const validationSchema = Yup.object({
    question: Yup.string().required("*Question Required"),
    answer: Yup.string().required("*Answer Required"),
});
const Render = (props) => {
  const params = useParams()
    const { currentRecord, search } = useSelector((state) => state.BookReducer)
    const [faqArr, setFaqArr] = useState([])
    const {
        setFieldValue,
        values,
        // errors,
        // touched,
        isSubmitting,
        onSearch,
        onClear,
        resetForm,
        handelChangeUpload,
        btnDisabled,
        setInitialValues,
        onSubmit
    } = props;

    useEffect(() => {
        const {faqs} = currentRecord
        console.log('data is',faqs)
        setFaqArr(faqs)
    },[currentRecord])

    const handleFaqEdit = (faq) => {
        console.log('faq clicked is',faq)
        // values.question = faq.question
        // values.answer = faq.answer
        // values.faq_id = faq._id
        setInitialValues({
            'question':faq.question,
            'answer':faq.answer,
             'faq_id':faq._id
        })
    }

    const handleFaqDelete = (faq) => {
      
    }

    return (
        <>
            <SEOBreadCrumb book_isbn={params.book_isbn} book_id={params.book_id}/>
            <div className='row ps-4 pe-4 pt-4'>
                <div className='col-md-4'>
                    <p>Manage Faq`s</p>
                    <hr />
                    <Form>
                        <p>Question</p>
                        <FormikInput
                            type="text"
                            placeholder="Enter question"
                            name="question"
                            className="form-control  enchilada"
                        // value={values.question}
                        />
                        <p>Answers</p>
                        <FormikTextArea
                            type="text"
                            placeholder="Enter question"
                            name="answer"
                            className="form-control  enchilada"
                            // value={values.answer}
                        />
                        <button className='mt-3 btnbluen1 mb-3 text-center' type="submit" onClick={() => onSubmit(values)}>Save FAQ</button>
                    </Form>

                </div>
                <div className='col-md-1' />
                <div className='col-md-6'>
                            <div
                            style={{
                              height: "420px",
                              overflowY: "scroll",
                              paddingRight: "15px",
                            }}
                            id="faqDiv"
                          >
                            {faqArr?.map((faq) => {
                              return (
                                <div
                                  className="subject-card"
                                  style={{ width: "100%" }}
                                  key={faq._id}
                                >
                                  <div className="subject-card-body">
                                    <div className="">
                                      <div className="">
                                        <span className="mdi mdi-account fa-1x mr-2 pt-1"></span>
                                        &nbsp; Question:
                                      </div>
                                      <div className="text-danger">
                                        {faq?.question}
                                      </div>
                                    </div>
                                    <hr className="mb-1 mt-1" />
                                    <div className="">
                                      <div className="">
                                        <span className="mdi mdi-comment fa-1x mr-2 pt-1"></span>
                                        &nbsp; Answer:
                                      </div>
                                      <div className="clearfix"></div>
                                      <div className="text-justify text-info">
                                        {faq?.answer?.substr(0, 80) + "..."}
                                      </div>
                                    </div>
                                  </div>
                                  <hr className="mt-1 mb-2" />
<div className='subject-card-heading'>
    <div>                                   
status
</div>
    <div>
    <span className="mdi mdi-tooltip-edit fa-1x mr-2 pt-1" onClick={() => handleFaqEdit(faq)}></span>
    <span className="mdi mdi-delete fa-1x mr-2 pt-1" onClick={() => handleFaqDelete(faq)}></span>
    </div>
</div>
                                  {/* <FAQHeading faq={faq} /> */}
                                </div>
                              );
                            })}
                          </div>
                    <hr />
                </div>
            </div>
        </>
    )
}

const BookFAQ = () => {

    const { currentRecord } = useSelector((state) => state.BookReducer)
    // const {}
    const [bookIsbn, setBookIsbn] = useState('')
    const [bookId, setBookId] = useState('')
     
    const [initialValues, setInitialValues] = useState({
        question: "",
        answer: "",
        faq_id:''
      });

      const validationSchema = Yup.object({
        question: Yup.string().required("*Question Required"),
        answer: Yup.string().required("*Answer Required"),
      });

    // const onSubmit = useCallback((values,formikUtils)=>{
    //     ({ setSubmitting } = formikUtils);
    //     if(file){
    //       values.isUpload=true
    //       values.file=file
    //     }
    //     delete values.type;
    //    //  if(values.scoDetails.length===0 ) delete values.scoDetails;
    //     dispatch(bookUpsertAction(values))
    //  },[dispatch])  

    useEffect(() => {
     setBookId(currentRecord._id)
     setBookIsbn(currentRecord['ISBN13'])
    },[])
    
    const onSubmit = async (values) => {
        if(values.question === '' || values.answer === ''){
            return 
        } else {
            const add_faq = await addBookFaqAPI({...values,book_id:bookId, isbn:bookIsbn})
            if(add_faq){
setInitialValues({
    question:'',
    answer:'',
    faq_id:''
})
            }
        }
    }

    return (
        // <p>faq page </p>
        <Formik
        onSubmit={onSubmit}
        // onClear={onClear}
        // onSearch={onSearch}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        >
            {(formikProps) => (
                <Render
                // onClear={onClear}
                // onSearch={onSearch}
                setInitialValues={setInitialValues}
                onSubmit={onSubmit}
                {...formikProps}
                />
            )}
        </Formik>
    )
}

export default BookFAQ