import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const BlogPropTypes = PropTypes.shape({
    _id: PropTypes.string,
    categoryId: PropTypes.string,
    tags: PropTypes.any,
    author: PropTypes.string,
    schedule: PropTypes.string,
    title: PropTypes.string,
    blogURL: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    isPreview:PropTypes.boolean,

    isPublish: PropTypes.boolean,
    isPopular: PropTypes.boolean,
});


export const BlogDefaultProps = {
    categoryId: '',
    tags: [],
    authorId: '',
    schedule: '',
    title: '',
    blogURL: '',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    isPublish: true,
    isPopular: true,
    isPreview:false,
    _id: undefined,
};

 

export const BlogYupSchema = Yup.object().shape({
    categoryId: Yup.string().required('Required'),
    // tags: Yup.string().required('Required'),
    authorId: Yup.string().required('Required'),
    schedule: Yup.string().required('Required'),
    title: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    blogURL: Yup.string().required('Required'),
    metaTitle: Yup.string().required('Required'),
    metaDescription: Yup.string().required('Required'), 
});

export default {
    BlogDefaultProps,
    BlogPropTypes,
    BlogYupSchema,
};
