import React, { useEffect, useState } from "react";
import DayVisitsReport from "./dayVisitsReport";
import CountriesReport from "./countriesReport";
import PageviewsReport from "./pageviewReport";
import SourceReport from "./sourceReport";
import BrowsersReport from "./browsersReport";
import DevicesReport from "./devicesReport";
 
import { LastRow } from "./styles";
 

const DashBoard = (props) => {    
  const {
    GoogleAPI: {
      sessionPerDay = {}, 
      userPerDay = {}, 
      topCountry = {},
      sourceData = {},
      topPages = {},
      browserData = {},
      devicesData = {}
    } = {}
  } = props; 
  return (
     <> 
        <>
        {
          (Object.keys(userPerDay).length > 0) &&
           <DayVisitsReport 
            sessionPerDay={userPerDay}
            title={"Users"}
          />
        } 
        {
          (Object.keys(sessionPerDay).length > 0) &&
            <DayVisitsReport
            sessionPerDay={sessionPerDay}
            title={"Sessions"} 
            />
        }        
        {
          (Object.keys(sessionPerDay).length > 0) &&
          <CountriesReport topCountry={topCountry} />
        }
        {
          (Object.keys(topPages).length > 0) &&
          <PageviewsReport topPages={topPages} />
        }
        {
          (Object.keys(sourceData).length > 0) &&
          <SourceReport sourceData={sourceData} />
        }
        {
          (Object.keys(browserData).length > 0) &&
          <LastRow>
            <BrowsersReport browserData={browserData} />
            <DevicesReport devicesData={devicesData} />
          </LastRow>
        } 
        </> 
    </>
  );
};

export default DashBoard;
