export const TableContents = [
    {
        label:'SOS ID',
        value:''
    },
    {
        label:'Raised by',
        value:''
    },
    {
        label:'Name',
        value:''
    },
    {
        label:'Student Email Id',
        value:''
    },
    {
        label:'Student Contact N.',
        value:''
    },
    {
        label:'Subject',
        value:''
    },
    {
        label:'Sub Subject',
        value:''
    },
    {
        label:'Date',
        value:''
    },
    {
        label:'Student Gender',
        value:''
    },
    {
        label:'Student Country',
        value:''
    },
    {
        label:'Tutor ID',
        value:''
    },
    {
        label:'Tutor Name',
        value:''
    },
    {
        label:'Tutor Email Id',
        value:''
    },
    {
        label:'Tutor Contact N.',
        value:''
    },
    {
        label:'SOS Message',
        value:''
    },
    {
        label:'Session Time',
        value:''
    },
    {
        label:'Status',
        value:''
    },
    {
        label:'Session Duration',
        value:''
    },
    {
        label:'Action',
        value:''
    },
]