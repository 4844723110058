import React from 'react'
import { useHistory } from 'react-router-dom'
import { deleteFaqCategory } from 'src/api/FAQApi'
import { MakeSlug } from 'src/utils/CommonUtils'
import RoutingUtil from 'src/utils/RoutingUtil'

const SingleFaqContainer = ({faq}) => {

const history = useHistory()

const handleDeleteFaqCategory = async (faqId) => {
    const removeFaq =  await deleteFaqCategory(faqId)
    if(removeFaq){
        history.push(RoutingUtil.faqManagerUrl())
    }
}


    return (
<>
<div className="small-card p-2" key={faq._id} id={`card-${faq._id}`} style={{border:'1px solid grey'}}>

<div className="subject-card-body ">
<div className="admin-name"> 
    <img src={faq.faq_image} style={{ width: '100px'}}/>
    <div className="name-main">
        {faq.faq_category}
    </div>
</div>     
</div> 
<hr className="mt-1 mb-1"/>
<div className='row justify-content-end'>
    <div className='col-md-4'>
        <span className='mdi mdi-plus-circle text-success me-2' style={{cursor:'pointer'}} onClick={() => history.push(`/faq/add-faq-question/${MakeSlug(faq.faq_category)}/${faq._id}`)}></span>
        <span className='mdi mdi-tooltip-edit me-2' style={{cursor:'pointer'}} onClick={() => history.push(`/faq/edit-faq-category/${faq._id}`)}></span>
        <span className='mdi mdi-delete text-danger' style={{cursor:'pointer'}} onClick={() => handleDeleteFaqCategory(faq._id)}></span>
    </div>
</div>
</div>
</>
    )
}

export default SingleFaqContainer