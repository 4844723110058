/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import LinkComponent from '../LinkComponent';

const NavigationBrandComponent = ({ heading, closeSideBar }) => (
    <div className="sidebar-brand">
        <LinkComponent>
            <img
                src="/logo.jpg"
                alt="user"
                style={{
                    height: '80px',
                }}
            />
        </LinkComponent>
        <div
            id="close-sidebar"
            onClick={closeSideBar}
        >
            <i className="fas fa-times" />
        </div>
    </div>
);

NavigationBrandComponent.propTypes = {
    heading: PropTypes.string.isRequired,
    closeSideBar: PropTypes.func.isRequired,
};

export default NavigationBrandComponent;
