import PropTypes from 'prop-types';
import { isProduction } from 'src/config/index';
import { withFormikDevtools } from 'formik-devtools-extension';
import { Formik, Form } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BlogYupSchema } from 'src/containers/blog-container/BlogPropTypes';
import FormikInput from 'src/components/formik/FormikInput';
import FormikTextArea from 'src/components/formik/FormikTextArea';
import FormikCKEditor from 'src/components/formik/FormikCKEditor/FormikCKEditor';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { blogResetAction, blogUpsertAction } from './actions/BlogActions';
import FormikFileDirect from 'src/components/formik/FormikFileDirect';
import { convertBase64,MakeSlug } from 'src/utils/CommonUtils';
import RoutingUtil from 'src/utils/RoutingUtil';
import { useHistory } from 'react-router-dom';
import FormCreateUpdate from './FormCreateUpdate';
import TagDropDown from 'src/business-components/TagDropDown';
import FormikToggle from 'src/components/formik/FormikToggle';
import AuthorDropDown from 'src/business-components/AuthorDropDown';
import FormikMathTypeCKEditor from 'src/components/formik/FormikCKEditor/FormikMathTypeCKEditor';
import CategoryDropDown from 'src/business-components/CategoryDropDown';
import config from 'src/config';

const Render=(props)=>{
   const history = useHistory();
    // DEV TOOL
    if (!isProduction()) {
        withFormikDevtools(props);
    }
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [CKEditorData, setCKEditorData] = useState("");
    const [imagePre,setImagePre] = useState("/assets/images/super-admin/home/icon.svg");

    useEffect(() => {
    setEditorLoaded(true);
    }, []);
    const handelImagePreview=async(e)=>{
      const file = e.target.files[0];       
      const img64 =await convertBase64(file)
      setImagePre(img64)
      values.img_path=file
    }


    const {
        initialValues,
        setFieldValue,
        values,
        // errors,
        // touched,
        onSubmit,
        isSubmitting,
        onSearch,
        onClear,
        resetForm,
    } = props;


    const handelPreviewPage=(id)=>{
      values.isPreview = true;
      values.isPublish = false;       
      onSubmit(values); 
    }
    useEffect(()=>{
      values.blogURL = MakeSlug(values.title)
    },[values.title])


return (
        <div className="content-wrapper">
            <Form className="forms-sample">
        <div className="page-header">
           <h4 className="page-title"> Create Blogs
           </h4>
           <div className="">
                {/* <button type="submit" className="btn bg-gradient-primary btn-rounded text-white btn-fw">Post</button> */}               
               {/* <button type="button" class="btn bg-gradient-outline btn-rounded btn-fw">Save in Draft</button> */}
               <button type="button"  className="btn bg-gradient-outline btn-rounded btn-fw" onClick={()=>{history.push('/blogs/search')}} >View Blogs</button>
           </div>
        </div>
        <div className="row">
           <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                 <div className="card-body">
                    
                       <div className="row">
                          <div className="col-md-3 form-group">
                             <label className="d-flex justify-content-between align-items-center">Category</label>                              
                             {/* <FormikSelect
                                items={BLOG_CATEGORY}
                                name="category"
                                className="form-select"
                                whereConditions={{type:"category"}}
                                value={values.category}
                            /> */}
                            <CategoryDropDown
                             name="categoryId"
                             className="form-select"
                             whereConditions={{type:"category"}}
                             value={values.categoryId}
                             onChange={(value)=>values.categoryId = value}
                            />
                          </div>
                          <div className="col-md-3 form-group">
                             <label className="d-flex justify-content-between align-items-center">Tags</label>
                              <TagDropDown
                              // className="form-select"
                              name="tags"
                              value={values.tags}
                              whereConditions={{type:"tags"}}
                              onChange={(id)=>{
                                 values.tags=id;
                              }}
                              />
                             {/* <SelectSearchExp/> */}
                          </div>
                          <div className="col-md-3 form-group">
                             <label className="d-flex justify-content-between align-items-center">Select Author</label>    
                                <AuthorDropDown 
                                placeholder="Enter blog author"
                                name="authorId"
                                className="form-control"
                                value={values.authorId}
                                onChange={(id)=>{
                                 values.authorId=id
                                }}
                                />
                          </div>
                          <div className="col-md-3 form-group">
                             <label>Schedule</label>
                             <div className="datepicker" id="datepicker">
                                {/* <input type="datetime-local" className="form-control  enchilada"/> */}
                                <FormikInput
                                    type="datetime-local"
                                    placeholder="Blog Schedule"
                                    name="schedule"
                                    className="form-control  enchilada"
                                    value={values.schedule}
                                />
                             </div>
                          </div>
                          <div className="col-md-6 form-group">
                             <label>Title</label>  
                             <FormikInput
                                    type="text"
                                    placeholder="Blog Title"
                                    name="title"
                                    className="form-control"
                                    value={values.title}
                                />
                          </div>
                          <div className="col-md-6 form-group">
                             <label className="d-flex justify-content-between align-items-center">Blog URL 
                             {/* <span>
                                    <button className="editbtn me-3">Copy URL</button>
                                    <button className="editbtn">Edit</button>
                                 </span> */}
                            </label>
                             <FormikInput
                                    type="text"
                                    name="blogURL"
                                    placeholder="Blog Title URL"
                                    className="form-control"
                                    value={values.blogURL}
                                />
                                <span>
                                     <button className="editbtn me-3">Full URL</button>
                                     {`${config.clientUrl}blog/${values.blogURL}`}
                                </span>
                          </div>
                          <div className="col-md-12 form-group">
                             <label>Meta Title</label> 
                             <FormikInput
                                    type="text"
                                    placeholder="Meta Title"
                                    name="metaTitle"
                                    className="form-control"
                                    value={values.metaTitle}
                                />
                          </div>
                          <div className="col-md-12 form-group">
                             <label>Meta Description</label> 
                             <FormikTextArea 
                                    name="metaDescription"
                                    placeholder="Meta Description"
                                    rows="5"
                                    className="form-control"
                                    value={values.metaDescription}
                                />
                          </div>
                          <div className="col-md-6 form-group">
                             <label>Meta Keywords</label> 
                             <FormikInput
                                    type="text"
                                    name="metaKeywords"
                                    className="form-control"
                                    value={values.metaKeywords}
                                />
                          </div>
                          <div className="col-md-6 form-group">
                             <label>Image Alt</label> 
                             <FormikInput
                                    type="text"
                                    placeholder="Image Alt"
                                    name="imageAlt"
                                    className="form-control"
                                    value={values.imageAlt}
                                />
                          </div>
                          <div className="col-md-12 form-group">
                             <label>Body Content</label>                                
                             <FormikCKEditor  
                              id="editor1"  
                              name="blog"
                              onBlur={(data) => {
                                 // values.blog=data;
                                 // setCKEditorData(data)
                                 setFieldValue("blog", data)     
                              }}
                              value={values.blog}
                              editorLoaded={editorLoaded}
                             /> 
                          </div>
                          <div className="col-md-12 form-group"> 
                             <div className="imgulpdn1xz">
                                <div className="privimgbvc1">
                                   <div className="js--image-preview" style={{backgroundImage:`url("${imagePre}"`}}></div>                                  
                                </div>
                                <FormikToggle 
                                name="isPopular" 
                                className="form-control" 
                                onLabel="Popular"
                                offLabel="Non Popular"
                                value={values.isPopular}
                                />
                                <FormikToggle 
                                name="isPublish" 
                                className="form-control" 
                                onLabel="Publish"
                                offLabel="Save in Draft"
                                value={values.isPublish}
                                />
                                <div className="upload-options">
                                   <div className="thumbntex">Thumbnail Image </div>
                                   <label>
                                   {/* <input type="file" className="image-upload" accept="image/*" />*/}
                                       <FormikFileDirect
                                          name="img_path"
                                          accept="image/*"
                                          className="image-upload custom-file-input"
                                          onChange={(e)=>{handelImagePreview(e)}}                                          
                                       />                                       
                                   </label>
                                   <div className="Imgmbntex">Image size 1200x628px</div>
                                </div>
                             </div>
                          </div>
                       </div>
                       <div className="text-center">               
                           {/* <button type="submit" className="btn btn-gradient-primary me-2">Post</button> */}
                           <FormCreateUpdate
                              isSubmitting={isSubmitting}
                              isUpdate={Boolean(values._id)}
                              onSearch={onSearch}
                              onClear={onClear}
                              resetForm={resetForm}
                              values={values}
                              submitButtonText="Publish"
                              onSubmitClick={()=>{
                                 values.isPreview = false;
                              }}
                              />
                            <button type="button"  className="btn bg-gradient-outline btn-rounded btn-fw" onClick={()=>{handelPreviewPage(values._id)}} >Preview</button>                           
                       </div>
                 </div>
              </div>
           </div>
        </div>
        </Form>
     </div>
     )
}

Render.propTypes = {
   initialValues: PropTypes.any.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    values: PropTypes.shape({
        _id: PropTypes.string,
        tags: PropTypes.array,
        // lat: PropTypes.number,
        // lng: PropTypes.number,
    }).isRequired,
};

let setSubmitting = () => { };

const BlogForm=()=>{

const dispatch = useDispatch();
const history = useHistory();

const {
    currentRecord,
    upsertReqStatus,
} = useSelector((state) => state.BlogReducer, shallowEqual);
    // if current record changed or request status changed the enable submit
    usePrevious({
      currentRecord,
      upsertReqStatus,
  }, (prev) => {
      if (
          prev.currentRecord !== currentRecord
          || (upsertReqStatus && prev.upsertReqStatus !== upsertReqStatus && upsertReqStatus !== REQUEST_STATUS.PENDING)) {
          setSubmitting(false);
      }
  });

const onSubmit = useCallback(( 
   values,
   formikUtils,
) => {
   if(!values.isPreview)
   ({ setSubmitting } = formikUtils);
   // includeStamp(values);
    delete values.comments;
   dispatch(blogUpsertAction(values));
   if(!values.isPreview){
      onSearch();
      dispatch(blogResetAction());
   }
   else
   {
      const redirectURL = `${config.clientUrl}blog${(values.isPublish===true)?'':'/drafts'}/${values.blogURL}`;     
      window.open(redirectURL, '_blank');
   }   
}, [
   dispatch,
]);


const onSearch = useCallback(() => {
   history.push(RoutingUtil.blogSearch());
}, [
   history,
]);
const onClear = useCallback(() => {
    dispatch(blogResetAction());
}, [dispatch]);




     return(
            <Formik
                onSubmit={onSubmit}
                onClear={onClear}
                onSearch={onSearch}
                initialValues={currentRecord}
                validationSchema={BlogYupSchema}
                enableReinitialize
            > 
                {(formikProps) => (
                    <Render
                        onClear={onClear}
                        onSearch={onSearch}
                        onSubmit={onSubmit}
                        {...formikProps}
                    />
                )}
            </Formik>
     )
}
export default BlogForm