import Pagination from "src/components/Pagination";
import moment from "moment";
import config from "src/config/index";
import { useEffect } from "react";

const ApprovedQnA=(props)=>{
   const {
      search:{data},
      search,
      activeTab,
      setPageNo,
      pageNo,
      editClick,
      selectedQuestions,
      setSelectedQuestions
     } = props;

     const handleCheckAll = (event) => {
      const checkboxes = Array.from(document.getElementsByName('questionId'));
      const isChecked = event.target.checked; 
      checkboxes.forEach((checkbox) => {
        checkbox.checked = isChecked;
      });
      if (isChecked) {
        const questionIds = checkboxes.map((checkbox) => checkbox.value);
        setSelectedQuestions(questionIds);
      } else {
        setSelectedQuestions([]);
      }
    };

 const handleCheckSingle = (event) => {
   const questionId = event.target.value;
   if (event.target.checked) {
     setSelectedQuestions((prevSelectedQuestions) => [
       ...prevSelectedQuestions,
       questionId,
     ]);
   } else {
     setSelectedQuestions((prevSelectedQuestions) =>
       prevSelectedQuestions.filter((id) => id !== questionId)
     );
   }
 };
 useEffect(()=>{
   return () => {
      setSelectedQuestions([]);
   }
 },[])

    return (
        <div className={`tab-pane fade ${(activeTab=="approved")?"show active":""}`} id="pills-approved" role="tabpanel" aria-labelledby="pills-approved-tab">
                        <div className="card-body ps-0 pe-0">
                           <div className="table-responsive">
                              <table className="table table-center datatablefnts datatable">
                                 <thead>
                                    <tr>
                                          <th>
                                             <label>
                                             <input type="checkbox" name="questionIds" onChange={handleCheckAll}/>  Select All</label>
                                          </th>
                                          <th>S. No   </th>
                                          <th>Created Date  </th>
                                          <th>Question ID</th>
                                          <th>Subject </th>
                                          <th>Sub Subject</th>
                                          <th>Action</th>
                                       </tr>
                                 </thead>
                                 <tbody>
                                 {
                                       data.map((question,index)=>(
                                                <tr key={index}>
                                                   <td>
                                                      <input
                                                      type="checkbox"
                                                      name="questionId"
                                                      value={question._id}
                                                      checked={selectedQuestions.includes(question._id)}
                                                      onChange={handleCheckSingle}
                                                      />
                                                   </td>
                                                   <td>{(index+1)}</td>
                                                   <td>{moment(question.created_at).format("MMMM DD, YYYY HH:mm:ss")}    </td>
                                                   <td>{question._id}</td>
                                                   <td>{question.subject}</td>
                                                   <td>{question.sub_subject} </td> 
                                                   <td>
                                                   <div className="text-bold"> 
                                                         <button className="btnbluen1 plwhit" onClick={()=>{editClick(question)}} >Edit </button>
                                                         <button className="btnbluen1 plwhit" onClick={()=>{editClick(question,true)}} >SEO </button>
                                                         <a target="_blank" href={`${config.clientUrl+question.link}/`} className="btnbluen1 plwhit">Preview</a>
                                                      </div>
                                                   </td>
                                                </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                           {data && data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>} 
                        </div>
                     </div> 
    )
}

export default ApprovedQnA