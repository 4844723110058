import { put, call } from 'redux-saga/effects';
import { SOSSearchApi } from 'src/api/SOSApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { SESSION_ACTIONS } from '../actions/SessionActions';


function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,pageType
} = {}) {
    try {
        const {
            data: {
                data,
                total, 
                groupCounter
            },
        } = yield call(SOSSearchApi, pageType, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate
        });

        yield put({
            type: SESSION_ACTIONS.SESSION_SEARCH_SUCCESS,
            data:data,
            groupCounter:groupCounter,
            total:total
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: SESSION_ACTIONS.SESSION_SEARCH_ERROR,
        });
        return null;
    }
}


export default [
    takeFirstSagaUtil(SESSION_ACTIONS.SESSION_SEARCH, search)
];