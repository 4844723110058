export const DASHBOARD_ACTIONS = {
    DASHBOARD_SEARCH: 'DASHBOARD_SEARCH',
    DASHBOARD_SEARCH_SUCCESS: 'DASHBOARD_SEARCH_SUCCESS',
    DASHBOARD_SEARCH_ERROR: 'DASHBOARD_SEARCH_ERROR',

    DASHBOARD_RESET: 'DASHBOARD_RESET',
};

export const dashboardResetAction = () => ({
    type: DASHBOARD_ACTIONS.DASHBOARD_RESET,
});

export const dashboardSearchAction = (payload) => ({
    type: DASHBOARD_ACTIONS.DASHBOARD_SEARCH,
    ...payload,
});



export default {
    DASHBOARD_ACTIONS,
    dashboardSearchAction ,
    dashboardResetAction
};
