import React, { useState } from 'react'
import { useEffect } from 'react'
import SOSFilters from 'src/containers/SOS-container/SOSFilters'
import { SOSUpdateApi } from 'src/api/SOSApi'
import { TableContents } from 'src/containers/SOS-container/TabsConfiguration'
import Pagination from './Pagination'

const SOSContainerTabPane = ({tableData,activeSelectedTab,handleFilterChange,pageNo,setPageNo, total}) => {

const [resolveAction, setResolveAction] = useState('')


   const handleSOSActionClick = async(sessionDetails,resolveAction) => {
      const updateAction = await SOSUpdateApi(
         {
            filter: {_id:sessionDetails._id, 'SOS._id':sessionDetails.SOS[0]['_id']},
            updates:{$set:{'SOS.$.resolve_action':resolveAction,'SOS.$.is_resolved':true}}
         }
        )
      if(updateAction){
         console.log('Session Action Completed Successfully.')
      }
   }

    return (
        <div className="tab-content" id="pills-tabContent">
             <div className="tab-pane fade show active" id={`pills-${activeSelectedTab.id}`} role="tabpanel" aria-labelledby={`pills-tab${activeSelectedTab.id}`}>
                              <div className="col-12 grid-margin  mt-4">
                                 <div className="card">
                                    <div className="card-body ps-0 pe-0">
                                       <div className="dateinydt">
                                       <SOSFilters handleFilterChange={handleFilterChange} activeSelectedTab={activeSelectedTab}/>
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                   <div className="">
                                                      <h4 className="card-title">SOS/ Total SOS </h4>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="table-responsive">
                                          <table className="table table-center datatablefnts datatable">
                                             <thead>
                                                <tr>
                                                   <th>S. No   </th>
                                                   {
                                                      TableContents.map((heads) => {
                                                         return (
                                                            <th>{heads.label}</th>
                                                         )
                                                      })
                                                   }
                                                </tr>
                                             </thead>
                                             <tbody>
                                             {
                                                    tableData.map((data,idx) => {
                                                        return (
                                                            <tr>
                                                                <td>{idx+1}</td>
                                                                <td>{data.SOS[0]['SOS_id']}</td>
                                                                <td>{data.SOS[0]['raised_by']}</td>
                                                                <td>{data.student_id?.Name}</td>
                                                                <td>{data.student_id?.Email}
                                                                <div className="messagebn1">
                                                                  <a href={`mailto:${data.student_id?.Email}`}><button><i className="mdi mdi-email-outline" target="_blank"></i> Message</button></a>
                                                                 </div>
                                                                </td>
                                                                <td>  <div className="">{data.student_id?.Contact}</div>
                                                            <div className="callphnwhaaap">
                                                            <a href={`https://api.whatsapp.com/send?phone=${data.student_id?.Contact}`} title="Share on whatsapp" target="_blank">         <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button>
                                                                     <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button></a>
                                                            </div></td>
                                                                <td>{data.tutor_id['SubjectExpertise'][0].name}</td>
                                                                <td>{data.expertise}</td>
                                                                <td>{data.SOS[0]['created_at']}</td>
                                                                <td>{data?.student_id?.Gender}</td>
                                                                <td>{data.student_id?.Country}</td>
                                                                <td>{data.tutor_id?.id}</td>
                                                                <td>{data.tutor_id?.name}</td>
                                                                <td>{data.tutor_id?.email}
                                                                <div className="messagebn1">
                                                                  <a href={`mailto:${data.tutor_id.email}`}><button><i className="mdi mdi-email-outline" target="_blank"></i> Message</button></a>
                                                                 </div>
                                                                </td>
                                                                <td> <div className="">{data.tutor_id.Contact}</div>
                                                            <div className="callphnwhaaap">
                                                            <a href={`https://api.whatsapp.com/send?phone=${data.tutor_id.Contact}`} title="Share on whatsapp" target="_blank">         <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button>
                                                                     <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button></a>
                                                            </div></td>
                                                                <td>
                                                                  {data.SOS[0]['raised_query']}
                                                                  {/* <div className=""><button className="btnbluen1">View </button> </div> */}
                                                                  </td>
                                                                <td>{data.schedule.start}</td>
                                                                <td> <div className="text-bold text-green">{data.SOS[0]['is_resolved'] === true ? 'Resolved': 'Pending'} </div></td>
                                                                <td>30 m</td>
                                                                <td>
                                                      <div className="dropdown custabldropn">
                                                         <button 
                                                         // onClick={()=> handleSOSActionClick(data)}
                                                         className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Action
                                                         </button>
                                                         <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item"
                                                            style={{cursor:'pointer'}}
                                                         onClick={()=> handleSOSActionClick(data,'Reschedule Session')}   
                                                         >Book a Another Session</a></li>
                                                            <li><a className="dropdown-item"
                                                            style={{cursor:'pointer'}}
                                                             onClick={()=> handleSOSActionClick(data,'Refunded')}  
                                                            >Issue Refund</a></li>
                                                         </ul>
                                                      </div>
                                                   </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                             </tbody>
                                          </table>
                                          {tableData && tableData?.length > 0 && <Pagination total={total} data={tableData} setPageNo={setPageNo} pageNo={pageNo} />}
                                             
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
            </div>
    )
}


export default SOSContainerTabPane
