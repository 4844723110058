import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AUTH_ACTIONS } from 'src/containers/auth-container/actions/AuthActions';
import { BlogDefaultProps } from 'src/containers/blog-container/BlogPropTypes';
import { BLOG_ACTIONS } from '../actions/BlogActions';

const initialState = () => ({
    currentRecord: { ...BlogDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    }, 

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        // SEARCH
        case BLOG_ACTIONS.BLOG_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case BLOG_ACTIONS.BLOG_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case BLOG_ACTIONS.BLOG_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // UPSERT
        case BLOG_ACTIONS.BLOG_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case BLOG_ACTIONS.BLOG_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case BLOG_ACTIONS.BLOG_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // RESET
        case BLOG_ACTIONS.BLOG_RESET: {
            return {
                ...state,
                currentRecord: { ...BlogDefaultProps },
            };
        }

        // LOGOUT
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...initialState(),
            };

        default:
            return state;
    }
};
