import React from "react"
import { Link } from 'react-router-dom';
// mdi-phone-voip
const LinkNav=({to,icon,lable})=>{
  return (
  <>
  <li className="nav-item">
        <Link  to={to} className="nav-link" >
         <i className={`mdi ${icon} mdi-24px menu-icon`}></i>
         <span className="menu-title">{lable}</span> 
        </Link> 
      </li>
  </>
  )
}
export default LinkNav;