import React, { useEffect } from 'react'
import SEOBreadCrumb from './SEOBreadCrumb'
import * as Yup from 'yup'
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput';
import FormikTextArea from 'src/components/formik/FormikTextArea';
import Rating from 'react-rating'
import { useRef } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { addBookReview } from 'src/api/BookApi';
import { useParams } from 'react-router-dom';


const Render = (props) => {
    const {
        setFieldValue,
        values,
        // errors,
        // touched,
        isSubmitting,
        onSearch,
        onClear,
        resetForm,
        handelChangeUpload,
        btnDisabled,
        setInitialValues,
        onSubmit
    } = props;
    const ratingRef = useRef()
    const { currentRecord, search } = useSelector((state) => state.BookReducer)
    const [rating, setRating] = useState('')
    const [singleReview, setSingleReview] = useState({ userName: '', review: '' });
    const [ratingArr, setRatingArr] = useState([])
    const params = useParams()

    useEffect(() => {
        const { reviews } = currentRecord
        setRatingArr(reviews)
    }, [currentRecord])

    const handleReviewEdit = (review) => {
        setInitialValues(review)
    }


    return (
        <>
            <SEOBreadCrumb book_isbn={params.book_isbn} book_id={params.book_id}/>
            <div className='row ps-4 pe-4 pt-4'>
                <div className='col-md-4'>
                    <p>Manage Reviews</p>
                    <hr />
                    <Form>
                        <p>User Name</p>
                        <FormikInput
                            type="text"
                            placeholder="Enter user name"
                            name="userName"
                            className="form-control  enchilada"
                        // value={values.schedule['startDate']}
                        />
                        <p>Reviews</p>
                        <FormikTextArea
                            type="text"
                            placeholder="Reviews"
                            name="review"
                            className="form-control  enchilada"
                            rows="12"
                        />
                        <p>Rating</p>
                        <Rating
                            ref={ratingRef}
                            initialRating={values && values.rating}
                            defaultValue={values && values.rating}
                            emptySymbol="mdi mdi-star-outline mdi-48px"
                            fullSymbol="mdi mdi-star mdi-48px"
                            onChange={e => 
                                {
                                    values.rating = e
                                    setRating(e)
                                }
                                }
                        />
                        <button className='mt-3 btnbluen1 mb-3 text-center' onClick={() => onSubmit(values)}>Save Review</button>
                    </Form>

                </div>
                <div className='col-md-1' />
                <div className='col-md-6'>
                    <p>All Reviews for - ISBN 9390294021948</p>
                    <div style={{ height: '420px', overflowY: 'scroll', paddingRight: '15px' }} id="reviewDiv">
                        {ratingArr && ratingArr.map(review => {
                            return (
                                <div className="subject-card"
                                    style={{ width: '100%' }}
                                    key={review._id}>

                                    <div className="subject-card-body">
                                        <div className="admin-name">
                                            <div className="name-label">
                                                <span className="mdi mdi-star fa-1x mr-2 pt-1"></span>
                                                &nbsp; Ratings:
                                            </div>
                                            <div className="name-main">
                                                <Rating
                                                    initialRating={review && review.rating}
                                                    emptySymbol="mdi mdi-star-outline text-danger"
                                                    fullSymbol="mdi mdi-star text-success"
                                                    readonly
                                                    className="displayRating"

                                                />
                                            </div>
                                        </div>
                                        <div className="admin-name">
                                            <div className="name-label">
                                                <span className="mdi mdi-calendar fa-1x mr-2 pt-1"></span>
                                                &nbsp; Review Date:
                                            </div>
                                            <div className="name-main">
                                                {review && review.created_at}
                                            </div>
                                        </div>
                                        <div className="admin-name">
                                            <div className="name-label">
                                                <span className="mdi mdi-account fa-1x mr-2 pt-1"></span>
                                                &nbsp; UserName:
                                            </div>
                                            <div className="name-main">
                                                {review && review.userName}
                                            </div>
                                        </div>
                                        <div className="admin-name">
                                            <div className="name-label">
                                                <span className="mdi mdi-comment fa-1x mr-2 pt-1"></span>
                                                &nbsp; Reviews:
                                            </div>
                                            <div className="name-main text-justify">
                                                {review && review.review.substr(0, 60) + '...'}
                                            </div>
                                        </div>


                                    </div>
                                    <hr className="mt-1 mb-2" />
                                    <div className='subject-card-heading'>
                                        <div>
                                            status
                                        </div>
                                        <div>
                                            <span className="mdi mdi-tooltip-edit fa-1x mr-2 pt-1" onClick={() => handleReviewEdit(review)}></span>
                                            <span className="mdi mdi-delete fa-1x mr-2 pt-1"></span>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    <hr />
                </div>
            </div>
        </>
    )
}

const BookRatingAndReview = () => {
    const { currentRecord } = useSelector((state) => state.BookReducer)
    const [bookIsbn, setBookIsbn] = useState('')
    const [bookId, setBookId] = useState('')


    const [initialValues, setInitialValues] = useState({
rating:0,
review:'',
status:true,
userName:'',
review_id:''
    });

    useEffect(() => {
        setBookId(currentRecord._id)
        setBookIsbn(currentRecord['ISBN13'])
    }, [])


    const onSubmit = async (values) => {
        if(values.question === '' || values.answer === ''){
            return 
        } else {
            const add_faq = await addBookReview({...values,book_id:bookId, isbn:bookIsbn})
            if(add_faq){
setInitialValues({
    rating:0,
    review:'',
    status:true,
    userName:'',
    review_id:''
})
            }
        }
    }

    return (
        // <p>faq page </p>
        <Formik
        onSubmit={onSubmit}
        // onClear={onClear}
        // onSearch={onSearch}
        initialValues={initialValues}
        // // validationSchema={BlogYupSchema}
        enableReinitialize
        >
            {(formikProps) => (
                <Render 
                // onClear={onClear}
                // onSearch={onSearch}
                setInitialValues={setInitialValues}
                onSubmit={onSubmit}
                {...formikProps}
                />
            )}
        </Formik>
    )
}

export default BookRatingAndReview