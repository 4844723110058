import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getSubjects } from 'src/api/Assignment'
import { createSubjectAPI, subSubjectCreateAPI } from 'src/api/MastersApi'
import RoutingUtil from 'src/utils/RoutingUtil'

const SubSubjectCreate = () => {

const history = useHistory()    
const params = useParams()
const [formData, setFormData] = useState({})
const [subSubjectName, setSubSubjectName] = useState('')
const [subjectArr, setsubjectArr] = useState([])


useEffect(() => {
    getAllSubjects()
},[])

const handleSubmit = async(e) => {
       e.preventDefault()
       if(params.id){
        //
       }else {
        // console.log('form data', formData)
        const createSub = await subSubjectCreateAPI(formData)
        if(createSub){
            history.push(RoutingUtil.subSubjectMasterUrl())
        }
       } 
}

const handleInputChange = (e) => {
    const data = e.target.value;
    setSubSubjectName(data)
    const sub_subject = data.replace(/[^a-zA-Z0-9, ]/g, "");
    setFormData({...formData, sub_subject: sub_subject});
}

const getAllSubjects = async () => {
    const {data} = await getSubjects()
    setsubjectArr(data)
}

const handleSubjectSelect = (e) => {
    const value = e.target.value
    const subject_name = value.split('_')[0]
    const subject_id = value.split('_')[1]
    setFormData({...formData,subject:subject_name,subject_id:subject_id})
}

    return (
        <div className='row mt-4 p-3'>
            <div className='col-md-12'>
                <p className='ms-2'>
                    <span className='mdi mdi-arrow-left-bold me-2' style={{fontSize:'1rem', cursor:'pointer',border:'1px solid black',color:'white', background:'black'}} onClick={() => history.push(RoutingUtil.subSubjectMasterUrl())}></span>
                    Create New Sub Subject</p>
            </div>
            <div className='col-md-4'>
                <p className='ms-2'>Subject Name</p>
               <select className='form-control' style={{height:'3rem'}} onChange={(e) => handleSubjectSelect(e)}>
                <option>Select Subject</option>
                {
                   subjectArr.map((subs) => {
                    return (
                        <option key={subs._id} value={subs.subject+'_'+subs._id}>{subs.subject}</option>
                    )
                   })
                }
               </select>
            </div> 
            <div className='col-md-12 mt-3'>
                <p className='ms-2'>Sub Subject Name</p>
                <input className='form-control' type='text' value={subSubjectName} placeholder='Enter Sub Subject Name'  style={{width:'30%'}} onChange={(e) => handleInputChange(e)}/>
                <button className='btnbluen1 mt-4 mb-4' onClick={(e) => handleSubmit(e)}
                disabled={subSubjectName === ''}
                >Save Sub Subject</button>
            </div> 
        </div>
    )
}

export default SubSubjectCreate 