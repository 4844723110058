import { SubscriptionPlanWiseDefault } from "../DashboardConstants";
const SubscriptionPlanWise=(props)=>{
 
 let subscriptionPlanWise = props.subscriptionPlanWise;
  if (Object.keys(subscriptionPlanWise).length === 0) {
    subscriptionPlanWise = SubscriptionPlanWiseDefault;
  }
  const totalSubscriptions = subscriptionPlanWise.length>0 && subscriptionPlanWise?.reduce((total, item) => {
    return total + item.totalSubscriptions;
  }, 0);
  const totalRevenue = subscriptionPlanWise.length>0 && subscriptionPlanWise?.reduce((total, item) => {
    return total + item.totalRevenue;
  }, 0);

  const statsSubscription = (plans)=>{
    let allStats = []
    const active = plans?.filter((item) => item.status === 'active')
    const cancelled = plans?.filter((item)=> item.status === 'cancelled')
    allStats.push({
        "status": "active",
        "totalSubscriptions": active.length ? active[0].totalSubscriptions : 0,
        "totalRevenue": active.length ? active[0].totalRevenue : 0,
    })
    allStats.push({
        "status": "cancelled",
        "totalSubscriptions": cancelled.length ? cancelled[0].totalSubscriptions : 0,
        "totalRevenue": cancelled.length ? cancelled[0].totalRevenue : 0,
    })
    console.log('all stats',allStats)
    return allStats
  }

 const subscriptionsArr = [
    {
        "status": "active",
        "totalSubscriptions": 0,
        "totalRevenue":0
    },
    {
        "status": "cancelled",
        "totalSubscriptions": 0,
        "totalRevenue": 0
    }
]

    return (
        <div className="col-md-7 mtops">
        <div className="card shadow">
           <div className="card-body">
              {/* <div className="dfnam">
                 <h4>{totalSubscriptions} Subscribers</h4>
                 <div className="bgawa">
                    <ul className="nav nav-pills mb-3" id="pills-tab2" role="tablist">
                       <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="pills-all2-tab" data-bs-toggle="pill" data-bs-target="#pills-all2" type="button" role="tab" aria-controls="pills-all2" aria-selected="true">All</button>
                       </li>
                       <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-Web2-tab" data-bs-toggle="pill" data-bs-target="#pills-Web2" type="button" role="tab" aria-controls="pills-Web2" aria-selected="false">Web</button>
                       </li>
                       <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-App2-tab" data-bs-toggle="pill" data-bs-target="#pills-App2" type="button" role="tab" aria-controls="pills-App2" aria-selected="false">App</button>
                       </li>
                    </ul>
                 </div>
              </div> */}
              <div className="">
                 <div className="tab-content" id="pills-tabContent2">
                    <div className="tab-pane fade show active" id="pills-all2" role="tabpanel" aria-labelledby="pills-all2-tab">
                       <div className="">
                       <div className="revenueTobg"> 
                          <div className="prdsb2bg">
                          <div className="prdsb1">
                                <div className="prdsb2">{totalSubscriptions}</div>
                                <div className="prdsb3"> Total Subscribers </div>
                             </div>
                             <div className="prdsb1">
                                {/* <div className="prdsb2">${totalSubscriptions}</div>
                                <div className="prdsb3"> Total Subscribers </div> */}
                             </div>
                             <div className="prdsb1">
                                <div className="prdsb2 active">${totalRevenue}</div>
                                <div className="prdsb3">Total revenue from subscriptions </div>
                             </div>
                          </div>
                       </div>

                            {
                              subscriptionPlanWise.length>0 &&  subscriptionPlanWise.map((subscriptionPlan,index)=>{
                                    const PlanWiseTotal = subscriptionPlan.subscriptions?.reduce((total, item) => {
                                        return total + item.totalRevenue;
                                      }, 0);

                            //   let subStats = []

                            //   subscriptionPlan.subscriptions.length ? statsSubscription(subscriptionPlan)


                                    return(                                       
                                            <div className="subres1sub1bg" key={`subscription_${index}`}>
                                            <div className="subres1sub1">
                                                <div className="subres1sub2 annual">{subscriptionPlan._id === 'Annualy' ? "Annually" : subscriptionPlan._id} <span>{subscriptionPlan.totalSubscriptions} Subscribers</span> </div>
                                                <div className="subres1sub3">${subscriptionPlan.totalRevenue.toFixed(2).replace(/\.00$/, "")}</div>
                                                </div>
                                                <div className="subres1bg">
                                                <div className="subres1">
                                                    <div className="subres2">${PlanWiseTotal.toFixed(2).replace(/\.00$/, "")}</div>
                                                    <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-blue"></i> Total Revenue  </div>
                                                </div>
                        
                                                {
                                                    
                                                    subscriptionPlan.subscriptions.length ?  statsSubscription(subscriptionPlan?.subscriptions)?.map((subStatus,subIndex)=>{
                                                        return (                                                             
                                                                <div className="subres1" key={`subStatus_${subIndex}`}>
                                                                    <div className="subres2">${subStatus.totalRevenue.toFixed(2).replace(/\.00$/, "")}</div>
                                                                    <div className="subres3"><i className={`mdi mdi-checkbox-blank-circle ${(subStatus.status=="cancelled")?'text-yellow':'text-green'}`}></i> {subStatus.status.charAt(0).toUpperCase() + subStatus.status.slice(1)} Revenue </div>
                                                                </div> 
                                                        )
                                                    }) :
                                                        subscriptionsArr.map((subStatus,subIndex) => {
                                                            return (
                                                                <div className="subres1" key={`subStatus_${subIndex}`}>
                                                                <div className="subres2">${subStatus.totalRevenue.toFixed(2).replace(/\.00$/, "")}</div>
                                                                <div className="subres3"><i className={`mdi mdi-checkbox-blank-circle ${(subStatus.status=="cancelled")?'text-yellow':'text-green'}`}></i> {subStatus.status.charAt(0).toUpperCase() + subStatus.status.slice(1)} Revenue </div>
                                                            </div> 
                                                            )
                                                        })
                                                 }
                                                    {/*<div className="subres1">
                                                        <div className="subres2">$4588</div>
                                                        <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Total subscription </div>
                                                    </div>
                                                     <div className="subres1">
                                                    <div className="subres2">$4588</div>
                                                    <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Cancelled  </div>
                                                    </div>
                                                    <div className="subres1">
                                                    <div className="subres2">$4588</div>
                                                    <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-blue"></i> Active  </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                       
                                    )
                                })
                            }
                           {/* <div className="subres1sub1bg">
                           <div className="subres1sub1">
                               <div className="subres1sub2 annual">ANNUAL <span>1,93 Subscribers</span> </div>
                               <div className="subres1sub3">$829</div>
                               </div>
                               <div className="subres1bg">
                               <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Total subscription </div>
                                   </div>
                                   <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Cancelled  </div>
                                   </div>
                                   <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-blue"></i> Active  </div>
                                   </div>
                               </div>
                           </div>
                           <div className="subres1sub1bg">
                           <div className="subres1sub1">
                               <div className="subres1sub2 quarterly">QUARTERLY  <span>1,93 Subscribers</span> </div>
                               <div className="subres1sub3">$829</div>
                               </div>
                               <div className="subres1bg">
                               <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Total subscription </div>
                                   </div>
                                   <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Cancelled  </div>
                                   </div>
                                   <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-blue"></i> Active  </div>
                                   </div>
                               </div>
                           </div>
                           <div className="subres1sub1bg">
                           <div className="subres1sub1">
                               <div className="subres1sub2 monthly">MONTHLY   <span>1,93 Subscribers</span> </div>
                               <div className="subres1sub3">$829</div>
                               </div>
                               <div className="subres1bg">
                               <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Active </div>
                                   </div>
                                   <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Awaiting  </div>
                                   </div>
                                   <div className="subres1">
                                   <div className="subres2">$4588</div>
                                   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-blue"></i> Refunded  </div>
                                   </div>
                               </div>
                           </div>										     */}
        </div>
        </div>
        {/* <div className="tab-pane fade" id="pills-Web2" role="tabpanel" aria-labelledby="pills-Web2-tab">...</div>
        <div className="tab-pane fade" id="pills-App2" role="tabpanel" aria-labelledby="pills-App2-tab">...</div> */}
      </div>
  </div>
</div>
</div>
</div>
    );
}
export default SubscriptionPlanWise;