import React from "react"
const PaymentInvoiceContainer=()=>{
    return(
        <>
        <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2" >
                        <i className="mdi mdi-home"></i>
                        </span> Payments and Invoices 
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-12 grid-margin  mt-4">
                        <div className="dateinydt">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="scscm21 scscm21-step6">
                                    <div className="">
                                       <select className="form-select">
                                          <option>Select </option>
                                       </select> 
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="netsliXvbg">
                                    <div className="d-flex ">
                                       <div className="netsliXv">
                                          <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                             <div className="totSlidX totSlidX1">
                                                <div className="d-flex justify-content-between align-items-center NetSlidX">Net Profit <span>$45887</span></div>
                                                <div className="prtg"><i className="mdi mdi-trending-up text-green"></i><span>+15%</span></div>
                                             </div>
                                             <div className="nav-link active activwb1" id="v-pills-tab1" data-bs-toggle="pill" data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-1" aria-selected="true">
                                                <div className="totSlidX">
                                                   <div className="d-flex justify-content-between align-items-center NetSlidX">Payment released <span>$45887</span></div>
                                                   <div className="prtg"><i className="mdi mdi-trending-up text-green"></i><span>+15%</span></div>
                                                </div>
                                             </div>
                                             <div className="nav-link activwb2" id="v-pills-tab2" data-bs-toggle="pill" data-bs-target="#v-pills-2" type="button" role="tab" aria-controls="v-pills-2" aria-selected="false">
                                                <div className="totSlidX">
                                                   <div className="d-flex justify-content-between align-items-center NetSlidX">Payment Captured <span>$45887</span></div>
                                                   <div className="prtg"><i className="mdi mdi-trending-up text-green"></i><span>+15%</span></div>
                                                </div>
                                             </div>
                                             <div className="nav-link activwb3" id="v-pills-tab3" data-bs-toggle="pill" data-bs-target="#v-pills-3" type="button" role="tab" aria-controls="v-pills-3" aria-selected="false">
                                                <div className="totSlidX">
                                                   <div className="d-flex justify-content-between align-items-center NetSlidX">Net deduction <span>$45887</span></div>
                                                   <div className="prtg"><i className="mdi mdi-trending-up text-green"></i><span>+15%</span></div>
                                                </div>
                                             </div>
                                             <div className="nav-link activwb4" id="v-pills-tab4" data-bs-toggle="pill" data-bs-target="#v-pills-4" type="button" role="tab" aria-controls="v-pills-4" aria-selected="false">
                                                <div className="totSlidX">
                                                   <div className="d-flex justify-content-between align-items-center NetSlidX">Pending payment requests <span>$45887</span></div>
                                                   <div className="prtg"><i className="mdi mdi-trending-up text-green"></i><span>+15%</span></div>
                                                </div>
                                             </div>
                                             <div className="nav-link activwb5" id="v-pills-tab5" data-bs-toggle="pill" data-bs-target="#v-pills-5" type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">
                                                <div className="totSlidX">
                                                   <div className="d-flex justify-content-between align-items-center NetSlidX">Refunds <span>$45887</span></div>
                                                   <div className="prtg"><i className="mdi mdi-trending-up text-green"></i><span>+15%</span></div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="tab-content" id="v-pills-tabContent">
                                          <div className="tab-pane fade show active" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-tab1">
                                             <div className="card shadow">
                                                <div className="card-body">
                                                   <div className="overThsWkTx">
                                                      <div className="oveWkTx">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Overview </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="barChartjs">
                                                            <canvas id="barChart" className="barChart" style={{height:'230px'}}></canvas>
                                                         </div>
                                                      </div>
                                                      <div className="oveWkTx mt-4">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Earning Reports </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="applistwebbg">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                               <div className="applistweb">
                                                                  <ul>
                                                                     <li>
                                                                        <div className="">App </div>
                                                                        <span>25%</span>
                                                                     </li>
                                                                     <li>
                                                                        <div className="">Web </div>
                                                                        <span>75%</span>
                                                                     </li>
                                                                  </ul>
                                                               </div>
                                                               <div className="progress blue">
                                                                  <span className="progress-left">
                                                                  <span className="progress-bar"></span>
                                                                  </span>
                                                                  <span className="progress-right">
                                                                  <span className="progress-bar"></span>
                                                                  </span> 
                                                               </div>
                                                               <div className="applisttext">
                                                                  <span> 01 January 2020 to 31 December 2020</span>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="tab-pane fade" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-tab2">
                                             <div className="card shadow">
                                                <div className="card-body">
                                                   <div className="overThsWkTx">
                                                      <div className="oveWkTx">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Overview </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="barChartjs">
                                                            <canvas id="barChart2" className="barChart" style={{height:'230px'}}></canvas>
                                                         </div>
                                                      </div>
                                                      <div className="oveWkTx mt-4">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Earning Reports </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="applistwebbg">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                               <div className="applistweb">
                                                                  <ul>
                                                                     <li>
                                                                        <div className="">App </div>
                                                                        <span>25%</span>
                                                                     </li>
                                                                     <li>
                                                                        <div className="">Web </div>
                                                                        <span>75%</span>
                                                                     </li>
                                                                  </ul>
                                                               </div>
                                                               <div className="progress blue">
                                                                  <span className="progress-left">
                                                                  <span className="progress-bar"></span>
                                                                  </span>
                                                                  <span className="progress-right">
                                                                  <span className="progress-bar"></span>
                                                                  </span> 
                                                               </div>
                                                               <div className="applisttext">
                                                                  <span> 01 January 2020 to 31 December 2020</span>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="tab-pane fade" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-tab3">
                                             <div className="card shadow">
                                                <div className="card-body">
                                                   <div className="overThsWkTx">
                                                      <div className="oveWkTx">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Overview </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="barChartjs">
                                                            <canvas id="barChart3" className="barChart" style={{height:'230px'}}></canvas>
                                                         </div>
                                                      </div>
                                                      <div className="oveWkTx mt-4">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Earning Reports </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="applistwebbg">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                               <div className="applistweb">
                                                                  <ul>
                                                                     <li>
                                                                        <div className="">App </div>
                                                                        <span>25%</span>
                                                                     </li>
                                                                     <li>
                                                                        <div className="">Web </div>
                                                                        <span>75%</span>
                                                                     </li>
                                                                  </ul>
                                                               </div>
                                                               <div className="progress blue">
                                                                  <span className="progress-left">
                                                                  <span className="progress-bar"></span>
                                                                  </span>
                                                                  <span className="progress-right">
                                                                  <span className="progress-bar"></span>
                                                                  </span> 
                                                               </div>
                                                               <div className="applisttext">
                                                                  <span> 01 January 2020 to 31 December 2020</span>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="tab-pane fade" id="v-pills-4" role="tabpanel" aria-labelledby="v-pills-tab4">
                                             <div className="card shadow">
                                                <div className="card-body">
                                                   <div className="overThsWkTx">
                                                      <div className="oveWkTx">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Overview </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="barChartjs">
                                                            <canvas id="barChart4" className="barChart" style={{height:'230px'}}></canvas>
                                                         </div>
                                                      </div>
                                                      <div className="oveWkTx mt-4">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Earning Reports </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="applistwebbg">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                               <div className="applistweb">
                                                                  <ul>
                                                                     <li>
                                                                        <div className="">App </div>
                                                                        <span>25%</span>
                                                                     </li>
                                                                     <li>
                                                                        <div className="">Web </div>
                                                                        <span>75%</span>
                                                                     </li>
                                                                  </ul>
                                                               </div>
                                                               <div className="progress blue">
                                                                  <span className="progress-left">
                                                                  <span className="progress-bar"></span>
                                                                  </span>
                                                                  <span className="progress-right">
                                                                  <span className="progress-bar"></span>
                                                                  </span> 
                                                               </div>
                                                               <div className="applisttext">
                                                                  <span> 01 January 2020 to 31 December 2020</span>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="tab-pane fade" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-tab5">
                                             <div className="card shadow">
                                                <div className="card-body">
                                                   <div className="overThsWkTx">
                                                      <div className="oveWkTx">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Overview </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="barChartjs">
                                                            <canvas id="barChart5" className="barChart" style={{height:'230px'}}></canvas>
                                                         </div>
                                                      </div>
                                                      <div className="oveWkTx mt-4">
                                                         <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                            <div className="">
                                                               <h4 className="card-title">Earning Reports </h4>
                                                            </div>
                                                            <div className="">
                                                               <select className="form-select">
                                                                  <option>This Week </option>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         <div className="applistwebbg">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                               <div className="applistweb">
                                                                  <ul>
                                                                     <li>
                                                                        <div className="">App </div>
                                                                        <span>25%</span>
                                                                     </li>
                                                                     <li>
                                                                        <div className="">Web </div>
                                                                        <span>75%</span>
                                                                     </li>
                                                                  </ul>
                                                               </div>
                                                               <div className="progress blue">
                                                                  <span className="progress-left">
                                                                  <span className="progress-bar"></span>
                                                                  </span>
                                                                  <span className="progress-right">
                                                                  <span className="progress-bar"></span>
                                                                  </span> 
                                                               </div>
                                                               <div className="applisttext">
                                                                  <span> 01 January 2020 to 31 December 2020</span>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card shadow">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="bgtodnlist">
                                 <div className="text-start">
                                    <div className="daterangepkr"> <span><i className="mdi mdi-calendar-check"></i></span>
                                       <input type="text" id="demo-12" placeholder="start date - end date" className="form-control form-control-sm"/>
                                    </div>
                                 </div>
                                 <div className="dailyTxtbg">
                                    <div className="dailyTxt1">
                                       <button className="btndaten1 active">Daily  </button>
                                       <button className="btndaten1 ">Tomorrow </button>   
                                       <button className="btndaten1">Last 7 Days</button>     
                                       <button className="btndaten1">Last 15 Days </button>    
                                       <button className="btndaten1">Last 30 Days  </button>   
                                       <button className="btndaten1">Last 6 Month </button>    
                                       <button className="btndaten1">Last 1 Year</button>
                                       <button className="btndaten1">All Time</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="totalTab1">
                           <div className="col-md-12">
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101 mt-4">
                                 <div className="">
                                    <h4 className="card-title">Payment and Invoice /Payment Released Details</h4>
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                 <div className="">
                                    <h4 className="card-title">Total Released Amounts</h4>
                                 </div>
                                 <div className=""><strong> $45887</strong> </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="scscm21 scscm21-step3">
                                    <div className="">
                                       <select className="form-select">
                                          <option>Tutor Type </option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <select className="form-select">
                                          <option>Service Type</option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <div className="sltbsxb1">
                                          <div className="button-group">
                                             <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                             <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="table-responsive">
                              <table className="table table-center datatablefnts datatable">
                                 <thead>
                                    <tr>
                                       <th>S. No   </th>
                                       <th>Tutor ID </th>
                                       <th>Tutor Name</th>
                                       <th>Tutor Type  </th>
                                       <th>Cancelled Sessions</th>
                                       <th>Average Rating</th>
                                       <th>Total Deducation</th>
                                       <th>Amount on Hold  </th>
                                       <th>Net Released Amount </th>
                                       <th>Invoice </th>
                                       <th>Status</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td><span className="sNo">1</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="">Online Tutor +Experts  </div>
                                       </td>
                                       <td>
                                          <div className="">4</div>
                                       </td>
                                       <td>
                                          <div className="">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$15</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#invimsnn-Modal">View </button> </div>
                                       </td>
                                       <td>
                                          <div className="text-bold text-green">Released   </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">2</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="">Online Tutor +Experts  </div>
                                       </td>
                                       <td>
                                          <div className="">4</div>
                                       </td>
                                       <td>
                                          <div className="">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$15</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#invimsnn-Modal">View </button> </div>
                                       </td>
                                       <td>
                                          <div className="text-bold text-green">Released   </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">3</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="">Online Tutor +Experts  </div>
                                       </td>
                                       <td>
                                          <div className="">4</div>
                                       </td>
                                       <td>
                                          <div className="">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$15</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#invimsnn-Modal">View </button> </div>
                                       </td>
                                       <td>
                                          <div className="text-bold text-green">Released   </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div className="totalTab2" style={{display: 'none'}}>
                           <div className="col-md-12">
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101 mt-4">
                                 <div className="">
                                    <h4 className="card-title">Payment and Invoice /Payment  Captured Details</h4>
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                 <div className="">
                                    <h4 className="card-title">Total Captured Amounts</h4>
                                 </div>
                                 <div className=""><strong> $45887</strong> </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="scscm21 scscm21-step3">
                                    <div className="">
                                       <select className="form-select">
                                          <option>Tutor Type </option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <select className="form-select">
                                          <option>Service Type</option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <div className="sltbsxb1">
                                          <div className="button-group">
                                             <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                             <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="table-responsive">
                              <table className="table table-center datatablefnts datatable">
                                 <thead>
                                    <tr>
                                       <th>S. No   </th>
                                       <th>Student ID </th>
                                       <th> Student name </th>
                                       <th>Service Type </th>
                                       <th> Rating   </th>
                                       <th> Status   </th>
                                       <th> Net Captured Amount </th>
                                       <th>Invoice  </th>
                                       <th>Total Subscription Tenure</th>
                                       <th>Current Subscription Tenure </th>
                                       <th>Subscription Type</th>
                                       <th>Start Date </th>
                                       <th>End Date </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td><span className="sNo">1</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-green">Completed</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#paymentcaptured-Modal">View </button> </div>
                                       </td>
                                       <td>8 Months</td>
                                       <td>2 Months</td>
                                       <td>Quarterly </td>
                                       <td>4 SEP 2022</td>
                                       <td>4 DEC 2022</td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">2</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-blue">Upcoming</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#paymentcaptured-Modal">View </button> </div>
                                       </td>
                                       <td></td>
                                       <td></td>
                                       <td> </td>
                                       <td></td>
                                       <td></td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">3</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-green">Active</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#paymentcaptured-Modal">View </button> </div>
                                       </td>
                                       <td>8 Months</td>
                                       <td>2 Months</td>
                                       <td>Quarterly </td>
                                       <td>4 SEP 2022</td>
                                       <td>4 DEC 2022</td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">4</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-green">Completed</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#paymentcaptured-Modal">View </button> </div>
                                       </td>
                                       <td>8 Months</td>
                                       <td>2 Months</td>
                                       <td>Quarterly </td>
                                       <td>4 SEP 2022</td>
                                       <td>4 DEC 2022</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div className="totalTab3" style={{display: 'none'}}>
                           <div className="col-md-12">
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101 mt-4">
                                 <div className="">
                                    <h4 className="card-title">Payment and Invoice /Net Deduction </h4>
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                 <div className="">
                                    <h4 className="card-title">Total Deduction Amount</h4>
                                 </div>
                                 <div className=""><strong> $45887</strong> </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="scscm21 scscm21-step3">
                                    <div className="">
                                       <select className="form-select">
                                          <option>Tutor Type </option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <select className="form-select">
                                          <option>Service Type</option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <div className="sltbsxb1">
                                          <div className="button-group">
                                             <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                             <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="table-responsive">
                              <table className="table table-center datatablefnts datatable">
                                 <thead>
                                    <tr>
                                       <th>S. No   </th>
                                       <th>Tutor ID  </th>
                                       <th>Tutor Name</th>
                                       <th>Service Type </th>
                                       <th>Red Flags  </th>
                                       <th>Average Rating </th>
                                       <th>Total Deduction</th>
                                       <th>Amount on Hold </th>
                                       <th>Net Released Amount</th>
                                       <th>Net Deducted Amount  </th>
                                       <th>Invoice</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td><span className="sNo">1</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="">3</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-red">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$10</div>
                                       </td>
                                       <td>
                                          <div className="text-green">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-red">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#net-deduction-Modal">View </button> </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">2</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="">3</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-red">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$10</div>
                                       </td>
                                       <td>
                                          <div className="text-green">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-red">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#net-deduction-Modal">View </button> </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">3</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="">3</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-red">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$10</div>
                                       </td>
                                       <td>
                                          <div className="text-green">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-red">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#net-deduction-Modal">View </button> </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div className="totalTab4" style={{display: 'none'}}>
                           <div className="col-md-12">
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101 mt-4">
                                 <div className="">
                                    <h4 className="card-title">Payment and Invoice /Pending Payment Requests </h4>
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                 <div className="">
                                    <h4 className="card-title">Total Pending Amount</h4>
                                 </div>
                                 <div className=""><strong> $45887</strong> </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="scscm21 scscm21-step3">
                                    <div className="">
                                       <select className="form-select">
                                          <option>Tutor Type </option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <select className="form-select">
                                          <option>Service Type</option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <div className="sltbsxb1">
                                          <div className="button-group">
                                             <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                             <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="table-responsive">
                              <table className="table table-center datatablefnts datatable">
                                 <thead>
                                    <tr>
                                       <th>S. No   </th>
                                       <th>Tutor ID  </th>
                                       <th>Tutor Name</th>
                                       <th>Service Type </th>
                                       <th>Tenure of the Amount Requested</th>
                                       <th>Red Flags</th>
                                       <th>Average Rating</th>
                                       <th>Amount Requested</th>
                                       <th>Deduction Applicable Amount</th>
                                       <th>Amount on Hold</th>
                                       <th>Total Amount </th>
                                       <th>Invoice</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td><span className="sNo">1</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="">Last 14 Days</div>
                                       </td>
                                       <td>
                                          <div className="">3</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-red">-$538</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">-$10</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#pendingraised-Modal">View </button> </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">2</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="">Last 14 Days</div>
                                       </td>
                                       <td>
                                          <div className="">3</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">4.5</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className="text-red">-$538</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">-$10</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#pendingraised-Modal">View </button> </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div className="totalTab5" style={{display: 'none'}}>
                           <div className="col-md-12">
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101 mt-4">
                                 <div className="">
                                    <h4 className="card-title">Payment and Invoice /Refund</h4>
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                 <div className="">
                                    <h4 className="card-title">Total Refund Amounts</h4>
                                 </div>
                                 <div className=""><strong> $45887</strong> </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="scscm21 scscm21-step3">
                                    <div className="">
                                       <select className="form-select">
                                          <option>Tutor Type </option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <select className="form-select">
                                          <option>Service Type</option>
                                       </select>
                                    </div>
                                    <div className="">
                                       <div className="sltbsxb1">
                                          <div className="button-group">
                                             <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                             <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="table-responsive">
                              <table className="table table-center datatablefnts datatable">
                                 <thead>
                                    <tr>
                                       <th>S. No   </th>
                                       <th>Student ID</th>
                                       <th>Student name</th>
                                       <th>Service Type </th>
                                       <th>Cancel Date </th>
                                       <th>Net Refund Amount </th>
                                       <th>Invoice </th>
                                       <th>Status</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td><span className="sNo">1</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="">05 August 2022</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#refundinvoice-Modal">View </button> </div>
                                       </td>
                                       <td>
                                          <div className="text-bold text-green">Refunded  </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">2</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="">05 August 2022</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#refundinvoice-Modal">View </button> </div>
                                       </td>
                                       <td>
                                          <div className="text-bold text-green">Refunded  </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td><span className="sNo">2</span> </td>
                                       <td>T2540    </td>
                                       <td>
                                          <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                       </td>
                                       <td>
                                          <div className="text-bold">5</div>
                                       </td>
                                       <td>
                                          <div className="">05 August 2022</div>
                                       </td>
                                       <td>
                                          <div className="text-bold">$538</div>
                                       </td>
                                       <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#refundinvoice-Modal">View </button> </div>
                                       </td>
                                       <td>
                                          <div className="text-bold text-yellow">Pending </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
        </>
    )
}

export default PaymentInvoiceContainer;