export const BLOG_ACTIONS = {
    BLOG_SEARCH: 'BLOG_SEARCH',
    BLOG_SEARCH_SUCCESS: 'BLOG_SEARCH_SUCCESS',
    BLOG_SEARCH_ERROR: 'BLOG_SEARCH_ERROR',

    BLOG_UPSERT: 'BLOG_UPSERT',
    BLOG_UPSERT_SUCCESS: 'BLOG_UPSERT_SUCCESS',
    BLOG_UPSERT_ERROR: 'BLOG_UPSERT_ERROR',

    BLOG_EDIT: 'BLOG_EDIT',

    BLOG_RESET: 'BLOG_RESET',
};
 
export const blogResetAction = () => ({
    type: BLOG_ACTIONS.BLOG_RESET,
});

export const blogSearchAction = (payload) => ({
    type: BLOG_ACTIONS.BLOG_SEARCH,
    ...payload,
});

export const blogEditAction = (payload) => ({
    type: BLOG_ACTIONS.BLOG_EDIT,
    ...payload,
});

export const blogUpsertAction = (payload) => ({
    type: BLOG_ACTIONS.BLOG_UPSERT,
    ...payload,
});

export default {
    BLOG_ACTIONS,
    blogSearchAction,
    blogUpsertAction,
    blogEditAction,
};
