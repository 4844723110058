import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { ChartTitle, Subtitle, PieChartWrapper, colors } from "./styles";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

const DevicesReport = (props) => {
  const {
    devicesData ={}, 
} = props; 
  const INITIAL_STATE = {
    labels: [],
    values: [],
    colors: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [totalUsers, setTotalUsers] = useState(0);

  const displayResults = (response) => {
    const queryResult = response.reports[0].data.rows;
    const total = response.reports[0].data.totals[0].values[0];
    setTotalUsers(total);
    let labels = [];
    let values = [];
    let bgColors = [];
    queryResult.forEach((row, id) => {
      labels.push(row.dimensions[0]);
      values.push(row.metrics[0].values[0]);
      bgColors.push(colors[id + 4]);
    });
    setReportData({
      ...reportData,
      labels,
      values,
      colors: bgColors,
    });
  };

  const data = {
    labels: reportData.labels,
    datasets: [
      {
        data: reportData.values,
        backgroundColor: reportData.colors,
      },
    ],
  };

  const options = {
    legend: { position: "bottom" },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  useEffect(() => {  
    if(devicesData?.reports?.length>0)
      displayResults(devicesData)  
  }, []);

  return (
    <div>
      <ChartTitle>Devices by Users</ChartTitle>
      <Subtitle>{`Total Users - ${totalUsers}`}</Subtitle> 
      {reportData && (
        <PieChartWrapper>
          <Doughnut data={data} options={options} width={300} height={300} />
        </PieChartWrapper>
      )}
    </div>
  );
};

export default DevicesReport;
