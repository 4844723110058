import React from 'react';
import PropTypes from 'prop-types';
// import NavigationComponent from 'src/components/navigation-component/NavigationComponent';
import { useDispatch, useSelector } from 'react-redux';
import { shallowEqual } from 'recompose';
import { DEFAULT_DP } from 'src/constants/CommonConstants';
import { logoutAction } from '../auth-container/actions/AuthActions';
import LinkNav from 'src/components/navigation-component/LinkNav';
import NavigationComponent from 'src/components/navigation-component/NavigationComponent';

const menus = [
   {
       title: 'Internal',
       scope: '',
       items: [
            {
                faIcon: 'mdi-home',
                title: 'Dashboard',
                scope: 'dashboard',
                link:'/',
            },
            {
                faIcon: 'mdi mdi-whatsapp',
                title: 'Send whatsapp SMS',
                scope: 'sendWhatsapp',
                link:'/whatsapp',
            },
            {
                faIcon: 'mdi-phone-voip',
                title: 'Google Analytics',
                scope: 'googleAnalytics',
                items: [
                    {
                        title: 'Crazy For Study',
                        scope: 'googleAnalytics.cfs',
                        link: '/google-analytics/crazyforstudy',
                    },
                    {
                        title: 'Acadecraft',
                        scope: 'googleAnalytics.acadecraft',
                        link: '/google-analytics/acadecraft',
                    },
                    {
                        title: 'Acadestudio',
                        scope: 'googleAnalytics.acadestudio',
                        link: '/google-analytics/acadestudio',
                    },
                    {
                        title: 'Acadereality',
                        scope: 'googleAnalytics.acadereality',
                        link: '/google-analytics/acadereality',
                    },
                ],
            },
            {
                faIcon: 'mdi-phone-voip',
                title: 'Subscriptions Overview',
                scope: 'subscriptionsOverview',
                link:'/subscriptions',
            },
            {
                faIcon: 'mdi-phone-voip',
                title: 'Student overview ',
                scope: 'studentOverview ',
                link:'/students',
            },
            {
                faIcon: 'mdi-human',
                title: 'Tutor overview',
                scope: 'tutorOverview',
                link:'/tutors',
            },
            {
                faIcon: 'mdi-login',
                title: 'Sessions overview',
                scope: 'sessionsOverview',
                link:'/sessions',
            },
            {
                faIcon: 'mdi-help-circle',
                title: 'SOS',
                scope: 'sos',
                link:'/sos',
            },
            {
                faIcon: 'mdi-chart-areaspline',
                title: 'Create Announcement',
                scope: 'createAnnouncement',
                link:'/announcements',
            },
            {
                faIcon: 'mdi-chart-areaspline',
                title: 'Role Modules',
                scope: 'roleModule',
                link:'/role-modules',
            },
            {
                faIcon: 'mdi-chart-areaspline',
                title: 'Blogs',
                scope: 'master',
                link: '/blogs/search',
            },
           {
               faIcon: 'mdi-home',
               title: 'People',
               scope: 'people',
               items: [
                   {
                       title: 'User Manager',
                       scope: 'people.user_manager',
                       link: '/user/manage',
                   },
                   {
                       title: 'Role Manager',
                       scope: 'people.role_manager',
                       link: '/permissions/role',
                   },
               ],
           },
           {
            faIcon: 'mdi-home',
            title: 'Enquiry',
            scope: 'enquiries',
            items: [
                {
                    title: 'Resume Writing',
                    scope: 'enquiries.resume_writing',
                    link: '/enquiries/resume-writing',
                },
                {
                    title: 'Mentor Assistant',
                    scope: 'enquiries.mentor_assistant',
                    link: '/enquiries/mentor-assistant',
                },
                {
                    title: 'SOP',
                    scope: 'enquiries.sop',
                    link: '/enquiries/sop',
                },
            ],
        },
            {
            faIcon: 'mdi-home',
            title: 'Masters',
            scope: 'master',
            items: [
                {
                    title: 'Subject',
                    scope: 'master.subject',
                    link: '/masters/subject',
                },
                {
                    title: 'Sub Subject',
                    scope: 'master.sub-subject',
                    link: '/masters/sub-subject',
                },
                {
                    title: 'Admin',
                    scope: 'master.admin',
                    link: '/masters/admin',
                },
                {
                    title: 'Role',
                    scope: 'master.role',
                    link:'/masters/role'
                }
            ],
            },
           {
               faIcon: 'mdi-home',
               title: 'Payments and Invoice',
               scope: 'paymentsAndInvoice',
               link: '/payments-invoice',
           },
           {
               faIcon: 'mdi-library',
               title: 'Books',
               scope: 'books',
               items: [
                        {
                            title: 'Book',
                            scope: 'books.list',
                            link: '/books',
                        },
                        {
                            title: 'Ready to GO Live',
                            scope: 'books.list',
                            link: '/books/live',
                        },
                        {
                            title: 'Bulk Upload',
                            scope: 'books.list',
                            link: '/books/bulk-upload',
                        },
                        {
                            title: 'Authoring',
                            scope: 'books.list',
                            link: '/books/authoring',
                        },
                        {
                            title: 'Pending for QC',
                            scope: 'books.list',
                            link: '/books/pending-for-qc',
                        }
               ],
           }, 
            {
                faIcon: 'mdi-home',
                title: "QnA's",
                scope: 'questions',
                items: [
                 {
                     title: 'All Questions',
                     scope: 'questions.all',
                     link: '/questions',
                 },                 
                 {
                     title: 'Add New Questions',
                     scope: 'questions.manage',
                     link: '/questions/manage',
                 }, 
                 {
                     title: 'Others Question',
                     scope: 'questions.list',
                     link: '/questions/other',
                 },
                 {
                     title: 'Others Question1',
                     scope: 'questions.list',
                     link: '/questions/miscellaneous',
                 }
                ],
            },
            {
                faIcon: 'mdi-home',
                title: 'Manage FAQ',
                scope: 'manageFaq',
                link: '/faq',
            },
            {
                faIcon: 'mdi-home',
                title: 'Assignment Requests',
                scope: 'assignmentRequest',
                link: '/assignment-request',
            },
            {
                faIcon: 'mdi-home',
                title: 'Solutions report',
                scope: 'solutionsReport',
                link: '/',
            },
            {
                faIcon: 'mdi-home',
                title: 'Experts',
                scope: 'experts',
                link: '/experts',
            },
            {
                faIcon: 'mdi-home',
                title: 'Rating and Reviews',
                scope: 'ratingAndReviews',
                link: '/rating-reviews',
            },
       ],
   },
];

 
const NavigationContainer = ({ closeSideBar, openSideBar }) => {
    const dispatch = useDispatch();
    const { personalInfo, userType, profilePicUrl } = useSelector(
        (state) => state.AuthReducer,
        shallowEqual,
    );
    const logout = () => dispatch(logoutAction());
    return (
      <>           
         <NavigationComponent
            closeSideBar={closeSideBar}
            openSideBar={openSideBar}
            heading="CFS"
            menus={menus}
            logout={logout}
            personalInfo={personalInfo}
            userType={userType}
            profilePicUrl={profilePicUrl || DEFAULT_DP}
        />
</>
    );
};

NavigationContainer.propTypes = {
    closeSideBar: PropTypes.func.isRequired,
    openSideBar: PropTypes.func.isRequired,
};

export default NavigationContainer;
