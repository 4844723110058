import React from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const SubjectSeoBreadCrumb = () => {

const history = useHistory()
const params = useParams()

useEffect(() => {
    console.log('params is',params)
}, [])

    return (
        <div className=' mt-2 mb-2'>
        <button className="btn btn-sm counter pe-2 ps-2 ms-2">
                     <span className="mdi mdi-earth mr-2"></span>
                     SEO
                 </button>
                 <button className="btn btn-sm counter pe-2 ps-2 ms-2"
                //  onClick={() => history.push()}
                 >
                     <span className="mdi mdi-star mr-2"></span>
                     Student Reviews
                 </button>
                 <button className="btn btn-sm counter pe-2 ps-2 ms-2">
                     <span className="mdi mdi-book mr-2"></span>
                     Content
                 </button>
        </div>
    )
}

export default SubjectSeoBreadCrumb