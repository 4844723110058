const QnATabs=({activeTab,setActiveTab,tab,questionCount})=>{
    const totalVal = questionCount[tab.countKey];
 return (
       <li className="nav-item" role="presentation" >
          <button className={`nav-link ${(activeTab==tab.key)?'active':''}`} 
          id="pills-all-tab" data-bs-toggle="pill" 
          data-bs-target="#pills-all" 
          type="button" role="tab" 
          aria-controls="pills-all" 
          aria-selected="true" 
          onClick={()=>{setActiveTab(tab.key)}}
          >{tab.value} <span>({totalVal})</span>
          </button>
    </li>
 )
 }
export default QnATabs;