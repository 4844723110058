/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest } from 'src/utils/RequestUtil';

export const dashboardSearchAPI = function (data) {
    const url = `${config.baseUrl}master-admin/getAnalyticsFilterWise`;
    return postRequest({
        url,
        data,
    });
};

 