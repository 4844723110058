const ListTitle=({title})=>{
 return (
   <div className="dateinydt">
          <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
             <div className="">
                <h4 className="card-title">{title}:</h4>
             </div>
          </div>
       </div>
 )
}
export default ListTitle

