import { Formik, Form } from 'formik';
import { useCallback,useEffect,useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { shallowEqual } from 'recompose';
import { isProduction } from 'src/config/index';
import { withFormikDevtools } from 'formik-devtools-extension';
import { bookUpsertAction } from './actions/BookActions';
import { BookYupSchema } from './BookPropTypes';
import { headerShowAction } from '../header-container/actions/HeaderActions';
import SuSubjectDropDown from 'src/business-components/SubSubjectDropDown';
import SubjectDropDown from 'src/business-components/SubjectDropDown';
import FormikInput from 'src/components/formik/FormikInput';
import FormikTextArea from 'src/components/formik/FormikTextArea';
import FormikFileDirect from 'src/components/formik/FormikFileDirect';
import { convertBase64 } from 'src/utils/CommonUtils';
import FormCreateUpdate from '../blog-container/FormCreateUpdate';
import RoutingUtil from 'src/utils/RoutingUtil';
import SEOBreadCrumb from './SEOBreadCrumb';

let setSubmitting = () => { };
const ManageBookForm=(props)=>{
       // DEV TOOL
       if (!isProduction()) {
         withFormikDevtools(props);
     }
     const [editorLoaded, setEditorLoaded] = useState(false);
     const [subjectId,setSubjectId] = useState(null);
     const [imagePre,setImagePre] = useState("/assets/images/super-admin/home/icon.svg");
     const [isSEOEdit, setIsSEOEdit] = useState(false)

     useEffect(() => {
         setEditorLoaded(true);
         const formType = localStorage.getItem('form-edit-type')
         if(formType!== undefined && formType !== null){
            setIsSEOEdit(true)
         }
         return () => {
            localStorage.removeItem('form-edit-type')
         }
         }, []); 

      const handelImagePreview=async(e)=>{
         console.log('file is',e.target.files[0])
         const file = e.target.files[0];       
         const img64 =await convertBase64(file)
         setImagePre(img64)
         // values.img_path=file
         values.file = file
         }


         const {
          setFieldValue,
          values,
          // errors,
          // touched,
          isSubmitting,
          onSearch,
          onClear,
          resetForm,
          handelChangeUpload,
          btnDisabled
      } = props;

      return (
         <div className="card-body">
               {/* <form className="forms-sample"> */}
               <Form >
                  <div className="row">
                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">Subject Name</label>
                        <SubjectDropDown
                           className="form-select"
                           value={values.subject_id}
                           name="subject_id"
                           onChange={(value)=>{
                              values.subject_id = value
                              setSubjectId(value);
                           }}
                           disabled={isSEOEdit}
                        />
                     </div>
                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">Sub Subject Name</label>
                        <SuSubjectDropDown
                                className="form-select"
                                subjectId={subjectId}
                                value={values.sub_subject_id}
                                name="sub_subject_id"
                                onChange={(value)=>{
                                 values.sub_subject_id = value 
                                }}
                            />
                     </div>
                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">ISBN 13</label>
                        <FormikInput
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="ISBN13"
                           className="form-control"
                           value={values.ISBN13}
                           disabled={isSEOEdit}
                        />
                     </div>
                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">ISBN 10</label>
                        <FormikInput
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="ISBN10"
                           className="form-control"
                           value={values.ISBN10}
                           disabled={isSEOEdit}
                        />
                     </div>
                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">Author1</label>
                        <FormikInput
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="Author1"
                           className="form-control"
                           value={values.Author1}
                           disabled={isSEOEdit}
                        />
                     </div>
                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">Author2</label>
                        <FormikInput
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="Author2"
                           className="form-control"
                           value={values.Author2}
                           disabled={isSEOEdit}
                        />
                     </div>
                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">Author3</label>
                        <FormikInput
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="Author3"
                           className="form-control"
                           value={values.Author3}
                           disabled={isSEOEdit}
                        />
                     </div>
                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">Book Name</label>
                        <FormikInput
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="BookName"
                           className="form-control"
                           value={values.BookName}
                           disabled={isSEOEdit}
                        />
                     </div>

                     <div className="col-md-3 form-group">
                        <label className="d-flex justify-content-between align-items-center">Edition </label>
                        <FormikInput
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="Edition"
                           className="form-control"
                           value={values.Edition}
                           disabled={isSEOEdit}
                        />
                     </div>
                     <div className="col-md-6 form-group">
                        <label className="d-flex justify-content-between align-items-center">Meta Description </label>
                        <FormikTextArea
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="metaDescription"
                           className="form-control"
                           value={values.metaDescription}
                        />
                     </div>
                     {
                        isSEOEdit && (
                           <div className="col-md-6 form-group">
                           <label className="d-flex justify-content-between align-items-center">No Index </label>
                                <select className='form-control mt-2' style={{height:'60%'}}>
                                 <option>Index</option>
                                 <option>No Index</option>
                                </select>
                        </div>
                        )
                     }
                   
                     <div className="col-md-6 form-group">
                        <label className="d-flex justify-content-between align-items-center">Meta Keywords </label>
                        <FormikTextArea
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="metaKeywords"
                           className="form-control"
                           value={values.metaKeywords}
                        />
                     </div>
                     <div className="col-md-6 form-group">
                        <label className="d-flex justify-content-between align-items-center">Alt Image </label>
                        <FormikTextArea
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="metaKeywords"
                           className="form-control"
                           value={values.metaKeywords}
                        />
                     </div>
                     <div className="col-md-12 form-group">
                        <label className="d-flex justify-content-between align-items-center">Description </label>
                        <FormikTextArea
                           type="text"
                           placeholder="Please Enter Meta Keywords"
                           name="Description"
                           className="form-control"
                           value={values.Description}
                        />
                     </div>
                     {
                        !isSEOEdit && (
                           <div className="col-md-12 form-group"> 
                           <div className="imgulpdn1xz">
                              <div className="privimgbvc1">
                                 <div className="js--image-preview" style={{backgroundImage:`url("${imagePre}"`}}></div>                                  
                              </div> 
                              <div className="upload-options">
                                 <div className="thumbntex">Thumbnail Image </div>
                                 <label> 
                                     <FormikFileDirect
                                        name="file"
                                        accept="image/*"
                                        className="image-upload custom-file-input"
                                        onChange={(e)=>{handelImagePreview(e)}}
                                                                                  
                                     />
                                 </label>
                                 <div className="Imgmbntex">Image size 1200x628px</div>
                              </div>
                           </div>
                        </div>
                        )
                     }
                  
                  </div>
                  <div className="text-center"> 
                     {/* <button type="submit" className="btn btn-gradient-primary me-2">Save Book</button>  */}
                     {/* <button className="btnbluen1 plwhit">Book Preview </button> */}
                     <FormCreateUpdate
                              isSubmitting={isSubmitting}
                              isUpdate={Boolean(values._id)}
                              onSearch={onSearch}
                              onClear={onClear}
                              resetForm={resetForm}
                              values={values}
                              submitButtonText="Save Book"
                              onSubmitClick={()=>{
                                 values.isPreview = false;
                              }}
                              />
                  </div>
                  </Form>
            </div>
      )
}
const BookAuthoring=()=>{
const dispatch = useDispatch();
const history = useHistory();
const {
   currentRecord,
   upsertReqStatus, 
   search,
   searchReqStatus,
   } = useSelector((state)=>state.BookReducer,shallowEqual) 
   const [isSEOEdit, setIsSEOEdit] = useState(false)
const [btnDisabled, setBtnDisbaled] = useState(true);
const [file,setFile] = useState(null);
const onSubmit = useCallback((values,formikUtils)=>{
   ({ setSubmitting } = formikUtils);
   if(file){
     values.isUpload=true
     values.file=file
   }
   delete values.type;
  //  if(values.scoDetails.length===0 ) delete values.scoDetails;
   dispatch(bookUpsertAction(values))
},[dispatch])
 

useEffect(() => {
   const formType = localStorage.getItem('form-edit-type')
   if(formType!== undefined && formType !== null){
      setIsSEOEdit(true)
   }
   return () => {
      localStorage.removeItem('form-edit-type')
   }
   }, []); 

const handelChangeUpload = useCallback((e)=>{
   const filename = e.target.files[0].name;
   let ext = filename.split(".");
   ext = ext[(ext.length - 1)]
   if (ext === "jpeg"||ext === "png"||ext === "jpg") {
     setBtnDisbaled(false);
     setFile(e.target.files[0]);
   } else {
      setBtnDisbaled(true);
      dispatch(headerShowAction({data:"Only .docx files are allowed"}))
   }
},[])

  return(
   <div className="content-wrapper">
   <div className="page-header">
      <h4 className="page-title"> Book Authoring
      </h4>
   </div>
   {
      isSEOEdit && (
         <SEOBreadCrumb book_isbn={currentRecord?.ISBN13} book_id={currentRecord?._id}/>
      )
   }
   <div className="row">
      <div className="col-md-12 grid-margin stretch-card">
         <div className="card">
         <Formik
                onSubmit={onSubmit}
                initialValues={currentRecord}
                validationSchema={BookYupSchema}
                enableReinitialize
                >
            {(formikProps)=>(
            <ManageBookForm
              onSubmit={onSubmit}
              handelChangeUpload={handelChangeUpload}
              btnDisabled={btnDisabled}
              {...formikProps}
              />
            )}
        </Formik>
         </div>
      </div>
   </div>
</div>
   )
}
export default BookAuthoring