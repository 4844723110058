import {useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import RoutingUtil from "src/utils/RoutingUtil";
import { questionCountAction } from "./actions/QuestionActions";
import Loader from "src/components/Loader";
import { REQUEST_STATUS } from "src/constants/CommonConstants";

const AllQuestion = () => {
  const dispatch = useDispatch();

  const {
   questionCount:{askQuestion},
   searchReqStatus
  } = useSelector((state) => state.QuestionReducer, shallowEqual);


  const [questionCount, setQuestionCount] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
 
const getQuestionCount=async()=>{
   setIsLoaded(false)
   dispatch(questionCountAction()); 
 }

 useEffect(()=>{
   if(askQuestion && searchReqStatus===REQUEST_STATUS.SUCCESS) {
      setQuestionCount(askQuestion)
     setIsLoaded(true)
   }
   else
   {
       setIsLoaded(false) 
   }
 },[askQuestion])

 useEffect(()=>{
   getQuestionCount();
 },[])
    
    
    return (
            <> 
            {isLoaded ?
            <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2" >
                        <i className="mdi mdi-home"></i>
                        </span> Questions
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="allsujectbk d-flex justify-content-center askwsnsbg1">
                          <div className="card">
                          <div className="card-body">
							<div className="askwsnsbg">
                             <Link to={RoutingUtil.questionAskUrl}>
							  {/* <a href="all-ask-qa.php"> */}
								<div className="askwsns">
								  <div className="askWimg">
                                    <span>
                                        <img src="assets/images/all-questions/questionmark.svg" className="img-fluid" alt="questionmark"/>
                                        </span> {questionCount.total_qna}
                                     </div>
								  <div className="askWText">ASK 50 questions</div>
								  </div>
								{/* </a> */}
                            </Link>
							  </div> 
							</div>  
							</div> 
							<div className="card">
                          <div className="card-body">
							<div className="askwsnsbg">
                     <Link to={RoutingUtil.questionOtherUrl()}>
								<div className="askwsns">
								  <div className="askWimg">
                                    <span>
                                        <img src="assets/images/all-questions/questionmark.svg" className="img-fluid" alt="questionmark1"/>
                                        </span> {questionCount.total_qna_other}
                                     </div>
								  <div className="askWText"> Other Questions</div>
								  </div>
                          </Link>
							  </div> 
							</div>  
							</div>
                        </div>
                     </div>
                  </div>
               </div>:<Loader/> 
               }
            
            </>
    )
}

export default AllQuestion;