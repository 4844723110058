/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {
    get, isEqual, throttle,
} from 'lodash-es';
import { Field, ErrorMessage } from 'formik';

class Render extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
            selectedValue:[],
        };

        this.throttledGetOptions = throttle(this.getOptions, 1000);
    }

    componentDidMount() {
        this.getDefaultOption();
    }

    componentDidUpdate(prevProps) {
        const {
            value,
            form,
            field,
            whereConditions,
        } = this.props;

        if (prevProps.value !== value) {
            this.getDefaultOption();
        }
        // e.g. state change then city where condition changed.. so it should reset
        else if (!isEqual(prevProps.whereConditions, whereConditions)) {
            form.setFieldValue(field.name, '');
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                options: [],
            });
        }
    }

    getOptionsByWhereClause = async () => {
        const {
            value,
            displayKeyPath,
            valueKeyPath,
            apiCallAddonData,
            api,
            whereConditions
        } = this.props;

        try {
            // fetch only data that is needed
            const select = displayKeyPath.reduce((total, current) => {
                // eslint-disable-next-line no-param-reassign
                total[current] = 1;
                return total;
            }, {});

            // get options from server
            // const {
            //     data: {
            //         data: {
            //             data,
            //         },
            //     },
            // }            
            const {data:{data}} = await api({
                where:whereConditions,
                select,
                ...apiCallAddonData,
            });
            console.log('api res is',whereConditions)
            // default option
            const optionsData = data.map((item) => {
                const displayName = displayKeyPath.map((path) => get(item, path)).filter((x) => !!x).join(', ');
                return {
                    label: displayName,
                    value: get(item, valueKeyPath),
                };
            });

            // update option in state
            this.setState({
                options: optionsData,
                selectedValue:value,
            });
        }
        catch (error) {
            console.error('LOG: getOptions -> error', error);
        }
    }

    getDefaultOption = async () => {
        const {
        } = this.props; 
            try {
                await this.getOptionsByWhereClause();
            }
            catch (error) {
                console.error('LOG: getOptions -> error', error);
            }
    };

    getOptions = async (query) => {
        const {
            whereClauseKeysPaths,
            whereConditions,
        } = this.props;

        // build where clause
        const whereWithOrCondition = {
            $or: [],
            ...whereConditions,
        };

        whereClauseKeysPaths.forEach((keyPath) => {
            const condition = {};
            condition[keyPath] = {
                $regex: query,
                $options: 'i',
            };
            // set(condition, keyPath, {
            //     $regex: `^${query}`,
            //     $options: 'i',
            // });
            whereWithOrCondition.$or.push(condition);
        });

        try {
            await this.getOptionsByWhereClause({
                where: whereWithOrCondition,
            });
        }
        catch (error) {
            console.error('LOG: getOptions -> error', error);
        }
    };

    render() {
        const {
            value,
            placeholder,
            disabled,
            onChange,

            field,
            form,
        } = this.props;

        const {
            options,
            selectedValue
        } = this.state;
        return (
            <>
                <Select
                    value={selectedValue}
                    options={Array.isArray(options) ? options : []}                   
                    isMulti                  
                    onChange={(selectedValue) => { 
                        onChange(selectedValue); 
                        this.setState({ 
                            selectedValue,
                        });
                        // form.setFieldValue(field.name, selectedValue);
                    }}
                    placeholder={placeholder}
                />
                <ErrorMessage
                    component="div"
                    className="text-danger"
                    name={field.name}
                />
            </>
        );
    }
}

Render.propTypes = {
    api: PropTypes.func.isRequired,
    apiCallAddonData: PropTypes.any,
    defaultValueFetchByKeyPath: PropTypes.string,
    disabled: PropTypes.bool,
    displayKeyPath: PropTypes.array.isRequired,
    field: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
        setFieldValue: PropTypes.func,
    }).isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    valueKeyPath: PropTypes.string.isRequired,
    whereClauseKeysPaths: PropTypes.array,
    whereConditions: PropTypes.any,
    onChange: PropTypes.func,
};

Render.defaultProps = {
    apiCallAddonData: {},
    defaultValueFetchByKeyPath: '',
    disabled: false,
    placeholder: '',
    whereClauseKeysPaths: [],
    whereConditions: {},
    onChange: () => { },
    value: '',
};

const FormikMultiSelectDropdown = (props) => (
    <Field
        {...props}
        component={Render}
    />
);

export default FormikMultiSelectDropdown;
