export const EXPERT_ACTIONS = {
    EXPERT_SEARCH: 'EXPERT_SEARCH',
    EXPERT_SEARCH_SUCCESS: 'EXPERT_SEARCH_SUCCESS',
    EXPERT_SEARCH_ERROR: 'EXPERT_SEARCH_ERROR',

    EXPERT_COUNT: 'EXPERT_COUNT',

    EXPERT_UPSERT: 'EXPERT_UPSERT',
    EXPERT_UPSERT_SUCCESS: 'EXPERT_UPSERT_SUCCESS',
    EXPERT_UPSERT_ERROR: 'EXPERT_UPSERT_ERROR',

    EXPERT_EDIT: 'EXPERT_EDIT',

    EXPERT_RESET: 'EXPERT_RESET',

} 

export const expertResetAction = (payload) => ({
    type: EXPERT_ACTIONS.EXPERT_RESET,
    ...payload
});

export const expertSearchAction = (payload) => ({
    type: EXPERT_ACTIONS.EXPERT_SEARCH,
    ...payload
})

export const expertCountAction = (payload) => ({
    type: EXPERT_ACTIONS.EXPERT_COUNT,
    ...payload
})

export const expertEditAction = (payload) => ({
    type: EXPERT_ACTIONS.EXPERT_EDIT,
    ...payload,
});

export const expertUpsertAction = (payload) => ({
    type: EXPERT_ACTIONS.EXPERT_UPSERT,
    ...payload,
});

export default {
    EXPERT_ACTIONS,
    expertSearchAction,
    expertCountAction,
    expertUpsertAction,
    expertEditAction,
    expertResetAction
}