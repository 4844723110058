import React from 'react';

const NavigationSearchComponent = () => (
    <div className="sidebar-search">
        <div>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control search-menu"
                    placeholder="Search..."
                />
                <div className="input-group-append">
                    <span className="input-group-text">
                        <i
                            className="fa fa-search"
                            aria-hidden="true"
                        />
                    </span>
                </div>
            </div>
        </div>
    </div>
);

NavigationSearchComponent.propTypes = {

};

export default NavigationSearchComponent;
