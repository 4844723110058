import React from "react"
const SubscriptionContainer=()=>{
    return(
        <>
        <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="mdi mdi-home"></i>
                        </span> Subscriptions Overview   
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="bgtodnlist">
                           <div className="text-start">
                              <div className="daterangepkr"> <span><i className="mdi mdi-calendar-check"></i></span>
                                 <input type="text" id="demo-12" placeholder="start date - end date" className="form-control form-control-sm"/>
                              </div>
                           </div>
                           <div className="dailyTxtbg">
                              <div className="dailyTxt1">
                                 <button className="btndaten1 active">Daily  </button>
                                 <button className="btndaten1 ">Tomorrow </button>   
                                 <button className="btndaten1">Last 7 Days</button>     
                                 <button className="btndaten1">Last 15 Days </button>    
                                 <button className="btndaten1">Last 30 Days  </button>   
                                 <button className="btndaten1">Last 6 Month </button>    
                                 <button className="btndaten1">Last 1 Year</button>
                                 <button className="btndaten1">All Time</button>
                              </div>
                           </div>
                        </div>
                        <div className="subscptionTxtbg">
                           <div className="subscptionTxt ubscpt1">
                              <span className="stx1">SUBSCRIPTION</span>
                              <div className="d-flex stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                              <div className="d-flex align-items-center stx3">Total  <span>$1,608,469.50</span></div>
                           </div>
                           <div className="subscptionTxt ubscpt2">
                              <span className="stx1">ASSIGNMENT </span>
                              <div className="d-flex align-items-center stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                              <div className="d-flex align-items-center stx3">Total  <span>$1,608,469.50</span></div>
                           </div>
                           <div className="subscptionTxt ubscpt3">
                              <span className="stx1">ONLINE TUTORING </span>
                              <div className="d-flex align-items-center stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                              <div className="d-flex align-items-center stx3">Total  <span>$1,608,469.50</span></div>
                           </div>
                           <div className="subscptionTxt ubscpt4">
                              <span className="stx1">OTHERS</span>
                              <div className="d-flex align-items-center stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                              <div className="d-flex align-items-center stx3">Total  <span>$1,608,469.50</span></div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 mtops">
                        <div className="card shadow">
                           <div className="card-body">
                              <div className="dfnam">
                                 <h3>NET EARNINGS</h3>
                                 <div className="bgawa">
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All</button>
                                       </li>
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="pills-Web-tab" data-bs-toggle="pill" data-bs-target="#pills-Web" type="button" role="tab" aria-controls="pills-Web" aria-selected="false">Web</button>
                                       </li>
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="pills-App-tab" data-bs-toggle="pill" data-bs-target="#pills-App" type="button" role="tab" aria-controls="pills-App" aria-selected="false">App</button>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="">
                                 <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                                       <div className="">
                                       <div className="revenueTobg">
                                          <div className="revenueTo1">
                                             <div className="revenueDol1 stg">$82,944.60</div>
                                             <div className="revenuetxt1">Total revenue from all Services </div>
                                          </div>
                                          <div className="prdsb1bg">
                                             <div className="prdsb1">
                                                <div className="prdsb2">$14,299.59 <span>+65%</span></div>
                                                <div className="prdsb3">This Month</div>
                                             </div>
                                             <div className="prdsb1">
                                                <div className="prdsb2">$14,299.59 <span>+65%</span></div>
                                                <div className="prdsb3">This Month</div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="barChartjs-sub">
                                           <canvas id="barChart" className="barChart" style={{height:'330px'}}></canvas>
                                       </div>
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-Web" role="tabpanel" aria-labelledby="pills-Web-tab">...</div>
                                    <div className="tab-pane fade" id="pills-App" role="tabpanel" aria-labelledby="pills-App-tab">...</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
					  <div className="col-md-6 mtops">
                        <div className="card shadow">
                           <div className="card-body">
                              <div className="dfnam">
                                 <h4>1,937 Subscribers</h4>
                                 <div className="bgawa">
                                    <ul className="nav nav-pills mb-3" id="pills-tab2" role="tablist">
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link active" id="pills-all2-tab" data-bs-toggle="pill" data-bs-target="#pills-all2" type="button" role="tab" aria-controls="pills-all2" aria-selected="true">All</button>
                                       </li>
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="pills-Web2-tab" data-bs-toggle="pill" data-bs-target="#pills-Web2" type="button" role="tab" aria-controls="pills-Web2" aria-selected="false">Web</button>
                                       </li>
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="pills-App2-tab" data-bs-toggle="pill" data-bs-target="#pills-App2" type="button" role="tab" aria-controls="pills-App2" aria-selected="false">App</button>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="">
                                 <div className="tab-content" id="pills-tabContent2">
                                    <div className="tab-pane fade show active" id="pills-all2" role="tabpanel" aria-labelledby="pills-all2-tab">
                                       <div className="">
                                       <div className="revenueTobg"> 
                                          <div className="prdsb2bg">
                                             <div className="prdsb1">
                                                <div className="prdsb2 active">$82,944.60</div>
                                                <div className="prdsb3">Total revenue from subscriptions </div>
                                             </div>
                                             <div className="prdsb1">
                                                <div className="prdsb2">$82,944.60</div>
                                                <div className="prdsb3">Total Refunded subscriptions </div>
                                             </div>
											  <div className="prdsb1">
                                                <div className="prdsb2">$82,944.60</div>
                                                <div className="prdsb3">Total Awaiting subscriptions </div>
                                             </div>
                                          </div>
                                       </div>
										   <div className="subres1sub1bg">
										   <div className="subres1sub1">
											   <div className="subres1sub2 annual">ANNUAL <span>1,93 Subscribers</span> </div>
											   <div className="subres1sub3">$829</div>
											   </div>
											   <div className="subres1bg">
											   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Active </div>
												   </div>
												   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Awaiting  </div>
												   </div>
												   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-blue"></i> Refunded  </div>
												   </div>
											   </div>
										   </div>
										   <div className="subres1sub1bg">
										   <div className="subres1sub1">
											   <div className="subres1sub2 quarterly">QUARTERLY  <span>1,93 Subscribers</span> </div>
											   <div className="subres1sub3">$829</div>
											   </div>
											   <div className="subres1bg">
											   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Active </div>
												   </div>
												   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Awaiting  </div>
												   </div>
												   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-blue"></i> Refunded  </div>
												   </div>
											   </div>
										   </div>
										   <div className="subres1sub1bg">
										   <div className="subres1sub1">
											   <div className="subres1sub2 monthly">MONTHLY   <span>1,93 Subscribers</span> </div>
											   <div className="subres1sub3">$829</div>
											   </div>
											   <div className="subres1bg">
											   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Active </div>
												   </div>
												   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Awaiting  </div>
												   </div>
												   <div className="subres1">
												   <div className="subres2">$4588</div>
												   <div className="subres3"><i className="mdi mdi-checkbox-blank-circle text-blue"></i> Refunded  </div>
												   </div>
											   </div>
										   </div>
										    
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-Web2" role="tabpanel" aria-labelledby="pills-Web2-tab">...</div>
                                    <div className="tab-pane fade" id="pills-App2" role="tabpanel" aria-labelledby="pills-App2-tab">...</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
					  
					  <div className="col-md-6 mtops">
                        <div className="card shadow">
                           <div className="card-body">
                              <div className="dfnam">
                                 <h3>ASSIGNMENT </h3>
                                 <div className="bgawa">
                                    <ul className="nav nav-pills mb-3" id="pills-tab3" role="tablist">
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link active" id="pills-all3-tab" data-bs-toggle="pill" data-bs-target="#pills-all3" type="button" role="tab" aria-controls="pills-all3" aria-selected="true">All</button>
                                       </li>
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="pills-Web3-tab" data-bs-toggle="pill" data-bs-target="#pills-Web3" type="button" role="tab" aria-controls="pills-Web3" aria-selected="false">Web</button>
                                       </li>
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="pills-App3-tab" data-bs-toggle="pill" data-bs-target="#pills-App3" type="button" role="tab" aria-controls="pills-App3" aria-selected="false">App</button>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="">
                                 <div className="tab-content" id="pills-tabContent3">
                                    <div className="tab-pane fade show active" id="pills-all3" role="tabpanel" aria-labelledby="pills-all3-tab">
                                       <div className="">
                                       <div className="revenueTobg">
                                          <div className="revenueTo1">
                                             <div className="revenueDol1">$1,608,469.50</div>
                                             <div className="revenuetxt1">Total revenue from Delivered Assignment  </div>
                                          </div>
                                          <div className="prdsb1bg">
                                             <div className="prdsb1">
                                               <div className="d-flex stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                                                <div className="prdsb3">Last 24 hours  </div>
                                             </div>
                                             <div className="prdsb1 prXf1">
                                                <div className="d-flex stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                                                <div className="prdsb3"> Total Refunds</div>
                                             </div> 
                                          </div>
                                       </div>
										   <div className="waitin1">
										   <ul>
											   <li>Awaiting Assignment  <span>$150,200  </span></li>
											   <li>in progress Assignment  <span>$1502  </span></li>
											   <li>Cancelled Assignment  <span>$150  </span></li>
											   <li>Refunds  <span>$2865</span></li>
											   </ul>
										   </div>
										    
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-Web3" role="tabpanel" aria-labelledby="pills-Web3-tab">...</div>
                                    <div className="tab-pane fade" id="pills-App3" role="tabpanel" aria-labelledby="pills-App3-tab">...</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
					  <div className="col-md-6 mtops">
                        <div className="card shadow">
                           <div className="card-body">
                              <div className="dfnam">
                                 <h3>ONLINE TUTORING </h3>
                                 <div className="bgawa">
                                    <ul className="nav nav-pills mb-3" id="pills-tab4" role="tablist">
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link active" id="pills-all4-tab" data-bs-toggle="pill" data-bs-target="#pills-all4" type="button" role="tab" aria-controls="pills-all4" aria-selected="true">All</button>
                                       </li>
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="pills-Web4-tab" data-bs-toggle="pill" data-bs-target="#pills-Web4" type="button" role="tab" aria-controls="pills-Web4" aria-selected="false">Web</button>
                                       </li>
                                       <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="pills-App4-tab" data-bs-toggle="pill" data-bs-target="#pills-App4" type="button" role="tab" aria-controls="pills-App4" aria-selected="false">App</button>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="">
                                 <div className="tab-content" id="pills-tabContent3">
                                    <div className="tab-pane fade show active" id="pills-all4" role="tabpanel" aria-labelledby="pills-all4-tab">
                                       <div className="">
                                       <div className="revenueTobg">
                                          <div className="revenueTo1">
                                             <div className="revenueDol1">$1,608,469.50</div>
                                             <div className="revenuetxt1">Total revenue from Delivered Assignment  </div>
                                          </div>
                                          <div className="prdsb1bg">
                                             <div className="prdsb1">
                                               <div className="d-flex stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                                                <div className="prdsb3">Last 24 hours  </div>
                                             </div>
                                             <div className="prdsb1 prXf1">
                                                <div className="d-flex stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                                                <div className="prdsb3"> Total Refunds</div>
                                             </div> 
                                          </div>
                                       </div>
										   <div className="waitin1">
										   <ul>
											   <li>Unpaid Sessions  <span>$150,200  </span></li>
											   <li>Cancelled sessions  <span>$1502  </span></li>
											   <li>sessons in progress  <span>$150  </span></li>
											   <li>Refunds  <span>$2865</span></li>
											   </ul>
										   </div>
										    
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-Web4" role="tabpanel" aria-labelledby="pills-Web4-tab">...</div>
                                    <div className="tab-pane fade" id="pills-App4" role="tabpanel" aria-labelledby="pills-App4-tab">...</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
					  
					  <div className="col-md-12 mtops">
                        <div className="card shadow">
                           <div className="card-body">
                              <div className="dfnam">
                                 <h3>OTHERS SERVICES </h3> 
                              </div>
                              <div className=""> 
                                       <div className="revenueXv1">
                                       <div className="revenueTobg">
                                          <div className="revenueTo1">
                                             <div className="revenueDol1">$1,608,469.50</div>
                                             <div className="revenuetxt1">Total revenue from Delivered Assignment  </div>
                                          </div>
                                          <div className="prdsbbg">
                                             <div className="prdsb1">
                                               <div className="d-flex stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                                                <div className="prdsb3">Last 24 hours  </div>
                                             </div>
                                             <div className="prdsb1">
                                                <div className="d-flex stx2">$150,200  <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                                                <div className="prdsb3"> Total Refunds</div>
                                             </div> 
                                          </div>
                                       </div>
										   <div className="waitin1">
										   <ul>
											   <li>Experts Helps <span>$150,200  </span></li>
											   <li>Others Sessions <span>$1502  </span></li>
											   <li>Consultant  <span>$150  </span></li>
											   <li>Refunds <span>$2865</span></li>
											   </ul>
										   </div>
										    
                                    </div> 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
        </>
    )
}

export default SubscriptionContainer;