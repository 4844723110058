/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from 'src/utils/RequestUtil';


export const getAllFaqCategory = function (pageNo, pageLimit) {
    const url = `${config.baseUrl}faq/all/${pageNo}/${pageLimit}`;
    return getRequest({
        url,
    });
};

export const addFaqCategory = function(data){
    const url = `${config.baseUrl}faq/add-category`
    return postRequest({
        url,
        data
    })
}

export const getSingleFaqCategory = function(faqId){
    const url = `${config.baseUrl}faq/single/${faqId}`
    return getRequest({
        url
    })
}

export const addFaqQuestionAPI = function(data, faqId){
    const url = `${config.baseUrl}faq/add-question/${faqId}`
    return postRequest({
        url,
        data
    })
}

export const deleteFaqQuestion = function(faqId, questionId){
    const url = `${config.baseUrl}faq/delete-question/${faqId}/${questionId}`

    return getRequest({
        url
    })
}

export const deleteFaqCategory = function(faqId){
    const url = `${config.baseUrl}faq/delete-faq/${faqId}`
    return deleteRequest({
        url
    })
}