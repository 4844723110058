// import React, { useState } from "react";
// import { google } from "googleapis";

// const VisitorsGeoData = () => {
//   const [data, setData] = useState([]);

//   const initAuth = () => {
//     const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
//     const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

//     return google.auth.getApplicationDefault()
//       .then((auth) => {
//         return auth.createScoped(
//           ["https://www.googleapis.com/auth/analytics.readonly"]
//         );
//       })
//       .then((auth) => {
//         return google.analytics({
//           auth,
//           viewId: "UA-XXXXX-Y",
//         });
//       });
//   };

//   const getChartData = () => {
//     const analytics = initAuth();

//     return analytics.data().ga().get({
//       dimensions: ["ga:date"],
//       metrics: ["ga:sessions"],
//     })
//       .then((results) => {
//         setData(results.data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   return (
//     <div>
//       <button onClick={initAuth}>Authorize</button>
//       <div>
//         {data.map((row) => (
//           <div key={row.date}>
//             {row.sessions} sessions on {row.date}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default VisitorsGeoData;


import React from "react";
import { GoogleAnalytics } from "google-analytics-js";

class VisitorsGeoData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visitorsGeoData: null,
    };
  }

  componentDidMount() {
    // Create a new Google Analytics client object.
    const client = new GoogleAnalytics({
      trackingId: "UA-236272773-1",
    });

    // Call the client object's getVisitorsGeoData() method to fetch the data.
    // client.getVisitorsGeoData().then((data) => {
    //   // Update the state with the data.
    //   this.setState({
    //     visitorsGeoData: data,
    //   });
    // });
  }

  render() {
    // Display the data in the component's render() method.
    const { visitorsGeoData } = this.state;

    if (!visitorsGeoData) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <h1>Google Analytics Visitors Geo Data</h1>
        <table>
          <thead>
            <tr>
              <th>Country</th>
              <th>City</th>
              <th>Region</th>
              <th>Continent</th>
            </tr>
          </thead>
          <tbody>
            {visitorsGeoData.map((row) => (
              <tr key={row.country}>
                <td>{row.country}</td>
                <td>{row.city}</td>
                <td>{row.region}</td>
                <td>{row.continent}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default VisitorsGeoData;