const Loader=()=>(
<div className="col-md-12 mtops">
    <div className="card shadow">
      <div className="card-body d-flex justify-content-center">
          <div  className="col-md-4">
            <div></div>
          </div>
            <div  className="col-md-4">
                <h3>Details Loading Please Wait.</h3> 
                <img src="/assets/images/loader.gif" />
            </div>
           <div  className="col-md-4">.
              <div></div>
           </div>
      </div>
    </div>
  </div>
)
export default Loader;