import { Formik, Form } from 'formik';
import { useCallback,useEffect,useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { shallowEqual } from 'recompose';
import FormikCKEditor from "src/components/formik/FormikCKEditor/FormikCKEditor";
import { questionBuklApproveAction, questionCountAction, questionEditAction, questionSearchAction, questionUpsertAction } from './actions/QuestionActions';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import { REQUEST_STATUS, QuestionUploadTab } from 'src/constants/CommonConstants';
import { QuestionYupSchema } from './QuestionPropTypes';
import SubjectDropDown from 'src/business-components/SubjectDropDown';
import SuSubjectDropDown from 'src/business-components/SubSubjectDropDown';
import { isProduction } from 'src/config/index';
import { withFormikDevtools } from 'formik-devtools-extension';
import FormCreateUpdate from '../blog-container/FormCreateUpdate';
import FormikFileDirect from 'src/components/formik/FormikFileDirect';
import { headerShowAction } from '../header-container/actions/HeaderActions';
import AllAskQnA from './BulkQnA/AllAskQnA';
import Qa from './BulkQnA/Qa';
import PendingQnA from './BulkQnA/PendingQnA';
import ErrorLog from './BulkQnA/ErrorLog';
import ApprovedQnA from './BulkQnA/ApprovedQnA';
import Loader from 'src/components/Loader';
import QnATabs from './BulkQnA/QnATabs';
import FilterSections from './FilterSections';
import FormikInput from 'src/components/formik/FormikInput';
import FormikToggle from 'src/components/formik/FormikToggle';



let setSubmitting = () => { };
const ManageQuestionForm=(props)=>{
    // DEV TOOL
    if (!isProduction()) {
        withFormikDevtools(props);
    }
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [subjectId,setSubjectId] = useState(null);
    useEffect(() => {
        setEditorLoaded(true);
        }, []);

        const {
         setFieldValue,
         values,
         // errors,
         // touched,
         isSubmitting,
         onSearch,
         onClear,
         resetForm,
         handelChangeUpload,
         btnDisabled
     } = props;
     return (
        <>
        <div className="card marb1 shadow">
            <div className="card-body">
               <div className="tabsaddnewqus">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                     <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-Upload1-tab" data-bs-toggle="pill" data-bs-target="#pills-Upload1" type="button" role="tab" aria-controls="pills-Upload1" aria-selected="true">Upload Question </button>
                     </li>
                     <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-Bulk-tab" data-bs-toggle="pill" data-bs-target="#pills-Bulk" type="button" role="tab" aria-controls="pills-Bulk" aria-selected="false">Upload Questions Bulk </button>
                     </li>
                  </ul>
               </div>
               <div className="tabsaddnewqus-content">
               <Form >
                  <div className="tab-content" id="pills-tabContent">
                     <div className="tab-pane fade show active" id="pills-Upload1" role="tabpanel" aria-labelledby="pills-Upload1-tab">
                        <div className="row forms-sample">
                           <div className="col-md-6 form-group">
                              <label className="d-flex justify-content-between align-items-center">Subject Name</label>
                              <SubjectDropDown
                                className="form-select"
                                value={values.subject_id}
                                name="subject_id"
                                onChange={(value)=>{
                                    values.subject_id = value
                                    setSubjectId(value);
                                }}
                            />
                           </div>
                           <div className="col-md-6 form-group">
                              <label className="d-flex justify-content-between align-items-center">Sub Subject Name</label>
                               <SuSubjectDropDown
                                className="form-select"
                                subjectId={subjectId}
                                value={values.sub_subject_id}
                                name="sub_subject_id"
                                onChange={(value)=>{
                                 values.sub_subject_id = value 
                                }}
                            />
                           </div>
                           <div className="col-md-12 form-group">
                              <label>Question</label>
                              <FormikCKEditor  
                                 id="editor1"  
                                 name="question"
                                 onBlur={(data) => {
                                    setFieldValue("question", data)
                                 }}
                                 value={values.question}
                                 editorLoaded={editorLoaded}
                             />
                           </div>
                           <div className="col-md-12 form-group">
                              <label>Answer</label>
                              <FormikCKEditor
                                 id="editor2"  
                                 name="completeanswer"
                                 onBlur={(data) => {
                                    setFieldValue("completeanswer", data)
                                 }}
                                 value={values.completeanswer}
                                 editorLoaded={editorLoaded}
                             />
                           </div>
                           <div className="col-md-12 ms-auto me-auto">
                              <div className="col-md-12 text-center"> 
                                 {/* <button type="submit" className="btn btn-gradient-primary me-2">Submit</button>  */}
                                 <FormCreateUpdate
                                    isSubmitting={isSubmitting}
                                    isUpdate={Boolean(values._id)}
                                    onSearch={onSearch}
                                    onClear={onClear}
                                    resetForm={resetForm}
                                    values={values}
                                    submitButtonText="Submit"
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="tab-pane fade" id="pills-Bulk" role="tabpanel" aria-labelledby="pills-Bulk-tab">
                        <div className="row forms-sample">
                           <div className="col-md-9 ms-auto me-auto">
                              <div className="form-group upcsvFlibg">
                                 <div className="upcsvFli">
                                    <div className="">Upload QuestionsFile <span>.csv format only</span></div>
                                    <button className="btnbluen1 plwhit d-flex align-items-center" 
                                    onClick={()=>{window.open("/sampledata/QNA_Sample_File.docx", '_blank')}}>
                                        <i className="mdi mdi-arrow-down mdi-18px"></i> Download Sample File
                                    </button>
                                 </div>
                                 <label htmlFor="et_pb_contact_brand_file_request_0" className="et_pb_contact_form_label"></label>
                                 {/* <input type="file" id="et_pb_contact_brand_file_request_0" className="file-upload"/>  */}
                                 <FormikFileDirect
                                    name="books"
                                    autoComplete="off" 
                                    accept=".docx"
                                    id="et_pb_contact_brand_file_request_0" 
                                    className="file-upload"
                                    onChange={handelChangeUpload}
                                 />
                              </div>
                              <div className="col-md-12 text-center"> 
                                 <button 
                                 type="submit" 
                                 className="btn btn-gradient-primary me-2"
                                 disabled={btnDisabled}
                                 >Upload Questions</button> 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  </Form>
               </div>
            </div>
         </div>
        </>
     )
}
const ManageSCOForm=(props)=>{
   // DEV TOOL
   if (!isProduction()) {
       withFormikDevtools(props);
   }
       const {
        values,       
        isSubmitting,
        onSearch,
        onClear,
        resetForm, 
    } = props;

    return (
       <>
       <div className="card marb1 shadow">
           <div className="card-body">
              <div className="tabsaddnewqus-content">
              <Form >
                 <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-Upload1" role="tabpanel" aria-labelledby="pills-Upload1-tab">
                       <div className="row forms-sample">                           
                          <div className="col-md-6 form-group">
                             <label className="d-flex justify-content-between align-items-center">Meta Title</label>
                             <FormikInput
                                    type="text"
                                    placeholder="Please Enter Meta Title"
                                    name="scoDetails.metaTitle"
                                    className="form-control"
                                    value={values?.scoDetails?.metaTitle}
                                />
                          </div>
                          <div className="col-md-6 form-group">
                             <label className="d-flex justify-content-between align-items-center">Meta Keywords</label>
                             <FormikInput
                                    type="text"
                                    placeholder="Please Enter Meta Keywords"
                                    name="scoDetails.metaKeywords"
                                    className="form-control"
                                    value={values?.scoDetails?.metaKeywords}
                                />
                          </div>
                          <div className="col-md-6 form-group">
                             <label className="d-flex justify-content-between align-items-center">Page URL</label>
                             <FormikInput
                                    type="text"
                                    placeholder="Please Enter Meta Title"
                                    name="link"
                                    className="form-control"
                                    value={values?.link}
                                />
                          </div>
                          <div className="col-md-6 form-group">
                             <label className="d-flex justify-content-between align-items-center">Bread Crumbs</label>
                             <FormikInput
                                    type="text"
                                    placeholder="Please Enter Bread Crumbs"
                                    name="scoDetails.breadCrumbs"
                                    className="form-control"
                                    value={values?.scoDetails?.breadCrumbs}
                                />
                          </div>
                          <div className="col-md-6 form-group">
                             <label className="d-flex justify-content-between align-items-center">Meta Description</label>
                             <FormikInput
                                    type="text"
                                    placeholder="Please Enter Meta Description"
                                    name="scoDetails.metaDescription"
                                    className="form-control"
                                    value={values?.scoDetails?.metaDescription}
                                />
                          </div>
                          <div className="col-md-3 form-group">
                             <label className="d-flex justify-content-between align-items-center">Page Index</label> 
                            <FormikToggle
                                 checked={false}
                                 name="scoDetails.isIndex" 
                                 className="form-control" 
                                 onLabel="Index"
                                 offLabel="No Index"
                                 value={(values?.scoDetails?.isIndex)?values.scoDetails.isIndex:false}
                                />
                          </div>
                          <div className="col-md-3 form-group">
                             <label className="d-flex justify-content-between align-items-center">Apprroval Status</label>
                              <FormikToggle
                               checked={false}
                                 name="isApprovedSCO" 
                                 className="form-control" 
                                 onLabel="Approved"
                                 offLabel="Pending"
                                 value={(values?.isApprovedSCO)?values.isApprovedSCO:false}
                                />
                          </div>
                          
                           
                          <div className="col-md-12 ms-auto me-auto">
                             <div className="col-md-12 text-center"> 
                                {/* <button type="submit" className="btn btn-gradient-primary me-2">Submit</button>  */}
                                <FormCreateUpdate
                                   isSubmitting={isSubmitting}
                                   isUpdate={Boolean(values._id)}
                                   onSearch={onSearch}
                                   onClear={onClear}
                                   resetForm={resetForm}
                                   values={values}
                                   submitButtonText="Submit"
                                />
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 </Form>
              </div>
           </div>
        </div>
       </>
    )
}

const ManageQuestion=()=>{ 
const dispatch = useDispatch();
const history = useHistory();
const {
   currentRecord,
   upsertReqStatus,
   questionCount:{askQuestion},
   questionCount:{isLoaded},
   search,
   searchReqStatus,
   } = useSelector((state)=>state.QuestionReducer,shallowEqual) 

const [btnDisabled, setBtnDisbaled] = useState(true);
const [file,setFile] = useState(null);
const [questionCount, setQuestionCount] = useState(askQuestion);
const [isLoadedLocal, setIsLoadedLocal] = useState(true);
const [activeTab,setActiveTab] = useState("ask");
const[pageNo,setPageNo] = useState(0);
const [filterState,setFilterState]=useState({});
const [filterDefaultValue,setFilterDefaultValue] = useState({subject_id:null,sub_subject_id:null});
const [isSCO,setIsSCO] = useState(false)
const [selectedQuestions, setSelectedQuestions] = useState([]);


  // if current record changed or request status changed the enable submit
  usePrevious({
    currentRecord,
    upsertReqStatus,
}, (prev) => {
    if (
        prev.currentRecord !== currentRecord
        || (upsertReqStatus && prev.upsertReqStatus !== upsertReqStatus && upsertReqStatus !== REQUEST_STATUS.PENDING)) {
        setSubmitting(false);
    }
});

const onSubmit = useCallback((values,formikUtils)=>{
    ({ setSubmitting } = formikUtils);
    if(file){
      values.isUpload=true
      values.file=file
    }
    delete values.type;
    console.log('scoDetails=>',values);
   //  if(values.scoDetails.length===0 ) delete values.scoDetails;
    dispatch(questionUpsertAction(values))
},[dispatch])

const handelChangeUpload = useCallback((e)=>{
   const filename = e.target.files[0].name;
   let ext = filename.split(".");
   ext = ext[(ext.length - 1)]
   if (ext === "docx") {
     setBtnDisbaled(false);
     setFile(e.target.files[0]);
   } else {
      setBtnDisbaled(true);
      dispatch(headerShowAction({data:"Only .docx files are allowed"}))
   }
},[]) 

const getQuestionCount=async()=>{
   setIsLoadedLocal(false)   
   // if(!isLoaded)
   dispatch(questionCountAction({reqSource:"Bulk"}));

   const where = getFilterValue();
   dispatch(questionSearchAction({
      where,
      currentPage: pageNo,
      autopopulate:true
    })); 
 }
 useEffect(()=>{
   getQuestionCount();
 },[
   activeTab,
   pageNo,
   filterState,
   dispatch,
   upsertReqStatus,
])

 useEffect(()=>{
   if(askQuestion && searchReqStatus===REQUEST_STATUS.SUCCESS) {
      setQuestionCount(askQuestion)
      setIsLoadedLocal(true)
   }
   else
   {
      setIsLoadedLocal(false) 
   }
 },[askQuestion])

const getFilterValue=()=>{
   let filter={...filterState};
      if(activeTab=="ask") Object.assign(filter,{type:"ASK50" })
      else if(activeTab=="qa") Object.assign(filter,{type:{$ne:"ASK50"}})
      else if(activeTab=="pending") Object.assign(filter,{isApprovedSCO:false})
      else if(activeTab=="approved") Object.assign(filter,{isApprovedSCO:true})
      else if(activeTab=="errorLog") Object.assign(filter,{isErrorLog:true})
   return filter;
}
const handelFilterChange=(payload)=> {
   setFilterState({...filterState,...payload})
}
const onClear=useCallback(()=>{
   setFilterDefaultValue({subject_id:null,sub_subject_id:null})
},[])
const editClick = useCallback((record,SCO=false) => {
    delete record.type;
   dispatch(questionEditAction(record));
   setIsSCO(SCO)
   window.scrollTo(0, 0);
}, [
   dispatch,
   questionEditAction,        
   history,
]);
const onBulkApprove=useCallback(()=>{
   // console.log('selectedQuestions=>',selectedQuestions);
   if(selectedQuestions.length===0) {
      window.scroll(0,0);
      dispatch(headerShowAction({
           data:"Please select at least one record."
      }))
      return;
   }
   if(window.confirm("Are you sure to update approval status in bulk ?"))
    dispatch(questionBuklApproveAction({selectedQuestions}));
},[selectedQuestions])

    return (
            <>
<div className="content-wrapper">
   <div className="page-header">
      <div className="">
         <h4 className="page-title"> Manage Questions
         </h4>
         <div className="top-breadcrumb">
            <nav aria-label="breadcrumb">
               <ol className="breadcrumb ps-0">
                  <li className="breadcrumb-item"><a href="books.php">All Questions </a></li> 
                  <li className="breadcrumb-item active" aria-current="page">Manage Questions</li>
               </ol>
            </nav>
         </div>
      </div>
      <div className="d-flex align-items-center justify-content-end">  
         <button className="btnbluen1 plwhit d-flex align-items-center" onClick={()=>("javascript:void(0)") }>Save</button> 
      </div>
   </div>
   <div className="row">
      <div className="col-md-12">         
        <Formik
                onSubmit={onSubmit}
                initialValues={currentRecord}
                validationSchema={QuestionYupSchema}
                enableReinitialize
                >
            {(formikProps)=>(
                (isSCO)?<ManageSCOForm 
                onSubmit={onSubmit}
                handelChangeUpload={handelChangeUpload}
                btnDisabled={btnDisabled}
                {...formikProps}
              />:<ManageQuestionForm
              onSubmit={onSubmit}
              handelChangeUpload={handelChangeUpload}
              btnDisabled={btnDisabled}
              {...formikProps}
              />
            )}
        </Formik>

         <div className="card marb1 shadow">
            <div className="card-body">
               <div className="alltabsc">
                  <div className="col-md-12">
                     <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                        <div className="">
                           <h4 className="card-title">All Questions Uploading status </h4>
                        </div>
                     </div>
                  </div>
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                     {
                        QuestionUploadTab.map((tab,index)=>
                         <QnATabs
                           key={index}
                           activeTab={activeTab}
                           setActiveTab={setActiveTab}
                           tab={tab}
                           questionCount={questionCount}
                         />
                       )
                     }
                  </ul>
                  <div className="dateinydt mt-5">
                     <div className="row">
                          <Formik
                                 onSubmit={handelFilterChange}
                                 onClear={onClear}
                                 initialValues={filterDefaultValue}
                                 // validationSchema={QuestionYupSchema}
                                 enableReinitialize
                                 >
                              {(formikFilterProps)=>(
                                 <FilterSections
                                 onSubmit={handelFilterChange}
                                 onClear={onClear}
                                 onBulkApprove={onBulkApprove}
                                 activeTab={activeTab}
                                 // handelChangeUpload={handelChangeUpload}
                                 // btnDisabled={btnDisabled}
                                 {...formikFilterProps}
                                 />
                              )}
                        </Formik>
                       
                     </div>
                  </div>
               </div>
               <div className="alltabsc2">
                  <div className="tab-content" id="pills-tabContent">
                  {
                     (searchReqStatus!==REQUEST_STATUS.PENDING) ?
                     <>
                     <AllAskQnA 
                     activeTab={activeTab}
                     setPageNo={setPageNo}
                     pageNo={pageNo}
                     search={search}
                     editClick={editClick}
                     />
                     <Qa
                     activeTab={activeTab} 
                     setPageNo={setPageNo} 
                     pageNo={pageNo}
                     search={search}
                     editClick={editClick} 
                     />
                     <PendingQnA 
                     activeTab={activeTab} 
                     setPageNo={setPageNo} 
                     pageNo={pageNo}
                     search={search}
                     editClick={editClick}
                     selectedQuestions={selectedQuestions}
                     setSelectedQuestions={setSelectedQuestions}
                     />
                     <ApprovedQnA 
                     activeTab={activeTab} 
                     setPageNo={setPageNo} 
                     pageNo={pageNo}
                     search={search}
                     editClick={editClick}
                     selectedQuestions={selectedQuestions}
                     setSelectedQuestions={setSelectedQuestions}
                     />
                     <ErrorLog 
                     activeTab={activeTab} 
                     setPageNo={setPageNo} 
                     pageNo={pageNo}
                     search={search}
                     editClick={editClick}
                     />
                     </>:<Loader />
                 }
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
            </>
    )
}

export default ManageQuestion;