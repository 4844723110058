const TutorByCountry=(props)=>{
  const {
    countryWiseCount = []
} = props;
const countryList = (Object.keys(countryWiseCount).length>0)?countryWiseCount?.countryWiseTutor:[]
 return (
    <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Tutors by country </div>
                  </div>
                  <div className="uncunrybg">
                    <ul>
                    {
                         countryList.map((ExpertCountry,index)=>{
                          return (
                          <li key={`expertCnt${index}`} >
                            <div className="uncunry1">{(ExpertCountry._id)?ExpertCountry._id:"Not Available"}</div>
                            <div className="uncunry-progss">
                               <div className="progress">
                                <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:`${ExpertCountry.percentage}%`}} aria-valuenow="23.54" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <div className="prsg">{ExpertCountry.percentage}%</div>
                            </div>
                            <div className="uncunryNo2">{ExpertCountry.total}</div>
                          </li>
                          )
                       })

                      } 
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
 )
}
export default TutorByCountry