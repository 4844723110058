export const SESSION_ACTIONS = {
    SESSION_SEARCH:'SESSION_SEARCH',
    SESSION_SEARCH_SUCCESS: 'SESSION_SEARCH_SUCCESS',
    SESSION_SEARCH_ERROR: 'SESSION_SEARCH_ERROR',
}

export const sessionSearchAction = (payload) => ({
    type:SESSION_ACTIONS.SESSION_SEARCH,
    ...payload
})

export default {
    SESSION_ACTIONS,
    sessionSearchAction
}