/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { deleteRequest, getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';


export const bookSearchAPI = function (data) {
    const url = `${config.baseUrl}book/search`;
    return postRequest({
        url,
        data,
    });
};

export const bookCreateAPI = function (data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) { 
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}books/create`;
    return postRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const bookUpdateAPI = function ({
    bookId,
    data,
}) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}books/update/${bookId}`;
    return putRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export async function getBlogMaster( data ) {
        const url = `${config.baseUrl}book/bookMaster`;
        return postRequest({
            url,
            data,
        });   
}
export async function getBlogAuthor( data ) {
    const url = `${config.baseUrl}book/bookAuthor`;
    return postRequest({
        url,
        data,
    });   
}

export async function booksSearchAPI(data){
    const url = `${config.baseUrl}books/search-books`;
    return postRequest({
        url,
        data,
    });   
}

export async function getBooksCount(data){
    const url = `${config.baseUrl}books/get-books-count`;
    return postRequest({
        url,
        data,
    });   
}

export async function updateBookStatusAPI(data){
    const url = `${config.baseUrl}books/update-book`;
    return postRequest({
        url,
        data,
    });   
}

export async function getBookAuthors(data){
    const url = `${config.baseUrl}books/book-authors`;
    return getRequest({
        url,
        data
    })
}

export const bookLogSearchAPI = function (data) {
    const url = `${config.baseUrl}books/get-book-logs`;
    return postRequest({
        url,
        data,
    });
};

export const addBookFaqAPI = function(data) {
    const url = `${config.baseUrl}books/add-faq`;
    return postRequest({
        url,
        data,
    })
}

export const addBookReview = function(data) {
    const url = `${config.baseUrl}books/add-review`;
    return postRequest({
        url,
        data
    })
}

export const deleteBookFaq = function(data) {
    const url = `${config.baseUrl}books/delete-faq/${data.book_id}/${data.faq_id}`
    return deleteRequest({
        url,
        data
    })
}
