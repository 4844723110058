import { REQUEST_STATUS } from "src/constants/CommonConstants";
import { SESSION_ACTIONS } from "../actions/SessionActions";

const initialState = () => ({
    currentRecord:{
       
    },
    search: {
        data: [],
        groupCounter:{'Unpaid Sessions':0, 'Upcoming Sessions':0, 'Cancelled Sessions':0, 'Session History':0, 'Session in Progress':0},
        currentPage: 0,
        pages: 0,
        total: 0
    },
    searchReqStatus: null,
})

export default (state = initialState(), action) => {
    switch(action.type){
        //SEARCH
        case SESSION_ACTIONS.SESSION_SEARCH:{
            return {
                ...state,
                searchReqStatus:REQUEST_STATUS.PENDING
            }}
            case SESSION_ACTIONS.SESSION_SEARCH_SUCCESS: {
                return {
                    ...state,
                    
                    search: {
                        data:action.data.sessionTableData,
                        groupCounter:{
                            'Unpaid Sessions':action.data.unpaidSessionCount, 
                            'Upcoming Sessions':action.data.upcomingSessionCount, 
                            'Cancelled Sessions':action.data.cancelledSessionCount, 
                            'Session History':action.data.allSessionCount, 
                            'Session in Progress':action.data.ongoingSessionCount
                        },
                        total:action.data.total

                    },
                    searchReqStatus: REQUEST_STATUS.SUCCESS,
                };
            }
            case SESSION_ACTIONS.SESSION_SEARCH_ERROR: {
                return {
                    ...state,
                    searchReqStatus: REQUEST_STATUS.ERROR,
                };
            }
            default:
            return state;
    }
}