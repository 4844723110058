import React, {useState} from 'react'
import { useEffect } from 'react'
import { getRoleModules } from 'src/api/MastersApi'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';



const methods = [
    {
        // key: 'create',
        value: 'create',
        label: 'Create',
        // icon: `<span class="bi bi-plus-circle-fill"></span>`
    },
    {
        // key: 'update',
        value: 'update',
        label: 'Update',
        // icon: `<span class="bi bi-pencil-fill"></span>`
    },
    {
        // key: 'delete',
        value: 'delete',
        label:'Delete',
        // icon: `<span class="bi bi-trash-fill"></span>`
    },
    {
        // key: 'upload',
        value: 'upload',
        label:'Upload',
        // icon: `<span class="bi bi-cloud-upload-fill"></span>`
    },
    {
        // key: 'view',
        value: 'view',
        label:'View',
        // icon: `rct-icon-parent-open`
    }
]




const RoleModuleContainer = () => {

    const [checked, setChecked] = useState([])
    const [expanded, setExpanded] = useState([])
    const [nodes, setNodes] = useState([])
    
    
useEffect(() => {
getModules()
},[])

const getModules = async() => {
    const res = await getRoleModules()
    const moduleData = res.data.data
    const nodeArr = moduleData.map((item,idx) => {
        return {
            value:item.module_name,
            label:item.module_name,
             children:[
                {
                    // key: 'create',
                    value: `create${idx}`,
                    label: 'Create',
                    // icon: `<span class="bi bi-plus-circle-fill"></span>`
                },
                {
                    // key: 'update',
                    value: `update${idx}`,
                    label: 'Update',
                    // icon: `<span class="bi bi-pencil-fill"></span>`
                },
                {
                    // key: 'delete',
                    value: `delete${idx}`,
                    label:'Delete',
                    // icon: `<span class="bi bi-trash-fill"></span>`
                },
                {
                    // key: 'upload',
                    value: `upload${idx}`,
                    label:'Upload',
                    // icon: `<span class="bi bi-cloud-upload-fill"></span>`
                },
                {
                    // key: 'view',
                    value: `view${idx}`,
                    label:'View',
                    // icon: `rct-icon-parent-open`
                }
            ]
        }
    })
    setNodes(nodeArr)
    console.log('res is',nodeArr)
}

const handleCheck = (checkedValues) => {
    const filteredCheckedValues = checkedValues.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      console.log('checked value is',checkedValues)
      setChecked(checkedValues);
  };

  const handleExpand = (expanded) => {
    setExpanded(expanded);
  };

    return (
        <div>
             <CheckboxTree
      nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={handleCheck}
      onExpand={handleExpand}
    />
        </div>
    )
}

export default RoleModuleContainer
