import { combineReducers } from 'redux'; 

import CityReducer from './containers/city-container/reducer/CityReducer';
import DashboardReducer from './containers/dashboard-container/reducer/DashboardReducer';
import HeaderReducer from 'src/containers/header-container/reducer/HeaderReducer';
import AuthReducer from './containers/auth-container/reducer/AuthReducer';
import AssignmentRequestReducer from './containers/assignment-request-container/reducer/AssignmentRequestReducer';
import BlogReducer from './containers/blog-container/reducer/BlogReducer';
import BookReducer from './containers/book-container/reducer/BookReducer';
import QuestionReducer from './containers/question-container/reducer/QuestionReducer';
import EnquiryReducer from './containers/enquiry-container/reducer/EnquiryReducer';
import StudentReducer from './containers/student-container/reducer/StudentReducer';
import ExpertReducer from './containers/expert-container/reducer/ExpertReducer';
import AnnouncementReducer from './containers/announcement-container/reducer/AnnouncementReducer';
import TutorReducer from './containers/tutor-container/reducer/TutorReducer';
import SOSReducer from './containers/SOS-container/reducer/SOSReducer';
import SessionReducer from './containers/session-container/reducer/SessionReducer';
import WhatsappReducer from './containers/whatsapp-container/reducer/WhatsappReducer';


export default combineReducers({
    CityReducer,
    HeaderReducer,
    AuthReducer,
    AssignmentRequestReducer,
    BlogReducer,
    BookReducer,
    QuestionReducer,
    EnquiryReducer,
    StudentReducer,
    DashboardReducer,
    ExpertReducer,
    AnnouncementReducer,
    TutorReducer,
    SOSReducer,
    SessionReducer,
    WhatsappReducer
});
