import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import RoutingUtil from 'src/utils/RoutingUtil'
import { getAllFaqCategory } from 'src/api/FAQApi'
import SingleFaqContainer from './SingleFaqContainer'
import CrudLayoutContainer from 'src/components/crud-layout-component/CrudLayoutContainer'
import AllFaq from './AllFaq'
import CreateFaqCategory from './CreateFaq'
import AddFaqQuestion from './AddFaqQuestion'

const FaqContainer = () => {

const routes = [
    {
        link: RoutingUtil.faqCreateUrl(),
        component: () => (
          <CreateFaqCategory />
        ),
    },
    {
        link: RoutingUtil.faqEditUrl(),
        component: () => (
          <CreateFaqCategory />
        ),
    },
    {
        link: RoutingUtil.faqManagerUrl(),
        component: () => (
          <AllFaq />
        ),
    },
    {
        link: RoutingUtil.faqAddQuesUrl(),
        component:() => (
            <AddFaqQuestion />
        )
    }
 ];


    
    return (
        <React.Fragment>
            <CrudLayoutContainer 
            routes={routes}
            />

        </React.Fragment>
    )
}

export default FaqContainer