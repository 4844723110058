import React from 'react';
import CrudLayoutContainer from 'src/components/crud-layout-component/CrudLayoutContainer';
import RoutingUtil from 'src/utils/RoutingUtil';
import BookMain from './BookMain';
import BookLive from './BookLive';
import BookPendingForQC from './BookPendingForQC';
import BookBulkUpload from './BookBulkUpload';
import BookAuthoring from './BookAuthoring';
import BookFAQ from './BookFAQ';
import BookRatingAndReview from './BookRatingAndReview';
import SimilarBooks from './SimilarBooks';

const routes = [
    {
        link: RoutingUtil.bookManagerUrl(),
        component: () => (
             <BookMain/>
        ),
    },
    {
        link: RoutingUtil.bookLiveUrl(),
        component: () => (
             <BookLive/>
        ),
    },
    {
        link: RoutingUtil.bookBulkUploadUrl(),
        component: () => (
             <BookBulkUpload/>
        ),
    },
    {
        link: RoutingUtil.bookAuthoringUrl(),
        component: () => (
             <BookAuthoring/>
        ),
    },
    {
        link: RoutingUtil.bookPendingForQCUrl(),
        component: () => (
             <BookPendingForQC/>
        ),
    },
    {
        link: RoutingUtil.bookFAQUrl(),
        component: () => (
             <BookFAQ />
        ),
    },
    {
        link: RoutingUtil.bookRatingUrl(),
        component: () => (
             <BookRatingAndReview />
        ),
    },
    {
        link: RoutingUtil.similarBooksUrl(),
        component:() => (
            <SimilarBooks />
        )
    },
    
];

const BookContainer = () => (
    <CrudLayoutContainer
        title="Book Manager"        
        routes={routes}
    />
);

BookContainer.propTypes = {
};

export default BookContainer;
