import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getSubSubject, getSubjects } from 'src/api/Assignment';
import SingleSubjectDropdown from 'src/business-components/SingleSubjectDropdown';
import ExpertiseDropdown from 'src/business-components/ExpertiseDropdown';
import { MakeSlug, convertBase64 } from 'src/utils/CommonUtils';
import FormikFileDirect from 'src/components/formik/FormikFileDirect';
import { tutorResetAction, tutorUpsertAction } from './actions/TutorActions';
import { countries } from "countries-list"
import CountrySelect from 'src/business-components/CountrySelect';
import { expertUpdateAPI } from 'src/api/ExpertApi';


const genderArr = [
   {
      id: 1,
      name: 'Male'
   },
   {
      id: 2,
      name: 'Female'
   },
   {
      id: 3,
      name: 'Others'
   }
]
const Render = (props) => {
   const {
      initialValues,
      setFieldValue,
      values,
      // errors,
      // touched,
      onSubmit,
      isSubmitting,
      onSearch,
      onClear,
      resetForm,
  } = props;
 
  const [imagePre, setImagePre] = useState("/assets/images/super-admin/home/icon.svg");
  const [expertiseArr, setExprtiseArr] = useState([
   {
      subject:'',
      subSubject:[]
   }
  ])
  const [subjectArr, setSubjectArr] = useState([])
  const [services, setServices] = useState([
   {
      service: 'Assignment Writing Services',
      id: 1,
      rate: '',
      proofreading: '',
      is_proofreading: false,
      is_service: false
   },
   {
      service: 'Assignment Proofreading Services',
      id: 2,
      rate: '',
      proofreading: '',
      is_proofreading: false,
      is_service: false
   },
   {
      service: 'Assignment Editing Services',
      id: 3,
      rate: '',
      proofreading: '',
      is_proofreading: false,
      is_service: false
   },
   {
      service: 'Assignment Graphic Designing Services',
      id: 4,
      rate: '',
      proofreading: '',
      is_proofreading: false,
      is_service: false
   }
])

const handleSetsubject = async(evtdata,idx) => {
   const { data } = await getSubSubject(MakeSlug(evtdata))
   const tempArr = []
   data.data.forEach((item) => {
      tempArr.push({label: item?.sub_subject, value: item?.sub_subject,id:item?._id})
   })
   values.subSubject[idx] = tempArr 
}

useEffect(() => {
   getAllSubjects()
}, [])
const getAllSubjects = async () => {
   const { data } = await getSubjects()
   setSubjectArr(data)
}



const handleServiceChange = (e, idx, key) => {
   if (key === 'is_service' || key === 'is_proofreading') {
      setServices((prev) => {
         const arr = [...prev]
         arr[idx][key] = e.target.checked
         return arr
      })
   } else {
      setServices((prev) => {
         const arr = [...prev]
         arr[idx][key] = e.target.value
         return arr
      })
   }

   values.services = services
}

useEffect(() => {
   if(values.services.length > 0){
      setServices(values.services)
   }
},[values.services])

useEffect(() =>{
   if(values.SubjectExpertise.length > 0){
   //    const tempArr = []
   //    const tempSubArr = []
   //    setExprtiseArr(values.SubjectExpertise)
   //    values.SubjectExpertise.forEach((item,idx) => {
   //       tempArr.push(item.name)
   //       // item.sub_subject.map((item) => {
   //       //    tempSubArr.push({label: item?.name, value: item?.name,id:item?.id})
   //       // })
   //       // tempSubArr.push({label: item?.name, value: item?.name,id:item?.id})
   //    })
   //    values.Subject = tempArr
   //    if(tempSubArr.length > 0){
   //       // values.subSubject = [tempSubArr]
   //       // console.log('options',tempSubArr)
   //    }
   }
},[values.SubjectExpertise])

const handelImagePreview = async (e) => {
   const file = e.target.files[0];
   const img64 = await convertBase64(file)
   setImagePre(img64)
   values.img_path = file
   // values.profileImg = file
   
}

    return (
        <div className="content-wrapper">
             <div className="page-header">
                     <h4 className="page-title"> Create Online Tutor
                     </h4>
                  </div>
                  <Form>
                  <div className="row">
                     <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                           <div className="card-body">
                              <div className='row'>
                                 <div className="col-md-12 form-group">
                                    <h4>Fill Tutor Details </h4>
                                 </div>          
                                 <div className='col-md-6 form-group'>
                                    <label>Enter Name</label>
                                    <FormikInput 
                                    type = 'text'
                                    className='form-control'
                                    name='name'
                                    placeholder = 'Enter Name'
                                    value={values.name}
                                    />
                                 </div>
                                 <div className='col-md-6 form-group'>
                                    <label>Enter Email</label>
                                    <FormikInput 
                                    type = 'text'
                                    name='email'
                                    className='form-control'
                                    placeholder = 'Enter Email'
                                    value={values.email}
                                    />
                                 </div>
                                 <div className='col-md-6 form-group'>
                                    <label>Enter Contact</label>
                                    <FormikInput 
                                    type = 'text'
                                    className='form-control'
                                    name='Contact'
                                    placeholder = 'Enter Contact'
                                    value={values.Contact}
                                    />
                                 </div>
                                 <div className='col-md-6 form-group'>
                                    <label>Select Gender</label>
                                    <CountrySelect
                                       items={genderArr}
                                       name="gender"
                                       className="form-control"
                                    />
                                 </div>
                                 <div className='col-md-6 form-group'>
                                    <label>Enter Country</label>
                                    <CountrySelect
                                       items={countries}
                                       name="country"
                                       className="form-control"
                                    />
                                 </div>
                                 <div className='col-md-12 form-group'>
                                    <label>Know more about me</label>
                                    <FormikInput 
                                    type = 'text'
                                    className='form-control'
                                    name='aboutDetails'
                                    placeholder = 'Write a note about yourself...'
                                    value={values.aboutDetails}
                                    />
                                 </div>
                                 <div className='col-md-12 form-group'>
                                    <label>Know more about my journey </label>
                                    <FormikInput 
                                    type = 'text'
                                    className='form-control'
                                    name='journeyDetails'
                                    placeholder = 'Write a note about your journey...'
                                    value={values.journeyDetails}
                                    />
                                 </div>
                                 <div className='col-md-12'>
                                    {
                                       expertiseArr.map((item,idx) => {
                                          return (
                                             <div className='row'>
                                             <div className='col-md-6 form-group'>
                                             <label>Enter Subject</label>
                                             <SingleSubjectDropdown
                                              items={subjectArr}
                                              name={`Subject[${idx}]`}
                                              placeholder={'Select Subject'}
                                              className="form-control"
                                              values={values}
                                              idx={idx}
                                              value={item.name}
                                              onChange = {
                                                 (data) => {
                                                    handleSetsubject(data,idx)
                                                 }
                                              }
                                             />
                                            </div>
                                          <div className='col-md-6 form-group'>
                                             <label>Enter Sub Subject/Expertise</label>
                                             <ExpertiseDropdown 
                                               name={`subSubjectObj[${idx}]`}
                                               className="form-control"
                                               placeholder={'Select Sub Subject'}
                                               options={values?.subSubject?.[idx] }
                                               idx={idx}
                                               values={values}
                                             />
                                               </div>
                                             </div>
                                          )
                                       })
                                    }
                                 </div>
                                 <div className='col-md-12'>
                                 <button type="button" className="btn bg-gradient-outline btn-rounded mt-2 mb-4" style={{width:'15%'}} onClick={()=>
                                 {
                                    setExprtiseArr((prev) => [...prev,{
                                       subject:'',
                                       subSubject:[]
                                    }])
                                    values.SubjectExpertise.push({
                                       name:'',
                                       id:'',
                                       sub_subject:[]
                                    })
                                 }
                                 }>Add</button>
                                 </div>
                                 <div className='col-md-12'>
                                    <label>Select Category Area</label>
                                    {
                                       services.map((service, idx) => {
                                          return (
                                             <div className='row mt-2' key={service.id}>
                                                <div className='col-md-4 mt-2'>
                                                   <label className="cheboxNw">  {service.service}
                                                      <input type="checkbox" name="journal_selection" checked={service.is_service}
                                                         onChange={(e) => handleServiceChange(e, idx, 'is_service')}
                                                      />
                                                      <span className="checkmark"></span> </label>
                                                </div>
                                                <div className='col-md-2'>
                                                   <input type='text'
                                                      placeholder='Enter price'
                                                      value={service.rate}
                                                      className='form-control'
                                                      onChange={(e) => handleServiceChange(e, idx, 'rate')}
                                                   />
                                                </div>
                                                <div className='col-md-2'>
                                                   <div className="d-flex align-items-center entrPc1 mt-1"><span>Is Proofreading</span>
                                                      <input type="checkbox" name="journal_selection" className='ms-2 mt-1' checked={service.is_proofreading}
                                                         onChange={(e) => handleServiceChange(e, idx, 'is_proofreading')}
                                                      />
                                                   </div>
                                                </div>
                                                <div className='col-md-3'>
                                                   <input type='text'
                                                      placeholder='Enter proofreading price' 
                                                      value={service.proofreading}
                                                      className='form-control'
                                                      onChange={(e) => handleServiceChange(e, idx, 'proofreading')}
                                                   />
                                                </div>
                                             </div>
                                          )
                                       })
                                    }
                                 </div>
                                 <div className='row'>
                                 <div className="col-md-12 form-group mb-0">
                                    <h4>Upload Expert Profile Image </h4>
                                 </div>
                                 <div className="col-md-12 form-group">

                                    <div className="imgulpdn1xz imgulpdn1xz2">
                                       <div className="privimgbvc1">
                                          <div className="js--image-preview" style={{ backgroundImage: `url("${imagePre}")`}}></div>
                                       </div>
                                       <div className="upload-options">
                                          <label>
                                             <FormikFileDirect
                                                name="img_path"
                                                accept="image/*"
                                                className="image-upload custom-file-input"
                                                onChange={(e) => { handelImagePreview(e) }}
                                             />
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                                 </div>
                                 <div className='col-md-8'>
                                 <button className='btn btn-gradient-primary me-2' type='submit' onClick={()=>onSubmit(values)}>Save</button>
                                 </div>
                                 </div>
                                 </div>
                                 </div>
                                 </div>
                                 </div>
                  </Form>
        </div>
    )
}



const CreateOnlineTutorForm = () => {
   const dispatch = useDispatch();
   const history = useHistory();
   
const {currentRecord} = useSelector((state) => state.TutorReducer)
   
   const onSubmit = useCallback(( 
      values,
      formikUtils,
   ) => {
      if(values._id){
         const res = expertUpdateAPI({id:values._id,updateType:values})
         if(res){
            history.push('/tutors')
            dispatch(tutorResetAction())
         } 
      }else {
         dispatch(tutorUpsertAction(values))
      }
      
   }, [
      dispatch
   ]);
   

   const onSearch = () => {
       //
   }

   const onClear = () => {
       //
   }
   return (
      <Formik
      onSubmit={onSubmit}
      onClear={onClear}
      onSearch={onSearch}
      initialValues={currentRecord}
      // validationSchema={BlogYupSchema}
      enableReinitialize
  > 
      {(formikProps) => (
          <Render
              onClear={onClear}
              onSearch={onSearch}
              onSubmit={onSubmit}
              {...formikProps}
          />
      )}
  </Formik>
   )
}





export default CreateOnlineTutorForm