import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BulkUploadTabPane from 'src/components/BulkUploadTabPane'
import { bookLogSearchAction, bookSearchAction } from './actions/BookActions'

const BooksUploadTabHeaders = [
    {
        id: 1,
        Header: 'All',
        tabPaneId: 'all'
    },
    {
        id: 2,
        Header: 'Pending',
        tabPaneId: 'pending'
    },
    {
        id: 3,
        Header: 'Approved',
        tabPaneId: 'approved'
    },
    {
        id: 4,
        Header: 'Rejected',
        tabPaneId: 'rejected'
    },
    {
        id: 5,
        Header: 'Error',
        tabPaneId: 'error'
    },
]

const ChapterUploadTabHeaders = [
    // {
    //     id:1,
    //     Header:'All',
    //     tabPaneId:'all'
    // },
    {
        id: 1,
        Header: 'Error Logs',
        tabPaneId: 'error'
    }
]

const BulkUploadTable = ({ uploadOperation }) => {

    const dispatch = useDispatch()
    const { search } = useSelector((state) => state.BookReducer)
    const [tableData, setTableData] = useState([])
    const [pageNo, setPageNo] = useState(0)
    const [activeSelectedTab, setActiveSelectedTab] = useState({
        id: 1, Header: 'All', tabPaneId: 'all'
    })
    const [filter, setFilter] = useState({})
    const [bookStatusSelected, setBookStatusSelected] = useState('Authoring')

    useEffect(() => {
        const { data } = search
        setTableData(data)
    }, [search])

    useEffect(() => {
        dispatch(bookSearchAction({
            where: filter,
            currentPage: pageNo,
            autopopulate: true
        }))
    }, [filter])

    const handleFilterChange = (payload) => {
        setFilter({ ...filter, ...payload })
    }

    const handleTabSelection = (tabDetails) => {
        setActiveSelectedTab(tabDetails)
        setBookStatusSelected('Authoring')
        if (tabDetails.Header === 'All') {
            dispatch(bookSearchAction({
                where: {},
                currentPage: pageNo,
                autopopulate: true
            }))
            setFilter({})
        }
        if (tabDetails.Header === 'Pending') {
            dispatch(bookSearchAction({
                where: { authoringStatus: 'pending' },
                currentPage: pageNo,
                autopopulate: true
            }))
            setFilter({ authoringStatus: 'pending' })
        }
        if (tabDetails.Header === 'Approved') {
            dispatch(bookSearchAction({
                where: { authoringStatus: 'approved' },
                currentPage: pageNo,
                autopopulate: true
            }))
            setFilter({ authoringStatus: 'approved' })
        }
        if (tabDetails.Header === 'Rejected') {
            dispatch(bookSearchAction({
                where: { authoringStatus: 'rejected' },
                currentPage: pageNo,
                autopopulate: true
            }))
            setFilter({ authoringStatus: 'rejected' })
        }
        if (tabDetails.Header === 'Error') {
            dispatch(bookLogSearchAction({
                where: {},
                currentPage: pageNo,
                autopopulate: true
            }))
            // setFilter({authoringStatus:'rejected'})
        }
    }

    const handleSeoStatusSelected = (targetValue, filterValue) => {
        setBookStatusSelected(targetValue)
        if (activeSelectedTab.Header === 'Pending') {
            dispatch(bookSearchAction({
                where: { [filterValue]: 'pending' },
                currentPage: pageNo,
                autopopulate: true
            }))
            setFilter({ [filterValue]: 'pending' })
        }
        if (activeSelectedTab.Header === 'Approved') {
            dispatch(bookSearchAction({
                where: { [filterValue]: 'approved' },
                currentPage: pageNo,
                autopopulate: true
            }))
            setFilter({ [filterValue]: 'approved' })
        }
        if (activeSelectedTab.Header === 'Rejected') {
            dispatch(bookSearchAction({
                where: { [filterValue]: 'rejected' },
                currentPage: pageNo,
                autopopulate: true
            }))
            setFilter({ [filterValue]: 'rejected' })
        }
    }


    return (
        <>
            {
                uploadOperation === 'Books Upload Chapters' ? (
                    <div className="card">
                        <div className="card-body">
                            <div className="alltabsc">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                        <div className="">
                                            <h4 className="card-title">All Books Uploading status </h4>
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {
                                        ChapterUploadTabHeaders.map((tabs, idx) => {
                                            return (
                                                <li className="nav-item" role="presentation" onClick={() => handleTabSelection(tabs)}>
                                                    <button className={`nav-link ${tabs.Header === activeSelectedTab.Header && 'active'}`} id={`pills-${tabs.tabPaneId}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${tabs.tabPaneId}`} type="button" role="tab" aria-controls={`pills-${tabs.tabPaneId}`} aria-selected="true">{tabs.Header} <span></span>   </button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="alltabsc2">
                                <BulkUploadTabPane uploadOperation={uploadOperation} tableData={tableData} activeSelectedTab={activeSelectedTab} handleFilterChange={handleFilterChange} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="card">
                        <div className="card-body">
                            <div className="alltabsc">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                        <div className="">
                                            <h4 className="card-title">All Books Uploading status </h4>
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {
                                        BooksUploadTabHeaders.map((tabs) => {
                                            return (
                                                <li className="nav-item" role="presentation" onClick={() => handleTabSelection(tabs)}>
                                                    <button className={`nav-link ${tabs.Header === activeSelectedTab.Header && 'active'}`} id={`pills-${tabs.tabPaneId}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${tabs.tabPaneId}`} type="button" role="tab" aria-controls={`pills-${tabs.tabPaneId}`} aria-selected="true">{tabs.Header} <span></span>   </button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                {
                                    activeSelectedTab.Header !== 'All' && activeSelectedTab.Header !== 'Error' && (
                                        <div className="row align-items-center justify-content-center mt-3 mb-2">
                                            <div className="col-md-3"><input type="radio" id="authoring" value="Authoring" style={{ cursor: 'pointer' }}
                                                checked={bookStatusSelected === 'Authoring'}
                                                onChange={(e) => {
                                                    handleSeoStatusSelected(e.target.value, 'authoringStatus')
                                                    setBookStatusSelected(e.target.value)
                                                }
                                                }
                                            />
                                                <label for="javascript" className="mt-1 ms-1">Authoring</label></div>
                                            <div className="col-md-3"><input type="radio" id="seo" value="SEO" style={{ cursor: 'pointer' }}
                                                checked={bookStatusSelected === 'SEO'}
                                                onChange={(e) =>
                                                    handleSeoStatusSelected(e.target.value, 'seoStatus')
                                                }
                                            />
                                                <label for="html" className="mt-1 ms-1">SEO</label></div>

                                        </div>
                                    )
                                }
                                {
                                    activeSelectedTab.Header !== 'Error' && (
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                Upload Type
                                            </div>
                                            <div className='col-md-3 mt-3 mb-2'>
                                                <select className='form-control' onChange={(e) => {
                                                    if (e.target.value === 'uploaded') {
                                                        handleFilterChange({ is_uploaded: true })
                                                    } else if (e.target.value === 'manual') {
                                                        handleFilterChange({ is_uploaded: false })
                                                    }
                                                }}>
                                                    <option value=''>Select Upload Type</option>
                                                    <option value="uploaded">Bulk Uploaded</option>
                                                    <option value='manual'>Manual</option>
                                                </select>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="alltabsc2">
                                <BulkUploadTabPane tableData={tableData} activeSelectedTab={activeSelectedTab} handleFilterChange={handleFilterChange} />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default BulkUploadTable