import { useEffect, useState } from 'react';
import DateRangeCalendar from '../DateRangeCalendar';
import { useHistory } from 'react-router';
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { blogSearchAction } from 'src/containers/blog-container/actions/BlogActions';

// import './WelcomeComponent.scss';

const WelcomeComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    search,
   } = useSelector((state) => state.BlogReducer, shallowEqual);

   const [filter,setBlogFilter] = useState({isPublish:true});

  const getBlogs=async()=>{
    dispatch(blogSearchAction({
      where: filter, 
      autopopulate:true
  })); 
  }

  const handelFilterChange=(payload)=> {
    setBlogFilter({...filter,...payload})
  }

  useEffect(()=>{
    getBlogs();
 },[filter])

    return(
        <>
        <div className="content-wrapper">
        <div className="page-header">
          <h3 className="page-title"> <span className="page-title-icon bg-gradient-primary text-white me-2"> <i className="mdi mdi-home"></i> </span> Dashboard </h3>
        </div>
        <div className="row">
          <div className="col-md-12">
                   <DateRangeCalendar onSelect={handelFilterChange}/>
          </div>
          <div className="col-md-5 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="hdhomX1">
                  <div className="hdhomX2">Users</div>
                  <div className="hdhomX3 yellow-icon"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                </div>
                <div className="hdhomXd1">
                  <div className="hdhomXd2">Registered Users </div>
                  <div className="hdhomXd3">458</div>
                </div>
                <div className="linhom1">
                  <div className="linhom2"><img src="/assets/images/home-imgs/line-users.svg" className="img-fluid" alt=""/></div>
                  <div className="pendcp text-green"><span><i className="mdi mdi-menu-up mdi-36px"></i> 15%</span> </div>
                </div>
                <div className="bgsusebg">
                  <div className="bgsuse1">
                    <div className="bgsuse2">
                      <div className="bgsuse3"> Subscribed Users</div>
                      <div className="bgsuse4">
                        <div className="d-flex align-items-center"> <span className="numcount">4587</span>
                          <div className="pendcp text-green ms-3"><span><i className="mdi mdi-menu-up mdi-24px"></i> 75%</span> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bgsuse1">
                    <div className="bgsuse2">
                      <div className="bgsuse3">Unsubscribed Users </div>
                      <div className="bgsuse4">
                        <div className="d-flex align-items-center"> <span className="numcount">4587</span>
                          <div className="pendcp text-red ms-3"><span><i className="mdi mdi-menu-down mdi-24px"></i> 5%</span> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 greenl-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Books </div>
                  </div>
                  <div className="scscm21-step4 books-pan1">
                    <div className="asigntabnew1">
                      <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabIcon iconbg1"><img src="/assets/images/home-imgs/book-icon1.svg" className="img-fluid img-icon" alt=""/></span> <span className="valutext">4598</span> </h4>
                      <h2 className="mt-0">Total Books on the Panel </h2>
                    </div>
                    <div className="asigntabnew1">
                      <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabIcon iconbg2"><img src="/assets/images/home-imgs/book-icon2.svg" className="img-fluid img-icon" alt=""/></span> <span className="valutext">4598</span> </h4>
                      <h2 className="mt-0">Authored (QnA)</h2>
                    </div>
                    <div className="asigntabnew1">
                      <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabIcon iconbg3"><img src="/assets/images/home-imgs/book-icon3.svg" className="img-fluid img-icon" alt=""/></span> <span className="valutext">4598</span> </h4>
                      <h2 className="mt-0">Indexed</h2>
                    </div>
                    <div className="asigntabnew1">
                      <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabIcon iconbg4"><img src="/assets/images/home-imgs/book-icon4.svg" className="img-fluid img-icon" alt=""/></span> <span className="valutext">4598</span> </h4>
                      <h2 className="mt-0">Blank (only cover)</h2>
                    </div>
                  </div>
                </div>
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 greenl-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Questions </div>
                  </div>
                  <div className="scscm21-step3 books-pan1">
                    <div className="asigntabnew1">
                      <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabIcon iconbg3"><img src="/assets/images/home-imgs/book-icon3.svg" className="img-fluid img-icon" alt=""/></span> <span className="valutext">4598</span> </h4>
                      <h2 className="mt-0">Indexed</h2>
                    </div>
                    <div className="asigntabnew1">
                      <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabIcon iconbg4"><img src="/assets/images/home-imgs/book-icon4.svg" className="img-fluid img-icon" alt=""/></span> <span className="valutext">4598</span> </h4>
                      <h2 className="mt-0">Total Books on the Panel </h2>
                    </div>
                    <div className="asigntabnew1">
                      <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabIcon iconbg4"><img src="/assets/images/home-imgs/book-icon4.svg" className="img-fluid img-icon" alt=""/></span> <span className="valutext">4598</span> </h4>
                      <h2 className="mt-0">Authored (QnA)</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="revOvbg scscm21-step2">
                  <div className="">
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center">
                        <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                        Revenue  Overview </div>
                    </div>
                    <div className="nexX1bg">
                      <div className="nexX1">
                        <div className="nexX2"><i className="mdi mdi-checkbox-blank-circle"></i> Net Earnings</div>
                        <div className="nexX3">$1,608,469.50</div>
                      </div>
                      <div className="nesS1">
                        <div className="nesS2"><i className="mdi mdi-checkbox-blank-circle"></i> Net Spent</div>
                        <div className="nesS3">$1,608,469.50</div>
                      </div>
                      <div className="prolsbv1">
                        <div className="ogres ogres1">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-info" role="progressbar" style={{width:'65%'}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                            <div className="prsg">76%</div>
                          </div>
                        </div>
                        <div className="ogres ogres2">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-info" role="progressbar" style={{width:'45%'}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                            <div className="prsg">45%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="realtmearnbg">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Real-Time Earning</h3>
                      <a href="#">View Details</a> </div>
                    <div className="prdsb1bg">
                      <div className="prdsb1"> <span className="stx1">Subscription</span>
                        <div className="d-flex stx2">$150,200 <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                        <div className="prdsb3">Total  $1,608,469.50 </div>
                      </div>
                      <div className="prdsb1 prXf1"> <span className="stx1">Assignment </span>
                        <div className="d-flex stx2">$150,200 <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                        <div className="prdsb3"> Total  $1,608,469.50</div>
                      </div>
                      <div className="prdsb1"> <span className="stx1">Online Tutoring </span>
                        <div className="d-flex stx2">$150,200 <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                        <div className="prdsb3">Total  $1,608,469.50</div>
                      </div>
                      <div className="prdsb1 prXf1"> <span className="stx1">Others</span>
                        <div className="d-flex stx2">$150,200 <span>0.20% <i className="mdi mdi-menu-up mdi-24px text-green"></i></span></div>
                        <div className="prdsb3">Total  $1,608,469.50</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>          
          <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Assignments </div>
                    <div className="viebtnv1"><a href="#">View Details</a></div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center totdetaX">
                    <div className="">
                      <h3>Total Delivered</h3>
                    </div>
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center txnetotal"> <span className="numcount">4587</span>
                          <div className="pendcp text-green ms-3"><span>75% <i className="mdi mdi-plus mdi-24px"></i> </span> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="assignallTyp">
                    <div className="asTyp1">
                      <h4>458</h4>
                      <p>Total Pending </p>
                    </div>
                    <div className="asTyp2 textred">
                      <h4>458</h4>
                      <p>Total Cancelled </p>
                    </div>
                    <div className="asTyp3">
                      <h4>458</h4>
                      <p>Total Awaiting </p>
                    </div>
                    <div className="asTyp4">
                      <h4>458</h4>
                      <p>Total in progress </p>
                    </div>
                    <div className="asTyp5">
                      <h4>458</h4>
                      <p>Total Delayed </p>
                    </div>
                    <div className="asTyp6">
                      <h4>458</h4>
                      <p>Total Disputed </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
          <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 lightblue-icon me-2"><span><img src="/assets/images/home-imgs/online-icon.svg" className="img-fluid" alt=""/></span></div>
                      Online Tutoring Sessions </div>
                    <div className="viebtnv1"><a href="#">View Details</a></div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center totdetaX2">
                    <div className="">
                      <h3>Total Delivered</h3>
                    </div>
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center txnetotal"> <span className="numcount">4587</span>
                          <div className="pendcp text-green ms-3"><span>75% <i className="mdi mdi-plus mdi-24px"></i> </span> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sessaTxt">
                    <div className="sessaTxt1 inpress">
                      <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Session in Progress
                      </div>
                      <div className="sessaTxt3">258</div>
                    </div>
                    <div className="sessaTxt1">
                      <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Upcoming  Sessions
                      </div>
                      <div className="sessaTxt3">5887</div>
                    </div>
                    <div className="sessaTxt1">
                      <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-red"></i> Cancelled Sessions
                      </div>
                      <div className="sessaTxt3">588</div>
                    </div>
                    <div className="sessaTxt1">
                      <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Unpaid Sessions
                      </div>
                      <div className="sessaTxt3">488</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Onboarded </div>
                    <div className="viebtnv1"><a href="#">View Details</a></div>
                  </div>
                  <div className="hdh1bg">
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center">
                        <div className="hdhomX3 lightblue-icon me-2"><span><img src="/assets/images/home-imgs/online-icon2.svg" className="img-fluid" alt=""/></span></div>
                        Tutors onboarded </div>
                      <div className="hdh1No">4584</div>
                    </div>
                    <div className="hdhomX1 hdh1">
                      <div className="hdhomX2 d-flex align-items-center">
                        <div className="hdhomX3 lightblue-icon me-2"><span><img src="/assets/images/home-imgs/expert-icon.svg" className="img-fluid" alt=""/></span></div>
                        Expert's onboarded </div>
                      <div className="hdh1No">4584</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      App Activity </div>
                  </div>
                  <div className="newinApactivt">
                    <div className="newinApactivt1">
                      <div className="d-flex justify-content-between align-items-center totdetaX2">
                        <div className="">
                          <h3>Total installed App</h3>
                        </div>
                        <div className="">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center txnetotal"><span className="numcount">4587</span> </div>
                          </div>
                        </div>
                      </div>
                      <div className="newinAp1">
                        <div className="newinApbg newinAp1-bdr">
                          <div className="newinAp">
                            <h4>New installed App </h4>
                            <p>Last 24 hours</p>
                          </div>
                          <div className="newinno">42</div>
                        </div>
                        <div className="newinApbg">
                          <div className="newinAp">
                            <h4>Uninstalled App</h4>
                            <p>Last 24 hours</p>
                          </div>
                          <div className="newinno">5</div>
                        </div>
                      </div>
                      <div className="newinAp2">
                        <div className="newinApbg">
                          <div className="newinAp">
                            <h4 className="text-green">Subscription by App</h4>
                            <p className="text-green">Last 24 hours</p>
                          </div>
                          <div className="newinno">548</div>
                        </div>
                        <div className="newinApbg">
                          <div className="newinAp">
                            <h4>Subscription by Web</h4>
                            <p className="mb-0">Last 24 hours</p>
                          </div>
                          <div className="newinno">5668</div>
                        </div>
                      </div>
                    </div>
                    <div className="newinApactivt2">
                      <div className="">
                        <div className="sessaTxt1 inpress">
                          <div className="sessaTxt2"><i className="mdi mdi-checkbox-blank-circle text-green me-2"></i> Active Student </div>
                          <div className="sessaTxt3">589</div>
                        </div>
                      </div>
                      <div id="chart" style={{height: "100%"}}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      SOS </div>
                    <div className="viebtnv1"><a href="#">View Details</a></div>
                  </div>
                  <div className="hdh1bg hdhg2">
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center"> Total SOS </div>
                      <div className="hdh1No">4584</div>
                    </div>
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center"> Total Pending </div>
                      <div className="hdh1No">4584</div>
                    </div>
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center"> Total Resolved </div>
                      <div className="hdh1No">4584</div>
                    </div>
                  </div>
                  <div className="sostxcbg1">
                    <div className="sostxcbg fstsostxcbg">
                      <div className="sostxc1">Total SOS by Students <span>11588</span></div>
                      <div className="sostxc2">Still Pending SOS <span>458</span></div>
                      <div className="sostxc2">Resolved SOS <span>5888</span></div>
                    </div>
                    <div className="sostxcbg lastsostxcbg">
                      <div className="sostxc1">Total SOS by Tutors <span>11588</span></div>
                      <div className="sostxc2">Still Pending SOS <span>458</span></div>
                      <div className="sostxc2">Resolved SOS <span>5888</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>          
          <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Tutors by country </div>
                  </div>
                  <div className="uncunrybg">
                    <ul>
                      <li>
                        <div className="uncunry1">United States</div>
                        <div className="uncunry-progss">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:"23.54%"}} aria-valuenow="23.54" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="prsg">23.54%</div>
                        </div>
                        <div className="uncunryNo2">12,094</div>
                      </li>
                      <li>
                        <div className="uncunry1">India</div>
                        <div className="uncunry-progss">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:"7.19%"}} aria-valuenow="7.19" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="prsg">7.19%</div>
                        </div>
                        <div className="uncunryNo2">7,852</div>
                      </li>
                      <li>
                        <div className="uncunry1">Turkey</div>
                        <div className="uncunry-progss">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:"6.54%"}} aria-valuenow="6.54" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="prsg">6.54%</div>
                        </div>
                        <div className="uncunryNo2">6,383</div>
                      </li>
                      <li>
                        <div className="uncunry1">Bangladesh</div>
                        <div className="uncunry-progss">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-success" role="progressbar" style={{width: "5.29%"}} aria-valuenow="5.29" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="prsg">5.29%</div>
                        </div>
                        <div className="uncunryNo2">4,749 </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Student by country </div>
                  </div>
                  <div className="uncunrybg">
                    <ul>
                      <li>
                        <div className="uncunry1">United States</div>
                        <div className="uncunry-progss">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:"23.54%"}} aria-valuenow="23.54" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="prsg">23.54%</div>
                        </div>
                        <div className="uncunryNo2">12,094</div>
                      </li>
                      <li>
                        <div className="uncunry1">India</div>
                        <div className="uncunry-progss">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:"7.19%"}}aria-valuenow="7.19" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="prsg">7.19%</div>
                        </div>
                        <div className="uncunryNo2">7,852</div>
                      </li>
                      <li>
                        <div className="uncunry1">Turkey</div>
                        <div className="uncunry-progss">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:"6.54%"}}aria-valuenow="6.54" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="prsg">6.54%</div>
                        </div>
                        <div className="uncunryNo2">6,383</div>
                      </li>
                      <li>
                        <div className="uncunry1">Bangladesh</div>
                        <div className="uncunry-progss">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:"5.29%"}}aria-valuenow="5.29" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className="prsg">5.29%</div>
                        </div>
                        <div className="uncunryNo2">4,749 </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Tutors Gender </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="othfemlMale">
                      <ul>
                        <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Others</div>
                          <span>45%</span></li>
                        <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Female</div>
                          <span>35%</span></li>
                        <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Male</div>
                          <span>20%</span></li>
                      </ul>
                    </div>
                    <div className="chart-ducht">
                      <canvas id="doughnutChart"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Students Gender </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="othfemlMale othfemlMale2">
                      <ul>
                        <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Others</div>
                          <span>45%</span></li>
                        <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Female</div>
                          <span>35%</span></li>
                        <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Male</div>
                          <span>20%</span></li>
                      </ul>
                    </div>
                    <div className="chart-ducht">
                      <canvas id="doughnutChart2"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
        </>
    )
    };

export default WelcomeComponent;
