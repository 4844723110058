import React, { useState, useEffect } from 'react'
import SeoBreadCrumbSubjectQA from './SeoBreadCrumbSubjectQA'
import * as Yup from "yup";
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput'; 
import FormikCKEditor from 'src/components/formik/FormikCKEditor/FormikCKEditor';
import { useHistory, useParams } from 'react-router-dom';
import { saveContentSubSubjectQA } from 'src/api/MastersApi';

const Render = (props) => {
    const [editorLoaded, setEditorLoaded] = useState(false);
    useEffect(() => {
        setEditorLoaded(true);
        }, []);
    const {
        values,
        touched,
        errors,
        onSubmit,
        getFieldProps,
        setFieldValue,
    } = props;


    return (
        <Form>
            <div className='row p-4'>
                <div className='col-md-12'>
                <p><span className='mdi mdi-book-multiple'></span>Manage content</p>
            <hr />
                </div>
                <div className='col-md-6'>
                    <label>Banner Text</label>
                    <FormikInput
                        type='text'
                        placeholder="Enter Banner Heading"
                        name="bannerText"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6'>
                    <label>About Heading</label>
                    <FormikInput
                        type='text'
                        name="aboutHeading"
                        placeholder="Enter question heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Question Heading Text</label>
                    <FormikInput
                        type='text'
                        name="questionHeading"
                        placeholder="Enter answer heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>About Content</label>
                    <FormikInput
                        type='text'
                        name="aboutContent"
                        placeholder="Enter ask an expert text"
                        className="form-control  enchilada"
                    />
                </div>
               
                <div className='col-md-6 mt-4'>
                    <label>Question Sub Heading Text</label>
                    <FormikInput
                        name="questionSubHeading"
                        type='text'
                        placeholder="Enter college text books text"
                        className="form-control  enchilada"
                    />
                </div>
                {/* <div className='col-md-6 mt-4' /> */}
                {/* <div className='col-md-6 mt-4' /> */}
                <div className='col-md-12 mt-4'>
                    <label>Feature Main Heading</label>
                    <FormikInput
                        name="feature.mainHeading"
                        type='text'
                        placeholder="Enter college text books text"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-12 mt-4'>
                    <label>Feature Content</label>
                    <FormikInput
                        name="feature.mainContent"
                        type='text'
                        placeholder="Enter college text books text"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-12 mt-4'>
                    <label>Service Heading</label>
                    <FormikInput
                        type='text'
                        name="feature.serviceHeading"
                        placeholder="Enter Main Heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-12 mt-4'>
                    <label>Service Content</label>
                    <FormikCKEditor 
                   id='editor1'
                   name="feature.serviceContent"
                    value={values.feature.serviceContent}
                    onBlur={(data) => {
                        setFieldValue(
                            "feature.serviceContent",
                            data
                          );
                    }}
                    editorLoaded={editorLoaded}
                   />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Heading 1</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceHeading1"
                        placeholder="Enter Sub Service Heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Content  1</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceContent1"
                        placeholder="Enter Sub Service Content"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Heading 2</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceHeading1"
                        placeholder="Enter Sub Service Heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Content  2</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceContent2"
                        placeholder="Enter Sub Service Content"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>   <label>Service Sub Service Heading 3</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceHeading2"
                        placeholder="Enter Sub Service Heading"
                        className="form-control  enchilada"
                    />  </div>
                <div className='col-md-6 mt-4'>  <label>Service Sub Service Content  3</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceContent3"
                        placeholder="Enter Sub Service Content"
                        className="form-control  enchilada"
                    /> </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Heading 4</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceHeading4"
                        placeholder="Enter Sub Service Heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Content  4</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceContent4"
                        placeholder="Enter Sub Service Content"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <button className='btnbluen1' onClick={() => onSubmit(values)}>
                    Save Content
                    </button>
                    </div>
            </div>
        </Form>
    )
}


const UpdateSubSubjectContentQA = () => {

    const params = useParams()
    const history = useHistory()

    const validationSchema = Yup.object({
        bannerText: Yup.string(),
        questionHeading: Yup.string(),
        questionSubHeading: Yup.string(),
        aboutHeading: Yup.string(),
        aboutContent: Yup.string(),
        feature: Yup.object({
          mainHeading: Yup.string(),
          mainContent: Yup.string(),
          serviceHeading: Yup.string(),
          serviceContent: Yup.string(),
          subServiceHeading1: Yup.string(),
          subServiceHeading2: Yup.string(),
          subServiceHeading3: Yup.string(),
          subServiceHeading4: Yup.string(),
          subServiceContent1: Yup.string(),
          subServiceContent2: Yup.string(),
          subServiceContent3: Yup.string(),
          subServiceContent4: Yup.string(),
        }),
    });


    const [initialValues, setInitialValues] = useState({
        bannerText: "",
        questionHeading: "",
        questionSubHeading: "",
        aboutHeading: "",
        aboutContent: "",
        feature: {
          mainHeading: "",
          mainContent: "",
          serviceHeading: "",
          serviceContent: "",
          subServiceHeading1: "",
          subServiceHeading2: "",
          subServiceHeading3: "",
          subServiceHeading4: "",
          subServiceContent1: "",
          subServiceContent2: "",
          subServiceContent3: "",
          subServiceContent4: "",
        },
    })

    const onSubmit = async (values) => {
       const add_content = await saveContentSubSubjectQA(values,params.id)
       if(add_content){
        history.push('/masters/sub-subject')
       }
    }

    return (
        <div>
            <SeoBreadCrumbSubjectQA />
            <Formik
                onSubmit={onSubmit}
                // onClear={onClear}
                // onSearch={onSearch}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formikProps) => (
                    <Render
                        // onClear={onClear}
                        // onSearch={onSearch}
                        // setInitialValues={setInitialValues}
                        onSubmit={onSubmit}
                        {...formikProps}
                    />
                )}
            </Formik>
        </div>
    )
}

export default UpdateSubSubjectContentQA