/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const resumeWritingSearchAPI = function (data) {
    console.log('data=>',data)
    const url = `${config.baseUrl}dashboard/enquiry/getResume`;
    return postRequest({
        url,
        data,
    });
};
export const mentorAssistantSearchAPI = function (data) {
    const url = `${config.baseUrl}dashboard/enquiry/getMentor`;
    return postRequest({
        url,
        data,
    });
};
export const SOPSearchAPI = function (data) {
    const url = `${config.baseUrl}dashboard/enquiry/getSOP`;
    return postRequest({
        url,
        data,
    });
};

export const resumeWritingUpdateAPI = function ({enquiryId,data}) {
    const url = `${config.baseUrl}dashboard/enquiry/updateResume/${enquiryId}`;
    return putRequest({
        url,
        data,
    });
};
export const mentorAssistantUpdateAPI = function ({enquiryId,data}) {
    const url = `${config.baseUrl}dashboard/enquiry/updateMentor/${enquiryId}`;
    return putRequest({
        url,
        data,
    });
};
export const SOPUpdateAPI = function ({enquiryId,data}) {
    const url = `${config.baseUrl}dashboard/enquiry/updateStudent/${enquiryId}`;
    return putRequest({
        url,
        data,
    });
};

export const enquiryCreateAPI = function (data) {
    const url = `${config.baseUrl}enquiry`;
    return postRequest({
        url,
        data,
    });
};

export const enquiryUpdateAPI = function ({
    enquiryId,
    data,
}) {
    const url = `${config.baseUrl}enquiry/${enquiryId}`;
    return putRequest({
        url,
        data,
    });
};
