import React from 'react'

const AdditionalServiceModal = ({additionalService}) => {
    return (
        <div className="modal fade sman21-modal" id="additionalservices-Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
             <div className="modal-dialog modal-dialog-scrollable">
             <div className="modal-content">
             <div className="modal-header border-0"> 
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="mdi mdi-close"></i></button>
               </div>
               <div className="modal-body pt-0">
                  <div className="bgstrt">
                  <div className="bgstrtvb1 d-flex justify-content-between align-items-center">
                        <h3>Additional Services Details</h3>
                  </div>
                    {
                        additionalService.length > 0 && (
                            additionalService.map((service) => {
                                return (
                                    <div className={`bgNew-white snpn-1 bsupdhad d-flex ${service.is_proofreading === true && 'justify-content-around'}`} key={service._id}>
                                        <div>
                                    <p>{service.service}</p>
                                    <p>${service.rate}</p>
                                        </div>
                                   {
                                    service.is_proofreading === true && (
                                        <div>
                                            <p>Proofreading Service</p>
                                            <p>
                                            ${service.proofreading}
                                                </p>
                                        </div>
                                    )
                                   }
                                    </div>
                                )
                            })
                        )
                    }
                    </div>
                    </div>
             </div>
             </div>
            </div>
    )
}

export default AdditionalServiceModal