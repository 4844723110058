import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { PieChartWrapper, colors } from "./styles";
 
import { ChartTitle, ReportWrapper, Subtitle } from "./styles";
import "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

const CountriesReport = (props) => {
  const {
    topCountry = {}
} = props;

  const INITIAL_STATE = {
    labels: [],
    values: [],
    colors: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE); 
  const [totalCoutries, setTotalCountries] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const displayResults = (response) => {
    const queryResult = response.reports[0].data.rows;
    setTotalUsers(response.reports[0].data.totals[0].values[0]);
    setTotalCountries(queryResult.length);
    let labels = [];
    let values = [];
    let bgColors = [];
    queryResult.forEach((row, idx) => {
      if (idx < 5) {
        labels.push(row.dimensions[0]);
        values.push(row.metrics[0].values[0]);
        bgColors.push(colors[idx + 1]);
      }
    });
    setReportData({
      ...reportData,
      labels,
      values,
      colors: bgColors,
    });
  };

  const data = {
    labels: reportData.labels,
    datasets: [
      {
        data: reportData.values,
        backgroundColor: reportData.colors,
      },
    ],
  };

  const options = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return data.labels[tooltipItem["index"]];
        },
      },
    },
    plugins: {
      datalabels: {
        color: "black",
        font: {
          size: 20,
        },
        formatter: function (value, context) {
          const perc = parseInt((value / totalUsers) * 100);
          return perc + "%";
        },
      },
    },
  };

  useEffect(() => { 
    if(topCountry?.reports?.length>0)
    displayResults(topCountry)
  }, [topCountry]);

  return (
    <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 lightblue-icon me-2"><span><img src="/assets/images/home-imgs/online-icon.svg" className="img-fluid" alt=""/></span></div>
                      Top 5 Countries by Users </div> 
                  </div> 
                  <div className="sessaTxt"> 
                      <ReportWrapper> 
                          <Subtitle>{`Total countries - ${totalCoutries}`}</Subtitle> 
                          {reportData && (
                          <PieChartWrapper>
                             <Pie data={data} options={options} />
                          </PieChartWrapper>
                          )}
                      </ReportWrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>    
  );
};

export default CountriesReport;
