import React from 'react'; 
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import PropTypes from 'prop-types';
import FormikMultiSelectDropdown from 'src/components/formik/FormikMultiSelectDropdown/FormikMultiSelectDropdown';
import { getBlogMaster } from 'src/api/BlogApi';


const TagDropDown = ({ value, name, readOnly,...rest }) => (
    <FormikMultiSelectDropdown
        name={name}
        placeholder="Select Tags"
        displayKeyPath={[
            'name',
        ]}
        valueKeyPath="_id"
        defaultValueFetchByKeyPath="_id"
        whereClauseKeysPaths={[
            'name',
        ]}
        api={getBlogMaster}
        apiCallAddonData={{
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
        }}
        value={value}
        disabled={readOnly}
        {...rest}
    />
);

TagDropDown.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

TagDropDown.defaultProps = {
    value: '',
    readOnly: false,
};

export default TagDropDown;
