import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { dashboardResetAction, dashboardSearchAction } from 'src/containers/dashboard-container/actions/DashboardActions';
import DateRangeCalendar from 'src/components/DateRangeCalendar';
import DashBoard from './dashboard'; 
import CountriesReport from './countriesReport';
import DayVisitsReport from './dayVisitsReport';
import SourceReport from './sourceReport';  
import DivicesList from './DivicesList';
import PageviewsReport from './pageviewReport';
import Loader from 'src/components/Loader';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import ConversionsDataReport from './ConversionsDataReport';

const GoogleAnalyticsPreview=({clientName})=>{
    const history = useHistory();
    const dispatch = useDispatch();
    const { 
      GoogleAPI, 
      searchReqStatus
     } = useSelector((state) => state.DashboardReducer, shallowEqual);
  
     const [filter,setBlogFilter] = useState({});
     const [isLoaded,setIsLoaded] = useState(false);
     const [GoogleAPIState,setGoogleAPIState] = useState({
      sessionPerDay: {},
      userPerDay: {},
      topCountry: {},
      sourceData: {},
      topPages: {},
      browserData: {},
      devicesData: {},
      conversionsData: {},
    });
  
    const dashboardSearch=async()=>{ 
      setIsLoaded(false)
      dispatch(dashboardSearchAction({
        where: filter, 
        autopopulate:true,
        requestedSourceDefault:false,
        clientName
    })); 
    }
  
    const handelFilterChange=(payload)=> {
      setBlogFilter({...filter,...payload})
    }
  
    useEffect(()=>{
      dashboardSearch(); // revert required
   },[filter])
  
   useEffect(()=>{
    if(GoogleAPI && searchReqStatus===REQUEST_STATUS.SUCCESS) {
      setGoogleAPIState(GoogleAPI)
      setIsLoaded(true)
    }
    else
    {
        setIsLoaded(false) // revert required
    }
  },[GoogleAPI])

  useEffect(()=>{
//    dispatch(dashboardResetAction())
dashboardSearch(); // revert required
  },[])
  
      return(
          <>
          <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> <span className="page-title-icon bg-gradient-primary text-white me-2"> <i className="mdi mdi-home"></i> </span> Dashboard </h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              <DateRangeCalendar 
                  onSelect={handelFilterChange}
                  isSeperate={true}
                  skips={["Daily","Tomorrow"]}
              />
            </div>  
              {
              isLoaded ? <> 
                      <DayVisitsReport sessionPerDay={GoogleAPIState.userPerDay} title={"Users"} />
                      <DayVisitsReport sessionPerDay={GoogleAPIState.sessionPerDay} title={"Sessions"} />
                      <SourceReport sourceData={GoogleAPIState.sourceData} />
                      <DivicesList browserData={GoogleAPIState.browserData} devicesData={GoogleAPIState.devicesData}/>
                      <PageviewsReport topPages={GoogleAPIState.topPages} />
                      <CountriesReport topCountry={GoogleAPIState.topCountry} />
                      <ConversionsDataReport conversionsData={GoogleAPIState.conversionsData} title={"Conversions: Event name"}/>
                  
              </>:<Loader />
              }
          </div>
        </div>  
          </>
      )
}
export default GoogleAnalyticsPreview