import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { dashboardSearchAction } from 'src/containers/dashboard-container/actions/DashboardActions';
import DateRangeCalendar from 'src/components/DateRangeCalendar';
import RegisterStudent from './Sections/RegisterStudent';
import SubscriptionPlanWise from './Sections/SubscriptionPlanWise';
import RevenueOverview from './Sections/RevenueOverview';
import CountryWiseRegisterStudent from './Sections/CountryWiseRegisterStudent';
import CountryWiseVisiterGoogle from './Sections/CountryWiseVisiterGoogle';
import AssignmentStatus from './Sections/AssignmentStatus'; 
import Loader from 'src/components/Loader';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import OnlineTutoring from './Sections/OnlineTutoring';
import Onboarded from './Sections/Onboarded';
import SOS from './Sections/SOS';
import TutorByCountry from './Sections/TutorByCountry';
import ExpertByCountry from './Sections/ExpertByCountry';
import StudentGender from './Sections/StudentGender';
import TutorGender from './Sections/TutorGender';

const DashboardContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    registerStudent,
    countryWiseUser,
    subscriptionPlanWise,
    revenueOverview, 
    assignmentOverview,
    onlineTutoring,
    onboardedExpertTutor,
    countryWiseCount,
    genderWiseCount,
    SOSSection,  
    bookQnaSection,
    searchReqStatus
   } = useSelector((state) => state.DashboardReducer, shallowEqual);

   const [filter,setBlogFilter] = useState({});
   const [isLoaded,setIsLoaded] = useState(false);

  const dashboardSearch=async()=>{
    setIsLoaded(false)
    dispatch(dashboardSearchAction({
      where: filter, 
      autopopulate:true,
      requestedSourceDefault:true
  })); 
  }

  const handelFilterChange=(payload)=> {
    setBlogFilter({...filter,...payload})
  }

  useEffect(()=>{
    dashboardSearch();
 },[filter])

useEffect(()=>{
   if((searchReqStatus===REQUEST_STATUS.SUCCESS)) setIsLoaded(true)
},[searchReqStatus])
    return(
        <>
        <div className="content-wrapper">
        <div className="page-header">
          <h3 className="page-title"> <span className="page-title-icon bg-gradient-primary text-white me-2"> <i className="mdi mdi-home"></i> </span> Dashboard </h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            <DateRangeCalendar onSelect={handelFilterChange} isSeperate={true}/>
          </div>
          {
            (searchReqStatus!==REQUEST_STATUS.PENDING) ? <> 
              <RegisterStudent  registerStudent={registerStudent} countryWiseUser={countryWiseUser}/> 
              <SubscriptionPlanWise subscriptionPlanWise={subscriptionPlanWise}/>
              <CountryWiseRegisterStudent countryWiseUser={countryWiseUser}/>  
              <RevenueOverview revenueOverview={revenueOverview}/> 
              <AssignmentStatus assignmentOverview={assignmentOverview}/>
              <TutorGender genderWiseCount={genderWiseCount}/>
              {/* <OnlineTutoring   onlineTutoring={onlineTutoring} /> */}
              <Onboarded onboardedExpertTutor={onboardedExpertTutor}/>
              {/* <BooksAndQnA /> */}
              {/* <SOS/> */}
              <TutorByCountry countryWiseCount={countryWiseCount}/>
              <ExpertByCountry countryWiseCount={countryWiseCount}/>
              
              {/* <StudentGender genderWiseCount={genderWiseCount}/> */}
            </>:<Loader />
            }  
        </div>
      </div>  
        </>
    )
    };

export default DashboardContainer;
