import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateBookStatusAPI } from 'src/api/BookApi'
import { bookEditAction } from 'src/containers/book-container/actions/BookActions'
import RoutingUtil from 'src/utils/RoutingUtil'

const BookContainerTabPane = ({ containerPage, activeSelectedTab, tableData, setActiveSelectedTab, rejectModal, setRejectModal, filterModal, setFilterModal }) => {

const history = useHistory()
const dispatch = useDispatch()
const [isAllSelected, setIsAllSelected] = useState(false)


const handleBookStatusChange = async (bookId,updatedStatus) => {
if(containerPage === 'Live'){
    const updateStatus = await updateBookStatusAPI(updatedStatus === 'approved' ? {_id:bookId, authoringStatus:'approved',seoStatus:'pending'} : {_id:bookId, authoringStatus:'rejected'})
    if(updateStatus){
        console.log('book approved successfully')
        setActiveSelectedTab(  {
            id:1,
            heading:'All Pending Books',
            name:'authoringPending'
         })
    }else {
        console.log('error updating book status')
    }
}else {
    const updateStatus = await updateBookStatusAPI(updatedStatus === 'approved' ? {_id:bookId, seoStatus:'approved'} : {_id:bookId, seoStatus:'rejected'})
    if(updateStatus){
        console.log('book approved successfully')
        setActiveSelectedTab(  {
            id:1,
            heading:'All Pending Books',
            name:'authoringPending'
         })
    }else {
        console.log('error updating book status')
    }
}

} 


const handleBookEditClick = useCallback((record) => {
    if (bookEditAction) {
        dispatch(bookEditAction(record));    
        if(containerPage === 'SEO'){
            localStorage.setItem('form-edit-type','SEO')
        }
    }
    history.push(RoutingUtil.bookAuthoringUrl())
   
}, [
    dispatch,
    bookEditAction,        
    history,
]);

const handleSelectAllDocs = (e) => {
    setIsAllSelected(e.target.checked)
}

const handleDeleteAllDocuments = async() => {
if(isAllSelected){
    const docArr = []
    tableData.forEach((data) => docArr.push(data._id))
    const res = await updateBookStatusAPI({updateType:'deleteAll',ids:docArr})
    if(res){
        console.log('all docs deleted successfully.')
    }
}
}

const handleApproveAllDocuments = async() => {
    if(containerPage === 'Live'){
        if(isAllSelected){
            const docArr = []
            tableData.forEach((data) => docArr.push(data._id))
            const res = await updateBookStatusAPI({updateType:'approveAll',ids:docArr, authoringStatus:'approved',seoStatus:'pending'})
            if(res){
                console.log('all docs deleted successfully.')
            }
        }
    }else {
        if(isAllSelected){
            const docArr = []
            tableData.forEach((data) => docArr.push(data._id))
            const res = await updateBookStatusAPI({updateType:'approveAll',ids:docArr, seoStatus:'approve'})
            if(res){
                console.log('all docs deleted successfully.')
            }
        }
    }
}

const handleBookReUploadClick = (data) => {
    const chapterUploadData = {
       book_id:data._id,
       book_name:data.BookName,
       book_isbn:data.ISBN13,
       activeTab:'Books Upload Chapters'
    }
    localStorage.setItem('chapter-upload',JSON.stringify(chapterUploadData))
    history.push('/books/bulk-upload')
}
    

    return (
        <>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-tab1">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="mb-0">{activeSelectedTab.tableHeader} books details  </h4>
                        <div className="btnsXn1x">
                            <div className="edtPreDelt d-flex align-items-center justify-content-between">
                                <div className="me-3"><label className="cheboxNw mb-0" >Select All <input type="checkbox" 
                                onClick={(e) => handleSelectAllDocs(e)}
                                /> <span className="checkmark"></span> </label></div>
                                <button className="btndownc mddeleteBtn" onClick={handleDeleteAllDocuments}><i className="mdi mdi-delete mdi-18px"></i></button>
                                <button className="btndownc"><i className="mdi mdi-arrow-down mdi-18px"></i></button>
                                {
                                    activeSelectedTab.tableHeader === 'Pending' && (
                                        <button className="btn1 approvedBtn d-flex align-items-center" onClick={handleApproveAllDocuments}><i className="mdi mdi-check-circle mdi-18px me-1"></i> Approve</button>
                                    )
                                }
                                 </div>
                        </div>
                    </div>
                    <div className="mtcustom3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="livebooksbg">
                                    {
                                        tableData.map((data) => {
                                            return (
                                                <div className="card livebooks">
                                                    <div className="card-body">
                                                        {/* <div className="d-flex justify-content-end chck-box1"><label className="cheboxNw"><input type="checkbox" /> <span className="checkmark"></span> </label></div> */}
                                                        <div className="d-fx">
                                                            <div className="text-center livebookImg">
                                                                <div className="text-center livebookImgbg">
                                                                    <span><img src="/assets/images/books/bookimg.png" className="img-fluid" alt="" /></span>
                                                                </div>
                                                            </div>
                                                            <div className="livebookText">
                                                                <h2>{data?.BookName}</h2>
                                                                <div className="isbnNum"><span>ISBN13</span> {data?.['ISBN13']}</div>
                                                                <ul>
                                                                    <li><span>Subject:  </span> {data?.subject_name?.charAt(0).toUpperCase() + data?.subject_name?.slice(1)} </li>
                                                                    <li><span>Sub Subject:  </span> {data?.sub_subject_name} </li>
                                                                    <li><span>Chapter: </span> {data?.total_question} </li>
                                                                    <li><span>Book Edition: </span> {data?.Edition} </li>
                                                                    <li><span>Uploaded Date: </span> {data?.created_at} </li>
                                                                    <li><span>Author: </span> {data?.Author1}, {data?.Author2}, {data?.Author3}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="btnsXn1x">
                                                            <div className="totalqusnum">
                                                                <div className="totalqusnum1"><span>Uploaded by: </span>  {data?.created_by}</div>
                                                               {/* < div className="totalqusnum1"><span>Qc’s Name:  </span>  Harishita Luthra (5874)</div> */}
                                                            </div>
                                                            {
                                                                activeSelectedTab.heading === 'All Pending Books' && (
                                                                    <div className="edtPreDelt">
                                                                        <button className="btn1 approvedBtn d-flex align-items-center" onClick={() => handleBookStatusChange(data._id, 'approved')}><i className="mdi mdi-check-circle mdi-18px me-1"></i> Approve</button>
                                                                        <button className="btn1 active" onClick={() => handleBookEditClick(data)}><i className="mdi mdi-pencil"></i> Edit Book</button>
                                                                        <button className="btn2 rejectBtn d-flex align-items-center" onClick={() => setRejectModal(true)}><i className="mdi mdi-close-circle mdi-18px me-1 text-red"></i> Reject</button>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activeSelectedTab.heading === 'Total Rejected Books' && (
                                                                    <div className="btnsXn1x">
                                                                        <div className="totalqusnum">
                                                                            <div className="totalqusnum1"><span className="img"><img src="/assets/images/book-pending-for-qc/ulicon.svg" className="img-fluid" alt="" /></span><span>Uploaded by: </span>  Ankita Sharma (4585)</div>
                                                                            <div className="totalqusnum1"><span className="img"><img src="/assets/images/book-pending-for-qc/ulicon.svg" className="img-fluid" alt="" /></span><span>Uploaded by: </span>  Ankita Sharma (4585)</div>
                                                                        </div>
                                                                        <div className="btnsXn1x">
                                                                            <div className="totalqusnum justify-content-between">
                                                                                <div className="totalqusnum1"><span>Rejected Reason: </span>  <span className="text-red">Incomplete Details </span></div>
                                                                                <button className="btndownc"><i className="mdi mdi-arrow-down"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activeSelectedTab.heading === 'Total Approved Books' && (
                                                                    <div className="edtPreDelt">
                                                                        <button className="btn1 text-green d-flex align-items-center" onClick="window.location.href = '#';"><i className="mdi mdi-check-circle mdi-18px me-1"></i> Approve</button>
                                                                        {/* <button class="btn2 reuploadBtn d-flex align-items-center" onclick="window.location.href = '#';"><i class="mdi mdi-autorenew mdi-18px me-1 text-yellow"></i> Re-upload</button> */}
                                                                         <button class="btn2 reuploadBtn d-flex align-items-center" onClick={() => handleBookReUploadClick(data)}><i class="mdi mdi-autorenew mdi-18px me-1 text-yellow"></i> Re-upload</button>
                                                                        <button className="btn1 active" onClick={() => handleBookEditClick(data)}><i className="mdi mdi-pencil"></i> Edit Book</button>
                                                                        {/* <button className="btndownc"><i className="mdi mdi-arrow-down mdi-18px"></i></button> */}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="modal fade filter-Modal mt- 5" id="filter-Modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                style={{
                    display: `${rejectModal ? "block" : "none"}`,
                    opacity: `${rejectModal ? 1 : 0}`,
                    background: `rgba(0,0,0,0.5)`,
                 }}
            >
         <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-body">
                  <div class="bgstrt">
                    <p>Are you sure you want to reject the book ?</p>
                  </div>
               </div>
            </div>
         </div>
      </div> */}
        </>
    )
}

export default BookContainerTabPane