import { useState } from "react"
import { titleCase } from "src/libs/common"

const CountryWiseSubscriber=(props)=>{
  const [isFullView,setIsFullView] = useState(false)
     const {countryWiseUser={}} = props
     const subscriberCountry = countryWiseUser?.subscribed_user?.[0]?.subscribed_user || []
   
    return (
        <>
         <div className="books-pan1bg" style={{marginTop:'25px'}}>
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Country Wise Subscriber 
                      </div>
                    
                  </div>
                  
                  <div className="uncunrybg">
                    <ul>
                      {
                        subscriberCountry.map((subscriber,index)=>{
                          if(index>5 && !isFullView) return;
                         else
                          return (
                              <li key={index}>
                                <div className="uncunry1">{titleCase(subscriber.country)}</div>
                                <div className="uncunry-progss">
                                  <div className="progress">
                                    <div className="progress-bar bg-gradient-success" role="progressbar" style={{width:subscriber.percentage.toFixed(0)+"%"}} aria-valuenow="23.54" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                  <div className="prsg">{subscriber.percentage.toFixed(0) }%</div>
                                </div>
                                <div className="uncunryNo2">{subscriber.count}</div>
                            </li>
                          )
                        })
                      }                       
                    </ul>
                    {    
                          !isFullView?
                          <a href="javascript:void(0)" onClick={()=>{setIsFullView(true)}}>View More</a>:
                          <a href="javascript:void(0)" onClick={()=>{setIsFullView(false)}}>Minimize</a>
                    }
                  </div>
                </div>
        </>
    )
}

export default CountryWiseSubscriber