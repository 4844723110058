/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest, getRequest, putRequest } from 'src/utils/RequestUtil';


export const SOSSearchApi = function (pageType, data) {
    const url = `${config.baseUrl}online-session/get-session-details/${pageType}`;
    return postRequest({
        url,
        data,
    });
};


export const SOSUpdateApi = function (data) {
    const url = `${config.baseUrl}online-session/update-session-admin`;
    return postRequest({
        url,
        data,
    });
};

export const getSessionsCount = function(pageType, data){
    const url = `${config.baseUrl}online-session/get-session-count/${pageType}`;
    return postRequest({
        url,
        data,
    });
}