import { useEffect,useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {useHistory } from 'react-router-dom';
import { getBookCountsAndDetails, getBooksCount } from "src/api/BookApi";
import RoutingUtil from 'src/utils/RoutingUtil';

const BookMain=()=>{
    const history = useHistory();
    const dispatch = useDispatch();
    const {
      BookCount,
      searchReqStatus
     } = useSelector((state) => state.BookReducer, shallowEqual);
     const [bookCount, setBookCount] = useState(
      {
         "authoringPending": 0,
         "authoringRejected": 0,
         "authoringApproved": 0,
         "seoPending": 0,
         "seoRejected": 0,
         "seoApproved": 0
     }
     )

useEffect(() => {
   getCounts()
},[])

    const getCounts = async() => {
      const countRes = await getBooksCount()
      // console.log('books count is', countRes.data.data)
     setBookCount(countRes.data.data)
    }

  return( 
      <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2" >
                        <i className="mdi mdi-home"></i>
                        </span> Books
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="books-asignt">

                           <div className="card asigntabnew1">
                              <div className="card-img-holder">
                                 <div className="card-body">
                                    <h4 className="font-weight-normal mb-3 d-flex align-items-center">
                                        <span className="asigntabIcon icon6">
                                        <img src="/assets/images/books/book-icon.svg" className="img-fluid img-icon" alt=""/> 
                                        <i className="mdi mdi-plus"></i>
                                        </span> 
                                        <span className="valutext">{bookCount.authoringPending}</span>
                                    </h4>
                                    <h2 className="mt-0">Books Authoring Pending</h2>
                                    {/* <h6 className="card-text d-flex justify-content-between align-items-center">Last 24 hours published <span>41</span></h6> */}
                                    <div className="pricdlrm1">
                                         <button className="btnbluen1" onClick={()=>{history.push(RoutingUtil.bookLiveUrl())}}>View</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="card asigntabnew1">
                              <div className="card-img-holder">
                                 <div className="card-body">
                                    <h4 className="font-weight-normal mb-3 d-flex align-items-center">
                                       <span className="asigntabIcon icon6">
                                          <img src="/assets/images/books/book-icon.svg" className="img-fluid img-icon" alt=""/> 
                                          <i className="mdi mdi-plus"></i></span> 
                                          <span className="valutext">{bookCount.authoringRejected}</span>
                                    </h4>
                                    <h2 className="mt-0">Books Authoring Rejected</h2>
                                    {/* <h6 className="card-text d-flex justify-content-between align-items-center">Last 24 hours published <span>41</span></h6> */}
                                    <div className="pricdlrm1"><button className="btnbluen1" onClick={()=>{
                                       window.localStorage.setItem('activeTab',  {
                                          id: 2,
                                          heading: 'All Rejected Books',
                                          name: 'authoringRejected',
                                          tableHeader:'Rejected'
                                       })
                                       history.push(RoutingUtil.bookLiveUrl())}}>View</button></div>
                                 </div>
                              </div>
                           </div>
                           <div className="card asigntabnew1">
                              <div className="card-img-holder">
                                 <div className="card-body">
                                    <h4 className="font-weight-normal mb-3 d-flex align-items-center">
                                       <span className="asigntabIcon icon6">
                                          <img src="/assets/images/books/book-icon.svg" className="img-fluid img-icon" alt=""/> 
                                          <i className="mdi mdi-plus"></i></span> 
                                          <span className="valutext">{bookCount.authoringApproved}</span>
                                    </h4>
                                    <h2 className="mt-0">Book Authoring Approved</h2>
                                    {/* <h6 className="card-text d-flex justify-content-between align-items-center">Last 24 hours published <span>41</span></h6> */}
                                    <div className="pricdlrm1"><button className="btnbluen1" onClick={()=>{
                                       window.localStorage.setItem('activeTab',  {
                                          id: 3,
                                          heading: 'All Approved Books',
                                          name: 'authoringApproved',
                                          tableHeader:'Approved'
                                       })
                                       history.push(RoutingUtil.bookLiveUrl())}}>View</button></div>
                                 </div>
                              </div>
                           </div>
                           <div className="card asigntabnew1">
                              <div className="card-img-holder">
                                 <div className="card-body">
                                    <h4 className="font-weight-normal mb-3 d-flex align-items-center">
                                       <span className="asigntabIcon icon6">
                                          <img src="/assets/images/books/book-icon.svg" className="img-fluid img-icon" alt=""/> 
                                          <i className="mdi mdi-plus"></i></span> 
                                          <span className="valutext">{bookCount.seoPending}</span>
                                    </h4>
                                    <h2 className="mt-0">Books SEO Pending</h2>
                                    {/* <h6 className="card-text d-flex justify-content-between align-items-center">Last 24 hours published <span>41</span></h6> */}
                                    <div className="pricdlrm1"><button className="btnbluen1" onClick={()=>{history.push(RoutingUtil.bookPendingForQCUrl())}}>View</button></div>
                                 </div>
                              </div>
                           </div>
                           <div className="card asigntabnew1">
                              <div className="card-img-holder">
                                 <div className="card-body">
                                    <h4 className="font-weight-normal mb-3 d-flex align-items-center">
                                       <span className="asigntabIcon icon6">
                                          <img src="/assets/images/books/book-icon.svg" className="img-fluid img-icon" alt=""/>
                                           <i className="mdi mdi-plus"></i></span> 
                                           <span className="valutext">{bookCount.seoRejected}</span>
                                    </h4>
                                    <h2 className="mt-0">Books SEO Rejected</h2>
                                    {/* <h6 className="card-text d-flex justify-content-between align-items-center">Last 24 hours published <span>41</span></h6> */}
                                    <div className="pricdlrm1"><button className="btnbluen1" onClick={()=>{
                                         window.localStorage.setItem('activeTab', JSON.stringify( {
                                          id: 2,
                                          heading: 'All Rejected Books',
                                          name: 'seoRejected',
                                          tableHeader:'Rejected'
                                       }))
                                       history.push(RoutingUtil.bookPendingForQCUrl())}}>View</button></div>
                                 </div>
                              </div>
                           </div>  

                           <div className="card asigntabnew1">
                              <div className="card-img-holder">
                                 <div className="card-body">
                                    <h4 className="font-weight-normal mb-3 d-flex align-items-center">
                                       <span className="asigntabIcon icon6">
                                          <img src="/assets/images/books/book-icon.svg" className="img-fluid img-icon" alt=""/>
                                           <i className="mdi mdi-plus"></i></span> 
                                           <span className="valutext">{bookCount.seoApproved}</span>
                                    </h4>
                                    <h2 className="mt-0">Books SEO Approved</h2>
                                    {/* <h6 className="card-text d-flex justify-content-between align-items-center">Last 24 hours published <span>41</span></h6> */}
                                    <div className="pricdlrm1"><button className="btnbluen1" onClick={()=>{
                                         window.localStorage.setItem('activeTab',  {
                                          id: 3,
                                          heading: 'All Approved Books',
                                          name: 'seoApproved',
                                          tableHeader:'Approved'
                                       })
                                       history.push(RoutingUtil.bookPendingForQCUrl())}}>View</button></div>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
               )
}
export default BookMain