/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';


export const blogSearchAPI = function (data) {
    const url = `${config.baseUrl}blog/search`;
    return postRequest({
        url,
        data,
    });
};

export const blogCreateAPI = function asyn(data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) { 
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}blog/save`;
    return postRequest({
        url,
        data: fd,
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // },
    });
};

export const blogUpdateAPI = function ({
    blogId,
    data,
}) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}blog/update/${blogId}`;
    return putRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export async function getBlogMaster( data ) {
        const url = `${config.baseUrl}blog/blogMaster`;
        return postRequest({
            url,
            data,
        });   
}
export async function getBlogAuthor( data ) {
    const url = `${config.baseUrl}blog/blogAuthor`;
    return postRequest({
        url,
        data,
    });   
}
