import { useCallback } from "react";
import Pagination from "src/components/Pagination";
import { blogEditAction } from "./actions/BlogActions";
import RoutingUtil from "src/utils/RoutingUtil";
import { MakeSlug } from "src/utils/CommonUtils";
import config from 'src/config';

const BlogList=({blogs,pageNo,setPageNo,history,dispatch})=>{
    const data = blogs.data||[];
    const editClick = useCallback((record) => {
        if (blogEditAction) {
            dispatch(blogEditAction(record));
        }
        history.push(RoutingUtil.blogManagerUrl())
    }, [
        dispatch,
        blogEditAction,        
        history,
    ]);

    return(
<div className="table-responsive">
 <table className="table datatablefnts datatable">
        <thead>
            <tr>
                <th>S. No</th>
                <th>Image</th>                
                <th>Category</th>                 
                <th>Author</th>
                <th>Schedule</th> 
                <th>Title</th>
                <th>Blog URL</th> 
                <th>Blog</th>                
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {
             data.map((blog,index)=>{
                    return <tr key={index}>
                        <td>{index+1}</td>
                        <td><img src={blog.img_path} height={50} width={50} alt={blog.imageAlt}/></td>                        
                        <td>{blog.categoryId.name} </td>                         
                        <td>{blog.authorId.name} </td>
                        <td>{blog.schedule} </td>     
                        <td>{blog.title}</td>                   
                        <td>                             
                            <a href={`${config.clientUrl}blog${(blog.isPublish===true)?'':'/drafts'}/${blog.blogURL}`} target="_blank" >{blog.blogURL}</a></td> 
                        <td>
                        <div className="nambtntx" dangerouslySetInnerHTML={{ __html: blog.blog.substring(0,150) }}>                            
                        </div>
                        </td>                         
                        <td>
                            <div>
                                <button className="virenbtn1" onClick={()=>{editClick(blog)}} >Edit </button>
                            </div> 
                        </td>  
                    </tr>
                })
            }
        </tbody>
        </table> 
        {data && data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={blogs.total}/>}
    </div>
    )
}
export default BlogList;