import SeoBreadCrumbSubjectTB from './SeoBreadCrumbSubjectTB';
import React, { useState, useEffect } from 'react'
import * as Yup from "yup";
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput'; 
import FormikCKEditor from 'src/components/formik/FormikCKEditor/FormikCKEditor';
import { useHistory, useParams } from 'react-router-dom';
import { saveContentSubjectTB } from 'src/api/MastersApi';


const Render = (props) => {
    const [editorLoaded, setEditorLoaded] = useState(false);
    useEffect(() => {
        setEditorLoaded(true);
        }, []);
    const {
        values,
        touched,
        errors,
        onSubmit,
        getFieldProps,
        setFieldValue,
    } = props;


    return (
        <Form>
            <div className='row p-4'>
                <div className='col-md-12'>
                <p><span className='mdi mdi-book-multiple'></span>Manage content</p>
            <hr />
                </div>
                <div className='col-md-6'>
                    <label>Banner Heading</label>
                    <FormikInput
                        type='text'
                        placeholder="Enter Banner Heading"
                        name="bannerHeading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6'>
                    <label>Question Heading</label>
                    <FormikInput
                        type='text'
                        name="question.heading"
                        placeholder="Enter question heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Answer Heading</label>
                    <FormikInput
                        type='text'
                        name="answer.heading"
                        placeholder="Enter answer heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Ask An Expert Text</label>
                    <FormikInput
                        type='text'
                        name="askAnExpertText"
                        placeholder="Enter ask an expert text"
                        className="form-control  enchilada"
                    />
                </div>
               
                <div className='col-md-6 mt-4'>
                    <label>College Text Books Text</label>
                    <FormikInput
                        name="collegeTextBooks"
                        type='text'
                        placeholder="Enter college text books text"
                        className="form-control  enchilada"
                    />
                </div>
                {/* <div className='col-md-6 mt-4' /> */}
                {/* <div className='col-md-6 mt-4' /> */}
                <div className='col-md-12 mt-4'>
                    <label>Question Content</label>
                   <FormikCKEditor 
                   id='editor1'
                    name="question.content"
                    value={values.question.content}
                    onBlur={(data) => {
                        setFieldValue("question.content", data);
                    }}
                    editorLoaded={editorLoaded}
                   />
                </div>
                <div className='col-md-12 mt-4'>
                    <label>Answer Content</label>
                    <FormikCKEditor 
                   id='editor1'
                   name="answer.content"
                    value={values.answer.content}
                    onBlur={(data) => {
                        setFieldValue("answer.content", data);
                    }}
                    editorLoaded={editorLoaded}
                   />
                </div>
                <div className='col-md-12 mt-4'>
                    <label>Feature Main Heading</label>
                    <FormikInput
                        type='text'
                        name="feature.mainHeading"
                        placeholder="Enter Main Heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-12 mt-4'>
                    <label>Feature Content</label>
                    <FormikInput
                        name="feature.mainContent"
                        type='text'
                        placeholder="Enter Feature Content"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-12 mt-4'> <label>Service Heading</label>
                    <FormikInput
                        type='text'
                        name="feature.serviceHeading"
                        placeholder="Enter Service Heading"
                        className="form-control  enchilada"
                    /></div>
                <div className='col-md-12 mt-4'> <label>Service Content</label>
                <FormikCKEditor 
                   id='editor1'
                   name="feature.serviceContent"
                    value={values.feature.serviceContent}
                    onBlur={(data) => {
                        setFieldValue(
                            "feature.serviceContent",
                            data
                          );
                    }}
                    editorLoaded={editorLoaded}
                   />
                    </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Heading 1</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceHeading1"
                        placeholder="Enter Sub Service Heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Content  1</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceContent1"
                        placeholder="Enter Sub Service Content"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Heading 2</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceHeading1"
                        placeholder="Enter Sub Service Heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Content  2</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceContent2"
                        placeholder="Enter Sub Service Content"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>   <label>Service Sub Service Heading 3</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceHeading2"
                        placeholder="Enter Sub Service Heading"
                        className="form-control  enchilada"
                    />  </div>
                <div className='col-md-6 mt-4'>  <label>Service Sub Service Content  3</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceContent3"
                        placeholder="Enter Sub Service Content"
                        className="form-control  enchilada"
                    /> </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Heading 4</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceHeading4"
                        placeholder="Enter Sub Service Heading"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <label>Service Sub Service Content  4</label>
                    <FormikInput
                        type='text'
                        name="feature.subServiceContent4"
                        placeholder="Enter Sub Service Content"
                        className="form-control  enchilada"
                    />
                </div>
                <div className='col-md-6 mt-4'>
                    <button className='btnbluen1' onClick={() => onSubmit(values)}>
                    Save Content
                    </button>
                    </div>
            </div>
        </Form>
    )
}
const UpdateSubSubjectContentTB = () => {
    const params = useParams()
    const history = useHistory()

    const validationSchema = Yup.object({
        bannerHeading: Yup.string().required(),
        askAnExpertText: Yup.string(),
        collegeTextBooks: Yup.string(),
        question: Yup.object({ heading: Yup.string(), content: Yup.string() }),
        answer: Yup.object({ heading: Yup.string(), content: Yup.string() }),
        feature: Yup.object({
            mainHeading: Yup.string(),
            mainContent: Yup.string(),
            serviceHeading: Yup.string(),
            serviceContent: Yup.string(),
            subServiceHeading1: Yup.string(),
            subServiceHeading2: Yup.string(),
            subServiceHeading3: Yup.string(),
            subServiceHeading4: Yup.string(),
            subServiceContent1: Yup.string(),
            subServiceContent2: Yup.string(),
            subServiceContent3: Yup.string(),
            subServiceContent4: Yup.string(),
        }),
    });


    const [initialValues, setInitialValues] = useState({
        bannerHeading: "",
        askAnExpertText: "",
        collegeTextBooks: "",
        question: { heading: "", content: "" },
        answer: { heading: "", content: "" },
        feature: {
            mainHeading: "",
            mainContent: "",
            serviceHeading: "",
            serviceContent: "",
            subServiceHeading1: "",
            subServiceHeading2: "",
            subServiceHeading3: "",
            subServiceHeading4: "",
            subServiceContent1: "",
            subServiceContent2: "",
            subServiceContent3: "",
            subServiceContent4: "",
        },
    })

    const onSubmit = async (values) => {
       const add_content = await saveContentSubjectTB(values,params.id)
       if(add_content){
        history.push('/masters/subject')
       }
    }
    return (
       <div>
        <SeoBreadCrumbSubjectTB/>
        <Formik
                onSubmit={onSubmit}
                // onClear={onClear}
                // onSearch={onSearch}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formikProps) => (
                    <Render
                        // onClear={onClear}
                        // onSearch={onSearch}
                        // setInitialValues={setInitialValues}
                        onSubmit={onSubmit}
                        {...formikProps}
                    />
                )}
            </Formik>
       </div>
    )
}

export default UpdateSubSubjectContentTB