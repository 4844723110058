import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const AuthPropTypes = PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
});

export const AuthDefaultProps = {
    email: '',
    password: '',
};

export const AuthYupSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
});

export default {
    AuthDefaultProps,
    AuthPropTypes,
    AuthYupSchema,
};
