import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { deleteFaqQuestion } from 'src/api/FAQApi';
import { MakeSlug } from 'src/utils/CommonUtils';

const QuestionList = ({ quesArr }) => {

const history = useHistory()
const params = useParams()

const handleQuestionDelete = async (questionId) => {
    const deleteQue = await deleteFaqQuestion(params.id,questionId)
    if(deleteQue){
      history.push(`/faq/add-faq-question/${params.categorySlug}/${params.id}`)
    }
}

    return (
        <div className='row'>
            <div className='col-md-12'>
                <div id="questionDiv" style={{ height: '400px', overflow: 'scroll', paddingRight: '15px' }}>
                    {quesArr && quesArr.map(content => {
                        return (
                            <div className="card p-2 mb-2" key={content._id}>
                                <div className="card-heading">
                                    <strong>Question</strong>: {content.question}
                                </div>
                                <div className="card-body p-0">
                                    <strong>Answer</strong>: {content.answer}
                                </div>
                                <hr style={{ margin: '5px 0px' }} />
                                <div className='row justify-content-end'>
                                    <div className='col-md-3'>
                                        <span className='mdi mdi-tooltip-edit me-2 text-success' style={{ cursor: 'pointer', fontSize: '1.5rem' }}
                                        onClick={() => history.push(`/faq/add-faq-question/${MakeSlug(params.categorySlug)}/${params.id}/${content._id}`)}
                                        ></span>
                                        <span className='mdi mdi-delete text-danger' style={{ cursor: 'pointer', fontSize: '1.5rem' }} onClick={() => handleQuestionDelete(content._id)}></span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default QuestionList