import React from "react"
import { TableContents } from "./TabsConfiguration"
import SOSContainerTabPane from "src/components/SOSContainerTabPane"
import { SOSSearchApi, getSessionsCount } from "src/api/SOSApi"
import { useEffect } from "react"
import { useState } from "react"
import DateRangeCalendar from "src/components/DateRangeCalendar"
import { useDispatch, useSelector } from "react-redux"
import { sosSearchAction } from "./actions/SOSActions"

const SOSTabs = [
   {
      id: 1,
      name: 'total',
      heading: 'Total SOS'
   },
   {
      id: 2,
      name: 'student',
      heading: 'Total SOS by Students'
   },
   {
      id: 3,
      name: 'tutor',
      heading: 'Total SOS by Tutors'
   }
]

const SOSContainer = () => {

const dispatch = useDispatch()
 const { search } = useSelector((state) => state.SOSReducer)   
 var today = new Date();
 today.setUTCHours(0, 0, 0, 0); 
 var tomorrow = new Date(today);
 tomorrow.setDate(tomorrow.getDate() + 1);

   const [filter, setFilter] = useState({ SOS:{$ne:null}, "SOS.created_at": {
      "$gte": today.toISOString(),
      "$lt": tomorrow.toISOString()
   }})
   const [dateFilter, setDateFilter] = useState({ SOS:{$ne:null}, "SOS.created_at": {
      "$gte": today.toISOString(),
      "$lt": tomorrow.toISOString()
   }})
   const [tableData, setTableData] = useState([])
   const [pageNo, setPageNo] = useState(0)
   const [sosCounts, setSosCounts] = useState({'Total SOS':0,'Total SOS by Students':0,'Total SOS by Tutors':0,'student_pending':0,'student_resolved':0,'tutor_resolved':0,'tutor_pending':0})
   const [activeSelectedTab, setActiveSelectedTab] = useState({tabName:'Total SOS',id:1})



useEffect(()=>{
 const { data, groupCounter } = search
 setTableData(data)
},[search])


useEffect(() => {
   getSOSDetails()
},[filter])


const getSOSDetails = async () => {
   dispatch(sosSearchAction({
      where:filter,
      currentPage:pageNo,
      pageType:'SOS',
      autopopulate:true
   }))
}

useEffect(() => {
   getAllGroupCounts()
},[dateFilter])


const getAllGroupCounts  = async() => {
   const res= await getSessionsCount('sos-count',{where:dateFilter})
   setSosCounts(res.data.data)
}

const handleFilterChange = (payload) => {
   if(payload?.created_at !== undefined && payload?.created_at !== null){
      setFilter({...filter,'SOS.created_at':payload.created_at})
   }else{
      setFilter({...filter, ...payload})
   } 
}

const handleDateRangefilter = (payload) => {
   if(payload?.created_at !== undefined && payload?.created_at !== null){
      setDateFilter({'SOS.created_at':payload.created_at})
      setFilter({...filter, 'SOS.created_at':payload.created_at})
}
}

const handleActiveTabClick = (tabHeading, tabId) => {
setActiveSelectedTab({tabName:tabHeading,id:tabId})
console.log('selected tab is',tabHeading)
if(tabHeading === 'Total SOS'){
   delete filter['SOS.raised_by']
   handleFilterChange({SOS:{$ne:null}})
   dispatch(sosSearchAction({
      where:{...filter, SOS:{$ne:null}},
      currentPage:pageNo,
      pageType:'SOS',
      autopopulate:true
   }))
} else if (tabHeading === 'Total SOS by Students'){
    handleFilterChange({SOS:{$ne:null},'SOS.raised_by':'student'})
    dispatch(sosSearchAction({
      where:{...filter, SOS:{$ne:null},'SOS.raised_by':'student'},
      currentPage:pageNo,
      pageType:'SOS',
      autopopulate:true
   }))
} else if (tabHeading === 'Total SOS by Tutors'){
      handleFilterChange({SOS:{$ne:null},'SOS.raised_by':'tutor'})
      dispatch(sosSearchAction({
      where:{...filter, SOS:{$ne:null},'SOS.raised_by':'tutor'},
      currentPage:pageNo,
      pageType:'SOS',
      autopopulate:true
   }))
}
}

   return (
      <>
         <div className="content-wrapper">
            <div className="page-header">
               <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white me-2" >
                     <i className="mdi mdi-home"></i>
                  </span> SOS
               </h3>
               <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                     <li className="breadcrumb-item active" aria-current="page">
                        <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                     </li>
                  </ul>
               </nav>
            </div>
            <div className="row">
               <div className="col-md-12">
                  <div className="card shadow">
                     <div className="card-body">
                        <DateRangeCalendar onSelect={handleDateRangefilter}/>
                        <div className="tabsnrstl1 tabsnrstl31">
                           <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                              {
                                 SOSTabs.map((tab) => {
                                    return (
                                       <React.Fragment>
                                          {
                                             tab.name === 'total' ? (
                                                <li className="nav-item" role="presentation" onClick={() => handleActiveTabClick(tab.heading,tab.id)}>
                                                   <div className="nav-link active" id="pills-tab1" data-bs-toggle="pill" data-bs-target="#pills-1" type="button" role="tab" aria-controls="pills-1" aria-selected="true">
                                                      <div className="stretch-card asigntabnew1">
                                                         <div className="card-img-holder">
                                                            <div className="card-body text-center">
                                                               <div className="sosTxtN2 mb-3">
                                                                  <img src="/assets/images/sos-img/sos-img.svg" className="img-fluid img1" alt="" />
                                                                  <img src="/assets/images/sos-img/sos-img2.svg" className="img-fluid img2" alt="" /></div>
                                                               <h4 className="font-weight-normal mb-3 text-center"><span className="valutext ms-0">{sosCounts[tab.heading]}</span></h4>
                                                               <h2 className="mt-0">{tab.heading}</h2>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </li>
                                             ) :
                                                (
                                                   <li className="nav-item" role="presentation" onClick={() => handleActiveTabClick(tab.heading,tab.id)}>
                                                      <div className="nav-link" id="pills-tab2" data-bs-toggle="pill" data-bs-target="#pills-2" type="button" role="tab" aria-controls="pills-2" aria-selected="false">
                                                         <div className="stretch-card asigntabnew1">
                                                            <div className="card-img-holder">
                                                               <div className="card-body">
                                                                  <div className="sosImgN1">
                                                                     <div className="sosImgN sosImgN2"><div className="sosTxtN2"><img src="/assets/images/sos-img/sos-img.svg" className="img-fluid img1" alt="" />
                                                                        <img src="/assets/images/sos-img/sos-img2.svg" className="img-fluid img2" alt="" /> {tab.heading}</div> <div className="nbs2N">{sosCounts[tab.heading]}</div></div>
                                                                        {
                                                                           tab.name === 'student' ? (
<>
<div className="sosImgN sosImgN3"><div className="sosTxtN3">Still Pending SOS </div><span>{sosCounts.student_pending}</span></div> 
                                                                     <div className="sosImgN sosImgN4"><div className="sosTxtN4">Resolved SOS </div><span>{sosCounts.student_resolved}</span></div> 
                                                                 
</>
                                                                           ) : (
<>
<div className="sosImgN sosImgN3"><div className="sosTxtN3">Still Pending SOS </div><span>{sosCounts.tutor_pending}</span></div> 
                                                                     <div className="sosImgN sosImgN4"><div className="sosTxtN4">Resolved SOS </div><span>{sosCounts.tutor_resolved}</span></div> 
                                                                 
</>
                                                                           )
                                                                        }
                                                                   </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </li>
                                                )
                                          }
                                       </React.Fragment>
                                    )
                                 })
                              }
                           </ul>
                        </div>
                        <SOSContainerTabPane tableData={tableData}
                        activeSelectedTab={activeSelectedTab} handleFilterChange={handleFilterChange}
                       pageNo={pageNo}
                       setPageNo={setPageNo}
                       total={search.total}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default SOSContainer;