import React from 'react';
import PropTypes from 'prop-types';
import PermissionHOC from 'src/hoc/PermissionHOC';
import MenuLinkComponent from './MenuLinkComponent';
import MenuDropdownComponent from './MenuDropdownComponent';
import LinkNav from './LinkNav';

const SideBarMenuComponent = ({
    menus,
}) => {
    const menuItems = menus.map((menu) => (
        [
             
            menu.items ? menu.items.map((item,idx) => {
                if (item.link) {
                    return (
                        // <PermissionHOC
                        //     scope={item.scope}
                        //     key={item.title}
                        // >
                            <MenuLinkComponent
                                key={item.title}
                                faIcon={item.faIcon}
                                title={item.title}
                                link={item.link}
                                bandage={item.bandage}
                            />
                        //  </PermissionHOC>
                    );
                }

                return (
                    // <PermissionHOC
                    //     scope={item.scope}
                    //     key={item.title}
                    // >
                        <MenuDropdownComponent
                            key={item.title}
                            faIcon={item.faIcon}
                            title={item.title}
                            bandage={item.bandage}
                            items={item.items}
                        />
                    // </PermissionHOC>
                );
            }) : null,
        ]
    ));

    return (
        // <div className="sidebar-menu">
        //     <ul>
        //         {menuItems}
        //     </ul>
        // </div>
        <ul className="nav navcstmne1">
               <li className="nav-item nav-profile">
                  <a href="#" className="nav-link">
                     <div className="nav-profile-image">
                        <img src="/assets/images/faces/face1.jpg" alt="profile" />
                        <span className="login-status online"></span> 
                     </div>
                     <div className="nav-profile-text d-flex flex-column">
                        <span className="font-weight-bold mb-2">David Grey. H</span>
                        <span className="text-secondary text-small">Project Manager</span>
                     </div>
                     <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                  </a>
               </li>
               {menuItems}
            </ul>
    );
};

const bandageShape = PropTypes.shape({
    title: PropTypes.string,
    faIcon: PropTypes.string,
});
SideBarMenuComponent.propTypes = {
    menus: PropTypes.arrayOf(PropTypes.shape({
        menus: PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.string,
                bandage: bandageShape,
                faIcon: PropTypes.string,
                items: PropTypes.arrayOf(PropTypes.shape({
                    bandage: bandageShape,
                    link: PropTypes.string,
                    title: PropTypes.string,
                })),
            })),
        }),
    })).isRequired,
};

export default SideBarMenuComponent;
