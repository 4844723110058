import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { tutorSearchAction } from "./actions/TutorActions";
import TutorList from "./TutorList";
import TutorFilters from "./TutorFilters";
import DateRangeCalendar from "src/components/DateRangeCalendar";
const TutorContainer=()=>{
   const history = useHistory()
   const dispatch = useDispatch()
   const {search} = useSelector((state) => state.TutorReducer)
   const [tabledata, setTableData] = useState([])
   const [totalTutorData,setTotalTutorData] = useState()
   const [filter, setFilter] = useState({role:'online tutor'})
   const [pageNo, setPageNo] = useState(0)

useEffect(() => {
   getAllTutors()
},[filter,pageNo])

const handleFilterChange = (payload) => {
   // console.log('payload is',payload)
   setFilter({...filter,...payload})
}


useEffect(()=>{
   const {data} = search
   setTableData(data)
   setTotalTutorData(search.total)
},[search])


   const getAllTutors = () => {
      dispatch(tutorSearchAction({
         where:filter,
         currentPage:pageNo,
         autopopulate: true,
      }))
   }


    return(
        <>
        <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2" >
                        <i className="mdi mdi-home"></i>
                        </span> Tutor overview 
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="card shadow">
                           <div className="card-body">
                              {/* <div className="bgtodnlist" style={{border:'1px solid red'}}>
                                 <div className="text-start">
                                    <div className="daterangepkr" style={{border:'1px solid red'}}> <span><i className="mdi mdi-calendar-check"></i></span>
                                       <input type="text" id="demo-12" placeholder="start date - end date" className="form-control form-control-sm"/>
                                    </div>
                                 </div>
                                 <div className="dailyTxtbg">
                                    <div className="dailyTxt1">
                                       <button className="btndaten1 active">Daily  </button>
                                       <button className="btndaten1 ">Tomorrow </button>   
                                       <button className="btndaten1">Last 7 Days</button>     
                                       <button className="btndaten1">Last 15 Days </button>    
                                       <button className="btndaten1">Last 30 Days  </button>   
                                       <button className="btndaten1">Last 6 Month </button>    
                                       <button className="btndaten1">Last 1 Year</button>
                                       <button className="btndaten1">All Time</button>
                                    </div>
                                 </div>
                              </div> */}
                              <DateRangeCalendar onSelect={handleFilterChange}/>
                              <div className="tabsnrstl1 tabsnrstl-m4">
                                 <ul className="nav nav-pills mb-3">
                                    <li className="nav-item">
                                       <div className="nav-link">
                                          <div className="stretch-card asigntabnew1 texbntscnt">
                                             <div className="card-img-holder">
                                                <div className="card-body text-center">
                                                   <div className="">
                                                      <h4 className="font-weight-normal mb-3"><span className="valutext ms-0">{totalTutorData}</span></h4>
                                                      <h2 className="mt-0"> Total Online Tutors</h2>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li className="nav-item">
                                       <div className="nav-link">
                                          <div className="stretch-card asigntabnew1">
                                             <div className="card-img-holder">
                                                <div className="card-body">
                                                  
                                                   <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="tutorIcon">
                                                      <img src="/assets/images/online-tutor-img/icon1.svg" className="img-fluid img-icon" alt=""/>
                                                      </span> <span className="valutext">0</span>
                                                   </h4>
                                                   <h2 className="mt-0 text-green">Total Sessions Taken </h2>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li className="nav-item">
                                       <div className="nav-link">
                                          <div className="stretch-card asigntabnew1">
                                             <div className="card-img-holder">
                                                <div className="card-body">
                                                  
                                                   <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="tutorIcon">
                                                      <img src="/assets/images/online-tutor-img/icon1.svg" className="img-fluid img-icon" alt=""/></span> 
                                                      <span className="valutext">0</span>
                                                   </h4>
                                                   <h2 className="mt-0 text-red">  Total Sessions Rejected</h2>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li className="nav-item">
                                       <div className="nav-link">
                                          <div className="stretch-card asigntabnew1">
                                             <div className="card-img-holder">
                                                <div className="card-body">
                                                  
                                                   <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="tutorIcon">
                                                      <img src="/assets/images/online-tutor-img/icon1.svg" className="img-fluid img-icon" alt=""/></span> 
                                                      <span className="valutext">0</span>
                                                   </h4>
                                                   <h2 className="mt-0 text-red">Total Sessions Cancelled</h2>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className="col-12 grid-margin  mt-4">
                                 <div className="card">
                                    <div className="card-body ps-0 pe-0">
                                       <div className="dateinydt">
                                          <div className="row">
                                             <div className="col-md-12" >
                                                <TutorFilters handleFilterChange={handleFilterChange}/>
                                             </div>
                                             <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                   <div className="">
                                                      <h4 className="card-title">Tutor Over view/Online Tutors </h4>
                                                   </div>
                                                   <div className="">
                                                     <button className='btnbluen1' onClick={() => history.push('/create-online-tutor')}>Add Online Tutor</button>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                      <TutorList tabledata={tabledata} pageNo={pageNo} setPageNo={setPageNo} total={totalTutorData}/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
        </>
    )
}

export default TutorContainer;