/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const assginmentSearchAPI = function (data) {
    const url = `${config.baseUrl}assignment/get-assignments`;
    return postRequest({
        url,
        data,
    });
};

export const assginmentCreateAPI = function (data) {
    const url = `${config.baseUrl}assignment`;
    return postRequest({
        url,
        data,
    });
};

export const assginmentUpdateAPI = function ({
    assignmentId,
    data,
}) {
    const url = `${config.baseUrl}assignment/${assignmentId}`;
    return putRequest({
        url,
        data,
    });
};
export async function getSubjects( param ) {
    try {
        const res = await axios.get(config.webUrl + `subsubject/all`)
        return res.data;
    }
    catch(e){

    }
}
export async function getSubjectsData( param ) {
    try {
        const res = await axios.get(config.webUrl + `subsubject/all`)
        return res;
    }
    catch(e){

    }
}
export async function getSubSubject( param ) {
    try {
        const res = await axios.get(config.webUrl + `subsubject/${param}`)
        return res;
    }
    catch(e){

    }
}
export async function getSubSubjectById( data ) {
    const url = `${config.webUrl}subsubject/subsubjectById`;
    return postRequest({
        url,
        data,
    });
}
export async function getBackendExpert( data ) {
    const url = `${config.webUrl}assignment/get-backend-experts`;
    return postRequest({
        url,
        data,
    });
}
export async function assignExpert(data) {
    const url = `${config.webUrl}assignment/assign-expert`;
    return postRequest({
        url,
        data,
    });
}

export async function getAssignmentRequestCounts(){
    try{
        const res = await axios.get(config.baseUrl + `assignment/get-assignments-count`)
        return res.data;
    }
    catch(e){
  console.log('LOG =>', 'Error Fetching Assignment Counts',e.message)
    }
}

export async function getSubjectMasterData( ) {
    try {
        const res = await axios.get(config.baseUrl + `subject/all`)
        return res;
    }
    catch(e){

    }
}
