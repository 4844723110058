import React from 'react'
import SubjectSeoBreadCrumb from './SubjectSeoBreadCrumb'

const SubjectSeoUpdate = () => {
    return (
        <div>
            <SubjectSeoBreadCrumb />
        </div>
    )
}

export default SubjectSeoUpdate