/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';


export const chapterLogSearchAPI = function (data) {
    const url = `${config.baseUrl}chapter/get-error-logs`;
    return postRequest({
        url,
        data,
    });
};