import React, {useEffect, useState} from 'react'
import { Formik, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import FormikTextArea from 'src/components/formik/FormikTextArea';
import { announcementResetAction, announcementUpsertAction } from './actions/AnnouncementActions';

const Categories = ['Session Reschedule', 'New Course Announcement', 'Course Certificate', 'Admin Under Maintanence']
const Render = (props) => {
    const {
        initialValues,
        setFieldValue,
        values,
        // errors,
        // touched,
        onSubmit,
        isSubmitting,
        onSearch,
        onClear,
        resetForm,
    } = props;

    const [categorySelected, setCategorySelected] = useState('') 
  const { userId } = useSelector((state) => state.AuthReducer)


useEffect(() =>{
    if(userId !== undefined && userId !== ''){
        values.createdBy = userId
    }
},[values])

    return (
        <Form>
                                          <div className="row">
                                    <div className="col-md-3 form-group">
                                       <label className="d-flex justify-content-between align-items-center">Select Category</label>
                                       <select className="form-select" onChange={(e) => {
                                        setCategorySelected(e.target.value)
                                        values.category = e.target.value
                                       }}
                                       value={values.category}
                                       >
                                          <option value="">Select Category </option>
                                          {
                                            Categories.map((categs) => {
                                                return (
                                                    <option key={categs} value={categs}>{categs}</option>
                                                )
                                            })
                                          }
                                       </select>
                                    </div>  
                                    <div className="col-md-3 form-group">
                                       <label>Schedule Start</label>
                                       <div className="datepicker" id="datepicker">
                                       <FormikInput
                                    type="datetime-local"
                                    placeholder="Announcement Schedule Start"
                                    name="schedule['startDate']"
                                    className="form-control  enchilada"
                                    value={values.schedule['startDate']}
                                />
                                       </div>
                                    </div> 
                                    <div className="col-md-3 form-group">
                                       <label>Schedule End</label>
                                       <div className="datepicker" id="datepicker">
                                       <FormikInput
                                    type="datetime-local"
                                    placeholder="Announcement Schedule End"
                                    name="schedule['endDate']"
                                    className="form-control  enchilada"
                                    value={values.schedule['endDate']}
                                />
                                       </div>
                                    </div> 
                                    <div className="col-md-12 form-group">
                                       <label> Announcement Title </label>
                                       <FormikInput 
                                        type="text"
                                        placeholder="Enter Announcement Title"
                                        name="announcement_title"
                                        className="form-control  enchilada"
                                        value={values.announcement_title}
                                       />
                                    </div>
                                    <div className="col-md-12 form-group">
                                       <label>Description</label>
                                       <FormikTextArea 
                                    name="announcement_desc"
                                    placeholder="Enter Announcement Description"
                                    rows="5"
                                    className="form-control"
                                    value={values.announcement_desc}
                                />
                                    </div> 
                                 </div>
                                 <div className="text-center"> <button type="submit" className="btn btn-gradient-primary me-2 mb-5" onClick={() => onSubmit(values)}>Post</button></div>
                             
        </Form>
    )
}

const CreateAnnouncementForm=({setPostedAnnouncement,postedAnnouncement})=>{

    const dispatch = useDispatch();
    // const history = useHistory();
    
const {currentRecord} = useSelector((state) => state.AnnouncementReducer)
    
    const onSubmit = useCallback(( 
       values,
       formikUtils,
    ) => {
       dispatch(announcementUpsertAction(values));
       setPostedAnnouncement((prev) => !prev)
    }, [
       dispatch
    ]);
    
    
    const onSearch = () => {
        //
    }

    const onClear = () => {
        //
    }

    // const onSearch = useCallback(() => {
    //    history.push(RoutingUtil.blogSearch());
    // }, [
    //    history,
    // ]);
    // const onClear = useCallback(() => {
    //     dispatch(blogResetAction());
    // }, [dispatch]);
    
    
         return(
                <Formik
                    onSubmit={onSubmit}
                    onClear={onClear}
                    onSearch={onSearch}
                    initialValues={currentRecord}
                    // validationSchema={BlogYupSchema}
                    enableReinitialize
                > 
                    {(formikProps) => (
                        <Render
                            onClear={onClear}
                            onSearch={onSearch}
                            onSubmit={onSubmit}
                            {...formikProps}
                        />
                    )}
                </Formik>
         )
    }
    export default CreateAnnouncementForm