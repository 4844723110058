import {useState, useEffect} from 'react';

export default function  Pagination({...props}) {
    const[pages, setPages] = useState(0);

    useEffect(() => {
        setPages(Math.ceil(props.total / 12));
        return () => {}
    },[props.total])

    const handleClick = (e) => {
        e.preventDefault();
        if(e.target.innerText != 0){
            props.setPageNo(e.target.innerText - 1);
        }
    }

    const handlePrev = (e) => {
        e.preventDefault();
        if(props.pageNo > 0){
            props.setPageNo(props.pageNo - 1)
        }
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(props.pageNo + 1 < pages){
            props.setPageNo(props.pageNo + 1)
        }
    }

    const getPaginationGroup = () => {
        let start = Math.floor((props.pageNo) / 5) * 5;
        return new Array(20).fill().map((_, idx) => start + idx + 1);
    };
    
    return(
        // <div className="col-md-12 mt-4">
        //     <div className="next_prew">
        //         {/* <ul>
        //             <li><a style={{ cursor: 'pointer' }} className="border-left-0 " onClick={handlePrev}>Previous</a></li>                     
        //             {getPaginationGroup().map((e, i) => 
        //                 e<=pages ? <li key={i}>
        //                     <a style={{ cursor: 'pointer' }} className={props.pageNo+1 == e ? 'active':''} onClick={handleClick}>{e}</a>
        //                 </li> : ''
        //             )}
        //             <li><a style={{ cursor: 'pointer' }} onClick={handleNext}>Next</a></li>
        //         </ul> */}
        //         <ul class="pagination"><li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="previous" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next disabled" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="next" tabindex="0" class="page-link">Next</a></li></ul>
        //     </div>
        // </div>

          <ul className="pagination">
            <li className="paginate_button page-item previous" id="DataTables_Table_0_previous" key="Previous">
                <a style={{ cursor: 'pointer' }}   className="page-link" onClick={handlePrev}>Previous</a>
            </li> 
            {getPaginationGroup().map((e, i) => 
                e<=pages ? <li className={`paginate_button page-item ${props.pageNo+1 == e ? 'active':''}`} key={i}>
                              <a  style={{ cursor: 'pointer' }}  className={`page-link ${props.pageNo+1 == e ? 'active':''}`} onClick={handleClick}>{e}</a>
                         </li> : ''
                    )}
           
            <li className="paginate_button page-item next" id="DataTables_Table_0_next">
                <a href="#"  className="page-link" onClick={handleNext} key="Next">Next</a>
            </li>
        </ul>
       
    )
}