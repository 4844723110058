import React from 'react';
import PropTypes from 'prop-types';
import LinkComponent from '../LinkComponent';

const NavigationFooterComponent = ({ logout }) => (
    <div className="sidebar-footer">
        {/* <LinkComponent>
            <i className="fa fa-bell" />
            <span className="badge badge-pill badge-warning notification">3</span>
        </LinkComponent>
        <LinkComponent>
            <i className="fa fa-envelope" />
            <span className="badge badge-pill badge-success notification">7</span>
        </LinkComponent>
        <LinkComponent>
            <i className="fa fa-cog" />
            <span className="badge-sonar" />
        </LinkComponent> */}
        <LinkComponent
            onClick={logout}
        >
            <i className="fa fa-power-off" />
        </LinkComponent>
    </div>
);

NavigationFooterComponent.propTypes = {
    logout: PropTypes.func.isRequired,
};

export default NavigationFooterComponent;
