import React from 'react';
import { getSubjectsData } from 'src/api/Assignment';
import FormikSearchDropdown from 'src/components/formik/FormikSearchDropdown/FormikSearchDropdown';
import FormikMultiSelectDropdown from 'src/components/formik/FormikMultiSelectDropdown/FormikMultiSelectDropdown';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import PropTypes from 'prop-types';
import { getSubSubject } from 'src/api/Assignment';

const SubjectDropDown = ({ value, name, onChange,...rest }) => (
    <FormikSearchDropdown
        name={name}
        placeholder="Select Subject"
        displayKeyPath={[
            'subject',
        ]}
        valueKeyPath="_id"
        defaultValueFetchByKeyPath="_id"
        whereClauseKeysPaths={[
            'subject',
        ]}
        api={getSubjectsData}
        apiCallAddonData={{
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
        }}
        value={value}
        onChange={onChange} 
        {...rest}
    />
    // <FormikMultiSelectDropdown
    //     onChange={onChange}
    //     name={name}
    //     placeholder="Select Subject"
    //     displayKeyPath={[
    //         // 'subject',
    //         'sub_subject'
    //     ]}
    //     whereConditions={whereConditions}
    //     valueKeyPath="sub_subject"
    //     defaultValueFetchByKeyPath="misc"
    //     whereClauseKeysPaths={[
    //         whereConditions,
    //         // 'sub_subject'
    //     ]}
    //     // api={getSubjectsData}
    //     api={api}
    //     apiCallAddonData={{
    //         pageLength: SEARCH_RECORD_PAGE_LIMIT,
    //     }}
    //     value={value}
    //     disabled={readOnly}
    //     {...rest}
    // />
);

SubjectDropDown.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

SubjectDropDown.defaultProps = {
    value: '',
    readOnly: false,
};

export default SubjectDropDown;
