import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import BlogList from './BlogList';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import DateRangeCalendar from 'src/components/DateRangeCalendar';
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { blogSearchAction } from './actions/BlogActions';
import AuthorDropDown from 'src/business-components/AuthorDropDown';
import CategoryDropDown from 'src/business-components/CategoryDropDown';
 

const Render=(props)=>{
   const {
      values,
      onSearch,
      onClear,
      resetForm,
  } = props;

const history = useHistory();
   // REDUX
const dispatch = useDispatch();
const {
 search,
} = useSelector((state) => state.BlogReducer, shallowEqual);

const [filter,setBlogFilter] = useState({isPublish:true});
const[pageNo,setPageNo] = useState(0);
const [isDraft,setIsDraft] = useState(false);
const [groupCounter,setGroupCounter] = useState({totalBlog:0,totalPublish:0,totalPopular:0,totalUnpopular:0});


const getBlogs=async()=>{
  dispatch(blogSearchAction({
    where: filter,
    currentPage: pageNo,
    autopopulate:true
})); 
}
const handelFilterChange=(payload)=> {
   setBlogFilter({...filter,...payload})}

useEffect(()=>{
   getBlogs();
},[filter,pageNo])

useEffect(()=>{
   const {data} = search;
   const totalBlog = data.length;

   const publish = data.filter((dt) => dt.isPublish === true).length;
   const popular = data.filter((dt) => dt.isPopuler=== true).length;
   const unPopular =(totalBlog-popular);
   setGroupCounter({totalBlog:totalBlog,totalPublish:publish,totalPopular:popular,totalUnpopular:unPopular});    
},[search])

return(
   <>
<div className="content-wrapper">
<div className="page-header">
 <h3 className="page-title">Blogs</h3>
 <div className="">
    <button type="button" className="btn bg-gradient-primary btn-rounded text-white btn-fw" onClick={()=>{history.push('/blogs/manage')}}>Create New Blogs</button>
    <button type="button" className="btn bg-gradient-outline btn-rounded btn-fw" onClick={()=>{
      setBlogFilter({...filter,isPublish:isDraft})
      setIsDraft(!isDraft)
   }
      }> {`${isDraft?'View Publish Blogs':'View Draft Blogs'}`} </button>
    {/* <button type="button" className="btn bg-gradient-outline btn-rounded btn-fw" onClick={()=>{history.push('/blogs/search')}}>View Draft Blogs (12)</button> */}
 </div>
</div>
<div className="row">
 <div className="col-md-12">
    <div className="card shadow">
       <div className="card-body">
          <div className="tabsnrstl1">
             <div className="bloXBg">
                <div className="bloX">
                   <p>Total Blogs Posted </p>
                   <div className="bloXPst">
                      <h5>{groupCounter.totalBlog}</h5>
                      <div className=""><i className="mdi mdi-trending-up"></i> +15% </div>
                   </div>
                </div>
                <div className="bloX">
                   <p>Total Blogs Published </p>
                   <div className="bloXPst">
                      <h5>{groupCounter.totalPublish}</h5>
                      <div className=""><i className="mdi mdi-trending-up"></i> +15% </div>
                   </div>
                </div>
                <div className="bloX">
                   <p>Total Blogs Popular</p>
                   <div className="bloXPst">
                      <h5>{groupCounter.totalPopular}</h5>
                      <div className=""><i className="mdi mdi-trending-down"></i> +15% </div>
                   </div>
                </div>
                <div className="bloX">
                   <p>Total Un Popular </p>
                   <div className="bloXPst">
                      <h5>{groupCounter.totalUnpopular}</h5>
                      <div className=""><i className="mdi mdi-trending-down"></i> +15% </div>
                   </div>
                </div>
             </div>
          </div>
          <div className="dateinydt">
             <div className="row">
                <div className="col-md-12">
                   <div className="adtin5">
                      <h4 className="card-title">Audience Overview  </h4>
                   </div>
                   <DateRangeCalendar onSelect={handelFilterChange}/>
                   {/* <div className="">
                      <canvas id="areachart-multi" ></canvas>
                   </div> */}
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 <Form className="forms-sample">
 <div className="col-12 grid-margin  mt-4">
    <div className="card shadow">
       <div className="card-body">
          <div className="psttabblog">
             <h4 className="card-title">Posted Bloags Details</h4>
             <div className="psttabblog1"> 
                  <AuthorDropDown 
                  name="authorId"                  
                  value={values.authorId}
                  onChange={(id)=>{ 
                  handelFilterChange({authorId:id})
                  }}
                  /> 
                <CategoryDropDown
                     name="category"
                     className="form-select"
                     whereConditions={{type:"category"}}
                     value={values.categoryId}
                     onChange={(id)=>{ 
                        handelFilterChange({categoryId:id})
                     }}
                  />
                {/* <div className="text-start">
                   <div className="daterangepkr"> 
                      <span>
                          <i className="mdi mdi-calendar-check"></i>
                      </span>
                       <DateTimePickerComponent  id="demo-13" className="form-control form-control-sm" format="YYYY-MM-DD" /> 
                   </div>
                </div> */}
                {/* </Formik> */}
             </div>
          </div>
          <div className="postablnewx">
             <BlogList blogs={search} setPageNo={setPageNo} pageNo={pageNo} history={history} dispatch={dispatch}/>
          </div>
       </div>
    </div>
 </div>
 </Form>
</div>
</div>
</>
)
}
Render.propTypes = {     
    values: PropTypes.shape({
      authorId: PropTypes.string,
      category: PropTypes.string,
        // lat: PropTypes.number,
        // lng: PropTypes.number,
    }).isRequired,
};


const BlogSearch=()=>{
   return (
      <Formik
      initialValues={{}}
      enableReinitialize
      >
         {(formikProps) => (
                    <Render
                        // onClear={onClear}
                        // onSearch={onSearch}
                        {...formikProps}
                    />
                )}
      </Formik>
   )
}

export default BlogSearch