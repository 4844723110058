import Pagination from "src/components/Pagination";
import moment from "moment";

const AllAskQnA=(props)=>{
   const {
      search:{data},
      search,
      activeTab,
      setPageNo,
      pageNo,
      editClick
     } = props;
 return (
    <div className={`tab-pane fade ${(activeTab=="ask")?"show active":""}`} id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
      <div className="card-body ps-0 pe-0">
      <div className="table-responsive">
                              <table className="table table-center datatablefnts datatable">
                                 <thead>
                                    <tr>
                                       <th>S. No   </th>
                                       <th>Created Date  </th>
                                       <th>Question ID</th>
                                       <th>Subject </th>
                                       <th>Sub Subject</th>
                                       <th>Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {
                                       data.map((question,index)=>(
                                                <tr key={index}>
                                                   <td>{(index+1)}</td>
                                                   <td>{moment(question.created_at).format("MMMM DD, YYYY HH:mm:ss")}    </td>
                                                   <td>{question._id}</td>
                                                   <td>{question.subject}</td>
                                                   <td>{question.sub_subject} </td> 
                                                   <td>
                                                      <div className="text-bold"> 
                                                         <button className="btnbluen1 plwhit" onClick={()=>{editClick(question)}} >Edit </button>
                                                      </div>
                                                   </td>
                                                </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
      {data && data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>} 
      </div>
   </div>
 )
}
export default AllAskQnA;