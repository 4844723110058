/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { getRequest } from 'src/utils/RequestUtil';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const questionSearchAPI = function (data) {
    const url = `${config.baseUrl}question/search`;
    return postRequest({
        url,
        data,
    });
};
export const questionEditSearchAPI = function (data) {
    const url = `${config.baseUrl}question/editSearch`;
    return postRequest({
        url,
        data,
    });
};
export const questionCountAPI = async function ({starDate,endDate,reqSource}) {
    const url = `${config.baseUrl}master-admin/dashboard-statics2/${starDate}/${endDate}/${reqSource}`;
    // const params = {starDate,endDate,reqSource}; 
            return getRequest({
                url,
                // params,
                // headers
            })
};

export const questionCreateAPI = function (data) {
    const url = `${config.baseUrl}question/save`;
    return postRequest({
        url,
        data,
    });
};
export const questionUploadAPI = function (data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}question/bulk-questions`;
    return postRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const questionUpdateAPI = function ({
    questionId,
    data,
}) { 
    const url = `${config.baseUrl}question/update/${questionId}`;
    return putRequest({
        url,
        data,
    });
};

export const questionBuklApproveAPI=(data)=>{
    const url =`${config.baseUrl}question/bulkApproved`;
    return putRequest({
        url,
        data,
    });
}
export async function getBlogMaster( data ) {
        const url = `${config.baseUrl}question/questionMaster`;
        return postRequest({
            url,
            data,
        });   
}
export async function getBlogAuthor( data ) {
    const url = `${config.baseUrl}question/questionAuthor`;
    return postRequest({
        url,
        data,
    });   
}
