import React, { useEffect, useState } from 'react'
import { getSubjects } from 'src/api/Assignment'

const Services = [
    'Assignment Writing Services',
    'Assignment Proofreading Services',
    'Assignment Editing Services',
    'Assignment Graphic Designing Services'
]
const TutorFilters = ({handleFilterChange}) => {

const [subjectArr, setSubjectArr] = useState([])

useEffect(() => {
    getAllSubjects()
},[])

const getAllSubjects = async() => {
    const {data} = await getSubjects()
     setSubjectArr(data)
}


    return (
        <>
          <div className="scscm21 scscm21-step5">
                                                   <div className="">
                                                      <select className="form-select" onChange={(e) => handleFilterChange({'services':{$elemMatch:{service:e.target.value,is_service:true}}})}>
                                                         <option value=''>Select Service Type</option>
                                                         {
                                                            Services.map((item) => {
                                                                return (
                                                                    <option value={item} key={item}>{item}</option>
                                                                )
                                                            })
                                                         }
                                                      </select>
                                                   </div>
                                                   <div className="">
                                                      <select className="form-select" onChange={(e)=>handleFilterChange({'SubjectExpertise.name':e.target.value})}>
                                                         <option>Select Subject</option>
                                                         {
                                                            subjectArr.map((item) => {
                                                                return (
                                                                    <option value ={item.subject} key={item._id}>{item.subject}</option>
                                                                )
                                                            })
                                                         }
                                                      </select>
                                                   </div>
                                                   {/* <div className="">
                                                      <select className="form-select">
                                                         <option>Start  Time </option>
                                                      </select>
                                                   </div> */}
                                                   {/* <div className="">
                                                      <select className="form-select">
                                                         <option>Master subject</option>
                                                      </select>
                                                   </div> */}
                                                   <div className="">
                                                      <select className="form-select" onChange={(e)=>handleFilterChange({'gender':e.target.value})}>
                                                         <option>Select Gender</option>
                                                         <option value='Male'>Male</option>
                                                         <option value='Female'>Female</option>
                                                      </select>
                                                   </div>
                                                </div>
        </>
    )
}

export default TutorFilters