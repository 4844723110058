export const QUESTION_ACTIONS = {
    QUESTION_SEARCH: 'QUESTION_SEARCH',
    QUESTION_SEARCH_SUCCESS: 'QUESTION_SEARCH_SUCCESS',
    QUESTION_SEARCH_ERROR: 'QUESTION_SEARCH_ERROR',

    QUESTION_COUNT: 'QUESTION_COUNT',
    QUESTION_COUNT_SUCCESS: 'QUESTION_COUNT_SUCCESS',
    QUESTION_COUNT_ERROR: 'QUESTION_COUNT_ERROR',

    QUESTION_UPSERT: 'QUESTION_UPSERT',
    QUESTION_UPSERT_SUCCESS: 'QUESTION_UPSERT_SUCCESS',
    QUESTION_UPSERT_ERROR: 'QUESTION_UPSERT_ERROR',

    QUESTION_EDIT: 'QUESTION_EDIT',
    QUESTION_APPROVED: 'QUESTION_APPROVED',
    QUESTION_APPROVED_SUCESS: 'QUESTION_APPROVED_SUCESS',
    QUESTION_APPROVED_ERROR: 'QUESTION_APPROVED_ERROR',

    QUESTION_RESET: 'QUESTION_RESET',
};

export const questionResetAction = () => ({
    type: QUESTION_ACTIONS.QUESTION_RESET,
});

export const questionSearchAction = (payload) => ({
    type: QUESTION_ACTIONS.QUESTION_SEARCH,
    ...payload,
});

export const questionEditAction = (payload) => ({
    type: QUESTION_ACTIONS.QUESTION_EDIT,
    ...payload,
});

export const questionUpsertAction = (payload) => ({
    type: QUESTION_ACTIONS.QUESTION_UPSERT,
    ...payload,
});
export const questionCountAction = (payload) => ({
    type: QUESTION_ACTIONS.QUESTION_COUNT,
    ...payload,
});
export const questionBuklApproveAction=(payload)=>({
    type:QUESTION_ACTIONS.QUESTION_APPROVED,
    ...payload
})

export default {
    QUESTION_ACTIONS,
    questionSearchAction,
    questionCountAction,
    questionUpsertAction,
    questionEditAction,
    questionBuklApproveAction,
};
