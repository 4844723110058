import { useEffect } from "react";
import { useCallback } from "react";
import { shallowEqual,useDispatch, useSelector } from "react-redux";
import { enquirySearchAction, enquiryUpsertAction } from "./actions/EnquiryActions";
import { useState } from "react";
import Pagination from "src/components/Pagination";


const SOPList=()=>{
   const dispatch = useDispatch();
   const [searchInput, setSearchInput] = useState('')
   const {
      SOP:{
         search,
         search:{
            data,
            totalPending,
            totalNotConverted,
            totalConverted,
         }
      }
   }=useSelector((state)=>state.EnquiryReducer,shallowEqual);
    const [filter,setFilter] = useState({});
    const[pageNo,setPageNo] = useState(0);
    const onSearch = useCallback(()=>{
         dispatch(enquirySearchAction({
            where:filter,
            searchType:"SOP",
            currentPage: pageNo,
         }));
    },
    [pageNo,filter])
    const handleStatus=useCallback((id,status)=>{
      const values = {_id:id,enquiryStatus:status,upsertType:"SOP"}
         dispatch(enquiryUpsertAction(values))
         onSearch();
    }
    ,[])
   const handelFilterChange=(filterType,filterValue)=>{
     let newFilter; 
       if(filterType=="status")
       {
         switch(filterValue)
         { 
            case 'pending':
               newFilter={enquiryStatus:0}
               break
            case 'notConverted':
               newFilter={enquiryStatus:2}
               break
            case 'converted':
               newFilter={enquiryStatus:1}
               break
            default:
               setFilter({});
            return;
         }
       }
       else if(filterType=="text")
       {
         newFilter= {
          $or:[
               {Name:{$regex:filterValue,$options:"i"}},
               {Email:{$regex:filterValue,$options:"i"}} 
          ]
         }
       }
       const updatedFilter = { ...filter, ...newFilter };
       setFilter(updatedFilter);
   }
    useEffect(()=>{
      onSearch();
    },[filter])
   
   
    
   
    useEffect(() => {
      if (searchInput) {
      const delayDebounceFn = setTimeout(() => {
         handelFilterChange('text',searchInput);
      }, 500);
      return () => {
        clearTimeout(delayDebounceFn);
      };
   }
    }, [searchInput]);
   

    
  return(
    <>
     <div className="content-wrapper">
   <div className="page-header">
      <div className="">
         <h4 className="page-title"> SOP Enquiry
         </h4>
         <div className="top-breadcrumb">
           <nav aria-label="breadcrumb">
               <ol className="breadcrumb ps-0">
                  <li className="breadcrumb-item"><a href="#">Enquiries</a></li> 
                  <li className="breadcrumb-item active" aria-current="page">SOP Assistant</li>
               </ol>
            </nav>
         </div>
      </div>     
   </div>
   <div className="row">
      <div className="col-md-12">          
            <div className="card">
               <div className="card-body">
                  <div className="txtUllistbg">
                     <div className="txtUlauQ" style={{cursor:'pointer'}} onClick={()=>{handelFilterChange('status','all')}}>
                        <h2>{totalPending+totalNotConverted+totalConverted}</h2>
                        <p><i className="mdi mdi-help-circle "></i> All Requested Query</p>
                     </div>
                     <div className="txtUllist">
                        <ul>
                           <li>
                              <div className="" style={{cursor:'pointer'}} onClick={()=>{handelFilterChange('status','pending')}}><i className="mdi mdi-help-circle"></i> Enquiry Pending</div>
                              <span>{totalPending}</span>
                           </li>
                           <li>
                              <div className="" style={{cursor:'pointer'}} onClick={()=>{handelFilterChange('status','notConverted')}}><i className="mdi mdi-help-circle"></i> Not Converted</div>
                              <span>{totalNotConverted}</span>
                           </li>
                           <li>
                              <div className="" style={{cursor:'pointer'}} onClick={()=>{handelFilterChange('status','converted')}}><i className="mdi mdi-help-circle"></i> Converted</div>
                              <span>{totalConverted}</span>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         <div className="col-md-12">
            <div className="card shadow mtcustom3">
               <div className="card-body">
                  <div className="tabsnrstl1 tabsnrstl-g2">
                     <div className="col-md-12">
                        <div className="scscm21 scscm21-step2 mtcustom4">
                           <div className="searchBar">
                              <input type="search" placeholder="Search Enquiry by Name and Email" onChange={(e)=>{setSearchInput(e.target.value)}}  className="form-control"/>
                              <button><i className="mdi mdi-magnify"></i></button>
                           </div>
                           <div className="d-flex align-items-center justify-content-end datewfiltr">
                              <div className="me-2"><button className="flitrbtn" data-bs-toggle="modal" data-bs-target="#filter-Modal"><i className="mdi mdi-filter-outline mdi-24px"></i></button></div>
                              <div className="ms-2"><button className="flitrbtn active"><i className="mdi mdi-filter-variant mdi-24px"></i></button></div>
                           </div>
                        </div>
                     </div>                    
                  </div>
                  <div className="tab-content" id="pills-tabContent">
                     <div className="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-tab1">
                        <div className="col-12 grid-margin  mt-4">
                           <div className="">
                              <div className="table-responsive">
                                 <table className="table table-center datatablefnts datatable">
                                    <thead>
                                       <tr>
                                          <th>S. No </th>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Service Type</th>
                                          <th>Sub Service</th>
                                          <th>Created At </th>
                                          <th>Status</th> 
                                          <th> Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                    {
                                          data.map((enquiry,index)=>{
                                             return (
                                                   <tr key={index}>
                                                         <td><span className="sNo">{++index}</span> </td>
                                                         <td>{enquiry.Name}</td>
                                                         <td>{enquiry.Email} </td>
                                                         <td>{enquiry.serviceType}</td>
                                                         <td>{enquiry.subService}</td>
                                                         <td>{enquiry.created_at}</td>
                                                         <td>
                                                         {
                                                            enquiry.enquiryStatus==1 ? (
                                                               <div className="callphnwhaaap">
                                                               <button className="phobnt1">Converted</button>
                                                           </div>
                                                            ) : (
                                                               enquiry.enquiryStatus == 0 ? (
                                                               <div className="callphnwhaaap">
                                                                  <button className="pending">Pending</button>
                                                              </div>
                                                               ) : (
                                                               <div className="callphnwhaaap">
                                                                  <button className="rejected"> 
                                                                     Rejected
                                                                  </button>
                                                               </div>
                                                               )
                                                            )
                                                         }
                                                         </td>
                                                      <td>
                                                         <div className="ocial-Btn">
                                                            <div className="dropdown">
                                                               <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                               <i className="mdi mdi-dots-vertical"></i>
                                                               </button>
                                                               <ul className="dropdown-menu dropdown-menu-end">
                                                                  <li>
                                                                     {
                                                                        enquiry.enquiryStatus==0?
                                                                        (
                                                                      <>
                                                                        <a className="dropdown-item" href="javascript:void(0)" onClick={()=> handleStatus(enquiry._id,1)}>Converted</a>
                                                                        <a className="dropdown-item" href="javascript:void(0)" onClick={()=> handleStatus(enquiry._id,2)}>Rejected</a>
                                                                      </>
                                                                        ) :
                                                                        null
                                                                     }
                                                                     
                                                                  </li>
                                                                  {/* <li><a className="dropdown-item" href="#">Rejected</a></li> */}
                                                               </ul>
                                                            </div>
                                                         </div>
                                                      </td>
                                                   </tr>
                                             )
                                          })
                                       }                                    
                                    </tbody>
                                 </table>
                                 {data && data?.length>0 && <Pagination data={data} setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>}
                              </div>
                           </div>
                        </div>
                     </div> 
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
 
    </>
  )
     
}
export default SOPList;