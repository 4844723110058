import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { deleteRoleMaster, getAllMasterRoles } from 'src/api/MastersApi'
import RoutingUtil from 'src/utils/RoutingUtil'

const RoleMaster = () => {

const history = useHistory()
const params = useParams()
const [totalEntries, setTotalEntries] = useState(0)   
const [roleArr, setRoleArr] = useState([]) 

useEffect(() => {
getAdminList()
},[])

const getAdminList = async() => {
    const res = await getAllMasterRoles()
    setTotalEntries(res.data.total)
    setRoleArr(res.data.data)
} 

const handleDeleteRole = async(id) => {
const removeRole = await deleteRoleMaster(id)
if(removeRole){
    history.push(RoutingUtil.roleMasterUrl())
}
}
    
    return (
        <React.Fragment>
            <div className='row p-4'>
            <div className='col-md-12'>
                <h1>Role List</h1>
            </div>
            <div className='col-md-12 mt-2'>
            <button className='btnbluen1' onClick={() => history.push(RoutingUtil.roleCreateUrl())}>Add New Role</button>
            </div>
            <div className='col-md-12 mt-3'>
            <div className="postablnewx mt-1"> 
            <div className="table-responsive">
								   <table className="table datatablefnts datatable">
                                    <thead>
                                       <tr>
                                          <th>S.no </th>
                                          <th>Role </th>
                                          <th>Role_id </th>
                                          <th>Status </th>   
                                          <th>Created On</th>  
                                          <th>Description</th>
                                          <th>View Role Module</th>
                                          <td>Edit</td>
                                          <td>Delete</td>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                        roleArr.map((role, idx) => {
                                            return (
                                                <tr key={role._id}>
                                                    <td>{idx+1}</td>
                                                    <td>{role.name}</td>
                                                    <td>{role.role}</td>
                                                    <td>{role.status === true ? 'Active' : 'Inactive'}</td>
                                                    <td>{role.created_at.split('T')[0]}</td>
                                                    <td>
                                                        <div>
                                                            {role.description}
                                                        </div>
                                                        </td>
                                                    <td className='text-center'>
                                                        <span className='mdi mdi-lock text-warning'></span>
                                                    </td>
                                                    <td>
                                                        <span className='mdi mdi-tooltip-edit text-success' style={{fontSize:'1.5rem'}}
                                                        onClick={() => history.push(`/masters/role-update/${role._id}`)}
                                                        ></span>
                                                    </td>
                                                    <td className='text-center'>
                                                        <span className='mdi mdi-delete text-danger' style={{fontSize:'1.5rem'}} onClick={() => handleDeleteRole(role._id)}></span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                       }
                                    </tbody>
                                 </table> 
                              </div>        </div>
            </div>
            </div>
        </React.Fragment>
    )
}

export default RoleMaster