import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getSubSubject, getSubjects } from 'src/api/Assignment'
import { countries } from "countries-list"
import { MakeSlug, convertBase64 } from 'src/utils/CommonUtils';
import FormikInput from 'src/components/formik/FormikInput';
import CountrySelect from 'src/business-components/CountrySelect';
import FormikTextArea from 'src/components/formik/FormikTextArea';
import FormikFileDirect from 'src/components/formik/FormikFileDirect';
import { expertResetAction, expertUpsertAction } from './actions/ExpertActions';
import CountryFormikSelect from 'src/business-components/CountryDropdown';
import ExpertSubjectDropdown from 'src/business-components/SingleSubjectDropdown';
import ExpertiseDropdown from 'src/business-components/ExpertiseDropdown';
import MultiSubjectDropdown from 'src/business-components/MultiSubjectDropdown';
import { expertUpdateAPI } from 'src/api/ExpertApi';
import { useHistory } from 'react-router-dom';


const genderArr = [
   {
      id: 1,
      name: 'Male'
   },
   {
      id: 2,
      name: 'Female'
   },
   {
      id: 3,
      name: 'Others'
   }
]

const Render = (props) => {
   const [languageInput, setLanguageInput] = useState([''])
   const [isDraft, setIsDraft] = useState(false)
   const [subjectArr, setSubjectArr] = useState([])
   const [expertiseSubs, setExpertiseSubs] = useState([])
   const [bankDetails, setBankDetails] = useState({
      name:'',
      bankName:'',
      accNo:'',
      branch:'',
      ifsc:'',
      pan:'',
      panImg:''

   })
   const [educationDetails, setEducationdetails] = useState([
      {
         degrees: '',
         year: '',
         percentage: 0,
         subject: [
            {
               name:'',
               id:''
            }
         ]
      }
   ])
   const [experienceDetails, setExperienceDetails] = useState([
      {
         companyName:'',
         designation:'',
         specializationSubject:'',
         startDate:'',
         endDate:'',
         isCurrentlyWorking:false
      }
   ])
   const [addArr, setAddArr] = useState([{
      subject:'',
      subSubject:[]
   }])
   const [expertiseArr, setExpertiseArr] = useState([])

   const [services, setServices] = useState([
      {
         service: 'Assignment Writing Services',
         id: 1,
         rate: '',
         proofreading: '',
         is_proofreading: false,
         is_service: false
      },
      {
         service: 'Assignment Proofreading Services',
         id: 2,
         rate: '',
         proofreading: '',
         is_proofreading: false,
         is_service: false
      },
      {
         service: 'Assignment Editing Services',
         id: 3,
         rate: '',
         proofreading: '',
         is_proofreading: false,
         is_service: false
      },
      {
         service: 'Assignment Graphic Designing Services',
         id: 4,
         rate: '',
         proofreading: '',
         is_proofreading: false,
         is_service: false
      }
   ])

   const [imagePre, setImagePre] = useState("/assets/images/super-admin/home/icon.svg");
   const [badgeChecked, setBadgeChecked] = useState(false)
   const {
      initialValues,
      values,
      // errors,
      // touched,
      onSubmit,
      isSubmitting,
      onSearch,
      onClear,
      resetForm,
   } = props;

   useEffect(() => {
      getAllSubjects()
   }, [])


   // useEffect(() => {
   //    if (values.Subject !== []) {
   //       getExpertiseArr()
   //       setExpertiseSubs([])
   //    }
   // }, [values.Subject])

   // const getExpertiseArr = async () => {
   //    setExpertiseArr([])
   //    const { data } = await getSubSubject(MakeSlug(values.Subject))
   //    const getSubjectID = subjectArr.filter((item) => item.subject === values.Subject)
   //    values.SubjectId = getSubjectID[0]._id
   //    data.data.forEach((item) => {
   //       setExpertiseArr((prev) => [...prev, {
   //          label: item?.sub_subject, value: item?.sub_subject,id:item?._id
   //       }])
   //    })
   // }

   const handleSetsubject = async(evtdata,idx) => {
      const { data } = await getSubSubject(MakeSlug(evtdata))
      const tempArr = []
      // const getSubjectID = subjectArr.filter((item) => item.subject === values.Subject)
      // values.SubjectId = getSubjectID[0]._id
      data.data.forEach((item) => {
         tempArr.push({label: item?.sub_subject, value: item?.sub_subject,id:item?._id})
         // values.subSubject = {label: item?.sub_subject, value: item?.sub_subject,id:item?._id}
         // setExpertiseArr((prev) => [...prev, {
         //    label: item?.sub_subject, value: item?.sub_subject,id:item?._id
         // }])
      })
      values.subSubject[idx] = tempArr 
      console.log('values changing is',values)
   }


   const handleBankDetailsChange = (e,key) => {
      setBankDetails({...bankDetails,[key]:e.target.value})
      values.bankDetails = bankDetails
   }

   const handelImagePreview = async (e) => {
      const file = e.target.files[0];
      const img64 = await convertBase64(file)
      setImagePre(img64)
      values.img_path = file
      console.log('upload binary is',file)
   }


   const handleEducationDetailsArrChange = (e, idx, key) => {
      if(key === 'percentage'){
         setEducationdetails((prev) => {
            const arr = [...prev]
            arr[idx][key] = parseInt(e.target.value)
            return arr
         })
      } else if(key === 'subject'){
         setEducationdetails((prev) => {
            const arr = [...prev]
            arr[idx][key] = [e.target.value]
            return arr
         })
      }else {
         setEducationdetails((prev) => {
            const arr = [...prev]
            arr[idx][key] = e.target.value
            return arr
         })
      }
   
      values.education = educationDetails
   }

   const handleEducationClick = () => {
      setEducationdetails((prev) => [...prev, {
         degrees: '',
         year: '',
         percentage: 0,
         subject: []
      }])
   }

   const getAllSubjects = async () => {
      const { data } = await getSubjects()
      setSubjectArr(data)
   }

const handleExperienceDetailsChange = (e,idx,key) => {
if(key === 'isCurrentlyWorking'){
   setExperienceDetails((prev) => {
      const arr = [...prev]
      arr[idx][key] = e.target.checked
      if(e.target.checked === true){
         arr[idx]['endDate'] = 'Present'
      }
      return arr
   })
}
else {
   setExperienceDetails((prev) => {
      const arr = [...prev]
      arr[idx][key] = e.target.value
      return arr
   })
}
values.experience = experienceDetails
}


   return (
      <Form>
         <>
            <div>
               <div className="content-wrapper">
                  <div className="page-header">
                     <h4 className="page-title"> Create Front Experts
                     </h4>
                  </div>
                  <div className="row">
                     <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                           <div className="card-body">
                              <div className='row'>
                                 <div className="col-md-12 form-group">
                                    <h4>Fill experts Details </h4>
                                 </div>
                                 {
                                    addArr.map((item,idx) => {
                                       return (
                                          <>
                                           <div className='col-md-6 form-group'>
                                            <label>Select Subject</label>
                                             <ExpertSubjectDropdown
                                          items={subjectArr}
                                          name={`Subject[${idx}]`}
                                          placeholder={'Select Subject'}
                                          className="form-control"
                                          values={values}
                                          idx={idx}
                                          onChange = {
                                             (data) => {
                                                handleSetsubject(data,idx)
                                             }
                                          }
                                       /> 
                                       </div>
                                       <div className='col-md-6 form-group'>
                                       <label>Select Sub Subject</label>
                                           <ExpertiseDropdown
                                       name={`subSubjectObj[${idx}]`}
                                       className="form-control"
                                       placeholder={'Select Sub Subject'}
                                       options={values?.subSubject?.[idx] }
                                       idx={idx}
                                       values={values}
                                    />
                                    </div>
                                          </> 
                                       )
                                    })
                                 }
                                <div className='col-md-12'>
                                 <button type="button" className="btn bg-gradient-outline btn-rounded mt-2 mb-4" style={{width:'15%'}} onClick={()=>
                                 {
                                    setAddArr((prev) => [...prev,{
                                       subject:'',
                                       subSubject:[]
                                    }])
                                    values.SubjectExpertise.push({
                                       name:'',
                                       id:'',
                                       sub_subject:[]
                                    })
                                    // values.subSubject.push(
                                    //    {label: '', value: '',id:''}
                                    // )
                                 }
                                 }>Add</button>
                                 </div>
                                 {/* <div className="col-md-6 form-group">
                                    <label>Subject </label>
                                    <ExpertSubjectDropdown
                                       items={subjectArr}
                                       name="Subject"
                                       placeholder={'Select Subject'}
                                       className="form-control"
                                    />
                                 </div>
                                 <div className='col-md-6 form-group'>
                                    <label>Expertise </label>
                                    <ExpertiseDropdown
                                       name="subSubject"
                                       className="form-control"
                                       placeholder={'Select Sub Subject'}
                                       options={expertiseArr}
                                       values={values}
                                    />
                                 </div> */}
                                 <div className="col-md-6 form-group">
                                    <label>Experts Name</label>
                                    <FormikInput
                                       type="text"
                                       name="name"
                                       className="form-control"
                                       placeholder='Enter name'
                                    />
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <label>Email</label>
                                    <FormikInput
                                       type="text"
                                       name="email"
                                       className="form-control"
                                       placeholder='Enter name'
                                    />
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <label>Contact</label>
                                    <FormikInput
                                       type="text"
                                       name="Contact"
                                       className="form-control"
                                       placeholder='Enter name'
                                    />
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <label>Password</label>
                                    <FormikInput
                                       type="password"
                                       name="password"
                                       className="form-control"
                                       placeholder='Enter name'
                                    />
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <label>Address</label>
                                    <FormikInput
                                       type="text"
                                       name="address"
                                       className="form-control"
                                       placeholder='Enter name'
                                    />
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <label>Gender </label>
                                    <CountrySelect
                                       items={genderArr}
                                       name="gender"
                                       className="form-control"
                                    />
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <label>Country </label>
                                    <CountrySelect
                                       items={countries}
                                       name="country"
                                       className="form-control"
                                    />
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <label>Experience(in years) </label>
                                    <FormikInput
                                       type="number"
                                       name="totalExperience"
                                       className="form-control"
                                       placeholder='Enter Experience'
                                    />
                                 </div>
                              </div>
                              <div className="UniversityEnsadd row">
                                 <div className="col-md-12 form-group mb-2">
                                    <h4>Enter Bank Details  </h4>
                                 </div>
                                          <div className='row'>
                                             <div className="col-md-3 form-group">
                                                <label>Account Holder's Name</label>
                                                <input type="text" className="form-control" placeholder="Enter Subject"
                                                value={bankDetails.name}
                                                   onChange={(e) => {
                                                      handleBankDetailsChange(e,'name')
                                                      // handleEducationDetailsArrChange(e, idx, 'subject')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].subject = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div>
                                             <div className="col-md-3 form-group">
                                                <label>Bank Name </label>
                                                <input type="text" className="form-control" placeholder="Enter Percentage"
                                                value={bankDetails.bankName}
                                                   onChange={(e) => {
                                                      handleBankDetailsChange(e,'bankName')
                                                      // handleEducationDetailsArrChange(e, idx, 'percentage')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].percentage = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div>
                                             <div className="col-md-3 form-group">
                                                <label>Account No. </label>
                                                <input type="number" className="form-control" placeholder="Enter Subject"
                                                value={bankDetails.accNo}
                                                   onChange={(e) => {
                                                      handleBankDetailsChange(e,'accNo')
                                                      // handleEducationDetailsArrChange(e, idx, 'subject')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].subject = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div>
                                             <div className="col-md-3 form-group">
                                                <label>Branch </label>
                                                <input type="text" className="form-control" placeholder="Enter Subject"
                                                value={bankDetails.branch}
                                                   onChange={(e) => {
                                                      handleBankDetailsChange(e,'branch')
                                                      // handleEducationDetailsArrChange(e, idx, 'subject')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].subject = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div>
                                             <div className="col-md-3 form-group">
                                                <label>IFSC Code </label>
                                                <input type="text" className="form-control" placeholder="Enter Subject"
                                                value={bankDetails.ifsc}
                                                   onChange={(e) => {
                                                      handleBankDetailsChange(e,'ifsc')
                                                      // handleEducationDetailsArrChange(e, idx, 'subject')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].subject = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div>
                                             <div className="col-md-3 form-group">
                                                <label>PAN Number </label>
                                                <input type="text" className="form-control" placeholder="Enter Subject"
                                                value={bankDetails.pan}
                                                   onChange={(e) => {
                                                      handleBankDetailsChange(e,'pan')
                                                      // handleEducationDetailsArrChange(e, idx, 'subject')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].subject = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div>
                                             {/* <div className="col-md-3 form-group">
                                                <label>PAN Image </label>
                                                <input type="text" className="form-control" placeholder="Enter Subject"
                                                   onChange={(e) => {
                                                      // handleEducationDetailsArrChange(e, idx, 'subject')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].subject = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div> */}
                                          </div>
                                 
                              </div>
                              <div className="UniversityEnsadd row">
                                 <div className="col-md-12 form-group mb-2">
                                    <h4>Enter Experience Details  </h4>
                                    <div>
                                    {
                                    experienceDetails.map((exprnc,idx) => {
                                       return (
                                          <div className='row'>
                                  <div className='col-md-4 mt-2 mb-2'>
                                   <label>Company Name</label>
                                   <input type='text' 
                                   className='form-control'
                                   value={exprnc.companyName}
                                   placeholder='Enter Company Name'
                                   onChange={(e) => handleExperienceDetailsChange(e,idx,'companyName')}
                                   />
                                  </div>
                                  <div className='col-md-4 mt-2 mb-2'>
                                   <label>Designation</label>
                                   <input type='text' 
                                   className='form-control'
                                   value={exprnc.designation}
                                   placeholder='Enter Designation'
                                   onChange={(e) => handleExperienceDetailsChange(e,idx,'designation')}
                                   />
                                  </div>
                                  <div className='col-md-4 mt-2 mb-2'>
                                   <label>Specialization Subject</label>
                                   <input type='text' 
                                   className='form-control'
                                   value={exprnc.specializationSubject}
                                   placeholder='Enter Specialization Subject'
                                   onChange={(e) => handleExperienceDetailsChange(e,idx,'specializationSubject')}
                                   />
                                  </div>
                                  <div className='col-md-4 mt-2 mb-2'>
                                   <label>Start Date</label>
                                   <input type='month' 
                                   className='form-control'
                                   values={exprnc.startDate}
                                   onChange={(e) => handleExperienceDetailsChange(e,idx,'startDate')}
                                 
                                   />
                                  </div>
                                  <div className='col-md-4 mt-2 mb-2'>
                                  <label>End Date</label>
                                  <input type='month' 
                                   className='form-control'
                                   value={exprnc.endDate}
                                   onChange={(e) => handleExperienceDetailsChange(e,idx,'endDate')}
                                   />
                                  </div>
                                  <div className='col-md-12 mt-2 mb-2'>
                                  <input type='checkbox' 
                                  checked={exprnc.isCurrentlyWorking}  
                                  onChange={(e) => handleExperienceDetailsChange(e,idx,'isCurrentlyWorking')}
                                  />
                                   <label className='mt-1 ms-2'>Is currently working</label>
                                  </div>
                                          </div>
                                       )
                                    })
                                 }
                                    </div>
                                    <div className="col-md-6 form-group">
                                    <div className="">
                                       <button type="button" className="btn bg-gradient-outline btn-rounded btn-fw"
                                          onClick={() => {
                                             setExperienceDetails((prev) => [...prev, {
                                                companyName:'',
                                                designation:'',
                                                specializationSubject:'',
                                                startDate:'',
                                                endDate:'',
                                                isCurrentlyWorking:false
                                             }])
                                          }}
                                       >Add</button></div>
                                 </div>
                                 </div>
                              </div>
                              <div className="UniversityEnsadd row">
                                 <div className="col-md-12 form-group mb-2">
                                    <h4>Enter Education Details  </h4>
                                 </div>
                                 {
                                    educationDetails.map((edu, idx) => {
                                       return (
                                          <div className='row'>
                                             <div className="col-md-3 form-group">
                                                <label>Degree</label>
                                                <select onChange={(e) => {
                                                   handleEducationDetailsArrChange(e, idx, 'degrees')
                                                   // setEducationdetails((prev) => {
                                                   //    const arr = [...prev]
                                                   //    arr[idx].degrees = e.target.value
                                                   //    return arr
                                                   // })
                                                }}
                                                   className="form-control"
                                                   placeholder="Phd in Mathematics"
                                                   style={{ height: '60%' }}
                                                >
                                                   <option>Choose...</option>
                                                   <option>Diploma</option>
                                                   <option>Graduation</option>
                                                   <option>Post Graduation</option>
                                                   <option>Phd</option>
                                                </select>
                                             </div>
                                             <div className="col-md-3 form-group">
                                                <label>Year </label>
                                                <input type="text" className="form-control" placeholder="Enter Year(e.g.,2011-12)"
                                                   onChange={(e) => {
                                                      handleEducationDetailsArrChange(e, idx, 'year')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].year = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div>
                                             <div className="col-md-3 form-group">
                                                <label>Percentage </label>
                                                <input type="number" className="form-control" placeholder="Enter Percentage"
                                                   onChange={(e) => {
                                                      handleEducationDetailsArrChange(e, idx, 'percentage')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].percentage = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                />
                                             </div>
                                             <div className="col-md-3 form-group">
                                                <label>Subject </label>
                                                <MultiSubjectDropdown
                                      name={`education[${idx}].subject`}
                                      className="form-control"
                                      placeholder={'Select Sub Subject'}
                                      options={subjectArr}
                                      idx={idx}
                                      values={values}
                                      onChange = {
                                       (data) => {
                                          handleEducationDetailsArrChange(data,idx,'subject')
                                       }
                                    }
                                   />
                                                {/* <input type="text" className="form-control" placeholder="Enter Subject"
                                                   onChange={(e) => {
                                                      handleEducationDetailsArrChange(e, idx, 'subject')
                                                      // setEducationdetails((prev) => {
                                                      //    const arr = [...prev]
                                                      //    arr[idx].subject = e.target.value
                                                      //    return arr
                                                      // })
                                                   }}
                                                /> */}
                                             </div>
                                          </div>
                                       )
                                    })
                                 }

                                 <div className="col-md-6 form-group">
                                    <div className="">
                                       <button type="button" className="btn bg-gradient-outline btn-rounded btn-fw"
                                          onClick={handleEducationClick}
                                       >Add</button></div>
                                 </div>
                              </div>
                              <div className="UniversityEnsadd row">
                                 <div className="col-md-12 form-group mb-0">
                                    <h4>Upload Expert Profile Image </h4>
                                 </div>
                                 <div className="col-md-12 form-group">

                                    <div className="imgulpdn1xz imgulpdn1xz2">
                                       <div className="privimgbvc1">
                                          <div className="js--image-preview" style={{ backgroundImage: `url("${imagePre}"` }}></div>
                                       </div>
                                       <div className="upload-options">
                                          <label>
                                             <FormikFileDirect
                                                name="img_path"
                                                accept="image/*"
                                                className="image-upload custom-file-input"
                                                onChange={(e) => { handelImagePreview(e) }}
                                             />
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                                 
                              </div>

                              <div className="UniversityEnsadd row" style={{ display: 'none' }}>
                                 <div className="col-md-12 form-group mb-2">
                                    <h4>Additinal Popularity Featured </h4>
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <div className="d-flex justify-content-between align-items-center asdsbmc1">
                                       <div className="">Assignments booked <span>in last 24 hours</span></div>
                                       <div className=""><input type="text" placeholder="" className="form-control" /></div>
                                    </div>
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <div className="d-flex justify-content-between align-items-center asdsbmc1">
                                       <div className="">Students visited  <span>his/her profile
                                          in last 10 minutes</span></div>
                                       <div className=""><input type="text" placeholder="" className="form-control" /></div>
                                    </div>
                                 </div>
                              </div>
                              {/* <div className="UniversityEnsadd row">
                                 <div className="col-md-12 form-group mb-2">
                                    <h4>Badge </h4>
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <div className="badgeImg"><img src="assets/images/experts/badge.svg" className="img-fluid" alt="" /> Highly rated and Reliable </div>
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <div className="form-check form-switch custmnscswch mt-4">
                                       <input className="form-check-input" type="checkbox" checked={badgeChecked} onChange={(e) => {
                                          setBadgeChecked(e.target.checked)
                                          values.badge = e.target.checked
                                       }} />
                                    </div>
                                 </div>
                              </div> */}
                              <div className="text-start mt-4">
                                 <button type="submit" className="btn btn-gradient-primary me-2" onClick={() => onSubmit()}>Save</button>
                                 <button type="submit" className="btn btn-gradient-primary me-2" onClick={() => 
                                 {
                                 // setIsDraft(true)
                                 values.isDraft = false
                                 onSubmit()
                                 }
                              }
                                 >Save Draft</button></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      </Form>
   )
}



const CreateBackendExpertForm = () => {
   const [subjectArr, setSubjectArr] = useState([])
   const { currentBackendRecord } = useSelector((state) => state.ExpertReducer)
   const history = useHistory()
   const dispatch = useDispatch()
   useEffect(() => {
      getAllSubjectsList()
   }, [])

   const getAllSubjectsList = async () => {
      const { data } = await getSubjects()
      setSubjectArr(data)
   }


   const onSubmit = useCallback((
      values,
      formikUtils,
   ) => {
      // const payload = {...values}
      delete values.subSubject
      delete values.subSubjectObj
      if(values._id){
        const res = expertUpdateAPI({id:values._id,updateType:values})
        if(res){
         history.push('/experts')
         dispatch(expertResetAction({expertType:values?.role ? values.role :'expert'}))
        }
      }else {
         dispatch(expertUpsertAction({
            payload:values,
            param:'tutor'
         }));
      }
   }, [
      dispatch
   ]);

   const onClear = () => {
      //
   }

   const onSearch = () => {
      //
   }


   return (

      <Formik
         onSubmit={onSubmit}
         onClear={onClear}
         onSearch={onSearch}
         initialValues={currentBackendRecord}
         // validationSchema={BlogYupSchema}
         enableReinitialize
      >
         {(formikProps) => (
            <Render
               onClear={onClear}
               onSearch={onSearch}
               onSubmit={onSubmit}
               {...formikProps}
            />
         )}
      </Formik>
   )
}

export default CreateBackendExpertForm