//  /* eslint-disable react/forbid-prop-types */
// /* eslint-disable react/jsx-props-no-spreading */
// import React from 'react';
// import PropTypes from 'prop-types';
// import { Field, ErrorMessage } from 'formik';
// import { useState } from 'react';
// import { getSubjects } from 'src/api/Assignment';
// import { MakeSlug } from 'src/utils/CommonUtils';
// import { getSubSubject } from 'src/api/Assignment';
// import { useEffect } from 'react';
// import ExpertiseDropdown from './ExpertiseDropdown';

// const SubComponent = ({
//     field,
//     form,
//     items,
//     name,
//     onChange,
//     subjectSelected,
//     placeholder,
//     ...rest
// }) => 

// {


//     return (
//         <>
//               <ExpertiseDropdown 
//                     options={[
//                        { value:'1',label:'1'}
//                     ]}
//                     />
//             <ErrorMessage
//                 component="div"
//                 className="text-danger"
//                 name={field.name}
//             />
              
//         </>
//     )
// };

// SubComponent.propTypes = {
//     field: PropTypes.any.isRequired,
//     form: PropTypes.any.isRequired,
//     items: PropTypes.array,
// };

// SubComponent.defaultProps = {
//     items: [],
// };

// const CountryFormikSelect = (props) => (
//     <Field
//         {...props}
//         component={SubComponent}
//     />
// );

// export default CountryFormikSelect;


// // const CountryFormikSelect = () => {
// //     const [subjectArr, setSubjectArr] = useState([])
// //     const [subjectExpertise, setSubjectExpertise] = useState([
// //         {
// //             name:'',
// //             id:'',
// //             sub_subjectArr:[],
// //             sub_subject:[
// //                 {
// //                     name:'',
// //                     id:''
// //                 }
// //             ]
// //         }
// //     ])
// //     const [subSubject, setSubSubject] = useState([])
// //     const [subjectSelected, setSubjectSelected] = useState('')

// //     useEffect(() => {
// //         getAllSubjects()
// //     },[])

// //     const getAllSubjects = async () => {
// //         const {data} = await getSubjects()
// //         setSubjectArr(data)
// //     }

// //     const handleSubjectselection = async (e,idx) => {
// //         const subjectSlug = MakeSlug(e.target.value)
// //         const {data} = await getSubSubject(subjectSlug)
// //         console.log('list is',data)
// //         setSubSubject(data)
// //         setSubjectExpertise((prev) => {
// //          const arr = [...prev]
// //          arr[idx].name = e.target.value
// //          arr[idx].sub_subjectArr = data
// //            return arr    
// //         })
// //     }

// //     const handleAddMoreButton = () => {
// //         setSubjectExpertise((prev) => [...prev, {
// //             name:'',
// //             id:'',
// //             sub_subjectArr:[],
// //             sub_subject:[
// //                 {
// //                     name:'',
// //                     id:''
// //                 }
// //             ]
// //         }])
// //     }

// //     return (
// //         <>
// //         {
// //             subjectExpertise.map((subs,idx) => {
// //                 return (
// //                     <>
// //                     <div className='row'>
// //                         <div className='col-md-3'>
                            
// //                        <select onChange={(e) => handleSubjectselection(e,idx)}>
// //                         <option>Choose...</option>
// //                         {
// //                             subjectArr.map((subs) => {
// //                                 return (
// //                                     <option value={subs.subject} id={subs._id}>{subs.subject}</option>
// //                                 )
// //                             })
// //                         }
// //                        </select>
                       
// //                        </div>
// //                        <div className='col-md-3'> <select>
// //                         <option>Select Sub Subject</option>
// //                         {/* <option>{subs.name}</option> */}
// //                         {
// //                            subs.sub_subjectArr.length > 0 && subs.sub_subjectArr?.map((sub) => {
// //                                 return (
// //                                     <option value={sub.sub_subject} id={sub._id}>{sub.sub_subject}</option>
// //                                 )
// //                             })
// //                         }
// //                        </select></div>
                      
// //                     </div>
// //                     <button onClick={handleAddMoreButton}>Add +</button>
// //                     </>
// //                 )
// //             })
// //         }
// //             {/* <select
// //                 {...field}
// //                 {...rest}
// //                 value={field.value === undefined || field.value === null ? '' : field.value}
// //                 onChange={(e) => {
// //                     form.setFieldValue(field.name, e.target.value);
// //                     // eslint-disable-next-line no-unused-expressions
// //                     onChange && onChange(e.target.value);
// //                 }}
// //             >
// //                 <option
// //                     key="-1"
// //                     value=""
// //                     label={`${(placeholder)?placeholder:'Choose...'}`}
// //                 >
// //                     Choose...
// //                 </option>
// //                 {
// //                     Object.values(items).map(({
// //                         name,
                        
// //                     }) => (
// //                         <option
// //                             key={name}
// //                             value={name}
// //                             label={name}
// //                         >
// //                             {name}
// //                         </option>
// //                     ))
// //                 }
// //             </select> */}
            
// //             {/* <ErrorMessage
// //                 component="div"
// //                 className="text-danger"
// //                 name={field.name}
// //             /> */}
// //         </>  
// //     )
// // };

// // export default CountryFormikSelect;




