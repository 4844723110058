import { Link } from "react-router-dom";
import RoutingUtil from "src/utils/RoutingUtil";

const Onboarded=(props)=>{
    const {
        onboardedExpertTutor = []
    } = props;
   return (
    <div className="col-md-12 mtops">
    <div className="card shadow">
      <div className="card-body">
        <div className="books-pan1bg">
          <div className="hdhomX1">
            <div className="hdhomX2 d-flex align-items-center">
              <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
              Onboarded </div>
            <div className="viebtnv1">
                 <Link to={RoutingUtil.expertManagerUrl()}>
                        View Details
                </Link>
            </div>
          </div>
          <div className="hdh1bg">
            <div className="hdhomX1">
              <div className="hdhomX2 d-flex align-items-center">
                <div className="hdhomX3 lightblue-icon me-2"><span><img src="/assets/images/home-imgs/online-icon2.svg" className="img-fluid" alt=""/></span></div>
                Tutors onboarded </div>
              <div className="hdh1No">{onboardedExpertTutor.length>0 && onboardedExpertTutor?.tutors[0]?.totalTutor}</div>
            </div>
            <div className="hdhomX1 hdh1">
              <div className="hdhomX2 d-flex align-items-center">
                <div className="hdhomX3 lightblue-icon me-2"><span><img src="/assets/images/home-imgs/expert-icon.svg" className="img-fluid" alt=""/></span></div>
                Expert's onboarded </div>
              <div className="hdh1No">{onboardedExpertTutor.length>0 && onboardedExpertTutor?.experts[0]?.totalExpert}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   )
}
export default Onboarded