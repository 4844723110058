import React, { useState } from 'react'
import SeoBreadCrumbSubjectQA from './SeoBreadCrumbSubjectQA'
import Rating from 'react-rating'
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { deleteQASubSubjectStudentReview, getQASubSubjectStudentReview, updateQASubSubjectStudentReview } from 'src/api/MastersApi';
import { useEffect } from 'react';
import config from 'src/config/index';

const UpdateSubSubjectStudentReviewsQA = () => {
    const ratingRef = useRef()
    const params  = useParams()
    const formDataUpload = new FormData() 
    const [name, setName] = useState('')
    const [institute, setInstitute] = useState('')
    const [review, setReview] = useState('')
    const [formData, setFormData] = useState({})
    const [rating, setRating] = useState('')
    const [error, setError] = useState('')
    const [ratingArr, setRatingArr] = useState([])

    useEffect(() => {
        console.log('params is',params)
        getAllReviews()
    },[])

    const getAllReviews = async () => {
        const {data} = await getQASubSubjectStudentReview(params.id)
        setRatingArr(data?.data?.qa_reviews)
    }
 
    const handleReviewSubmit = async () => {
        if(name === '' && institute === '' && review === '' && rating === '' && formData['img_path'] === undefined){
            setError('Please fill all the required fields.')
        } else if(name === ''){
            setError('Please Enter your name.')
        } else if(review === ''){
            setError('Please fill the review.')
        } else if(institute === ''){
            setError('Please enter institute name.')
        } else if(!formData['img_path']){
            setError('Please choose image.')
        } else if(rating === ''){
            setError('Please select a rating.')
        } else {
            formDataUpload.append('name',formData['name'])
            formDataUpload.append('review',formData['review'])
            formDataUpload.append('rating',rating)
            formDataUpload.append('img_path',formData['img_path'])
            formDataUpload.append('institute',formData['institute'])
            console.log('formdata',formData)
            const saveReview = await updateQASubSubjectStudentReview(formDataUpload, params.id)
            if(saveReview) {
                getAllReviews()
            }
        }
    }

    const handleReviewDelete = async(paramsId, reviewId) => {
        const removeReview = await deleteQASubSubjectStudentReview(paramsId,reviewId)
        if(removeReview){
            getAllReviews()
        }
    } 

    return (
        <div className='row'>
            <SeoBreadCrumbSubjectQA />
            <div className='col-md-4'>
                <p>Manage Reviews</p>
                <hr />
           <div className='form-group p-4 pt-0 row'>
               <div className='col-md-12 mt-2'>
                <label>Name</label>
                <input type='text' placeholder='Enter name' className='form-control' value={name}
                onChange={(e) => {
                    setName(e.target.value)
                    setFormData({...formData, name:e.target.value})
                }}
                />
               </div>
               <div className='col-md-12 mt-4'>
                <label>Institute</label>
                <input type='text' placeholder='Enter institute' className='form-control'
                value={institute}
                onChange={(e) => {
                    setInstitute(e.target.value)
                    setFormData({...formData,institute:e.target.value})
                }}
                />
               </div>
               <div className='col-md-12 mt-4'>
                <label>Image</label>
                <input type='file' className='form-control' style={{width:'100%'}}
                onChange={(e) => {
                    setFormData({...formData, 'img_path':e.target.files[0]})
                }}
                />
               </div>
               <div className='col-md-12 mt-4'>
                <label>Reviews</label>
                <textarea type='text' className='form-control'
                onChange={(e) => {
                    setReview(e.target.value)
                    setFormData({...formData, review:e.target.value})
                }}
                />
               </div>
               <div className='col-md-12 mt-4'>
                <label>Rating</label>
               </div>
               <div className='col-md-12'>
               <Rating
                            ref={ratingRef}
                            initialRating={rating}
                            defaultValue={rating }
                            emptySymbol="mdi mdi-star-outline mdi-48px"
                            fullSymbol="mdi mdi-star mdi-48px"
                            onChange={e => 
                                {
                                    setRating(e)
                                }
                                }
                        />
               </div>
           <div className='col-md-12 mb-4 mt-4'>
            <button className='btnbluen1' onClick={handleReviewSubmit}>Save Review</button>
           </div>
           </div>
            </div>
            <div className='col-md-1' />
            <div className='col-md-7'>
                <p>All reviews List</p>
                <div style={{ height: '420px', overflowY: 'scroll', paddingRight: '15px' }} id="reviewDiv">
                        {ratingArr && ratingArr.map(review => {
                            return (
                                <div className="subject-card"
                                    style={{ width: '100%' }}
                                    key={review._id}>

                                    <div className="subject-card-body">
                                        <div className='row'>
                                    <div className="col-md-3">
                                            <img src={config.imageUrl + review?.img_path} className="img-fluid"/>
                                        </div>
                                        <div className='col-md-9'>
                                        <div className="admin-name">
                                            <div className="name-label">
                                                <span className="mdi mdi-star fa-1x mr-2 pt-1"></span>
                                                &nbsp; Ratings:
                                            </div>
                                            <div className="name-main">
                                                <Rating
                                                    initialRating={review && review.rating}
                                                    emptySymbol="mdi mdi-star-outline text-danger"
                                                    fullSymbol="mdi mdi-star text-success"
                                                    readonly
                                                    className="displayRating"
                                                />
                                            </div>
                                        </div>
                                        <div className="admin-name">
                                            <div className="name-label">
                                                <span className="mdi mdi-calendar fa-1x mr-2 pt-1"></span>
                                                &nbsp; Review Date:
                                            </div>
                                            <div className="name-main">
                                                {review && review.created_at.split('T')[0]}
                                            </div>
                                        </div>
                                        <div className="admin-name">
                                            <div className="name-label">
                                                <span className="mdi mdi-account fa-1x mr-2 pt-1"></span>
                                                &nbsp; UserName:
                                            </div>
                                            <div className="name-main">
                                                {review && review.name}
                                            </div>
                                        </div>
                                        <div className="admin-name">
                                            <div className="name-label">
                                                <span className="mdi mdi-comment fa-1x mr-2 pt-1"></span>
                                                &nbsp; Reviews:
                                            </div>
                                            <div className="name-main text-justify">
                                                {review && review.review.substr(0, 60)}
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                    <hr className="mt-1 mb-2" />
                                    <div className='subject-card-heading justify-content-end'>
                                            <span className="mdi mdi-delete fa-1x mr-2 pt-1 text-danger" style={{cursor:'pointer'}} onClick={() => handleReviewDelete(params.id, review._id)}></span>
                                    
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                <hr/>
            </div>
            </div>
    )
}


export default UpdateSubSubjectStudentReviewsQA
