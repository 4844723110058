import React from "react"
import CrudLayoutContainer from "src/components/crud-layout-component/CrudLayoutContainer";
import RoutingUtil from "src/utils/RoutingUtil";
import GoogleAnalyticsPreview from "./GoogleAnalyticsPreview";

// const routes = [
//   {
//       link: RoutingUtil.googleAnalyticsUrlCFS(),
//       component: () => (
//         <EesumeWritingList />
//       ),
 
//   },
//   {
//       link: RoutingUtil.googleAnalyticsUrlAcadecraft(),
//       component: () => (
//         <EesumeWritingList />
//       ),
//   },
// ];


// const GoogleAnalyticsContainer = () => {
//         <CrudLayoutContainer
//         title="Enquiry Manager"
//             routes={routes}
//         />
//     };

// GoogleAnalyticsContainer.propTypes = {

//   };
// export default GoogleAnalyticsContainer;

const routes = [
  {
      link: RoutingUtil.googleAnalyticsUrlCFS(),
      component: () => (
        <GoogleAnalyticsPreview clientName="CFS" />
      ),
  },
  {
      link: RoutingUtil.googleAnalyticsUrlAcadecraft(),
      component: () => (
        <GoogleAnalyticsPreview clientName="ACADECRAFT"/>
      ),
  },
  {
      link: RoutingUtil.googleAnalyticsUrlAcadestudio(),
      component: () => (
        <GoogleAnalyticsPreview clientName="ACADESTUDIO"/>
      ),
  },
  {
    link: RoutingUtil.googleAnalyticsUrlAcadereality(),
    component: () => (
      <GoogleAnalyticsPreview clientName="ACADEREALITY"/>
    ),
}
];

const GoogleAnalyticsContainer = () => (
  <CrudLayoutContainer
      title="Enquiry Manager"       
      routes={routes}
  />
);

GoogleAnalyticsContainer.propTypes = {
};

export default GoogleAnalyticsContainer;
