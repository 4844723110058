import { put, call } from 'redux-saga/effects';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { TUTOR_ACTIONS } from '../actions/TutorActions';
import { expertCreateAPI,expertSearchAPI,expertUpdateAPI } from 'src/api/ExpertApi';



function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true
} = {}) {
    try {
        const {
            data: {
                data,
                total,
                // groupCounter,
                // allTimeWebRegistered,
                // allTimeAppRegistered
            },
        } = yield call(expertSearchAPI, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
            expertType:'tutor'
            // allTimeWebRegistered,
            // allTimeAppRegistered
        });

        yield put({
            type: TUTOR_ACTIONS.TUTOR_SEARCH_SUCCESS,
            data: data,
            // groupCounter:groupCounter,
            total: total,
            // allTimeWebRegistered:allTimeWebRegistered,
            // allTimeAppRegistered:allTimeAppRegistered
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: TUTOR_ACTIONS.TUTOR_SEARCH_ERROR,
        });
        return null;
    }
}


function* update(tutorId, payload) {
    try {
        yield call(expertUpdateAPI, {
            tutorId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        yield put({
            type: TUTOR_ACTIONS.TUTOR_RESET,
        });

        yield edit({ _id: tutorId });
    }
    catch (error) {
        console.error('LOG: upsert announcement error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: TUTOR_ACTIONS.TUTOR_UPSERT_ERROR,
        });
    }
}


function* insert(payload) {
    try {
   
        const {
            data: {
                data,
            },
        } = yield call(expertCreateAPI, payload,'tutor');

        yield put(headerShowAction({
            data: 'Created',
        }));

        // add in reducer
        yield put({
            type: TUTOR_ACTIONS.TUTOR_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
        yield put({
            type: TUTOR_ACTIONS.TUTOR_RESET,
        });
    }
    catch (error) {
        console.error('LOG: announcement upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: TUTOR_ACTIONS.TUTOR_UPSERT_ERROR,
        });
    }
}

function* edit({ where, expertType }) {
    try {
        const {
            data: {

                data,

            },
        } = yield call(expertSearchAPI, {
            where,
            expertType,
            autopopulate: false,
        });

        if (!data || !data[0]) {
            // DATA NO FOUND
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }
        // add in reducer
        yield put({
            type: TUTOR_ACTIONS.TUTOR_UPSERT_SUCCESS,
            data: data[0],
            expertType: expertType
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}


function* upsert({
    _id,
    ...payload
}) {
    // console.log('tutor update running.',_id,payload)
    // if (_id) {
    //     console.log('tutor update running.')
    //     yield update(_id, payload);
    // }
    // else {
        yield insert(payload);
    // }
}

// function* upsert({
//     _id,
//     ...payload
// }) {
//     console.log('tutor update running.',_id,payload)
//     // if (_id) {
//     //     console.log('tutor update running.')
//     //     yield update(_id, payload);
//     // }
//     // else {
//         yield insert(payload);
//     // }
// }

export default [
    takeFirstSagaUtil(TUTOR_ACTIONS.TUTOR_SEARCH, search),
    takeFirstSagaUtil(TUTOR_ACTIONS.TUTOR_UPSERT, upsert),
    takeFirstSagaUtil(TUTOR_ACTIONS.TUTOR_EDIT, edit)
];