export const CITY_TYPES = [
    {
        value: 'RURAL',
        label: 'RURAL',
    },
    {
        value: 'URBAN',
        label: 'URBAN',
    },
    {
        value: 'SEMI_URBAN',
        label: 'SEMI_URBAN',
    },
];
export const SubscriptionPlanWiseDefault=[
    {
        "_id": "Annually",
        "subscriptions": [
            {
                "status": "active",
                "totalSubscriptions": 0,
                "totalRevenue": 0
            }
        ],
        "totalSubscriptions": 0,
        "totalRevenue": 0
    },
    {
        "_id": "Monthly",
        "subscriptions": [
            {
                "status": "cancelled",
                "totalSubscriptions": 0,
                "totalRevenue": 0
            },
            {
                "status": "active",
                "totalSubscriptions": 0,
                "totalRevenue": 0
            }
        ],
        "totalSubscriptions": 0,
        "totalRevenue": 0
    },
    {
        "_id": "Quarterly",
        "subscriptions": [
            {
                "status": "cancelled",
                "totalSubscriptions": 0,
                "totalRevenue": 0
            },
            {
                "status": "active",
                "totalSubscriptions": 0,
                "totalRevenue": 0
            }
        ],
        "totalSubscriptions": 0,
        "totalRevenue": 0
    }
]

export default {
    CITY_TYPES,
    SubscriptionPlanWiseDefault,
};
