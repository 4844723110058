const TutorGender=(props)=>{
    const {
        genderWiseCount = []
    } = props;
    const genderList = (Object.keys(genderWiseCount).length>0)?genderWiseCount.GenderWiseTutor:[]
 return (
    <div className="col-md-6 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                      Tutors Gender </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="othfemlMale">
                      <ul>
                        {
                            genderList.map((gender,index)=>{
                               return (
                                <li key={`gender_${index}`}>
                                <div className=""><i className="mdi mdi-checkbox-blank-circle"></i>{(gender._id)?gender._id:"Not Available"}</div>
                                <span>{`${gender.total} (${gender.percentage.toFixed(0)}%)`}</span>
                                </li>
                               )
                            })
                        }
                         {/* <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Others</div>
                          <span>45%</span></li>
                       <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Female</div>
                          <span>35%</span>
                          </li>
                        <li>
                          <div className=""><i className="mdi mdi-checkbox-blank-circle"></i> Male</div>
                          <span>20%</span></li> */}
                      </ul>
                    </div>
                    <div className="chart-ducht">
                      <canvas id="doughnutChart"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
 )
}
export default TutorGender