/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';


export const whatsappCreateAPI = function (data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) { 
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}whatsapp/senderCSV`;
    return postRequest({
        url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export const whatsappCreateAPIImage = function (data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) { 
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}whatsapp/senderCSVWithImg`;
    return postRequest({
        url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const whatsappUpdateAPI = function ({
    whatsappId,
    data,
}) {
    // const fd = new FormData();
    // Object.keys(data).forEach((key) => {
    //     if (Array.isArray(data[key])) {
    //         const jsonString = JSON.stringify(data[key]);
    //         fd.append(key, jsonString); 
    //     } else {
    //         fd.append(key, data[key]);
    //     }
    // });
    const url = `${config.baseUrl}dashboard/whatsapp/update/${whatsappId}`;
    return putRequest({
        url,
        data: data,
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // },
    });
};

export const whatsappSearchAPI =  function (data) {
    const url = `${config.baseUrl}whatsapp/searchLog`;
    return postRequest({
        url,
        data,
    });
};
