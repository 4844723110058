 export const ANNOUNCEMENT_ACTIONS = {
    ANNOUNCEMENT_SEARCH:'ANNOUNCEMENT_SEARCH',
    ANNOUNCEMENT_SEARCH_SUCCESS:'ANNOUNCEMENT_SEARCH_SUCCESS',
    ANNOUNCEMENT_SEARCH_ERROR:'ANNOUNCEMENT_SEARCH_ERROR',

    ANNOUNCEMENT_UPSERT:'ANNOUNCEMENT_UPSERT',
    ANNOUNCEMENT_UPSERT_SUCCESS: 'ANNOUNCEMENT_UPSERT_SUCCESS',
    ANNOUNCEMENT_UPSERT_ERROR: 'ANNOUNCEMENT_UPSERT_ERROR',

    ANNOUNCEMENT_EDIT: 'ANNOUNCEMENT_EDIT',

    ANNOUNCEMENT_RESET: 'ANNOUNCEMENT_RESET',
 }

 export const annnouncementSearchAction = (payload) => ({
    type: ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_SEARCH,
    ...payload,
});


 export const announcementUpsertAction = (payload) => ({
    type: ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_UPSERT,
    ...payload,
});

export const announcementEditAction = (payload) => ({
    type: ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_EDIT,
    ...payload,
});

export const announcementResetAction = () => ({
    type: ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_RESET,
});

export default {
    ANNOUNCEMENT_ACTIONS,
    announcementUpsertAction,
    annnouncementSearchAction,
    announcementEditAction,
    announcementResetAction
};