import React from 'react'
import SEOBreadCrumb from './SEOBreadCrumb'
import { useParams } from 'react-router-dom'

const SimilarBooks = () => {

const params = useParams()

    return (
        <div className='row p-4'>
            <SEOBreadCrumb book_id={params.book_id} book_isbn={params.book_isbn}/>
            <div className='col-md-6'>All Books</div>
            <div className='col-md-6'>Similar Books</div>
        </div>
    )
}

export default SimilarBooks