import { formatDateDiff } from "src/libs/common"

const AssignmentList=({assignments,setSelectedAssignment, selectedStatus, setBidTutors,setAdditionalService})=>{
return(
    <>
<div className="table-responsive">
        <table className="table table-center datatablefnts datatable">
            <thead>
                <tr>
                    <th>S. No   </th>
                    {/* <th>Assmt. ID </th> */}
                    <th>Student ID</th>
                    <th>Student Name</th>
                    <th>Student Email Id </th>
                    <th>Student Contact N.</th>
                    <th>Subject</th>
                    <th>Sub Subject</th>
                    <th>Service Type  </th>
                    <th>Additional Added Service </th>
                    <th>Number of Page </th>
                    <th>Words Count </th> 
                    <th>Frontend Expert </th>
                    <th>Deadline </th>
                    <th>Deadline Left</th>
                    <th>Status</th>
                    <th>Amount</th>
                    {
selectedStatus !== 'Awaiting' && selectedStatus !== 'Delivered'  && selectedStatus !== 'Rejected'&& (
    <>
        <th>Assign to a Expert </th>
    <th>Bid Experts</th>
    </>
)
                    }
                </tr>
            </thead>
            <tbody>

                {
                assignments.map((assignment,index)=>{
                    const remaningTime = formatDateDiff(assignment.deadline_date)
                    const paymentStatus = (assignment.payment_status=="paid-full")?"paid":"Un Paid"
                  return (
                            <tr key={index}>
                            <td><span className="sNo">{index+1}</span> </td>
                            {/* <td>00{Math.floor(1000 + Math.random() * 9000)}    </td> */}
                            <td>{assignment?.user_id?.name}   </td>
                            <td>
                            <div className="nambtntx"><a href="#">{assignment.user_id?.Name} </a></div>
                            </td>
                            <td>
                            {assignment.user_id?.Email}
                            <div className="messagebn1">
                                <a href={`mailto:${assignment.user_id?.Email}`} target="_blank">
                                <button><i className="mdi mdi-email-outline"></i> Message</button>
                                </a>
                                </div>
                            </td>
                            <td>
                            <div className="">+{assignment.user_id?.calling_code} {assignment.user_id?.Contact}</div>
                            <div className="callphnwhaaap">
                            <a href={`https://api.whatsapp.com/send?phone=${assignment.user_id?.calling_code}`} title="Share on whatsapp" target="_blank"> 
                                <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> </a>
                                <a href={`https://api.whatsapp.com/send?phone=${assignment.user_id?.calling_code}`} title="Share on whatsapp" target="_blank">
                                <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button>
                                </a>
                                 </div>
                            </td> 
                            <td>{assignment.subject}</td>
                            <td>{assignment.sub_subject}   </td>
                            <td>{assignment.service}</td>
                            <td>
                            <div className=""><button className="virenbtn1" data-bs-toggle="modal" data-bs-target="#additionalservices-Modal" onClick={() => setAdditionalService(assignment.services)}>View</button></div>
                            </td>
                            <td>
                            <div className="text-bold">{assignment.pages} </div>
                            </td>
                            <td>
                            <div className="text-bold">{assignment.words} words </div>
                            </td>
                            
                            <td>
                            <div className="nambtntx"><a href="#" className="">{assignment.expert_id?.expert_name} </a></div>
                            </td>
                            <td>
                            <div className="text-bold mb-2">{assignment.deadline_date}</div>                            
                            </td>
                            <td>
                            <div className="text-bold">{remaningTime} </div>
                            </td>
                            <td>
                            <div className={`text-bold ${(paymentStatus=="paid")?'text-green':'text-red'} paid`}>
                                <i className="mdi mdi-check-decagram"></i> {} 
                            </div>
                            </td>
                            <td>
                            <div className="text-bold">${assignment.amount}</div>
                            </td>
                            
                                
                                      {
                                        selectedStatus !== 'Awaiting' && selectedStatus !== 'Delivered' && selectedStatus !== 'Rejected' && (
                                            <>
                                            <td>
                                            <div className="">
                                            <button className="btnbluen1 plwhit" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal" onClick={()=>{setSelectedAssignment(assignment)}}>Assign to a Expert</button> 
                                        </div>
                                        </td>
                                        <td>
                                          <div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#biddingdetails-Modal" onClick={() => setBidTutors(assignment?.bids)}>View Bids ({assignment?.bids?.length > 0 ? assignment?.bids?.length : 0})</button> </div>
                                        </td>
                                            </>
                                        )
                                                            }
                                
                            </tr>
                  )
                })                
                }
            </tbody>
        </table>
        
        </div>
    </>
)
}
export default AssignmentList