export const TabDetails = [
    {
        id:1,
        heading:'Unpaid Sessions',
        icon:'pending-icon'
    },
    {
        id:2,
        heading:'Upcoming Sessions',
        icon:'pending-icon'
    },
    {
        id:3,
        heading:'Cancelled Sessions',
        icon:'pending-icon'
    },
    {
        id:4,
        heading:'Session History',
        icon:'pending-icon'
    },
    {
        id:5,
        heading:'Session in Progress',
        icon:'pending-icon'
    }
]


