import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const BookPropTypes = PropTypes.shape({
    _id: PropTypes.string,
    subject_id: PropTypes.string,
    sub_subject_id: PropTypes.string,
    Author1: PropTypes.string,
    Author2: PropTypes.number,
    Author3: PropTypes.string,
    BookName: PropTypes.number,
    Description: PropTypes.number,
    Edition: PropTypes.string,
    ISBN10: PropTypes.string, 
    ISBN13: PropTypes.string, 
    file: PropTypes.string, 
    NoIndex:PropTypes.string,
    faqs:PropTypes.array,
    reviews:PropTypes.array
});


export const BookDefaultProps = {
    subject_id: '',
    sub_subject_id: '',
    Author1: '',
    Author2: '',
    Author3: '',
    BookName: '',
    Description: '',
    Edition: '',
    ISBN10: '',
    ISBN13: '',
    file: '',
    NoIndex:'',
    faqs:[
        {
          status:true,
          question:'',
          answer:'',
          created_at:Date.now()
        }
    ],
    reviews:[
        {
            status:true,
            rating:0,
            review:'',
            userName:'',
            created_at:Date.now()
        }
    ],
    _id: undefined,
 
}; 

export const BookYupSchema = Yup.object().shape({
    // subject_id: Yup.string().required('Required'),
    // sub_subject_id: Yup.string().required('Required'), 
    // Author1: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    // BookName: Yup.string().min(2, 'Book Name Too Short!').max(150, 'Book Name Too Long!').required('Required'),
    // Edition: Yup.string().required('Required'),
    // ISBN13: Yup.string().min(13, 'Too Short!').max(13, 'Too Long!'),
     
});

export default {
    BookDefaultProps,
    BookPropTypes,
    BookYupSchema,
};
