import React, { useState, useEffect } from 'react'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useHistory, useParams } from 'react-router-dom'
import { createAdminMaster, getAllMasterRoles, getRoleModules, getSingleAdminView, updateAdminMaster } from 'src/api/MastersApi'
import RoutingUtil from 'src/utils/RoutingUtil'

const AdminCreateForm = () => {


    const history = useHistory()
    const params = useParams()
    const [roleSelectArr, setRoleSelectArr] = useState([])
    const [fullname, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState(null)
    const [status, setStatus] = useState(null)
    const [checked, setChecked] = useState([])
    const [expanded, setExpanded] = useState([])
    const formDataUpload = {}

    const nodes = [{
        value: 'mars',
        label: 'Mars',
        children: [
            { value: 'phobos', label: 'Phobos' },
            { value: 'deimos', label: 'Deimos' },
        ],
    }];

    useEffect(() => {
        getRolesArr()
        getModulesArr()
    }, [])

    useEffect(() => {
        if(params.id){
            getSingleAdmin()
        }
    },[])

    const getModulesArr = async() => {
        const  data  = await getRoleModules()
        // console.log('modules are',data.data.data)
        }

    const getRolesArr = async () => {
        const res = await getAllMasterRoles()
        setRoleSelectArr(res.data.data)
    }

    const getSingleAdmin = async() => {
        const res = await getSingleAdminView(params.id)
        setFullName(res.data.data.fullname)
        setEmail(res.data.data.email)
        setRole(res.data.data.role)
        setStatus(res.data.data.status)
    }

    const handleSubmit = async() => {
       
        let roleName = roleSelectArr.filter((item) => item.role === parseInt(role))
        formDataUpload['fullname'] = fullname
        formDataUpload['email'] = email
        formDataUpload['mobile'] = ""
        formDataUpload['password'] = password
        formDataUpload['role'] = parseInt(role)
        formDataUpload['role_name'] = roleName[0].name
        formDataUpload['status'] = status
        if(params.id){
            const updateAdmin = await updateAdminMaster(formDataUpload, params.id)
            if(updateAdmin){
                history.push(RoutingUtil.adminMasterUrl())
            }
        }else {
            const saveAdmin = await createAdminMaster(formDataUpload)
            if(saveAdmin){
                history.push(RoutingUtil.adminMasterUrl())
            }
        }
    }

    const handleCheck = (checked) => {
        setChecked(checked);
      };
    
      const handleExpand = (expanded) => {
        setExpanded(expanded);
      };

    return (
        <div className='row p-4'>
            <div className='col-md-12'>
                <div className='row mt-2'>
                    <label>Admin Name</label>
                    <div className='col-md-12'>
                        <input type='text' value={fullname} onChange = {(e) => setFullName(e.target.value)} name="fullname" placeholder='Enter Admin Name' autoComplete='off' className='form-control' style={{width:'30%'}}/>
                    </div>
                    <label className='mt-3'>Admin Email</label>
                    <div className='col-md-12'>
                        <input type='email' name="email" value={email} onChange = {(e) => setEmail(e.target.value)} placeholder='Enter Admin Email' autoComplete='off' className='form-control' style={{width:'30%'}}/>
                    </div>
                    <label className='mt-3'>Enter Admin Password</label>
                    <div className='col-md-12'>
                        <input type='password' name="password" value ={password} onChange = {(e) => setPassword(e.target.value)} placeholder='Enter Admin Password' autoComplete='off' className='form-control' style={{width:'30%'}}/>
                    </div>
                    <label className='mt-3'>Admin Role</label>
                    <div className='col-md-6'>
                        <select className='form-control' value={role} onChange = {(e) => e.target.value !== '' && setRole(e.target.value)} name="role_name" style={{width:'30%'}}>
                            <option value=''>Select Role</option>
                            {
                                roleSelectArr.map((role) => {
                                    if(role.role > 1){
                                        return (
                                            <option value={role.role} key={role._id}>{role.name}</option>
                                        )
                                    }
                                })
                            }
                        </select>
                    </div>
                        <label className='mt-3'>Admin Status</label>    
                    <div className='col-md-6'>
                        <select className='form-control' value={status} onChange = {(e) => e.target.value !== '' && setStatus(e.target.value)} name='status' style={{width:'30%'}}>
                        <option value=''>Select Status</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                        </select>
                    </div>
                    <div className='col-md-12 mt-5'>
                        <button className='btnbluen1' onClick={handleSubmit}>Save Admin</button>
                    </div>
                </div>
            </div>
            <div className='col-md-12'>
            <CheckboxTree
      nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={handleCheck}
      onExpand={handleExpand}
    />
            </div>
        </div>
    )
}


export default AdminCreateForm