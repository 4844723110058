import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import NotFoundContainer from '../NotFoundComponent';
// import TabsComponent from '../tabs-component/TabsComponent';

const CrudLayoutContainer = ({ 
    routes,
}) => (
         <Switch>
            {
                routes.map(({
                    link,
                    component: Component,
                }) => (
                    <Route
                        key={link}
                        path={link}
                        exact
                        component={Component}
                    />
                ))
            }
            <Route
                component={NotFoundContainer}
            />
        </Switch>
);

CrudLayoutContainer.propTypes = {    
    routes: PropTypes.arrayOf(PropTypes.shape({
        component: PropTypes.any.isRequired,
        link: PropTypes.string,
    })).isRequired,
};

export default CrudLayoutContainer;
