import React, { useEffect, useState } from "react"
import CreateAnnouncementForm from "./CreateAnnouncementForm";
import AnnouncementList from "./AnnouncementList";
import { useDispatch, useSelector } from "react-redux";
import { announcementSearchAPI } from "src/api/Announcement";
import { annnouncementSearchAction } from "./actions/AnnouncementActions";
const AnnouncementContainer = () => {
   const { userId } = useSelector((state) => state.AuthReducer)
   const { search } = useSelector((state) => state.AnnouncementReducer)
   const dispatch = useDispatch()
   const [tableData, setTableData] = useState([])
   const [filter, setFilter] = useState({})
   const [pageNo, setPageNo] = useState(0)
   const [totalData, setTotalData] = useState(0)
   const [postedAnnouncement, setPostedAnnouncement] = useState(false)

   const getAnnouncements = () => {
      dispatch(annnouncementSearchAction({
         where: { ...filter, createdBy: userId },
         currentPage: pageNo,
         autopopulate: true
      }))   
   }

   const handleFilter = (payload) => {
      setFilter({ ...filter, ...payload })
   }

   useEffect(() => {
      const { data } = search
      setTableData(data)
      setTotalData(search.total)
   }, [search])

   useEffect(() => {
      getAnnouncements()
   }, [userId, pageNo, filter,postedAnnouncement])


   return (
      <>
         <div className="content-wrapper">
            <div className="page-header">
               <h3 className="page-title"> Create announcement
               </h3>
            </div>

            <div className="row">
               <div className="col-12 grid-margin  mt-4">
                  <div className="card-body">
                     <CreateAnnouncementForm setPostedAnnouncement={setPostedAnnouncement} postedAnnouncement={postedAnnouncement}/>
                  </div>

                  <AnnouncementList tableData={tableData} pageNo={pageNo} setPageNo={setPageNo} handleFilter={handleFilter} total={totalData} />
               </div>
            </div>
         </div>
      </>
   )
}

export default AnnouncementContainer;