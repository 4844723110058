import React, { useState, useEffect } from 'react'
import { getSubSubject, getSubjects } from "src/api/Assignment"
import { getBookAuthors } from 'src/api/BookApi'

const BookFilters = ({handleFilterChange, setFilter}) => {

    const [subjectArr, setSubjectArr] = useState([])
    const [subSubjectArr,setSubSubjectArr] = useState([])
    const [subjectSelected, setSubjectSelected] = useState('')
    const [authorsList, setAuthorsList] = useState([])
    const [filterObj, setFilterObj] = useState({})


    useEffect(() => {
        getSubjectArr()
        getAuthorsList()
     }, [])

    useEffect(()=>{
        if(subjectSelected !== ''){
           getSubSubjectsArr()
        }
     },[subjectSelected])

    const getSubjectArr = async () => {
        const subsArr = await getSubjects()
        setSubjectArr(subsArr.data)
     }
  
     const getSubSubjectsArr = async () => {
        const res = await getSubSubject(subjectSelected)
        setSubSubjectArr(res.data.data)
     }

     const getAuthorsList = async() => {
        const res = await getBookAuthors()
        setAuthorsList(res.data.data)
     }

    return (
        <div className="bgstrt">
        <div className="mb-4">
           <h3>Apply Filters </h3>
           <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="mdi mdi-close"></i></button>
        </div>
        <div className="">
           <div className="">
              <select className="form-select" onChange={(e) =>{
if(e.target.value !== ''){
    setSubjectSelected(e.target.value)
    setFilterObj({...filterObj, subject_name:e.target.value})
}
              }}>
                 <option value="">Select Subject</option>
                 {
                    subjectArr.map((sub)=> {
                         return (
                            <option value={sub.subject} key={sub._id}>{sub.subject}</option>
                         )
                    })
                 }
              </select>
           </div>
           <div className="">
              <select className="form-select" onChange={(e) => {
                if(e.target.value !== ''){
                    setFilterObj({...filterObj, sub_subject_name:e.target.value})
                }
              }}>
                 <option value="">Select Sub Subject</option>
                 {
                    subSubjectArr.map((subs) => {
                        return (
                            <option value={subs.sub_subject} key={subs._id}>{subs.sub_subject}</option>
                        )
                    })
                 }
              </select>
           </div>
           <div className="">
              <select className="form-select"
              onChange={(e) => {
                setFilterObj({...filterObj, Author1:e.target.value})
              }}
              >
                 <option value="">Author</option>
                 {
                    authorsList.map((author) => {
                        return (
                            <option value={author} key={author}>{author}</option>
                        )
                    })
                 }
              </select>
           </div>
           {/* <div className="">
              <select className="form-select">
                 <option>Rating  </option>
              </select>
           </div> */}
           <div className="mdbtn">
              <button className="reset me-2" onClick={() => setFilter({})}>Reset </button>
              <button className="apply active" onClick={() => handleFilterChange(filterObj)}>Apply</button>
           </div>
        </div>
     </div>
    )
}


export default BookFilters