/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const userSearchAPI = function (data) {
    const url = `${config.baseUrl}user/search`;
    return postRequest({
        url,
        data,
    });
};

export const userCreateAPI = function (data) {
    const url = `${config.baseUrl}user`;
    return postRequest({
        url,
        data,
    });
};

export const userUpdateAPI = function ({
    userId,
    data,
}) {
    const url = `${config.baseUrl}user/${userId}`;
    return putRequest({
        url,
        data,
    });
};

export const loginAPI = ({
    email,
    password,
}) => {
    const url = `${config.baseUrl}admin/login`;
    return postRequest({
        url,
        data: {
            email,
            password,
        },
    });
};

export const fetchUserScopesAPI = ({
    userId,
}) => {
    const url = `${config.baseUrl}auth/scope/${userId}`;
    return getRequest({
        url,
    });
};
