import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PermissionHOC from 'src/hoc/PermissionHOC';
import MenuLinkComponent from './MenuLinkComponent';
import MenuBandageComponent from './MenuBandageComponent';
import LinkComponent from '../LinkComponent';

const MenuDropdownComponent = ({
    faIcon,
    title,
    bandage,
    items,
}) => {
    const sideBarDropDownRef = useRef(null);
    const [isCollapsed,setIsCollapsed] =useState(false)

    const menuClickHandler = useCallback(() => {
        const jQuery = window.$;
        // hide all side bar sub menu
        jQuery('.sidebar-submenu').slideUp(200);
        const target = jQuery(sideBarDropDownRef.current);

        if (target.parent().hasClass('active')) {
            jQuery('.nav-item').removeClass('active');
            target.parent().removeClass('active');
        }
        else {
            jQuery('.nav-item').removeClass('active');

            target.next('.sidebar-submenu').slideDown(200);
            target.parent().addClass('active');
            target.addClass('collapsed');
        }
    }, []);

    return (
        <li
            className="nav-item"
        >
            <LinkComponent
                ref={sideBarDropDownRef}
                // onClick={menuClickHandler}
                onClick={()=>{
                    menuClickHandler()
                    setIsCollapsed(!isCollapsed)
                }}
                className={`nav-link ${isCollapsed?'collapsed':''}`}
                data-bs-toggle="collapse" 
                href="#booksQC" 
                aria-expanded={isCollapsed}
                aria-controls="My Subscriptions"
                >                   
                <i className={`mdi mdi-24px menu-icon ${faIcon}`} />
                <span className='menu-title'>{title}</span>
                <i className="menu-arrow"></i>
                <MenuBandageComponent
                    title={bandage.title}
                    faIcon={bandage.faIcon}
                />
            </LinkComponent>
            <div className={`collapse ${isCollapsed?'show':''}`} id="create-blogsb">
                <ul className='nav flex-column sub-menu'>
                    {items.map((item) => (
                        // <PermissionHOC
                        //     scope={item.scope}
                        //     key={item.title}
                        // >
                            <MenuLinkComponent
                                key={item.title}
                                title={item.title}
                                link={item.link}
                                bandage={item.bandage}
                                className="nav-link"
                            />
                        // </PermissionHOC>
                    ))}
                </ul>
            </div>
        </li>
    );
};

MenuDropdownComponent.propTypes = {
    bandage: PropTypes.shape({
        faIcon: PropTypes.string,
        title: PropTypes.string,
    }),
    faIcon: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    title: PropTypes.string,
};

MenuDropdownComponent.defaultProps = {
    bandage: {
        faIcon: '',
        title: '',
    },
    faIcon: '',
    items: [],
    title: '',
};

export default MenuDropdownComponent;
