import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import RoutingUtil from 'src/utils/RoutingUtil'
import { getAllFaqCategory } from 'src/api/FAQApi'
import SingleFaqContainer from './SingleFaqContainer'
import CrudLayoutContainer from 'src/components/crud-layout-component/CrudLayoutContainer'
import CreateFaqCategory from './CreateFaq'
import { useHistory } from 'react-router-dom'

const AllFaq = () => {
    const [faqCategs, setFaqCategs] = useState([])
    const history = useHistory()
    useEffect(() =>{
        getAllCategories()
    },[])
    
    const getAllCategories = async() =>{
        const { data } = await getAllFaqCategory(0,10)
        setFaqCategs(data.data)
    }

    return (
        <React.Fragment>
            <div className='container ps-4 pe-4 mt-4'>
            <div className='row'>
                <div className='col-md-4'>
            <h2>All Subjects</h2>
            <button className='btnbluen1' onClick={() => history.push(RoutingUtil.faqCreateUrl())}>Add Faq Category</button>
                </div>
            </div>
            <div className='row mt-4 mb-4'>
                {
                    faqCategs.map((faq) => {
                        return (
                            <div className='col-md-4 mt-2 pe-2'>
                           <SingleFaqContainer faq={faq}/>
                            </div>
                        )
                    })
                }
          
          
</div>
</div>
        </React.Fragment>
    )
}

export default AllFaq