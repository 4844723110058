/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik'; 
import { useState } from 'react';
import { getSubjects } from 'src/api/Assignment';
import { useEffect } from 'react';

const component = ({
    field,
    form,
    items,
    name,
    onChange,
    placeholder,
    ...rest
}) => 

{
    return (
        <>
            <select
                {...field}
                {...rest}
                value={field.value === undefined || field.value === null ? '' : field.value}
                onChange={(e) => {
                    form.setFieldValue(field.name, e.target.value);
                    // eslint-disable-next-line no-unused-expressions
                    onChange && onChange(e.target.value);
                }}
                style={{height:'60%'}}
            >
                <option
                    key="-1"
                    value=""
                    label={`${(placeholder)?placeholder:'Choose...'}`}
                >
                    Choose...
                </option>
                {
                    Object.values(items).map(({
                        name,
                        
                    }) => (
                        <option
                            key={name}
                            value={name}
                            label={name}
                        >
                            {name}
                        </option>
                    ))
                }
            </select>
            
            <ErrorMessage
                component="div"
                className="text-danger"
                name={field.name}
            />
        </>
    )
};

component.propTypes = {
    field: PropTypes.any.isRequired,
    form: PropTypes.any.isRequired,
    items: PropTypes.array,
};

component.defaultProps = {
    items: [],
};

const CountrySelect = (props) => (
    <Field
        {...props}
        component={component}
    />
);

export default CountrySelect;


