import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { deleteAdminMaster, getAllAdmins } from 'src/api/MastersApi'
import RoutingUtil from 'src/utils/RoutingUtil'

const AdminMaster = () => {

const history = useHistory()
const params = useParams()
const [totalEntries, setTotalEntries] = useState(0)   
const [adminArr, setAdminArr] = useState([]) 

useEffect(() => {
getAdminList()
},[])

const getAdminList = async() => {
    const res = await getAllAdmins()
    setTotalEntries(res.data.total)
    setAdminArr(res.data.data)
} 

const handleRemoveAdmin = async (adminId) => {
const removeAdmin = await deleteAdminMaster(adminId)
if(removeAdmin){
    history.push(RoutingUtil.adminMasterUrl())
}
}
    
    return (
        <React.Fragment>
            <div className='row p-4'>
            <div className='col-md-12'>
                <h1>Admin List</h1>
            </div>
            <div className='col-md-12 mt-2'>
            <button className='btnbluen1' onClick={() => history.push(RoutingUtil.adminCreateUrl())}>Add New Admin</button>
            </div>
            <div className='col-md-12 mt-3'>
            <div className="postablnewx mt-1"> 
            <div className="table-responsive">
								   <table className="table datatablefnts datatable">
                                    <thead>
                                       <tr>
                                          <th>S.no </th>
                                          <th>Name </th>
                                          <th>Email </th>
                                          <th>Role </th>  
                                          <th>Status </th> 
                                          <th>Created On</th>  
                                          <td>Edit</td>
                                          <td>Delete</td>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                        adminArr.map((admin, idx) => {
                                            return (
                                                <tr key={admin._id}>
                                                    <td>{idx+1}</td>
                                                    <td>{admin.fullname}</td>
                                                    <td>{admin.email}</td>
                                                    <td>{admin.role_name}</td>
                                                    <td>{admin.status === true ? 'Active' : 'Inactive'}</td>
                                                    <td>{admin.created_at.split('T')[0]}</td>
                                                    <td>
                                                        <span className='mdi mdi-tooltip-edit text-success' style={{fontSize:'1.5rem'}}
                                                        onClick={() => history.push(`/masters/admin-update/${admin._id}`)}
                                                        ></span>
                                                    </td>
                                                    <td>
                                                        <span className='mdi mdi-delete text-danger' style={{fontSize:'1.5rem'}} 
                                                        onClick={() => handleRemoveAdmin(admin._id)}
                                                        ></span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                       }
                                    </tbody>
                                 </table> 
                              </div>        </div>
            </div>
            </div>
        </React.Fragment>
    )
}

export default AdminMaster