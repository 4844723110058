import React from 'react'
import { TableContents } from 'src/containers/expert-container/TabsConfiguration'
import { useCallback } from 'react';
import ExpertSearchSelect from './ExpertSearchSelect'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { expertEditAction } from 'src/containers/expert-container/actions/ExpertActions';
import { pendingExpertUpdateApi, removeExpertApi } from 'src/api/ExpertApi';
import Pagination from './Pagination';

const ExpertContainerTabPane = ({ activeSelectedTab, tableData, handelFilterChange,pageNo, setPageNo, total }) => {
   const history = useHistory()
   const dispatch = useDispatch()
   // const editClick = useCallback((record,routerQuery) => {
   //    if (expertEditAction) {
   //       dispatch(expertEditAction(record));
   //    }
   //    history.push(routerQuery)
  
   // }, [
   //    expertEditAction,
   //    history,
   // ]);

   const editClick = (record,routerQuery,type) => {
      if (expertEditAction) {
         dispatch(expertEditAction( {where:{_id:record._id},
            expertType:type}));
      }
      history.push(routerQuery)
  
   }

const handlePendingExpertUpdate = async (data,updateType,expertType) => {
   console.log('data is',updateType,data)
const resData = await pendingExpertUpdateApi(data._id,updateType,expertType)
}

const handleRemoveExpert = async(data,expertType) => {
   const resData = await removeExpertApi(data._id,expertType)
}

   
   return (
      <>
         <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id={`pills-${activeSelectedTab.id}`} role="tabpanel" aria-labelledby={`pills-tab${activeSelectedTab.id}`}>
               <div className="col-12 grid-margin  mt-4">
                  <div className="card">
                     <div className="card-body ps-0 pe-0">
                        <div className="dateinydt">
                           <ExpertSearchSelect activeSelectedTabName={activeSelectedTab.tabName} handelFilterChange={handelFilterChange} />
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                    <div className="">
                                       <h4 className="card-title">All Experts/ Backend Experts </h4>
                                    </div>
                                    {activeSelectedTab.tabName === 'Frontend' && (
                                       <div className="d-flex align-items-center">
                                          <div className="me-2">
                                             <button className="btnbluen1" onClick={() => history.push('/create-expert')}>Add Frontend Experts </button>
                                          </div>
                                       </div>
                                    )}
                                    {activeSelectedTab.tabName === 'Backend' && (
                                       <div className="d-flex align-items-center">
                                          <div className="me-2">
                                             <button className="btnbluen1" onClick={() => history.push('/create-backend-expert')}>Add Backend Experts </button>
                                          </div>
                                       </div>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>
                        {/* {activeSelectedTab.id === 1 && (
                           <div className="qsnXamn1Bg">
                              <div className="qsnXamn1">
                                 <h4><span>QUESTIONS</span></h4>
                                 <ul>
                                    <li>
                                       <div className="qsnXamn1ls1">
                                          <div className="qsnXamn1lsText">Total Questions Answered <span>within deadline</span></div>
                                          <div className="qsnXamn1lsCheck">
                                             <div className="cheboxNwTxtr1"><label className="cheboxNw">4584
                                                <input type="checkbox" name="journal_selection" value="Journal Selection" />
                                                <span className="checkmark"></span> </label>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="qsnXamn1ls1">
                                          <div className="qsnXamn1lsText">Total Questions Answered Post <span>deadline</span></div>
                                          <div className="qsnXamn1lsCheck">
                                             <div className="cheboxNwTxtr1"><label className="cheboxNw">4584
                                                <input type="checkbox" name="journal_selection" value="Journal Selection" />
                                                <span className="checkmark"></span> </label>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="qsnXamn1ls1">
                                          <div className="qsnXamn1lsText">Total Questions Rejected</div>
                                          <div className="qsnXamn1lsCheck">
                                             <div className="cheboxNwTxtr1"><label className="cheboxNw">4584
                                                <input type="checkbox" name="journal_selection" value="Journal Selection" />
                                                <span className="checkmark"></span> </label>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className="qsnXamn1">
                                 <h4><span>ASSIGNMENTS</span></h4>
                                 <ul>
                                    <li>
                                       <div className="qsnXamn1ls1">
                                          <div className="qsnXamn1lsText">Total Assignments Solved <span>within deadline</span></div>
                                          <div className="qsnXamn1lsCheck">
                                             <div className="cheboxNwTxtr1"><label className="cheboxNw">4584
                                                <input type="checkbox" name="journal_selection" value="Journal Selection" />
                                                <span className="checkmark"></span> </label>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="qsnXamn1ls1">
                                          <div className="qsnXamn1lsText">Total Assignments Post <span>deadline</span></div>
                                          <div className="qsnXamn1lsCheck">
                                             <div className="cheboxNwTxtr1"><label className="cheboxNw">4584
                                                <input type="checkbox" name="journal_selection" value="Journal Selection" />
                                                <span className="checkmark"></span> </label>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="qsnXamn1ls1">
                                          <div className="qsnXamn1lsText">Total Assignments Rejected</div>
                                          <div className="qsnXamn1lsCheck">
                                             <div className="cheboxNwTxtr1"><label className="cheboxNw">4584
                                                <input type="checkbox" name="journal_selection" value="Journal Selection" />
                                                <span className="checkmark"></span> </label>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        )} */}
                        <div className="table-responsive">
                           <table className="table datatablefnts datatable">
                              <thead>
                                 <tr>
                                    <th>S. No   </th>
                                    {TableContents[activeSelectedTab.tabName]['tableHeaders'].map((item) => {
                                       return (
                                          <th key={item.label}>
                                             {item.label}
                                          </th>
                                       )
                                    })}
                                 </tr>
                                
                              </thead>
                              <tbody>
                                
                                 {tableData.map((data, idx) => {
                                    return (
                                       <tr key={idx}>
                                          <td>{idx + 1}</td>
                                         
                                          {TableContents[activeSelectedTab.tabName].tableHeaders.map((heads,idx) => {
                                             return (
                                                <React.Fragment key={idx}>
                                                   {
                                                      heads.label === 'Completed Assignment' || heads.label === 'Revenue Generated' || heads.label === 'Amount Received' ? (
                                                         <td>0</td>
                                                      ) : (
                                                         <td>
                                                            {data[heads.value]}
                                                             {heads.label === 'Expert Email Id' && (
                                                            <div className="messagebn1">
                                                               <a href={`mailto:${data.email}`}><button><i className="mdi mdi-email-outline" target="_blank"></i> Message</button></a>
                                                            </div>
                                                         )}
                                                            </td>
                                                      )
                                                   }
                                                  {heads.value === 'Contact Number' && (
                                                            <>
                                                               <div className="callphnwhaaap">
                                                                  <a href={`https://api.whatsapp.com/send?phone=${data.Contact}`} title="Share on whatsapp" target="_blank">         <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button>
                                                                     <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button></a>
                                                               </div>
                                                            </>
                                                         )}
                                                   {
                                                      heads.label === 'Subject' && (
                                                         <td>
                                                            <div className='content-limited'>
                                                               {
                                                                  data?.SubjectExpertise?.map((sub)=> {
                                                                     return (
                                                                        <p key={sub.name} style={{ fontSize: '12px' }}>{sub.name}</p>
                                                                     )
                                                                  })
                                                               //    data?.education?.[0]?.subject?.map((sub) => {
                                                               //       return (
                                                               // <p key={sub.name} style={{ fontSize: '12px' }}>{sub.name}</p>
                                                               // )
                                                               //    })
                                                               }
                                                            </div>
                                                         </td>
                                                      )
                                                   }
                                                   
                                                   {
                                                      heads.label === 'Sub Subject' && (
                                                         <td style={{ height: '40px', width: '120px' }} className='content-limited'>
                                                            
                                                            {
                                                               
                                                               data?.SubjectExpertise?.map((sub, idx)=> {
                                                                  // sub?.sub_subject?.map((ssub)=> {
                                                                     
                                                                     return (
                                                                        <p key={idx}>{sub.sub_subject.map((val, idx) =>{
                                                                           return(
                                                                              <React.Fragment key={idx}>
                                                                              {val.name} &nbsp;
                                                                              </React.Fragment>
                                                                           )
                                                                        })}</p>
                                                                     )
                                                               })
                                                            }
                                                         </td>
                                                      )
                                                   }
                                                   {
                                                      heads.label === 'Service Type' && (
                                                         <td style={{ height: '40px', width: '120px' }} className='content-limited'>
                                                            {
                                                               data?.services?.map((sub) => {
                                                                  return (
                                                                     <p key={sub.service} style={{ fontSize: '12px' }}>{sub.service}</p>
                                                                  )
                                                               })
                                                            }
                                                         </td>
                                                      )
                                                   }
                                                   {
                                                      heads.label === 'Country' && (
                                                         <td>
                                                            {data?.Livingin?.split(',')[2]}
                                                         </td>
                                                      )
                                                   }
                                                   {
                                                      heads.label === 'Edit' && (
                                                         <td>
                                                            <div className=""><button className="virenbtn1" onClick={() => {
                                                               if(activeSelectedTab.tabName === 'Frontend'){
                                                                  // history.push('/create-expert')
                                                                  editClick(data, '/create-expert','expert','expert')
                                                               }else{
                                                                  // history.push('/create-backend-expert')
                                                                  editClick(data, '/create-backend-expert','tutor','tutor')
                                                               }
                                                               
                                                            }
                                                            }>Edit</button></div>
                                                         </td>
                                                      )
                                                   }
                                                   {
                                                      heads.label === 'Action' && (
                                                         <td>
                                                         <div className=""><button className="btnbluen1 plwhit" onClick={() => handlePendingExpertUpdate(data,{approve:true,status:true},data.type)}>Approve </button> </div>
                                                         <div className="mt-2"><button className="btnbluen1" onClick={() => handlePendingExpertUpdate(data,{isDraft:true},data.type)}>Send to Draft </button> </div>
                                                      </td>
                                                      )
                                                   }

                                                   {
                                                      heads.label === 'Draft Action' && (
                                                         <td>
                                                         <div className=""><button className="btnbluen1" onClick={() => handleRemoveExpert(data,data.type)}><i class="mdi mdi-delete mdi-24px"></i></button> </div>
                                                      </td>
                                                      )
                                                      
                                                   }
                                                   
                                                </React.Fragment>
                                             )
                                          })}
                                       </tr>
                                    )
                                 })}
                              </tbody>
                           </table>
                        </div>
                         <Pagination total={total}  setPageNo={setPageNo} pageNo={pageNo} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default ExpertContainerTabPane