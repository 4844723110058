export const TUTOR_ACTIONS = {
    TUTOR_SEARCH: 'TUTOR_SEARCH',
    TUTOR_SEARCH_SUCCESS: 'TUTOR_SEARCH_SUCCESS',
    TUTOR_SEARCH_ERROR: 'TUTOR_SEARCH_ERROR',

    TUTOR_COUNT: 'TUTOR_COUNT',

    TUTOR_UPSERT: 'TUTOR_UPSERT',
    TUTOR_UPSERT_SUCCESS: 'TUTOR_UPSERT_SUCCESS',
    TUTOR_UPSERT_ERROR: 'TUTOR_UPSERT_ERROR',

    TUTOR_EDIT: 'TUTOR_EDIT',

    TUTOR_RESET: 'TUTOR_RESET',

} 

export const tutorResetAction = () => ({
    type: TUTOR_ACTIONS.TUTOR_RESET,
});

export const tutorSearchAction = (payload) => ({
    type: TUTOR_ACTIONS.TUTOR_SEARCH,
    ...payload
})

// export const expertCountAction = (payload) => ({
//     type: EXPERT_ACTIONS.EXPERT_COUNT,
//     ...payload
// })

export const tutorEditAction = (payload) => ({
    type: TUTOR_ACTIONS.TUTOR_EDIT,
    ...payload,
});

export const tutorUpsertAction = (payload) => ({
    type: TUTOR_ACTIONS.TUTOR_UPSERT,
    ...payload,
});

// export const tutorUpsertAction = (payload) => {
//     console.log('upsert payload is',payload)
// }

export default {
    TUTOR_ACTIONS,
    tutorSearchAction,
    // tutorCountAction,
    tutorUpsertAction,
    tutorEditAction,
    tutorResetAction
}