import React, { useEffect, useState } from 'react'
import { getSubjects, getSubSubject } from 'src/api/Assignment';
import { MakeSlug } from 'src/utils/CommonUtils';

const Categories = [
    'Assignment Writing Services',
    'Assignment Proofreading Services',
    'Assignment Editing Services',
    'Assignment Graphic Designing Services'
]

const DropDownFilter=({handleFilter})=>{

const [subjects, setSubjects] = useState([])
const [selectedSubject, setSelectedSubject] = useState('')
const [subSubject, setSubSubject] = useState([])
const [selectedSubSubject, setSelectedSubSubject] = useState('')
const [selectedCategory, setSelectedCategory] = useState('')

useEffect(() => {
    getSubjectArrr()
},[])

useEffect(() => {
    if(selectedSubject !== ''){
        getSubSubjectsArr()
    }
},[selectedSubject])

const getSubjectArrr = async() => {
    const res = await getSubjects()
    setSubjects(res.data)
}

const getSubSubjectsArr = async () => {
    const res = await getSubSubject(selectedSubject)
    setSubSubject(res.data.data)
}

  return(
  <>
        <div className="scscm21 row">
            {/* <div className="col-md-12"> */}
                {/* <div className="scscm21 scscm21-step6"> */}
                    <div className="col-md-4">
                    <select className="form-select" onChange={(e) =>
                       { if(e.target.value !== ''){
                            handleFilter({'services':{$elemMatch:{service:e.target.value,is_service:true}}})
                            setSelectedCategory(e.target.value)
                        }else {
                            handleFilter({})
                        }
                    }
                        }>
                        <option value="">Select Category</option>
                        {
                            Categories.map((categs) => {
                                return (
                                    <option value={categs} key={categs}>{categs}</option>
                                )
                            })
                        }
                    </select>
                    </div>
                    <div className="col-md-4">
                    <select className="form-select" onChange={(e) => 
                        {
                            if(e.target.value !== ''){
                                handleFilter({'subject':e.target.value})
                                setSelectedSubject(e.target.value)
                            }else {
                                handleFilter({})
                            }
                        }
                        }>
                        <option value="">Select Subject</option>
                        {
                            subjects.map((sub) => {
                                return (
                                    <option key={sub._id} value={sub.subject}>{sub.subject}</option>
                                )
                            })
                        }
                    </select>
                    </div>
                    <div className="col-md-4">
                    <select className="form-select" onClick={(e) => 
                        {
                            if(e.target.value !== ''){
                                handleFilter({'sub_subject':e.target.value})
                                setSelectedSubSubject(e.target.value)
                            }else {
                                handleFilter({})
                            }
                        }
                        }>
                        <option value="">Sub Subject</option>
                        {
                            subSubject.map((subs) => {
                                return (
                                    <option key={subs._id} value={subs.sub_subject}>{subs.sub_subject}</option>
                                )
                            })
                        }
                    </select>
                    </div>
                {/* </div> */}
            {/* </div> */}
        </div>
  </>
  )
}

export default DropDownFilter;





