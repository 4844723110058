import React, { useEffect, useState } from 'react';
import AssignmentList from "./AssignmentList";
import { GROUP_STATUS } from "./AssignmentRequestConstants";
import DropDownFilter from "./DropDownFilter";
import ManageColumn from "./ManageColumn";
import { useDispatch, useSelector } from 'react-redux';
import { shallowEqual } from 'recompose';
import { assginmentSearchAPI } from 'src/api/Assignment';
import InternalExpertModal from './InternalExpertModal';
import { ASSIGNMENTCountAction } from './actions/AssignmentRequestActions';
import BidExpertModal from './BidExpertModal';
import AdditionalServiceModal from './AdditionalServiceModal';
const ExpertContainer=()=>{

   // REDUX
   const dispatch = useDispatch();
//    const {
//       currentRecord,
//       upsertReqStatus,
//       assignmentRequestCounts
//   } = useSelector((state) => state.CaseReducer, shallowEqual);
const {
   assignmentRequestCounts
} = useSelector((state) => state.AssignmentRequestReducer);

const [assignments,setAssignments] = useState([]);
const [selectedAssignment,setSelectedAssignment] = useState(null);
const [bidTutors, setBidTutors] = useState([])
const [selectedStatus,setSelectedStatus] = useState("Pending");
const [additionalService, setAdditionalService] = useState([])
const [tableFilter, setTableFilter] = useState({})
const [pageNo, setPageNo] = useState(0)

const getAssignmentDetails=async(type="Pending/Incomplete form")=>{
   // let filter={payment_status:"paid-full",assignment_status:"pending",tutor_id:null};
   let filter ={order_status:'incompleted'}
   setSelectedStatus(type)
   if(type=="Delivered")
   //  filter={payment_status:"paid-full",assignment_status:"answered"};
   filter={stage:5};
   else if(type=="Cancelled")
   //  filter={assignment_status:"cancelled"};
   filter={order_status:"canceled"};
    else if(type=="Awaiting payment")
   //  filter={ payment_status: "paid-full", assignment_status: "pending", tutor_id: { $ne: null } };
   filter={order_status:'awaiting'};
    else if(type=="Rejected")
   //  filter={ assignment_status:"cancelled",cancelledBy:{$eq:this.user_id,$ne:null}};
   filter={order_status:'rejected'};
if(tableFilter !== {}){
filter = {...filter, ...tableFilter}
}
   const assignment  = await  assginmentSearchAPI({filter});
   
   setAssignments(assignment.data.data)
}


useEffect(()=>{
   getAssignmentDetails();
},[tableFilter])

useEffect(() =>{
getAssignmentCounts()
},[])


const handleFilter = (payload) => {
   setTableFilter({...tableFilter, ...payload})
}


const getAssignmentCounts = () => {
   dispatch(ASSIGNMENTCountAction())
}

    return(
         <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2" >
                        <i className="mdi mdi-home"></i>
                        </span> Assignment Request overview 
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="card shadow">
                           <div className="card-body">
                              {/* <div className="bgtodnlist">
                                 <div className="text-start">
                                    <div className="daterangepkr"> <span><i className="mdi mdi-calendar-check"></i></span>
                                       <input type="text" id="demo-12" placeholder="start date - end date" className="form-control form-control-sm"/>
                                    </div>
                                 </div>
                                 <div className="dailyTxtbg">
                                    <div className="dailyTxt1">
                                       <button className="btndaten1 active">Daily  </button>
                                       <button className="btndaten1">Tomorrow </button>   
                                       <button className="btndaten1">Last 7 Days</button>     
                                       <button className="btndaten1">Last 15 Days</button>    
                                       <button className="btndaten1">Last 30 Days</button>   
                                       <button className="btndaten1">Last 6 Month</button>    
                                       <button className="btndaten1">Last 1 Year</button>
                                       <button className="btndaten1">All Time</button>
                                    </div>
                                 </div>
                              </div> */}
                              <div className="tabsnrstl1">
                                 <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {
                                       GROUP_STATUS.map((status,index)=>{
                                          const tabNo=(index+1);
                                           return(
                                             <li className="nav-item" role="presentation" onClick={()=>getAssignmentDetails(status.status)} key={index}>
                                             <div className={`nav-link ${(index==0)?'active':''}`} id={`pills-tab${tabNo}`} data-bs-toggle="pill" data-bs-target={`#pills-${tabNo}`} type="button" role="tab" aria-controls={`pills-${tabNo}`} aria-selected="true">
                                                <div className="stretch-card asigntabnew1">
                                                   <div className="card-img-holder">
                                                      <div className="card-body">
                                                         <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className={`asigntabIcon icon${tabNo}`}>
                                                            <img src="/assets/images/super-admin/home/assignment-icon.svg" className="img-fluid img-icon" alt=""/> 
                                                            <i className="mdi mdi-plus"></i></span> <span className="valutext">{assignmentRequestCounts[status.value]?.count}</span>
                                                         </h4>
                                                         <h6 className="card-text">Total</h6>
                                                         <h2 className="mt-0">{status.status}</h2>
                                                         <div className="pricdlrm1">${assignmentRequestCounts[status.value]?.amount}</div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </li>
                                           )
                                       })
                                    }
                                 </ul>
                              </div>
                              <div className="tab-content" id="pills-tabContent">
                                 <div className="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-tab1">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                             <div className="dateinydt">
                                                <DropDownFilter handleFilter={handleFilter}/>
                                               {/* <ManageColumn/> */}
                                             </div>
                                              <AssignmentList bidTutors={bidTutors} setBidTutors={setBidTutors} assignments={assignments} setSelectedAssignment={setSelectedAssignment} selectedStatus={selectedStatus} setAdditionalService={setAdditionalService}/> 
                                              
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <InternalExpertModal selectedAssignment={selectedAssignment}/>
                  <BidExpertModal bidTutors={bidTutors}/>
                  <AdditionalServiceModal additionalService={additionalService}/>
                  </div>
    )
}

export default ExpertContainer;