import { put, call } from 'redux-saga/effects';
import { enquiryCreateAPI,
     resumeWritingSearchAPI,mentorAssistantSearchAPI,SOPSearchAPI, 
     resumeWritingUpdateAPI,mentorAssistantUpdateAPI,SOPUpdateAPI,
    enquiryUpdateAPI } from 'src/api/EnquiryApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { ENQUIRY_ACTIONS } from '../actions/EnquiryActions';

function* search({
    where,searchType, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        let apiToCall;
    switch(searchType) {
        case 'resume':
            apiToCall = resumeWritingSearchAPI;
            break;
        case 'mentor':
            apiToCall = mentorAssistantSearchAPI;
            break;
        case 'SOP':
            apiToCall = SOPSearchAPI;
            break;
        default:
            // handle invalid type here
            break;
    }
    const {
        data: {
            data,
        },
    }  = yield call(apiToCall, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: ENQUIRY_ACTIONS.ENQUIRY_SEARCH_SUCCESS,
            data:{searchType,data},
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: ENQUIRY_ACTIONS.ENQUIRY_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({ _id }) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(resumeWritingSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {
            // DATA NO FOUND
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }

        // add in reducer
        yield put({
            type: ENQUIRY_ACTIONS.ENQUIRY_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(enquiryCreateAPI, payload);

        yield put(headerShowAction({
            data: 'Created',
        }));

        // add in reducer
        yield put({
            type: ENQUIRY_ACTIONS.ENQUIRY_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: ENQUIRY_ACTIONS.ENQUIRY_UPSERT_ERROR,
        });
    }
}

function* update(enquiryId,upsertType, payload) {
    try {
        let apiToCall;
        switch(upsertType) {
            case 'resume':
                apiToCall = resumeWritingUpdateAPI;
                break;
            case 'mentor':
                apiToCall = mentorAssistantUpdateAPI;
                break;
            case 'SOP':
                apiToCall = SOPUpdateAPI;
                break;
            default:
                // handle invalid type here
                break;
        }
        yield call(apiToCall, {
            enquiryId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));

        yield edit({ _id: enquiryId });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: ENQUIRY_ACTIONS.ENQUIRY_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    upsertType,
    ...payload
}) {
    if (_id) {
        yield update(_id,upsertType, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(ENQUIRY_ACTIONS.ENQUIRY_SEARCH, search),
    takeFirstSagaUtil(ENQUIRY_ACTIONS.ENQUIRY_UPSERT, upsert),
    takeFirstSagaUtil(ENQUIRY_ACTIONS.ENQUIRY_EDIT, edit),
];
