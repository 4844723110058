import React from "react"
import CrudLayoutContainer from "src/components/crud-layout-component/CrudLayoutContainer";
import RoutingUtil from "src/utils/RoutingUtil";
import BlogSearch from "./BlogSearch";
import BlogForm from "./BlogForn";

const routes = [
   {
       link: RoutingUtil.blogManagerUrl(),
       component: () => (
         <BlogForm />
       ),
   },
   {
       link: RoutingUtil.blogSearch(),
       component: () => (
         <BlogSearch />
       ),
   },
];
const BlogContainer=()=>(
         <CrudLayoutContainer
           routes={routes}
         /> 
)

export default BlogContainer;