import {useState } from 'react'; 
import { Form } from 'formik';
import SubjectDropDown from 'src/business-components/SubjectDropDown';
import SuSubjectDropDown from 'src/business-components/SubSubjectDropDown';
import FormCreateUpdate from '../blog-container/FormCreateUpdate';

const FilterSections=(props)=>{
    const [filterSubjectId,setfilterSubjectId] = useState(null);
 
    const { 
       values, 
       isSubmitting, 
       onClear,
       onBulkApprove,
       activeTab 
   } = props;
 //   console.log('values=>',values)
    return (
       <Form >
             <div className="col-md-12">
                <div className="scscm21 scscm21-step3">
                   <div className="">
                      <SubjectDropDown
                         className="form-select"
                         value={values?.subject_id}
                         name="subject_id"
                         onChange={(value)=>{
                            values.subject_id = value
                            setfilterSubjectId(value);
                         }}
                      />
                   </div>
                   <div className="">
                      <SuSubjectDropDown
                         className="form-select"
                         subjectId={filterSubjectId}
                         value={values.sub_subject_id}
                         name="sub_subject_id"
                         onChange={(value)=>{
                         values.sub_subject_id = value 
                         }}
                      />
                   </div>
                   <div className="">
                      {/* <select className="form-select">
                         <option>Select Uploaded Date  </option>
                      </select> */}
                      <FormCreateUpdate
                         isSubmitting={isSubmitting}
                         values={values}
                         submitButtonText="Search"
                   />
                   <button type='button' onClick={onClear} className='btn bg-gradient-outline btn-rounded btn-fw'>Reset</button>
                   {
                        (activeTab === "pending" || activeTab === "approved") && (
                           <>
                              <button
                              type="button"
                              onClick={onBulkApprove}
                              className={`btn btn-${activeTab === "pending" ? "success" : "danger"} btn-rounded`}
                              >
                              {activeTab === "pending" ? "Approve" : "Reject"}
                              </button>
                           </>
                        )
                  }
                    
                   </div>
                </div>
             </div>
          </Form>
    )
 }

 export default FilterSections