import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getSubjectMasterData, getSubjects, getSubjectsData } from 'src/api/Assignment'
import { getSubSubjects } from 'src/api/MastersApi'
import RoutingUtil from 'src/utils/RoutingUtil'

const SubSubjectMaster = () => {

const history = useHistory()    
const [subjectArr, setSubjectArr] = useState([])

useEffect(() => {
getAllSubjects()
}, [])

const getAllSubjects = async () => {
    const { data } = await getSubSubjects()
    setSubjectArr(data.data)
}
    

    return (
        <div className='container ps-4 pe-4 mt-4'>
            <div className='row'>
                <div className='col-md-4'>
            <h2>All Sub Subjects</h2>
            <button className='btnbluen1' onClick={() => history.push(RoutingUtil.subSubjectMasterCreateUrl())}>Add Sub Subject</button>
                </div>
            </div>
            <div className='row mt-4'>
            <p>All Sub Subject List</p>
            <div className="postablnewx mt-1"> 
            <div className="table-responsive">
								   <table className="table datatablefnts datatable">
                                    <thead>
                                       <tr>
                                          <th>S.no </th>
                                          <th>Subject </th>
                                          <th>Sub Subject Id</th>  
                                          <th>Sub Subject </th>
                                          <td>Edit</td>
                                          <td>Delete</td>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                        subjectArr.map((subs, idx) => {
                                            return (
                                                <tr key={subs._id}>
                                                    <td>{idx+1}</td>
                                                    <td>{subs.subject.charAt(0).toUpperCase() + subs.subject.slice(1)}</td>
                                                    <td>{subs._id}</td>
                                                    <td>{subs.sub_subject}</td>
                                                    <td>
                                                        <button className='btnbluen1'
                                                        onClick={() => history.push(`/masters/sub-subject-seo/qa/update/${subs._id}`)}
                                                        >Update Seo/Content Q&A</button>
                                                    </td>
                                                    <td>
                                                        <button className='btnbluen1'
                                                         onClick={() => history.push(`/masters/sub-subject-seo/textbook/update/${subs._id}`)}
                                                        >Update Seo/Content Soln Manuals</button>
                                                    </td>
                                                    <td>
                                                        <span className='mdi mdi-tooltip-edit text-success' style={{fontSize:'1.5rem'}}></span>
                                                    </td>
                                                    <td>
                                                        <span className='mdi mdi-delete text-danger' style={{fontSize:'1.5rem'}}></span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                       }
                                    </tbody>
                                 </table> 
                              </div>        </div>
            </div>
          
                              </div>
    )
}

export default SubSubjectMaster