
import React, { useState, useEffect } from 'react'
import BookContainerTabPane from 'src/components/BookContainerTabPane'
import DateRangeCalendar from 'src/components/DateRangeCalendar'
import { getBooksCount } from "src/api/BookApi";
import { useDispatch, useSelector } from "react-redux"
import { bookSearchAction } from "./actions/BookActions"
import BookFilters from './BookFilters';

const TabDetails = [
   {
      id: 1,
      heading: 'All Pending Books',
      name: 'seoPending',
      tableHeader: 'Pending'
   },
   {
      id: 2,
      heading: 'Total Rejected Books',
      name: 'seoRejected',
      tableHeader: 'Rejected'
   },
   {
      id: 3,
      heading: 'Total Approved Books',
      name: 'seoApproved',
      tableHeader: 'Approved'
   }
]
const BookPendingForQC = () => {
   
   const dispatch = useDispatch()
   const { search } = useSelector((state) => state.BookReducer)
   const [filterModal, setFilterModal] = useState(false)
   const [textInputSearch, setTextInputSearch] = useState('')
   const [filter, setFilter] = useState({ seoStatus: 'pending' })
   const [activeSelectedTab, setActiveSelectedTab] = useState({ id: 1, heading: 'All Pending Books', name: 'seoPending', tableHeader: 'Pending' })
   const [tableData, setTableData] = useState([])
   const [dateFilter, setDateFilter] = useState({})
   const [pageNo, setPageNo] = useState(0)
   const [bookCount, setBookCount] = useState(
      {
         "seoPending": 0,
         "seoRejected": 0,
         "seoApproved": 0
     }
     )

     useEffect(() => {
      getCounts()
   },[])

   // useEffect(() => {
   //    const activeTab = JSON.parse(window.localStorage.getItem('activeTab'))
   //    if(activeTab !== undefined && activeTab!== null){
   //       setActiveSelectedTab(activeTab)
   //       setFilter({authoringStatus:activeTab['tableHeader'].toLowerCase()})
   //    }
   //    // localStorage.removeItem('activeTab')
   // },[])
   
       const getCounts = async() => {
         const countRes = await getBooksCount()
         // console.log('books count is', countRes.data.data)
        setBookCount(countRes.data.data)
       }

       const handleFilterChange = (payload) => {
         setFilter({ ...filter, ...payload })
      }
   
      useEffect(() => {
         const { data } = search
         setTableData(data)
      }, [search])
   
      useEffect(() => {
         getBooksTableData()
      }, [filter, activeSelectedTab])
   
      const handleDateRangeFilter = (payload) => {
         setDateFilter({ ...filter, ...payload })
      }
   
      const getBooksTableData = () => {
         dispatch(bookSearchAction({
            where: filter,
            currentPage: pageNo,
            autopopulate: true
         }))
      }
   
      const handleTabSelection = (tabObj) => {
         setActiveSelectedTab(tabObj)
         if (tabObj.heading === 'All Pending Books') {
            handleFilterChange({ seoStatus: 'pending' })
         } else if (tabObj.heading === 'Total Rejected Books') {
            handleFilterChange({ seoStatus: 'rejected' })
         } else if (tabObj.heading === 'Total Approved Books') {
            handleFilterChange({ seoStatus: 'approved' })
         }
      }

      const handleInputBarSearch = (e) => {
         setTextInputSearch(e.target.value)
         handleFilterChange({ISBN13:e.target.value})
         }

   return (
      <>
      <div className="content-wrapper">
         <div className="page-header">
            <div className="">
               <h4 className="page-title"> Books Pending for QC
               </h4>
               <div className="top-breadcrumb">
                  <nav aria-label="breadcrumb">
                     <ol className="breadcrumb ps-0">
                        <li className="breadcrumb-item"><a href="books.php">Books </a></li>
                        <li className="breadcrumb-item"><a href="#">Books pending QC </a></li>
                        <li className="breadcrumb-item active" aria-current="page">All Pending Bookds</li>
                     </ol>
                  </nav>
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-md-12">
               <div className="card shadow">
                  <div className="card-body">
                     <DateRangeCalendar onSelect={handleDateRangeFilter}/>
                     <div className="tabsnrstl1 tabsnrstl31 spacnav30">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                           {
                              TabDetails.map((tab) => {
                                 return (
                                    <li className="nav-item" role="presentation" onClick={() => handleTabSelection(tab)}>
                                       <div className={`nav-link ${activeSelectedTab.heading === tab.heading && 'active'}`} id={`pills-tab${tab.id}`} data-bs-toggle="pill" data-bs-target={`#pills-${tab.id}`} type="button" role="tab" aria-controls={`pills-${tab.id}`} aria-selected="true">
                                          <div className="stretch-card asigntabnew1">
                                             <div className="card-img-holder">
                                                <div className="card-body">
                                                   <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabIcon icon-p"><img src="/assets/images/book-pending-for-qc/icon-p.svg" className="img-fluid img-icon" alt="" /></span> <span className="valutext">{bookCount[tab.name]}</span>
                                                   </h4>
                                                   <h2 className="mt-0">{tab.heading} </h2>
                                                   {/* <div className="pricdlrm1 d-flex justify-content-between align-items-center">Last 24 hours <span>0</span></div> */}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                 )
                              })
                           }
                        </ul>
                        <div className="col-md-12">
                           <div className="top-breadcrumb mtcustom4">
                              <nav aria-label="breadcrumb">
                                 <ol className="breadcrumb ps-0 border-0">
                                    <li className="breadcrumb-item"><a href="books.php">Books </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Book Authoring</li>
                                 </ol>
                              </nav>
                           </div>
                           <div className="scscm21 scscm21-step2">
                              <div className="searchBar">
                              <input type="search" placeholder="Search Book by ISBN 13" className="form-control" 
                                    value={textInputSearch}
                                    onChange={(e)=>handleInputBarSearch(e)}
                                    />
                                 <button><i className="mdi mdi-magnify"></i></button>
                              </div>
                              <div className="d-flex align-items-center justify-content-end datewfiltr">
                                 <div className="me-2"><button className="flitrbtn" 
                                 onClick={() => setFilterModal(true)}
                                 data-bs-toggle="modal" data-bs-target="#filter-Modal"><i className="mdi mdi-filter-outline mdi-24px"></i></button></div>
                                 {/* <div className="text-start">
                                    <div className="daterangepkr"> <span><i className="mdi mdi-calendar-check"></i></span>
                                       <input type="text" id="demo-13" placeholder="start date - end date" className="form-control form-control-sm" />
                                    </div>
                                 </div>
                                 <div className="ms-2"><button className="flitrbtn active"><i className="mdi mdi-filter-variant mdi-24px"></i></button></div> */}
                              </div>
                           </div>
                        </div>
                     </div>
                     <BookContainerTabPane tableData={tableData} activeSelectedTab={activeSelectedTab}
                     containerPage={'SEO'}
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal fade filter-Modal" id="filter-Modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
           style={{
            display: `${filterModal ? "block" : "none"}`,
            opacity: `${filterModal ? 1 : 0}`,
            background: `rgba(0,0,0,0.5)`,
         }}
         >
         <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-body">
                  <BookFilters handleFilterChange={handleFilterChange} setFilter={setFilter}/>
               </div>
            </div>
         </div>
      </div>
      </>
   )
}
export default BookPendingForQC