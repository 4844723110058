import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios';
import { getSubSubject, getSubjects } from 'src/api/Assignment'
import config from 'src/config/index';
import BulkUploadTable from './BulkUploadTable';
import { useDispatch, useSelector } from 'react-redux';
import { bookSearchAction, chapterLogSearchAction } from './actions/BookActions';


const BookBulkUpload = () => {

   const dispatch = useDispatch()
   const { search } = useSelector((state) => state.BookReducer)
   const [tableData, setTableData] = useState([])
   const [pageNo, setPageNo] = useState(0)
   const [uploadOperation, setUploadOperation] = useState('Books Upload')
   const [subjectArr, setSubjectArr] = useState([])
   const [subSubjectArr, setSubSubjectArr] = useState([])
   const [subjectSelected, setSubjectSelected] = useState('')
   const [subSubjectSelected, setSubSubjectSelected] = useState('')
   const [subjectIdSelected, setSubjectIdSelected] = useState('')
   const [subSubjectIdSelected, setSubSubjectIdSelected] = useState('')
   const [localStorageChapterData, setLocalStorageChapterData] = useState({})
   const [file, setFile] = useState(null);
   const state = JSON.parse(localStorage.getItem('AuthReducer'))
   // const [btnDisabled, setBtnDisbaled] = useState(true)
   const formDataUpload = new FormData();

   useEffect(() => {
      dispatch(bookSearchAction({
         where: {},
         currentPage: pageNo,
         autopopulate: true
      }))
   }, [])

   useEffect(() => {
      if (uploadOperation === 'Books Upload Chapters'){
         dispatch(chapterLogSearchAction({
            where:{},
            currentPage:pageNo,
            autopopulate:true
         }))
      }else {
         dispatch(bookSearchAction({
            where: {},
            currentPage: pageNo,
            autopopulate: true
         }))
      }
   },[uploadOperation])

   useEffect(() => {
      const { data } = search
      setTableData(data)
   }, [search])


   useEffect((() => {
      getSubjectsArr()
      getSubSubjectsArr()
   }), [])

   useEffect(() => {
      const isChapterUploadRoute = localStorage.getItem('chapter-upload')
      if (isChapterUploadRoute !== undefined && isChapterUploadRoute !== null) {
         setUploadOperation('Books Upload Chapters')
         setLocalStorageChapterData(JSON.parse(isChapterUploadRoute))
      }
      localStorage.removeItem('chapter-upload')
   }, [])

   useEffect(() => {
      if (subjectSelected !== '') {
         getSubSubjectsArr(subjectSelected)
      }
   }, [subjectSelected])

   const getSubjectId = (subjectSelected) => {
      if (subjectSelected !== '') {
         const subjectObj = subjectArr.filter((item) => item.subject === subjectSelected)
         setSubjectIdSelected(subjectObj[0]._id)
      }
   }

   const getSubSubjectId = (subSubjectSelected) => {
      if (subSubjectSelected !== '') {
         const subObj = subSubjectArr.filter((item) => item.sub_subject === subSubjectSelected)
         setSubSubjectIdSelected(subObj[0]._id)
      }
   }

   const getSubjectsArr = async () => {
      const res = await getSubjects()
      setSubjectArr(res.data)
   }

   const getSubSubjectsArr = async (subjectSelected) => {
      const res = await getSubSubject(subjectSelected)
      setSubSubjectArr(res.data.data)
   }

   const handelChangeUpload = (e) => {
      const filename = e.target.files[0].name;
      console.log('file onchange ', filename);
      const ext = filename.split('.')[1];
      console.log(ext)
      if (ext === "csv") {
         //  setBtnDisbaled(false);
         setFile(e.target.files[0]);
         formDataUpload.append('file', e.target.files[0]);
      } else {
         //  setBtnDisbaled(true);
         console.log({ type: 'SET_ERROR', payload: 'Only .csv files are allowed' });
      }
   }

   const options = {
      headers: {
         'Content-Type': 'Application/json',
         'Authorization': state.token
      }
   };

   async function handleSubmit(e) {
      e.preventDefault();
      console.log(formDataUpload.file);
      // return;
      let response = null;
      if (formDataUpload.sub_subject_name === '') {
         console.log({ type: 'SET_ERROR', payload: 'Please Select sub subject' });
      } else {
         formDataUpload.append('subject_name', subjectSelected.toLowerCase());
         formDataUpload.append('subject_id', subjectIdSelected);
         formDataUpload.append('sub_subject_name', subSubjectSelected);
         formDataUpload.append('sub_subject_id', subSubjectIdSelected);
         formDataUpload.append('file', file);
         //  setLoading(true);
         //  setBtnDisbaled(true);
         response = await axios.post(`${config.baseUrl}books/upload`, formDataUpload, options);
         if (response.status === 200) {
            console.log('uploaded successfully')
            //   history.push(`/books/${params.subject_name}/${subSubjectName}/${subSubjectId}`);
         } else {
            console.log('error uploading book.')
            //   errorDispatch({type: 'SET_SUCCESS', payload: response.message});
            //   setBtnDisbaled(false);
            //   setLoading(false);
            //   history.push(`/books-upload/${subSubjectName}/${subSubjectId}`);
         }

      }
   }

   const handleBulkBookSubmit = async () => {
      formDataUpload.append('file', file);
      const response = await axios.post(`${config.baseUrl}books/bulk-upload`, formDataUpload);
      if (response.status === 200) {
         console.log('books upload successfully.')
      }
      // console,log({type: 'SET_SUCCESS', payload: response.message});
   }

   const handleBulkChapterUpload = async () => {
      // formDataUpload.append('file', file);
      // const response = await axios.post(`${config.baseUrl}books/bulk-upload`, formDataUpload);
      let chkValue = false
      const data = await axios.get(`${config.baseUrl}books/view/${localStorageChapterData.book_id}`, options
      );
      // if (response.status === 200) {
      //    console.log('books upload successfully.')
      // }
      // e.preventDefault();
      let response = null;
      formDataUpload.append("file", file);
      formDataUpload.append("book_id", localStorageChapterData.book_id);
      formDataUpload.append("book_name", localStorageChapterData.book_name);
      formDataUpload.append("book_isbn", localStorageChapterData.book_isbn);
      formDataUpload.append("check", false);
      try {

         if ((data && data.problems === undefined) || (data && data.problems.length > 0) && chkValue) {
            response = await axios.post(
               `${config.baseUrl}chapter/upload`,
               formDataUpload,
               options
            );
         } else {

            response = await axios.post(
               `${config.baseUrl}chapter/update-chapter-csv`,
               formDataUpload,
               options
            );
         }

         if (response && response.data.success) {
            console.log('response.data.message ', response.data.message)

         } else {
            console.log('response.data.errors ', response.data.errors)
         }
      } catch (error) {
         console.log(error);
      }
   }

   return (
      <div className="content-wrapper">
         <div className="page-header">
            <div className="">
               <h4 className="page-title"> Bulk Upload
               </h4>
               <div className="top-breadcrumb">
                  <nav aria-label="breadcrumb">
                     <ol className="breadcrumb ps-0">
                        <li className="breadcrumb-item"><a href="books.php">Books </a></li>
                        <li className="breadcrumb-item active" aria-current="page">Bulk Upload</li>
                     </ol>
                  </nav>
               </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
               {/* <button type="submit" className="btn btn-gradient-primary me-2" onClick="location.href='#'">Upload Book</button> */}
                  {uploadOperation === 'Books Upload' && (
                     <a href="/sampledata/book_simple_upload.csv">
                          <button className="btnbluen1 plwhit d-flex align-items-center">
                                          <i className="mdi mdi-arrow-down mdi-18px"></i> Download Sample File
                                          </button>
                     </a>
                  )}
                    {uploadOperation === 'Books Bulk Upload' && (
                     <a href="/sampledata/book_bulk_upload.csv">
                         <button className="btnbluen1 plwhit d-flex align-items-center">
                                          <i className="mdi mdi-arrow-down mdi-18px"></i> Download Sample File
                                          </button>
                     </a>
                  )}
                    {uploadOperation === 'Books Upload Chapters' && (
                     <a href="/sampledata/chapter_upload-9780131453401.csv">
                         <button className="btnbluen1 plwhit d-flex align-items-center">
                                          <i className="mdi mdi-arrow-down mdi-18px"></i> Download Sample File
                                          </button>
                     </a>
                  )}
            </div>
         </div>
         <div className="row">
            <div className="col-md-12 ">
               <div className="card">
                  <div className="card-body">
                     <div className="row forms-sample">
                        <div className="col-md-12 form-group">
                           <div className="row">
                              {
                                 uploadOperation === 'Books Upload Chapters' ? (
                                    <div className="col-md-3"><input type="radio" id="chapter_upload" value="Books Upload Chapters" checked={uploadOperation === 'Books Upload Chapters'}
                                       onChange={(e) => setUploadOperation(e.target.value)}
                                    />
                                       <label for="javascript" className="mt-1 ms-1">Books Upload Chapters</label></div>
                                 ) : (
                                    <>
                                       <div className="col-md-3"><input type="radio" id="books_upload" value="Books Upload" checked={uploadOperation === 'Books Upload'}
                                          onChange={(e) => setUploadOperation(e.target.value)}
                                       />
                                          <label for="html" className="mt-1 ms-1">Books Upload</label></div>
                                       <div className="col-md-3"><input type="radio" id="books_bulk_upload" value="Books Bulk Upload" checked={uploadOperation === 'Books Bulk Upload'}
                                          onChange={(e) => setUploadOperation(e.target.value)}
                                       />
                                          <label for="css" className="mt-1 ms-1">Books Bulk Upload</label></div>
                                    </>
                                 )
                              }


                           </div>
                        </div>
                        {
                           uploadOperation === 'Books Upload' && (
                              <div className='row'>
                                 <div className="col-md-6 form-group">
                                    <label className="d-flex justify-content-between align-items-center">Subject Name</label>
                                    <select className="form-select" onChange={(e) => {
                                       setSubjectSelected(e.target.value)
                                       getSubjectId(e.target.value)
                                    }
                                    }>
                                       <option value="">Select Subject </option>
                                       {
                                          subjectArr.map((subs) => {
                                             return (
                                                <option value={subs.subject} key={subs._id} >{subs.subject}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>
                                 <div className="col-md-6 form-group">
                                    <label className="d-flex justify-content-between align-items-center">Sub Subject Name</label>
                                    <select className="form-select" onChange={(e) => {
                                       setSubSubjectSelected(e.target.value)
                                       getSubSubjectId(e.target.value)
                                    }
                                    }>
                                       <option>Select Sub Subject </option>
                                       {
                                          subSubjectArr.map((subs) => {
                                             return (
                                                <option value={subs.sub_subject} key={subs._id}>{subs.sub_subject}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>
                                 <div className="col-md-12 ms-auto me-auto">
                                    <div className="form-group">
                                       <label>Upload CSV File </label>
                                       <label for="et_pb_contact_brand_file_request_0" className="et_pb_contact_form_label"></label>
                                       <input type="file" id="et_pb_contact_brand_file_request_0" className="file-upload" accept=".csv"
                                          onChange={handelChangeUpload}
                                       />
                                    </div>
                                    <div className="col-md-12 text-center">
                                       <button type="submit" className="btn btn-gradient-primary me-2" onClick={handleSubmit}>Upload</button>
                                       {/* <button className="btnbluen1 plwhit">Save in Draft </button> */}
                                    </div>
                                 </div>
                              </div>
                           )
                        }
                        {
                           uploadOperation === 'Books Bulk Upload' && (
                              <div className='row'>
                                 <div className="col-md-12 ms-auto me-auto">
                                    <div className="form-group">
                                       <label>Upload CSV File </label>
                                       <label for="et_pb_contact_brand_file_request_0" className="et_pb_contact_form_label"></label>
                                       <input type="file" id="et_pb_contact_brand_file_request_0" className="file-upload" accept=".csv"
                                          onChange={handelChangeUpload}
                                       />
                                    </div>
                                    <div className="col-md-12 text-center">
                                       <button type="submit" className="btn btn-gradient-primary me-2" onClick={handleBulkBookSubmit}>Upload</button>
                                       {/* <button className="btnbluen1 plwhit">Save in Draft </button> */}
                                    </div>
                                 </div>
                              </div>
                           )
                        }

                        {
                           uploadOperation === 'Books Upload Chapters' && (
                              <div className='row'>
                                 <div className="col-md-12 ms-auto me-auto">
                                    <div className="form-group">
                                       <label>Upload CSV File </label>
                                       <label for="et_pb_contact_brand_file_request_0" className="et_pb_contact_form_label"></label>
                                       <input type="file" id="et_pb_contact_brand_file_request_0" className="file-upload" accept=".csv"
                                          onChange={handelChangeUpload}
                                       />
                                    </div>
                                    <div className="col-md-12 text-center">
                                       <button type="submit" className="btn btn-gradient-primary me-2" onClick={handleBulkChapterUpload}>Upload</button>
                                       {/* <button className="btnbluen1 plwhit">Save in Draft </button> */}
                                    </div>
                                 </div>
                              </div>
                           )
                        }
                        {/* {
                           uploadOperation !== 'Books Upload Chapters' && ( */}
                              <BulkUploadTable tableData={tableData} uploadOperation={uploadOperation}/>
                           {/* ) */}
                        {/* } */}

                     </div>
                  </div>
               </div>

            </div>
         </div>
      </div>
   )
}
export default BookBulkUpload