import React, {useContext, useCallback, useState, useEffect, useRef} from 'react'
import './Chat.css'
import { useSocket } from 'src/contexts/SocketProvider';
import { MakeSlug } from 'src/utils/CommonUtils';
import { getAllChats } from 'src/api/MentorChatApi';
import saveChat from 'src/api/MentorChatApi';
import {saveFiles} from 'src/api/MentorChatApi'

const AdminChat = ({studentId, mentorId, category,showChatBox}) => {
   const lastMessageRef = useRef(null);
   const hiddenFileInput = useRef(null);
   const [_window, setWindow] = useState(null)
    const socket = useSocket('https://admin.crazyforstudy.com') 
   // const socket = useSocket('http://localhost:8000')
    const [inputMessage, setInputMessage] = useState('')
    const [messagesArr, setMessagesArr] = useState([])
    const [mentorCategory, setMentorCategory] = useState('')
    const [showSpeakIcon, setShowSpeakIcon] = useState(false)
    const [speechRecognition, setSpeechRecognition] = useState(false)
    const [mic,setMic] = useState(null)
    const [incomingMsg, setIncomingMsg] = useState([])
    const [outgoingMsg,setOutgoingMsg] = useState([])
    const [allMessages,setAllMessages] = useState([])
    const [attachFileData,setAttachFileData] = useState(null)
    const [studentIds, setStudentIds] = useState([])
    const [activeStudentId,setActiveStudentId] = useState('')

    useEffect(()=>{
      if(socket && studentId !== '' && showChatBox)
        {socket.emit('subscribe',{
            id: mentorId,
            role:'tutor',
            room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`,
            category:category
          })}
    },[studentId])
  
    const getRoomID = () => {
       return `${studentId}-${MakeSlug(category)}-${mentorId}`
    }

   useEffect(  ()=>{
      if(studentId !== ''){
         setAllMessages([])
         const response =   getAllChats({room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`})
         console.log('chats are',response.data,studentId,category,mentorId, `${studentId}-${MakeSlug(category)}-${mentorId}`)
         response.then((res)=>{
           console.log('chats are',res.data,studentId,category,mentorId, `${studentId}-${MakeSlug(category)}-${mentorId}`)
           setAllMessages(res?.data?.data?.conversations)
         })
      }
     
      return () => {
        console.log("This will be logged on unmount");
      }

   },[studentId])


   useEffect(()=>{
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    setMic(new SpeechRecognition())
    setMic((prev)=>({
      ...prev,
      'continuous':true,
      'interimResults':true,
      'lang':"en-US"
    }))
   },[])

   useEffect(()=>{
if(showSpeakIcon){
   const toRef  = setTimeout(()=>{
      setShowSpeakIcon(false)
      clearTimeout(toRef)
   },2000)
}
   },[showSpeakIcon])

    useEffect(() => {
      if(socket){
        socket.on('messageResponse', (data) => {
         console.log('student message is',data)
         setAllMessages((prev)=>{
            if(prev !== undefined){
               return [...prev,data]
             }else{
               return [data]
             }}
            )
      });
      }
      }, [socket]);


      const handleSendMessage = async (inputText,fileData) => {
         if(fileData !== null){
            const fileUrl = await saveFiles(fileData)
            if(fileUrl){
               socket.emit('mentorResponse',{
                  text:'',
                  attachment:fileUrl.data.data,
                  userId:mentorId,
                  room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`,
                  category:'Competitive Exams'
               })
               saveChat({
                sender_id:mentorId,
                mentor_id:mentorId,
                category:category,
                conversations:[{
                   text: '',
                userId:mentorId,
                attachment:fileUrl.data.data,
                   userRole:'tutor',
                   isSeen:false,
                   createdAt:new Date()
                }],
                room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`
              }).then(()=>    setAllMessages((prev)=>{
                if(prev !== undefined){
                  return [...prev,{
                  
                      text: '',
                      attachment:fileUrl.data.data,
                      userRole:'tutor',
                      isSeen:false,
                      createdAt:new Date()
                   
                  }]
                }else{
                  return [{ 
                   
                      text: inputMessage.trim(),
                   userId:mentorId,
                   room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`,
                   category:category,
                   attachment:'',
                      userRole:'tutor',
                      isSeen:false,
                      createdAt:new Date()
                   ,
                   room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`}]
                }
             }))
            }
          
         }
         if( inputMessage!== ''){
            
            socket.emit('mentorResponse', {
               text: inputMessage.trim(),
               userId:mentorId,
               room_id:getRoomID(),
               category:'Competitive Exams',
               attachment:''
             });
             saveChat({
              sender_id:mentorId,
              mentor_id:mentorId,
              category:category,
              conversations:[{
                 text: inputMessage.trim(),
              userId:mentorId,
              attachment:'',
                 userRole:'tutor',
                 isSeen:false,
                 createdAt:new Date()
              }],
              room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`
            }).then(()=>    setAllMessages((prev)=>{
              if(prev !== undefined){
                return [...prev,{
                
                    text: inputMessage.trim(),
                    attachment:'',
                    userRole:'tutor',
                    isSeen:false,
                    createdAt:new Date()
                 
                }]
              }else{
                return [{ 
                 
                    text: inputMessage.trim(),
                 userId:mentorId,
                 room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`,
                 category:category,
                 attachment:'',
                    userRole:'tutor',
                    isSeen:false,
                    createdAt:new Date()
                 ,
                 room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`}]
              }
           }))
         }
         
          setInputMessage('')
      }

      const handleSpeechIcon = () => {
         setShowSpeakIcon(!showSpeakIcon)
         setSpeechRecognition(!speechRecognition)
         if(speechRecognition){
            mic.start();
            mic.onend = () => {
              console.log("continue..");
              mic.start();
            };
          } else {
            // mic.stop();
            mic.onend = () => {
              console.log("Stopped Mic on Click");
            };
         }
         mic.onstart = () => {
            console.log("User Mic is on.");
          };
          mic.onresult = (event) => {
            const transcript = Array.from(event.results)
              .map((result) => result[0])
              .map((result) => result.transcript)
              .join("");
            console.log(transcript);
            setInputMessage(transcript);
            mic.onerror = (event) => {
              console.log(event.error);
            };
          };
      }

      const handleInputFileChange = async(event) => {
         const file = event.target.files[0];
         console.log('file selected',event.target.files[0])
         const formData = new FormData()
         formData.append("file",file)
         formData.append("title",file.name)
         for (let key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
        setAttachFileData(formData)
      }

      const handleUploadFileButton = (event) => {
         hiddenFileInput.current.click();
      }

      const handleInputKeydown = (e) => {
 if(e.key ==='Enter'){
  if( inputMessage!== ''){
            
    socket.emit('mentorResponse', {
       text: inputMessage.trim(),
       userId:'611bbad2041edc60f449a157',
       room_id:getRoomID(),
       category:'Competitive Exams',
       attachment:''
     });
     saveChat({
      sender_id:mentorId,
      mentor_id:mentorId,
      category:category,
      conversations:[{
         text: inputMessage.trim(),
      userId:mentorId,
      attachment:'',
         userRole:'tutor',
         isSeen:false,
         createdAt:new Date()
      }],
      room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`
    }).then(()=>    setAllMessages((prev)=>{
      if(prev !== undefined){
        return [...prev,{
        
            text: inputMessage.trim(),
            attachment:'',
            userRole:'tutor',
            isSeen:false,
            createdAt:new Date()
         
        }]
      }else{
        return [{ 
         
            text: inputMessage.trim(),
         userId:mentorId,
         room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`,
         category:category,
         attachment:'',
            userRole:'tutor',
            isSeen:false,
            createdAt:new Date()
         ,
         room_id:`${studentId}-${MakeSlug(category)}-${mentorId}`}]
      }
   }))
 }
    setInputMessage('')
 }
      }


return (
    <>
      <div className='body'>
 {allMessages.length > 0 && (
  allMessages.map((msg)=>{
    return(
      <div className={msg.userRole==='tutor' ? 'outgoing':'incoming'} key={msg._id}>
      <div className={msg.userRole === 'tutor' ? 'bubble lower':'bubble'}>
      {msg.text !== '' ? (
                  <p>{msg.text}</p>
               ):(
                  <a href={msg.attachment} target='_blank'>
                       <i className="mdi mdi-file-download"></i>
                  </a>
               )}
      </div>
    </div>
    )
  })
 )}
 </div>
 <div className="foot">
				<input type="text" className="msg" placeholder="Type a message..." value={inputMessage} onChange={(e)=> setInputMessage(e.target.value)} onKeyUp={(e) => handleInputKeydown(e)} />
            <button type='button' className="start-recodingBtn border-left-0" onClick={(e) => handleUploadFileButton(e)}><input type="file" style={{display:'none'}} ref={hiddenFileInput}  onChange={(e) => handleInputFileChange(e)}/><i className="mdi mdi-attachment"></i></button>
				{/* <button type="button" className="start-recodingBtn border-left-0" onClick={handleSpeechIcon}><i className="mdi mdi-microphone"></i></button> */}
				<button type="submit" onClick={() => handleSendMessage(inputMessage,attachFileData)}><i className="mdi mdi-send"></i></button>
				
			</div>
    </>
)
}

export default AdminChat