/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from 'src/utils/RequestUtil';


export const createSubjectAPI = function (data) {
    const url = `${config.baseUrl}subject/create`;
    return postRequest({
        url,
        data,
    });
};

export const updateQASeoSubject = function (data,paramsId){
    const url = `${config.baseUrl}subject/update-QA/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const updateTBSeoSubject = function (data,paramsId){
    const url = `${config.baseUrl}subject/update-textbook/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const updateQASeoSubSubject = function (data, paramsId){
    const url = `${config.baseUrl}sub-subject/update-QA/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const updateQASubjectStudentReview = function(data,paramsId){
    const url = `${config.baseUrl}subject/save-reviews-qa/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const updateQASubSubjectStudentReview = function(data,paramsId){
    const url = `${config.baseUrl}sub-subject/save-reviews-qa/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const getQASubjectStudentReview = function(paramsId){
    const url = `${config.baseUrl}subject/review-qa/${paramsId}`
    return getRequest({
        url
    })
}

export const getQASubSubjectStudentReview = function(paramsId){
    const url = `${config.baseUrl}sub-subject/review-qa/${paramsId}`
    return getRequest({
        url,
        // data
    })
}

export const deleteQASubjectStudentReview = function(paramsId, reviewId) {
    const url = `${config.baseUrl}subject/delete-review-qa/${paramsId}/${reviewId}`
    return deleteRequest({
        url
    })
}

export const deleteQASubSubjectStudentReview = function(paramsId, reviewId) {
    const url = `${config.baseUrl}sub-subject/delete-review-qa/${paramsId}/${reviewId}`
    return deleteRequest({
        url
    })
}

export const saveContentSubjectQA = function(data,paramsId) {
    const url = `${config.baseUrl}subject/save-content-qa/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const saveContentSubjectTB = function(data,paramsId) {
    const url = `${config.baseUrl}subject/save-content/${paramsId}`
    return patchRequest({
        url,
        data
    })
}


export const saveContentSubSubjectQA = function(data,paramsId) {
    const url = `${config.baseUrl}sub-subject/save-content-qa/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const getSingleSubjectAPI = function(data){
    const url = `${config.baseUrl}subject/view/${data}`
    return getRequest({
        url,
        data
    })
}

export const subSubjectCreateAPI = function(data){
    const url = `${config.baseUrl}sub-subject/create`
    return postRequest({
        url,
        data
    })
}



export async function getSubSubjects() {
    const url = `${config.baseUrl}sub-subject/all`
    return getRequest({
        url
    })
}

export const getSingleSubSubjectAPI = function(paramsId){
    const url = `${config.baseUrl}sub-subject/view/${paramsId}`
    return getRequest({
        url
    })
}

export const getAllAdmins = function(){
    const url = `${config.baseUrl}master-admin/view-all`
    return getRequest({
        url
    })
}

export const getAllMasterRoles = function() {
    const url = `${config.baseUrl}master-role/view-all`
    return getRequest({
        url
    })
} 

export const createAdminMaster = function(data){
    const url = `${config.baseUrl}master-admin/create`
    return postRequest({
        url,
        data
    })
}

export const updateAdminMaster = function(data, paramsId){
    const url = `${config.baseUrl}master-admin/update/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const deleteAdminMaster = function(adminId){
    const url = `${config.baseUrl}master-admin/delete/${adminId}`
    return deleteRequest({
        url
    })
}

export const getSingleAdminView  = function(adminId){
    const url = `${config.baseUrl}master-admin/view/${adminId}`
    return getRequest({
        url
    })
}

export const getRoleModules = function(){
    const url = `https://admin.crazyforstudy.com/api/v1/master-module/view-all`
    return getRequest({
        url
    })
}

export const createRoleMaster = function(data){
    const url = `${config.baseUrl}master-role/create`
    return postRequest({
        url,
        data
    })
}

export const getSingleRoleView = (paramsId) => {
    const url = `${config.baseUrl}master-role/view/${paramsId}`
    return getRequest({
        url
    })
}

export const updateRoleMaster = function(data, paramsId){
    const url = `${config.baseUrl}master-role/update/${paramsId}`
    return patchRequest({
        url,
        data
    })
}

export const deleteRoleMaster  = function(roleId){
    const url = `${config.baseUrl}master-role/delete/${roleId}`
    return deleteRequest({
        url
    })
}

