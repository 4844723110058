import { put, call } from 'redux-saga/effects';
import { expertSearchAPI, expertCountAPI, expertCreateAPI, expertUpdateAPI } from 'src/api/ExpertApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { EXPERT_ACTIONS } from '../actions/ExpertActions';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true, expertType
} = {}) {
    try {
        const {
            data: {
                data,
                total,
                // groupCounter,
                // allTimeWebRegistered,
                // allTimeAppRegistered
            },
        } = yield call(expertSearchAPI, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
            expertType
            // allTimeWebRegistered,
            // allTimeAppRegistered
        });

        yield put({
            type: EXPERT_ACTIONS.EXPERT_SEARCH_SUCCESS,
            data: data,
            // groupCounter:groupCounter,
            total: total,
            // allTimeWebRegistered:allTimeWebRegistered,
            // allTimeAppRegistered:allTimeAppRegistered
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: EXPERT_ACTIONS.EXPERT_SEARCH_ERROR,
        });
        return null;
    }
}

function* countExpert() {
    try {
        const {
            data: {
                data,
                total,
                // groupCounter,
                // allTimeWebRegistered,
                // allTimeAppRegistered
            },
        } = yield call(expertCountAPI);

        yield put({
            type: EXPERT_ACTIONS.EXPERT_COUNT,
            expertCounts: data,
        });
        return data;
    }
    catch (error) {
        console.error('LOG: function*countExpert -> error', error);
        // yield put({
        //     type: EXPERT_ACTIONS.EXPERT_SEARCH_ERROR,
        // });
        return null;
    }
}

function* insertExpert({ payload, param }) {
    try {
        const {
            data: {
                data: [data],
            },
        } = yield call(expertCreateAPI, payload, param);

        yield put(headerShowAction({
            data: 'Created',
        }));

        // add in reducer

        yield put({
            type: EXPERT_ACTIONS.EXPERT_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id
                // _id: data._id,
            },
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: EXPERT_ACTIONS.EXPERT_UPSERT_ERROR,
        });
    }
}

function* edit({ where, expertType }) {
    try {
        const {
            data: {

                data,

            },
        } = yield call(expertSearchAPI, {
            where,
            expertType,
            autopopulate: false,
        });

        if (!data || !data[0]) {
            // DATA NO FOUND
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }
        // add in reducer
        yield put({
            type: EXPERT_ACTIONS.EXPERT_UPSERT_SUCCESS,
            data: data[0],
            expertType: expertType
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function *upsert({
    id,
    payload
}){
     if (id) {
        yield update(id, payload);
    }
    else {
        yield insertExpert(payload);
    }
}

function* update(expertId, payload) {
    try {
        yield call(expertUpdateAPI, {
            expertId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        yield put({
            type: EXPERT_ACTIONS.EXPERT_RESET,
        });

        // yield edit({ _id: expertId });
    }
    catch (error) {
        console.error('LOG: upsert expert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: EXPERT_ACTIONS.EXPERT_UPSERT_ERROR,
        });
    }
}

export default [
    takeFirstSagaUtil(EXPERT_ACTIONS.EXPERT_SEARCH, search),
    takeFirstSagaUtil(EXPERT_ACTIONS.EXPERT_COUNT, countExpert),
    takeFirstSagaUtil(EXPERT_ACTIONS.EXPERT_UPSERT, upsert),
    takeFirstSagaUtil(EXPERT_ACTIONS.EXPERT_EDIT, edit)
];