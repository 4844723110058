/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';


export const announcementCreateAPI = function (data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) { 
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}dashboard/announcement/save`;
    return postRequest({
        url,
        data: data,
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // },
    });
};

export const announcementUpdateAPI = function ({
    announcementId,
    data,
}) {
    // const fd = new FormData();
    // Object.keys(data).forEach((key) => {
    //     if (Array.isArray(data[key])) {
    //         const jsonString = JSON.stringify(data[key]);
    //         fd.append(key, jsonString); 
    //     } else {
    //         fd.append(key, data[key]);
    //     }
    // });
    const url = `${config.baseUrl}dashboard/announcement/update/${announcementId}`;
    return putRequest({
        url,
        data: data,
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // },
    });
};

export const announcementSearchAPI =  function (data) {
    const url = `${config.baseUrl}dashboard/announcement/search`;

    return postRequest({
        url,
        data,
    });
};
