export const SOS_ACTIONS = {
    SOS_SEARCH:'SOS_SEARCH',
    SOS_SEARCH_SUCCESS: 'SOS_SEARCH_SUCCESS',
    SOS_SEARCH_ERROR: 'SOS_SEARCH_ERROR',
}

export const sosSearchAction = (payload) => ({
    type:SOS_ACTIONS.SOS_SEARCH,
    ...payload
})

export default {
    SOS_ACTIONS,
    sosSearchAction
}