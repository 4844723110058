import React from "react"
const RatingReviewContainer=()=>{
    return(
        <>
        <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2" >
                        <i className="mdi mdi-home"></i>
                        </span> Rating and Reviews
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="card shadow">
                           <div className="card-body">
                              <div className="tabsnrstl1 tabsnrstl-m4">
                                 <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                       <div className="nav-link active" id="pills-tab1" data-bs-toggle="pill" data-bs-target="#pills-1" type="button" role="tab" aria-controls="pills-1" aria-selected="true">
                                          <div className="stretch-card asigntabnew1">
                                             <div className="card-img-holder">
                                                <div className="card-body">
                                                   <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="valutext">8,588</span>
                                                   </h4>
                                                   <h2 className="mt-0"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Total Rating & Reviews </h2>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <div className="nav-link" id="pills-tab2" data-bs-toggle="pill" data-bs-target="#pills-2" type="button" role="tab" aria-controls="pills-2" aria-selected="false">
                                          <div className="stretch-card asigntabnew1">
                                             <div className="card-img-holder">
                                                <div className="card-body">
                                                   <h4 className="font-weight-normal mb-3 d-flex align-items-center"> <span className="valutext">4,858</span>
                                                   </h4>
                                                   <h2 className="mt-0"><i className="mdi mdi-checkbox-blank-circle text-green"></i> Total Live Reviews   </h2>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <div className="nav-link" id="pills-tab3" data-bs-toggle="pill" data-bs-target="#pills-3" type="button" role="tab" aria-controls="pills-3" aria-selected="false">
                                          <div className="stretch-card asigntabnew1">
                                             <div className="card-img-holder">
                                                <div className="card-body">
                                                   <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="valutext">358</span>
                                                   </h4>
                                                   <h2 className="mt-0"><i className="mdi mdi-checkbox-blank-circle text-yellow"></i> Pending Reviews   </h2>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                       <div className="nav-link" id="pills-tab4" data-bs-toggle="pill" data-bs-target="#pills-4" type="button" role="tab" aria-controls="pills-4" aria-selected="false">
                                          <div className="stretch-card asigntabnew1">
                                             <div className="card-img-holder">
                                                <div className="card-body">
                                                   <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="valutext">65,886</span>
                                                   </h4>
                                                   <h2 className="mt-0"><i className="mdi mdi-checkbox-blank-circle text-red"></i> Reject Reviews   </h2>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </li> 
                                 </ul>
                              </div>
                              <div className="tab-content" id="pills-tabContent">
                                 <div className="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-tab1">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                             <div className="dateinydt"> 
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Total Rating & Reviews Details  </h4>
                                                         </div>
                                                          <div className="d-flex align-items-center"> 
															 <div className="me-2">
                                                            <select className="form-select">
                                                               <option>Expertise </option>
                                                            </select>
                                                         </div>
                                                            <div className="sltbsxb1">
                                                               <div className="button-group">
                                                                  <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                                                  <ul className="dropdown-menu dropdown-menu-end">
                                                                     <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  </ul>
                                                               </div>
                                                            </div>  
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> 
                                             <div className="table-responsive">
                                                <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>User ID  </th> 
                                                         <th>User Name  </th>  
                                                         <th>Email ID  </th>  
                                                         <th>Date & Time  </th>  
                                                         <th>Expertise  </th>  
                                                         <th>Subject  </th>  
                                                         <th>Country  </th>   
                                                         <th>Gender  </th>  
                                                         <th>Rating  </th>  
                                                         <th>Review </th>   
                                                         <th>Published Date & Time  </th> 
                                                         <th>Mediator  </th>   
                                                         <th>Status </th>
                                                         <th>Action </th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-green">Published </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
                                                            <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                               Action
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
                                                               <li><a className="dropdown-item" href="#">Pending</a></li> 
                                                               <li><a className="dropdown-item" href="#">Approved</a></li> 
                                                               <li><a className="dropdown-item" href="#">Reject</a></li> 
                                                            </ul>
                                                            </div>
                                                         </td> 
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-green">Published </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">3</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-yellow">Pending  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">4</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-yellow">Pending  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">5</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-red">Rejected  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">6</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-red">Rejected  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr> 
													   <tr>
                                                         <td><span className="sNo">7</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-bold">  Re-Sent  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>
                                                       
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="pills-2" role="tabpanel" aria-labelledby="pills-tab2">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                             <div className="dateinydt"> 
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Total Live Reviews</h4>
                                                         </div>
                                                         <div className="d-flex align-items-center"> 
															 <div className="me-2">
                                                            <select className="form-select">
                                                               <option>Expertise </option>
                                                            </select>
                                                         </div>
                                                            <div className="sltbsxb1">
                                                               <div className="button-group">
                                                                  <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                                                  <ul className="dropdown-menu dropdown-menu-end">
                                                                     <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  </ul>
                                                               </div>
                                                            </div>  
                                                         </div>
														  
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>User ID  </th> 
                                                         <th>User Name  </th>  
                                                         <th>Email ID  </th>  
                                                         <th>Date & Time  </th>  
                                                         <th>Expertise  </th>  
                                                         <th>Subject  </th>  
                                                         <th>Country  </th>   
                                                         <th>Gender  </th>  
                                                         <th>Rating  </th>  
                                                         <th>Review </th>   
                                                         <th>Published Date & Time  </th> 
                                                         <th>Mediator  </th>   
                                                         <th>Status </th>
                                                         <th>Action </th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-green">Published </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-green">Published </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>  
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="pills-3" role="tabpanel" aria-labelledby="pills-tab3">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                             <div className="dateinydt">
                                                 
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Pending Reviews </h4>
                                                         </div>
                                                         <div className="d-flex align-items-center"> 
															 <div className="me-2">
                                                            <select className="form-select">
                                                               <option>Expertise </option>
                                                            </select>
                                                         </div>
                                                            <div className="sltbsxb1">
                                                               <div className="button-group">
                                                                  <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                                                  <ul className="dropdown-menu dropdown-menu-end">
                                                                     <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  </ul>
                                                               </div>
                                                            </div>  
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                 <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>User ID  </th> 
                                                         <th>User Name  </th>  
                                                         <th>Email ID  </th>  
                                                         <th>Date & Time  </th>  
                                                         <th>Expertise  </th>  
                                                         <th>Subject  </th>  
                                                         <th>Country  </th>   
                                                         <th>Gender  </th>  
                                                         <th>Rating  </th>  
                                                         <th>Review </th>   
                                                         <th>Published Date & Time  </th> 
                                                         <th>Mediator  </th>   
                                                         <th>Status </th>
                                                         <th>Action </th>
                                                      </tr>
                                                   </thead>
                                                   <tbody> 
													   <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-yellow">Pending  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-yellow">Pending  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
  <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   Action
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
    <li><a className="dropdown-item" href="#">Pending</a></li> 
    <li><a className="dropdown-item" href="#">Approved</a></li> 
    <li><a className="dropdown-item" href="#">Reject</a></li> 
  </ul>
</div>
                                                         </td> 
                                                      </tr>  
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="pills-4" role="tabpanel" aria-labelledby="pills-tab4">
                                    <div className="col-12 grid-margin  mt-4">
                                       <div className="card">
                                          <div className="card-body ps-0 pe-0">
                                             <div className="dateinydt"> 
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      <div className="d-flex justify-content-between align-items-center mb-4 sltbsxb101">
                                                         <div className="">
                                                            <h4 className="card-title">Reject Reviews</h4>
                                                         </div>
                                                          <div className="d-flex align-items-center"> 
															 <div className="me-2">
                                                            <select className="form-select">
                                                               <option>Expertise </option>
                                                            </select>
                                                         </div>
                                                            <div className="sltbsxb1">
                                                               <div className="button-group">
                                                                  <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">Manage Column <span className="caret"></span></button>
                                                                  <ul className="dropdown-menu dropdown-menu-end">
                                                                     <li><a href="#" data-value="option1" tabIndex="-1"><label className="cheboxNw"> Assmt. ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option2" tabIndex="-1"><label className="cheboxNw">Student ID<input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option3" tabIndex="-1"><label className="cheboxNw">Student Name <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option4" tabIndex="-1"><label className="cheboxNw">Student Email Id <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option5" tabIndex="-1"><label className="cheboxNw">Student Contact N. <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option6" tabIndex="-1"><label className="cheboxNw">Gender <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option7" tabIndex="-1"><label className="cheboxNw">Country <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option8" tabIndex="-1"><label className="cheboxNw">Sub Subject <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option10" tabIndex="-1"><label className="cheboxNw">Service Type  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option11" tabIndex="-1"><label className="cheboxNw">Additional Added Service <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option12" tabIndex="-1"><label className="cheboxNw">Number of Page <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option13" tabIndex="-1"><label className="cheboxNw">Words Count <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option14" tabIndex="-1"><label className="cheboxNw">Attachment   <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option15" tabIndex="-1"><label className="cheboxNw">Frontend Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option16" tabIndex="-1"><label className="cheboxNw">Deadline left  <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option17" tabIndex="-1"><label className="cheboxNw">Amount    <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option18" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                     <li><a href="#" data-value="option19" tabIndex="-1"><label className="cheboxNw">Assign to a Expert <input type="checkbox"/> <span className="checkmark"></span> </label> </a></li>
                                                                  </ul>
                                                               </div>
                                                            </div>  
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-responsive">
                                                 <table className="table table-center datatablefnts datatable">
                                                   <thead>
                                                      <tr>
                                                         <th>S. No   </th>
                                                         <th>User ID  </th> 
                                                         <th>User Name  </th>  
                                                         <th>Email ID  </th>  
                                                         <th>Date & Time  </th>  
                                                         <th>Expertise  </th>  
                                                         <th>Subject  </th>  
                                                         <th>Country  </th>   
                                                         <th>Gender  </th>  
                                                         <th>Rating  </th>  
                                                         <th>Review </th>   
                                                         <th>Published Date & Time  </th> 
                                                         <th>Mediator  </th>   
                                                         <th>Status </th>
                                                         <th>Action </th>
                                                      </tr>
                                                   </thead>
                                                   <tbody> 
													   <tr>
                                                         <td><span className="sNo">1</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-red">Rejected  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
                                                            <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                               Action
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
                                                               <li><a className="dropdown-item" href="#">Pending</a></li> 
                                                               <li><a className="dropdown-item" href="#">Approved</a></li> 
                                                               <li><a className="dropdown-item" href="#">Reject</a></li> 
                                                            </ul>
                                                            </div>
                                                         </td> 
                                                      </tr>
													   <tr>
                                                         <td><span className="sNo">2</span> </td>
                                                         <td>Sd2540</td>
                                                         <td>
                                                            <div className="nambtntx"><a href="#">Leonardo Payne </a></div>
                                                         </td>
                                                         <td>
                                                            Leonardopayne34@gmail.com   
                                                         </td>
                                                         <td>
                                                            <div className="text-bold">12 AUG 2022 09:45PM </div> 
                                                         </td>
                                                         <td>Assignment Help</td>
                                                         <td>Math     </td>
														  <td><div className="text-bold">(+1)USA</div>      </td>
                                                         <td>Male</td>
                                                         <td><div className="starratntx">
																<div className="text-bold">4.5</div>
                                                               <div className="text-bold"><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i><i className="mdi mdi-star active"></i></div>
                                                                
                                                            </div>      </td>
                                                         <td><div className=""><button className="btnbluen1" data-bs-toggle="modal" data-bs-target="#assignaexpert-Modal">View </button> </div>      </td>
                                                         <td>
                                                            12 AUG 2022 09:45PM 
                                                         </td>
                                                         <td>Ankit Sharma (4532)</td>
                                                         <td><div className="text-red">Rejected  </div></td>
                                                         <td>
                                                           <div className="dropdown custabldropn">
                                                              <button className="btn dropdown-toggle plwhit" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                               Action
                                                              </button>
                                                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
                                                                <li><a className="dropdown-item" href="#">Pending</a></li> 
                                                                <li><a className="dropdown-item" href="#">Approved</a></li> 
                                                                <li><a className="dropdown-item" href="#">Reject</a></li> 
                                                              </ul>
                                                            </div>
                                                         </td> 
                                                      </tr>  
                                                       
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
								   
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
        </>
    )
}

export default RatingReviewContainer;