import { put, call } from 'redux-saga/effects';
import { questionBuklApproveAPI, questionCountAPI, questionCreateAPI, questionEditSearchAPI, questionSearchAPI, questionUpdateAPI, questionUploadAPI } from 'src/api/QuestionApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { QUESTION_ACTIONS } from '../actions/QuestionActions';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(questionSearchAPI, {
            where,
            // : buildSearchWithNoCase({
            //     where,
            //     fullTextSearch,
            // }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: QUESTION_ACTIONS.QUESTION_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: QUESTION_ACTIONS.QUESTION_SEARCH_ERROR,
        });
        return null;
    }
}
function* questionCount({starDate=null,endDate=null,reqSource=null}){
 try{
    const { data: { data } } = yield call(questionCountAPI,{starDate,endDate,reqSource})
    yield put({
        type: QUESTION_ACTIONS.QUESTION_COUNT_SUCCESS,
        data,
    });
    return data;
 }
 catch (error) {
     yield put({
         type: QUESTION_ACTIONS.QUESTION_SEARCH_ERROR,
     });
     return null;
 }
}

function* edit({ _id }) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(questionEditSearchAPI, {
            where: {
                _id,
            },
            pageLength:1,
            currentPage:0,
            autopopulate: false,
        });

        if (!data || !data[0]) {
            // DATA NO FOUND
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }

        // add in reducer
        yield put({
            type: QUESTION_ACTIONS.QUESTION_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(questionCreateAPI, payload);

        yield put(headerShowAction({
            data: 'Created',
        }));

        // add in reducer
        yield put({
            type: QUESTION_ACTIONS.QUESTION_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: QUESTION_ACTIONS.QUESTION_UPSERT_ERROR,
        });
    }
}

function* uploadQuestions(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(questionUploadAPI, payload);

        yield put(headerShowAction({
            data: 'Uploaded',
        }));
        console.log('data=>',data)

        // add in reducer
        // yield put({
        //     type: QUESTION_ACTIONS.QUESTION_UPSERT_SUCCESS,
        //     data: {
        //         ...payload,
        //         _id: data._id,
        //     },
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: QUESTION_ACTIONS.QUESTION_UPSERT_ERROR,
        });
    }
}

function* update(questionId, payload) {
    try {
        yield call(questionUpdateAPI, {
            questionId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));

        // yield edit({ _id: questionId });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: QUESTION_ACTIONS.QUESTION_UPSERT_ERROR,
        });
    }
}
function * bulkApproval(data){
    try{
    const count = data.selectedQuestions.length;
     yield call(questionBuklApproveAPI,{...data})
     yield put(headerShowAction({
        data: `Approved ${count} QnA's`,
    }));

    } 
    catch(error){
      yield put(headerShowAction({
        //  data:error.response.data.data,
      }));
      yield put({
        type:QUESTION_ACTIONS.QUESTION_UPSERT_ERROR
      })
    }
}

function* upsert({
    _id,
    isUpload,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        if(isUpload)
        yield uploadQuestions(payload);
        else
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(QUESTION_ACTIONS.QUESTION_SEARCH, search),
    takeFirstSagaUtil(QUESTION_ACTIONS.QUESTION_COUNT, questionCount),
    takeFirstSagaUtil(QUESTION_ACTIONS.QUESTION_UPSERT, upsert),
    takeFirstSagaUtil(QUESTION_ACTIONS.QUESTION_EDIT, edit),
    takeFirstSagaUtil(QUESTION_ACTIONS.QUESTION_APPROVED, bulkApproval),
];
