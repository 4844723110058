import React from 'react'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import RoutingUtil from 'src/utils/RoutingUtil'
import { bookEditAction } from './actions/BookActions'
import { useDispatch } from 'react-redux'

const SEOBreadCrumb = ({book_isbn, book_id}) => {

const history = useHistory()
const dispatch = useDispatch()

const handleFaqClick = () => {
    history.push(`/books/faq/${book_isbn}/${book_id}`)
}

const handleSeoClick = () => {
    history.push('/books/authoring')
    localStorage.setItem('form-edit-type','SEO')
}

    return (
        <div className=' mt-2 mb-2'>
        <button className="btn btn-sm counter pe-2 ps-2 ms-2" 
        onClick={handleSeoClick}
        >
                     <span className="mdi mdi-earth mr-2"></span>
                     SEO
                 </button>
                 <button className="btn btn-sm counter pe-2 ps-2 ms-2" onClick={() => handleFaqClick({_id:book_id})}>
                     <span className="mdi mdi-comment-question-outline mr-2"></span>
                     FAQ
                 </button>
                 <button className="btn btn-sm counter pe-2 ps-2 ms-2" onClick={() => history.push(`/books/rating-review/${book_isbn}/${book_id}`)}>
                     <span className="mdi mdi-star mr-2"></span>
                     Rating & Review
                 </button>
                 <button className="btn btn-sm counter pe-2 ps-2 ms-2" onClick={() => history.push(`/books/similar-books/${book_isbn}/${book_id}`)}>
                     <span className="mdi mdi-book mr-2"></span>
                     Similar Books
                 </button>
        </div>
    )
}

export default SEOBreadCrumb
 