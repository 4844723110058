import React from 'react';
import PropTypes from 'prop-types';

const NavigationHeaderComponent = ({
    firstName,
    lastName,
    role,
    status,
    dp,
}) => (
    <div className="sidebar-header">
        <div className="user-pic">
            <img
                className="img-responsive img-rounded"
                src={dp}
                alt="User"
            />
        </div>
        <div className="user-info">
            <span className="user-name">
                {firstName}
                {' '}
                <strong>{lastName}</strong>
            </span>
            <span className="user-role">{role}</span>
            <span className="user-status">
                <i className="fa fa-circle" />
                <span>{status}</span>
            </span>
        </div>
    </div>
);

NavigationHeaderComponent.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    dp: PropTypes.string.isRequired,
};

export default NavigationHeaderComponent;
