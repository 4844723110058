import React from 'react';
import CrudLayoutContainer from 'src/components/crud-layout-component/CrudLayoutContainer';
import RoutingUtil from 'src/utils/RoutingUtil';

// import { cityEditAction, citySearchAction } from './actions/ENquiryActions';
import ResumeWritingList from './ResumeWritingList';
import MentorAssistantList from './MentorAssistantList';
import SOPList from './SOPList';



const routes = [
    {
        link: RoutingUtil.resumeWritingManagerUrl(),
        component: () => (
            <ResumeWritingList/>
        ),
    },
    {
        link: RoutingUtil.mentorAssistantManagerUrl(),
        component: () => (
           <MentorAssistantList/>
        ),
    },
    {
        link: RoutingUtil.SOPManagerUrl(),
        component: () => (
           <SOPList/>
        ),
    },
];

const ENquiryContainer = () => (
    <CrudLayoutContainer
        title="Enquiry Manager"       
        routes={routes}
    />
);

ENquiryContainer.propTypes = {
};

export default ENquiryContainer;
