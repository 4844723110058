import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { ANNOUNCEMENT_ACTIONS } from '../actions/AnnouncementActions';

const initialState = () => ({
    currentRecord: { 
        category:'',
        schedule:{
            startDate:'',
            endDate:''
        },
        announcement_title:'',
        announcement_desc:'',
        createdBy:''
     },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    }, 

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        // SEARCH
        case ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // UPSERT
        case ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_UPSERT: {
            
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

       // RESET
        case ANNOUNCEMENT_ACTIONS.ANNOUNCEMENT_RESET: {
            return {
                ...state,
                currentRecord: {category:'',
                schedule:{
                    startDate:'',
                    endDate:''
                },
                announcement_title:'',
                announcement_desc:'',
                createdBy:''  },
            };
        }

        // LOGOUT
        // case AUTH_ACTIONS.LOGOUT:
        //     return {
        //         ...initialState(),
        //     };

        default:
            return state;
    }
};
