import React, { useEffect, useState } from "react";
import { CKEditor } from 'ckeditor4-react';


const FormikCKEditor=({ onChange,onBlur, editorLoaded, name, value})=>{
  const [editor, setEditor] = useState(null);
    const onBeforeLoad = (e) => {
      setEditor(e.editor);
  }
  
  useEffect(() => {
      if (editor) {
          editor.setData(value);
      }
  }, [value]);

  const handlePaste = (event) => {
    if(event.data.html) {
      const clipboardData = event.data.html;
    onBlur(clipboardData);
    }else{
      const clipboardData = event.data.dataValue;
      onBlur(clipboardData);
    }

  };
  
    return (
      <div style={{ height:"300px" }}>
        {editorLoaded ? (
          <CKEditor
            type="classic"
            style={{overflowX:'hidden'}}
            name={name}
            initData={value}
            onLoaded={onBeforeLoad}            
            onBlur={(event) => {
              onBlur(event.editor.getData());
            }}
            value={value}
            config={{
              on:{
                paste:handlePaste,
              },
            resize_enabled:false
            }}
          />
        ) : (
          <div>Editor loading</div>
        )}
      </div>
    );
}

export default FormikCKEditor;


// import React, { useEffect, useRef, useState } from "react";


// const FormikCKEditor=({ onChange, editorLoaded, name, value })=>{
//     const [editorContent, setEditorContent] = useState("");

//     const editorRef = useRef();
//     const { CKEditor, ClassicEditor } = editorRef.current || {};
  
//     useEffect(() => {
//       editorRef.current = {
//         CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
//         ClassicEditor: require("@ckeditor/ckeditor5-build-classic")
//       };
//     }, []);
  
//     return (
//       <div style={{ height: "500px" }}>
//         {editorLoaded ? (
//           <CKEditor
//             type=""
//             name={name}
//             editor={ClassicEditor}
//             config={{
//               ckfinder: {
//                 // Upload the images to the server using the CKFinder QuickUpload command
//                 // You have to change this address to your server that has the ckfinder php connector
//                 uploadUrl: "" //Enter your upload url
//               },
//               height: '500px'  //Hight prop not working check it later
//             }}
//             data={value}
//             onReady={(editor) => {
//               editor?.editing.view.change((writer) => {
//                 writer.setStyle(
//                   "height",
//                   "500px",
//                   editor.editing.view.document.getRoot()
//                 );
//               });
//               // uploadAdapterPlugin(editor,token);
//             }}
//             onChange={(event, editor) => {
//               const data = editor.getData();
//               // console.log({ event, editor, data })
//               onChange(data);
//             }}
//           />
//         ) : (
//           <div>Editor loading</div>
//         )}
//       </div>
//     );
// }

// export default FormikCKEditor;