import { get } from 'lodash-es';
import { allPossiblePathOfObject } from 'src/utils/CommonUtils';

export const openInGoogleMap = ({ lat, lon }) => `https://maps.google.com/?q=${lat},${lon}`;

const buildSearchUrl = ({
    prefix = '',
    data = {},
} = {}) => {
    const uri = `https://example.com/${prefix}`;
    const url = new URL(uri);

    if (data) {
        const paths = allPossiblePathOfObject(data);

        paths.forEach((path) => {
            const value = get(data, path);
            if (value) {
                url.searchParams.set(path, value);
            }
        });

        // for (const param in data) {
        //     if (Object.prototype.hasOwnProperty.call(data, param)) {
        //         if (data[param]) {
        //             url.searchParams.set(param, data[param]);
        //         }
        //     }
        // }
    }
    return url.pathname + url.search;
};

// BLOG
const blogSearch = (data) => buildSearchUrl({
    prefix: 'blogs/search',
    data,
});
const blogManagerUrl = () => '/blogs/manage';   

// EXPERT
const expertManagerUrl = () => '/experts';  
const createExpertUrl = () => '/experts/create-expert'

// CITY
const citySearch = (data) => buildSearchUrl({
    prefix: 'city/search',
    data,
});
const cityManagerUrl = () => '/city/manage';   

// USER
const userSearch = (data) => buildSearchUrl({
    prefix: 'user/search',
    data,
});
const userManagerUrl = () => '/user/manage';

// PERMISSIONS
const rolePermissionsUrl = () => '/permissions/role';
const userPermissionsUrl = () => '/permissions/user'; 

// CASE MANAGER
const caseManagerUrl = () => '/case/manage';
const caseVehicleQuotationUrl = () => '/case/vehicle-quotation';
const caseVehicleCoverNoteUrl = () => '/case/vehicle-cover-note';
const caseVehicleInsurancePaymentUrl = () => '/case/vehicle-payments';
const CoApplicants = () => '/case/co-applicants';
const caseSearch = (data) => buildSearchUrl({
    prefix: 'case/search',
    data,
});

// BOOK
const bookSearch = (data) => buildSearchUrl({
    prefix: 'books/search',
    data,
});
const bookManagerUrl = () => '/books';
const bookLiveUrl = () => '/books/live';
const bookBulkUploadUrl = () => '/books/bulk-upload';
const bookAuthoringUrl = () => '/books/authoring';
const bookPendingForQCUrl = () => '/books/pending-for-qc';
// const bookFAQUrl = (book_isbn,book_id) => `/books/faq/${book_isbn}/${book_id}`
// const bookFAQUrl = (book_isbn,book_id) => buildSearchUrl({
//     prefix: 'books/faq',
//     data:{
//         book_isbn,
//         book_id
//     }
// });
const bookFAQUrl = () =>  `/books/faq/:book_isbn/:book_id`
const bookRatingUrl = () => `/books/rating-review/:book_isbn/:book_id`
const similarBooksUrl = () => `/books/similar-books/:book_isbn/:book_id`
const bookSEOUrl = () => `/books/seo/:book_isbn/:book_id`

// QUESTION

const questionAllUrl = () => '/questions';   
const questionManagerUrl = () => '/questions/manage';
const questionAskUrl = () => '/questions/ask';
const questionOtherUrl = () => '/questions/other';
const questionMiscellaneousUrl = () => '/questions/miscellaneous';

//
const resumeWritingManagerUrl = () => '/enquiries/resume-writing';
const mentorAssistantManagerUrl = () => '/enquiries/mentor-assistant';
const SOPManagerUrl = () => '/enquiries/SOP';

// Google Anlytics Dashboard
const googleAnalyticsUrlCFS = () => '/google-analytics/crazyforstudy';   
const googleAnalyticsUrlAcadecraft = () => '/google-analytics/acadecraft';   
const googleAnalyticsUrlAcadestudio = () => '/google-analytics/acadestudio';   
const googleAnalyticsUrlAcadereality = () => '/google-analytics/acadereality';   


// Masters Manager

const subjectMasterUrl = () => '/masters/subject'
const subjectMasterCreateUrl = () => '/masters/subject/create'
const subSubjectMasterUrl = () => '/masters/sub-subject'
const subSubjectMasterCreateUrl = () => '/masters/sub-subject/create'
const subjectMasterUpdateUrl = () => '/masters/subject-update/:id'
const subSubjectMasterUpdateUrl = () => '/masters/sub-subject-update/:id'
// MASTERS SUBJECT CONTENT UPDATE URLS
const subjectSeoQAUpdateUrl = () => `/masters/subject-seo/qa/update/:id`
const subjectReviewQAUpdateUrl = () => '/masters/subject-student-reviews/qa/update/:id'
const subjectContentQAUpdateUrl = () => '/masters/subject-content/qa/update/:id'

const subjectSeoTBUpdateUrl = () => '/masters/subject-seo/textbook/update/:id'
const subjectReviewTBUpdateUrl = () => '/masters/subject-student-reviews/textbook/update/:id'
const subjectContentTBUpdateUrl = () => '/masters/subject-content/textbook/update/:id'

const subSubjectSeoQAUpdateUrl = () => `/masters/sub-subject-seo/qa/update/:id`
const subSubjectReviewQAUpdateUrl = () => '/masters/sub-subject-student-reviews/qa/update/:id'
const subSubjectContentQAUpdateUrl = () => '/masters/sub-subject-content/qa/update/:id'
const subSubjectRelatedQuestionQAUpdateUrl = () => '/sub-subject-related-questions/qa/update/:id'

const subSubjectSeoTBUpdateUrl = () => '/masters/sub-subject-seo/textbook/update/:id'
const subSubjectReviewTBUpdateUrl = () => '/masters/sub-subject-student-reviews/textbook/update/:id'
const subSubjectContentTBUpdateUrl = () => '/masters/sub-subject-content/textbook/update/:id'

// ADMIN MASTER MANAGER

const adminMasterUrl = () => '/masters/admin'
const adminCreateUrl = () => '/masters/admin-create'
const adminUpdateUrl = () => '/masters/admin-update/:id'
const roleMasterUrl = () => '/masters/role'
const roleCreateUrl = () => '/masters/role-create'
const roleUpdateUrl = () => '/masters/role-update/:id'

// FAQ MANAGER

const faqManagerUrl =  () => '/faq'
const faqCreateUrl = () => '/faq/manage-faq-category'
const faqEditUrl = () => '/faq/edit-faq-category/:id'

const faqAddQuesUrl = () => '/faq/add-faq-question/:categorySlug?/:id/:questionId?'


export default {
    openInGoogleMap,
    citySearch,
    cityManagerUrl, 
    userSearch,
    userManagerUrl,
    rolePermissionsUrl,
    userPermissionsUrl,  
    caseManagerUrl,
    caseSearch,
    caseVehicleQuotationUrl,
    caseVehicleCoverNoteUrl,
    caseVehicleInsurancePaymentUrl,
    CoApplicants,
    blogSearch,
    blogManagerUrl,
    bookSearch,
    bookManagerUrl,
    bookLiveUrl,
    bookBulkUploadUrl,
    bookAuthoringUrl,
    bookPendingForQCUrl,
    bookFAQUrl,
    bookSEOUrl,
    similarBooksUrl,
    bookRatingUrl,

    questionAllUrl,
    questionManagerUrl,
    questionAskUrl,
    questionOtherUrl,
    questionMiscellaneousUrl,

    resumeWritingManagerUrl,
    mentorAssistantManagerUrl,
    SOPManagerUrl,

    createExpertUrl,
    expertManagerUrl,
    googleAnalyticsUrlCFS,
    googleAnalyticsUrlAcadecraft,
    googleAnalyticsUrlAcadestudio,
    googleAnalyticsUrlAcadereality,


    subjectMasterUrl,
    subSubjectMasterUrl,
    subjectMasterCreateUrl,
    subSubjectMasterCreateUrl,
    subjectSeoQAUpdateUrl,
    subjectReviewQAUpdateUrl,
    subjectContentQAUpdateUrl,
    subjectSeoTBUpdateUrl,
    subjectReviewTBUpdateUrl,
    subjectContentTBUpdateUrl,
    subSubjectSeoQAUpdateUrl,
    subSubjectReviewQAUpdateUrl,
    subSubjectContentQAUpdateUrl,
    subSubjectRelatedQuestionQAUpdateUrl,
    subSubjectSeoTBUpdateUrl,
    subSubjectReviewTBUpdateUrl,
    subSubjectContentTBUpdateUrl,
    subjectMasterUpdateUrl,
    subSubjectMasterUpdateUrl,
    adminMasterUrl,
    adminCreateUrl,
    adminUpdateUrl,
    adminUpdateUrl,
    roleMasterUrl,
    roleCreateUrl,
    roleUpdateUrl,

    faqCreateUrl,
    faqManagerUrl,
    faqEditUrl,
    faqAddQuesUrl
};
