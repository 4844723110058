import {
    call, select, put,
} from 'redux-saga/effects';
import { fetchUserScopesAction } from 'src/containers/auth-container/actions/AuthActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { setDefaultHeaders } from 'src/utils/RequestUtil';
import { APP_ACTIONS } from '../actions/AppActions';

export function* setDefaultHeadersSaga() {
    try {
        // if token is there then set token
        const { AuthReducer: { token } } = yield select();

        setDefaultHeaders({
            Authorization: token,
        });
    }
    catch (error) {
        console.error('setDefaultHeadersSaga', error);
    }
}

// fetch history messages
export function* appInitSaga() {
    try {
        const { AuthReducer: { userId, isLoggedIn } } = yield select();
        if (isLoggedIn) {
            // SET TOKEN IN COMMON HEADERS
            yield call(setDefaultHeadersSaga);

            // FETCH USER PERMISSION SCOPES
            // yield put(fetchUserScopesAction({
            //     userId,
            // }));
        }
    }
    catch (error) {
        console.error('appInitSaga', error);
    }
}

export default [
    takeFirstSagaUtil(APP_ACTIONS.APP_INIT, appInitSaga),
    takeFirstSagaUtil(APP_ACTIONS.SET_DEFAULT_HEADERS, setDefaultHeadersSaga),
];
