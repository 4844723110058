import React, { useEffect, useState } from 'react'
import { countries } from "countries-list"
import { getSubjects } from 'src/api/Assignment'

const CategoryArr = [
   "Assignment Writing Services",
   "Assignment Proofreading Services",
   "Assignment Editing Services",
   "Assignment Graphic Designing Services"
]
const ExpertSearchSelect = ({activeSelectedTabName,handelFilterChange}) => {
   const [category, setCategory] = useState('')
   const [subject, setSubject] = useState('')
   const [subjectArr, setSubjectArr] = useState([])
   const [country, setCountry] = useState('')
   // const [serviceType, setserviceType] = useState('')
   const [experience, setExperience] = useState('')


   useEffect(() => {
      getAllSubjects()
   },[])

   const getAllSubjects = async () => {
      const { data } = await getSubjects()
      setSubjectArr(data)
   }

   useEffect(() => {
setCategory('')
setSubject('')
setCountry('')
setExperience('')
   }, [activeSelectedTabName])

   return (
      <>
         <div className="row">
            <div className="col-md-12">
               <div className="scscm21 scscm21-step5">
                  {/* <div className="">
                     <select className="form-select" value={category} onChange={(e) => setCategory(e.target.value)}>
                        <option>Select Category   </option>
                        {CategoryArr.map((category) => {
                           return (
                              <option value={category} key={category}>{category}</option>
                           )
                        })}
                     </select>
                  </div> */}
                  <div className="">
                     <select className="form-select" value={subject} onChange={(e) => 
                        {
                           setSubject(e.target.value)
                           handelFilterChange({'SubjectExpertise.name':e.target.value})
                        }
                        }>
                        <option>Select Subject  </option>
                        {
                           subjectArr.map((subs) => {
                              return (
                                 <option value={subs.subject} key={subs.subject}>{subs.subject}</option>
                              )
                           })
                        }
                     </select>
                  </div>
                  <div className="">
                     <select className="form-select" value={country} onChange={(e) => 
                        {
                        setCountry(e.target.value)
                        handelFilterChange({Country:e.target.value})
                        }
                     }>
                        <option>Country   </option>
                        {Object.values(countries).map((country) => {
                           return (
                              <option value={country.name} key={country.name}>{country.name}</option>
                           )
                        })}
                     </select>
                  </div>
                  <div className="">
                  <select className="form-select" value={category} onChange={(e) => 
                  {
                     setCategory(e.target.value)
                     handelFilterChange({'services.service':e.target.value,'services.is_service':true})
                  }
                     }>
                        <option>Service Type   </option>
                        {CategoryArr.map((category) => {
                           return (
                              <option value={category} key={category}>{category}</option>
                           )
                        })}
                     </select>
                  </div>
                  <div className="">
                     <select className="form-select" value={experience} onChange={(e) => 
                        {
                        setExperience(e.target.value)
                        handelFilterChange({totalExperience:{'$gte':e.target.value}})
                        }
                     }>
                        <option>Experience </option>
                        <option value={1}>1+ years</option>
                        <option value={2}>2+ years</option>
                        <option value={3}>3+ years</option>
                        <option value={5}>5+ years</option>
                     </select>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default ExpertSearchSelect