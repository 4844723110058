/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { withFormikDevtools } from 'formik-devtools-extension';
import FormikInput from 'src/components/formik/FormikInput';
import { isProduction } from 'src/config/index';
import PropTypes from 'prop-types';
import { usePrevious } from 'src/utils/ReactHooksUtil';
// import './LoginForm.scss';
import { useHistory } from 'react-router-dom';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AuthYupSchema, AuthDefaultProps } from '../AuthPropTypes';
import { confirmCredentialsAction } from '../actions/AuthActions';

const Render = (props) => {
    // DEV TOOL
    if (!isProduction()) {
        withFormikDevtools(props);
    }

    const {
    // errors,
    // touched,
        isSubmitting,
    } = props;

    return ( 
              <Form autoComplete="disabled" className="pt-3"> 
                  <div className="form-group"> 
                <FormikInput
                    name="email"
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="User Name"
                />
                  </div>
                  <div className="form-group">
                    <FormikInput
                    name="password"
                    type="password"
                    className="form-control form-control-lg"
                    placeholder="Password"
                />
                  </div>
                  <div className="mt-3"> 
                    <button
                    type="submit"
                    // disabled={isSubmitting}
                    className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                    >
                    Login
                    </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" /> Keep me signed in </label>
                    </div>
                    <a href="#" className="auth-link text-black">Forgot password?</a>
                  </div>
                  <div className="mb-2">
                    <button type="button" className="btn btn-block btn-facebook auth-form-btn">
                      <i className="mdi mdi-facebook me-2"></i>Connect using facebook </button>
                  </div>                    
        </Form>
    );
};

Render.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
};

// setting submit button state after submitting
let setSubmitting = () => {};

const LoginForm = () => {
    const history = useHistory();
    // REDUX
    const dispatch = useDispatch();
    const { isLoggedIn, loginConfirmationStatus } = useSelector(
        (state) => state.AuthReducer,
        shallowEqual,
    );

    // if current record changed or request status changed the enable submit
    usePrevious(
        {
            loginConfirmationStatus,
        },
        (prev) => {
            if (
                prev.loginConfirmationStatus !== loginConfirmationStatus
        && loginConfirmationStatus !== REQUEST_STATUS.PENDING
            ) {
                setSubmitting(false);
            }
        },
    );

    useEffect(() => {
        if (isLoggedIn) {
            history.push('/');
        }
    }, [
        isLoggedIn,
        history,
    ]);

    const onSubmit = useCallback(
        (values, formikUtils) => { 
            ({ setSubmitting } = formikUtils);
            dispatch(
                confirmCredentialsAction({
                    email: values.email,
                    password: values.password,
                }),
            );
        },
        [
            dispatch,
        ],
    );

    return (
        <> 
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo">
                  <img src="/assets/images/logo.svg" className="img-fluid" alt=""/>
                </div>
                <h4>Hello! let's get started</h4>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                <Formik
                            onSubmit={onSubmit}
                            enableReinitialize
                            initialValues={AuthDefaultProps}
                            validationSchema={AuthYupSchema}
                        >
                            {(formikProps) => <Render {...formikProps} />}
                </Formik>
                
              </div>
            </div>
          </div>
        </div>
      </div>       
    </div>
    </>
    );
};

LoginForm.propTypes = {};

export default LoginForm;
