import {
    put, call, take, fork,
} from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import {
    fetchUserScopesAPI,
    loginAPI,
} from 'src/api/UserApi';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { setDefaultHeaders, addResponseInterceptor } from 'src/utils/RequestUtil';
// import { decodeJwt } from 'src/utils/JwtUtils';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { AUTH_ACTIONS } from '../actions/AuthActions';

export function* fetchUserScopes({
    userId,
}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(fetchUserScopesAPI, {
            userId,
        });

        yield put({
            type: AUTH_ACTIONS.FETCH_PERMISSION_SCOPES_SUCCESS,
            payload: {
                userScopes: data,
            },
        });
    }
    catch (error) {
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        console.error('LoginSaga', error);

        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
    }
}

export function* LoginSaga({ email, password }) {
    if (!email || !password) {
        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
        return;
    }

    try {
        const {
            data: {
                accessToken,
                admin
            },
        } = yield call(loginAPI, {
            email,
            password,
        });

        // const {
        //     data: {
        //         userId,
        //         personalInfo,
        //         userType,
        //         profilePicUrl,
        //     },
        // } = decodeJwt(token);

        setDefaultHeaders({ Authorization:`Bearer ${accessToken}` });

        // fetch user role scopes
        // yield call(fetchUserScopes, {
        //     userId,
        // });

        yield put({
            type: AUTH_ACTIONS.LOGIN_SUCCESS,
            payload: {
                token:`Bearer ${accessToken}`,
                userId:admin._id
                // userId,
                // personalInfo,
                // userType,
                // profilePicUrl,
            },
        });
    }
    catch (error) {
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        console.error('LoginSaga', error);

        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
    }
}

function* requestErrorHandlerSaga() {
    const channel = eventChannel((emitter) => {
        addResponseInterceptor({
            callback: (error) => {
                emitter({ error: { ...error } });
            },
        });
        // Return an unsubscribe method
        return () => {
            emitter(END);
        };
    });

    // Process events until operation completes
    while (true) {
        const { error } = yield take(channel);
        if (!error) {
            break;
        }
        if (error.response.status === 401) {
            yield put({ type: AUTH_ACTIONS.LOGOUT });
        }
    }
}

function* logout() {
    yield localStorage.clear();
}

export default [
    takeFirstSagaUtil(AUTH_ACTIONS.LOGIN, LoginSaga),
    takeFirstSagaUtil(AUTH_ACTIONS.FETCH_PERMISSION_SCOPES, fetchUserScopes),
    takeFirstSagaUtil(AUTH_ACTIONS.LOGOUT, logout),
    fork(requestErrorHandlerSaga),
];
