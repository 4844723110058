

const RevenueOverview=(props)=>{
    const {
        revenueOverview = []
    } = props;
    const totalSubsRevenue = revenueOverview.length>0 && revenueOverview[0]?.subscription.reduce((total, item) => {
        return total + item.totalRevenue;
      }, 0);
      const totalAssRevenue = revenueOverview.length>0 && revenueOverview[0]?.assignment.reduce((total, item) => { 
        if(item._id=="paid-full")  total +=item.amount; 
        else total += 0;
        return  total
      }, 0);
    const totalEarning =Math.round((totalAssRevenue+totalSubsRevenue),0);

     return (
        <>
        <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="revOvbg scscm21-step2">
                  <div className="">
                    <div className="hdhomX1">
                      <div className="hdhomX2 d-flex align-items-center">
                        <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                        Revenue  Overview </div>
                    </div>
                    <div className="nexX1bg">
                      <div className="nexX1">
                        <div className="nexX2"><i className="mdi mdi-checkbox-blank-circle"></i> Net Earnings</div>
                        <div className="nexX3">${totalEarning}</div>
                      </div>
                      <div className="nesS1">
                        <div className="nesS2"><i className="mdi mdi-checkbox-blank-circle"></i> Current Earnings</div>
                        <div className="nesS3">${totalEarning}</div>
                      </div>
                      <div className="prolsbv1">
                        <div className="ogres ogres1">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-info" role="progressbar" style={{width:'65%'}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                            {/* <div className="prsg">76%</div> */}
                          </div>
                        </div>
                        <div className="ogres ogres2">
                          <div className="progress">
                            <div className="progress-bar bg-gradient-info" role="progressbar" style={{width:'45%'}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                            {/* <div className="prsg">45%</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="realtmearnbg">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Real-Time Earning</h3>
                      {/* <a href="#">View Details</a>  */}
                      </div>
                    <div className="prdsb1bg">
                      <div className="prdsb1"> <span className="stx1">Subscription</span>
                        <div className="d-flex stx2">${totalSubsRevenue}</div>
                        {/* <div className="prdsb3">Total  $1,608,469.50 </div> */}
                      </div>
                      <div className="prdsb1 prXf1"> <span className="stx1">Assignment </span>
                        <div className="d-flex stx2">${totalAssRevenue}</div>
                        {/* <div className="prdsb3"> Total  $1,608,469.50</div> */}
                      </div>
                      <div className="prdsb1"> <span className="stx1">Online Tutoring </span>
                        <div className="d-flex stx2">$0</div>
                        {/* <div className="prdsb3">Total  $1,608,469.50</div> */}
                      </div>
                      <div className="prdsb1 prXf1"> <span className="stx1">Others</span>
                        <div className="d-flex stx2">$0</div>
                        {/* <div className="prdsb3">Total  $1,608,469.50</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
     ) 
}
export default RevenueOverview