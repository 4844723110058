export const BOOK_ACTIONS = {
    BOOK_SEARCH: 'BOOK_SEARCH',
    BOOK_SEARCH_SUCCESS: 'BOOK_SEARCH_SUCCESS',
    BOOK_SEARCH_ERROR: 'BOOK_SEARCH_ERROR',

    BOOK_UPSERT: 'BOOK_UPSERT',
    BOOK_UPSERT_SUCCESS: 'BOOK_UPSERT_SUCCESS',
    BOOK_UPSERT_ERROR: 'BOOK_UPSERT_ERROR',

    BOOK_EDIT: 'BOOK_EDIT',

    BOOK_RESET: 'BOOK_RESET',

    CHAPTER_LOG_SEARCH:'CHAPTER_LOG_SEARCH',
    CHAPTER_LOG_SEARCH_SUCCESS: 'CHAPTER_LOG_SEARCH_SUCCESS',
    CHAPTER_LOG_SEARCH_ERROR: 'CHAPTER_LOG_SEARCH_ERROR',

    BOOK_LOG_SEARCH:'BOOK_LOG_SEARCH',
    BOOK_LOG_SEARCH_SUCCESS: 'BOOK_LOG_SEARCH_SUCCESS',
    BOOK_LOG_SEARCH_ERROR: 'BOOK_LOG_SEARCH_ERROR',

    ADD_FAQ:'ADD_FAQ',
    RESET_FAQ:'RESET_FAQ',
};

export const bookResetAction = () => ({
    type: BOOK_ACTIONS.BOOK_RESET,
});

export const bookSearchAction = (payload) => ({
    type: BOOK_ACTIONS.BOOK_SEARCH,
    ...payload,
});

export const bookEditAction = (payload) => ({
    type: BOOK_ACTIONS.BOOK_EDIT,
    ...payload,
});

export const bookUpsertAction = (payload) => ({
    type: BOOK_ACTIONS.BOOK_UPSERT,
    ...payload,
});

export const chapterLogSearchAction = (payload) => ({
    type:BOOK_ACTIONS.CHAPTER_LOG_SEARCH,
    ...payload
})

export const bookLogSearchAction = (payload) => ({
    type:BOOK_ACTIONS.BOOK_LOG_SEARCH,
    ...payload
})

export const bookAddFaqAction = (payload) => ({
    type:BOOK_ACTIONS.ADD_FAQ,
    ...payload
})

export default {
    BOOK_ACTIONS,
    bookSearchAction,
    bookUpsertAction,
    bookEditAction,
    chapterLogSearchAction,
    bookLogSearchAction,
    bookAddFaqAction
};
