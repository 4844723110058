import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { addFaqCategory, getSingleFaqCategory } from 'src/api/FAQApi';
import FormikInput from 'src/components/formik/FormikInput';
import RoutingUtil from 'src/utils/RoutingUtil'
import * as Yup from "yup";


const Render = (props) => {

    const {
        values,
        touched,
        errors,
        onSubmit,
        getFieldProps,
        setFieldValue,
    } = props;


    const history = useHistory()
    const [formData, setFormData] = useState({})
    let _URL = window.URL || window.webkitURL;
    const [image, setImage] = useState("");

const handleFileUpload = (e) => {
    e.preventDefault();
    var file, img, base64, blob, reader;
    if ((file = e.target.files[0])) {
      img = new Image();
      blob = new Blob([file], { type: file.type });
      img.src = _URL.createObjectURL(blob);
      reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        base64 = reader.result;
        setImage(base64);
        values.faq_image = base64
        setFormData({ ...formData, faq_image: base64 });
      };
    }
}     

    return (
        <Form>
        <div className='row p-4 m-2'>
        <div className='col-md-12'>
            <h2><span className='mdi mdi-arrow-left-bold-box' onClick={() => history.push(RoutingUtil.faqManagerUrl())}></span>Add Faq Category</h2>
            <hr/>
        </div>
        <div className='col-md-12 mt-2'>
            <label>Category Name</label>
            <FormikInput 
              type='text'
              placeholder="Enter Category Name"
              name="faq_category"
              className="form-control  enchilada"
              style={{width:'30%'}}
            />
        </div>
        <div className='col-md-12 mt-4'>
            <label>Heading</label>
            <FormikInput 
              type='text'
              placeholder="Enter Heading"
              name="faq_heading"
              className="form-control  enchilada"
              style={{width:'30%'}}
            />
        </div>
        <div className='col-md-12 mt-4'>
            <label>Category Image</label>
            <input type ='file' className='form-control' style={{width:'30%'}}  name="faq_image" onChange={(e) => handleFileUpload(e)} accept='image/*'/>
            <div style={{ height: "130px", overflow: "hidden", width:'30%' }} className='mt-2'>
                <img src={values.faq_image ? values.faq_image : ""} />
              </div>
        </div>
        <div className='col-md-12 mt-4'>
            <button className='btnbluen1'  onClick={() => onSubmit(values)}>Save Category</button>
        </div>
    </div>
        </Form>
    )
}

const CreateFaqCategory = () => {
    const history = useHistory()
    const params = useParams()
    const [initialValues, setInitialValues] = useState({
        faq_category: "",
        faq_heading: "",
        faq_image: "",
      });
      const validationSchema = Yup.object({
        faq_category: Yup.string().required("*Required"),
        faq_heading: Yup.string().required("*Required"),
      });  

      const handleSubmit = async (values) => {
        const addCategory = await addFaqCategory(values)
        if(addCategory){
            history.push('/faq')
        }
      }

      useEffect(() => {
         if(params.id){
            isFaqEdit(params.id)
         }
      },[])

      const isFaqEdit = async (paramId) => {
         const result = await getSingleFaqCategory(paramId)
         if(result && result.status === 201){
             let {data} = result.data
             let init_data = {
                faq_category: data?.faq_category,
                faq_heading: data?.faq_heading,
                faq_image: data?.faq_image,
              };
              setInitialValues(init_data);
            //   setImage(init_data.faq_image);
         }
      }

    return (
       <Formik
       initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
       >
 {(formikProps) => (
                    <Render
                        setInitialValues={setInitialValues}
                        onSubmit={handleSubmit}
                        {...formikProps}
                    />
                )}
       </Formik>
    )
}

export default CreateFaqCategory