/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

const PrivateRoute = ({ component: Component, ...rest }) => {
    // REDUX
    const {
        token,
    } = useSelector((state) => state.AuthReducer, shallowEqual);

    return (
        <Route
            {...rest}
            render={
                (props) => {
                    if (token) {
                        return <Component {...props} />;
                    }

                    return (
                        <Redirect to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                        />
                    );
                }
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.any,
    // location: ReactRouterPropTypes.location,
};

export default PrivateRoute;
