export const registerStudentDefaultProps = {
    registeredUsers:[
        // { totalCount: [{ count: 0 }] , statusCount:0 }
    ],
    subscribedUsers:0,
    unsubscribedUsers:0,
    subscriptionCancledUsers:0,
};
export const countryWiseUserDefaultProps = {
    registeredUsers:[
        // { totalCount: [{ count: 0 }] , statusCount:0 }
    ], 
};
export const subscriptionPlanWiseDefaultProps = [
    {
        _id:'Monthly',
        subscriptions:[],
        totalSubscriptions:0,
        totalRevenue:0
    },
    {
        _id:'Quarterly',
        subscriptions:[],
        totalSubscriptions:0,
        totalRevenue:0
    },
    {
        _id:'Annually',
        subscriptions:[],
        totalSubscriptions:0,
        totalRevenue:0
    }
];
export const revenueOverviewDefaultProps = {
};
export const onboardedExpertTutorDefaultProps = {
};
export const countryWiseCountDefaultProps = {
};
export const genderWiseCountDefaultProps = {
};
export const SOSDefaultProps = {
};
export const bookQnaSection = {
};
export const GenderWiseDefaultProps = {
};
export const bookQnaDefaultProps = {
};


export default {
    registerStudentDefaultProps,
    subscriptionPlanWiseDefaultProps,
    revenueOverviewDefaultProps,
    onboardedExpertTutorDefaultProps,
    countryWiseCountDefaultProps,
    genderWiseCountDefaultProps,
    SOSDefaultProps,
    bookQnaSection, 
};
