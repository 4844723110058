import { REQUEST_STATUS } from "src/constants/CommonConstants";
import { EXPERT_ACTIONS } from "../actions/ExpertActions";

const initialState = () => ({
    currentBackendRecord:{
        name:'',
        email:'',
        password:'',
        Contact:'',
        address:'',
        country:'',
        gender:'',
        profileImg:'',
        Subject: [],
        SubjectId:'',
        subSubject: [],
        subSubjectObj:[],
        subsArr:[],
        SubjectExpertise:[
            { name:'',
             id:'',
             sub_subject:[]}
         ],
        education: [
            {
                degrees:'',
                year:'',
                percentage:0,
                subject:[
                    {
                        name:'',
                        id:''
                    }
                ]
            }
        ],
        totalExperience:0,
        bankDetails:{},
        experience:[
            {
                companyExp:[
                    {
                        companyName:'',
                        designation:'',
                        specializationsubject:'',
                        startDate:'',
                        endDate:'',
                        isCurrentlyWorking:false
                    }
                ]
            }
        ],
        badge:false,
        isDraft:false,
        status:false,
        approve:false,
        createdAt:new Date(),
        updatedAt:new Date()
    },
    currentRecord: {
        // expertId:'',
        // expertName:'',
        // expertEmail:'', 
        // subject:[],
        // subSubject:[],
        // serviceType:[],
        // expertise:[],
        // language:'',
        // gender:'',
        // country:'',
        // ratings:'',
        // revenue:0,
        img_path:'',
        status: false,
        isDraft: false,
        Subject: [],
        SubjectId:'',
        subSubject: [],
        subSubjectObj:[],
        subsArr:[],
        country: '',
        languages: [],
        totalExperience: '',
        gender: '',
        aboutDetails: '',
        aboutJourney: '',
        profile_img: '',
        services: [],
        Livingin: '',
        expert_name: '',
        education: [
            {
                degrees:'',
                year:'',
                percentage:0,
                subject:[
                    {
                        name:'',
                        id:''
                    }
                ]
            }
        ],
        badge: false,
        approve:false,
        img_path: '',
        SubjectExpertise:[
           { name:'',
            id:'',
            sub_subject:[]}
        ]

    },
    expertCounts: {
        Backend: 0,
        Frontend: 0,
        'Pending Frontend': 0,
        Draft: 0
    },
    search: {
        data: [],
        // groupCounter:{'Registered':0,'Subscribed':0,'Non-Subscribed':0,'Cancelled Subscription':0,'Student reviews':0},
        currentPage: 0,
        pages: 0,
        total: 0,
        // allTimeAppRegistered:0,
        // allTimeWebRegistered:0
    },
    searchReqStatus: null,
    upsertReqStatus: null,
})

export default (state = initialState(), action) => {
    switch (action.type) {
        //SEARCH
        case EXPERT_ACTIONS.EXPERT_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING
            }
        }
        case EXPERT_ACTIONS.EXPERT_SEARCH_SUCCESS: {
            return {
                ...state,

                search: {
                    data: action.data,
                    total: action.total,
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case EXPERT_ACTIONS.EXPERT_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case EXPERT_ACTIONS.EXPERT_COUNT: {
            return {
                ...state,
                expertCounts: {
                    Backend: action?.expertCounts?.backendExperts,
                    Frontend: action?.expertCounts?.frontendExperts,
                    'Pending Frontend': action?.expertCounts?.pendingExperts,
                    Draft: action?.expertCounts?.draftExperts
                }
            };
        }


        // UPSERT
        case EXPERT_ACTIONS.EXPERT_UPSERT: {
            
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case EXPERT_ACTIONS.EXPERT_UPSERT_SUCCESS: {
            console.log('reducer expert type',action, action.expertType)
            if(action.expertType === 'expert'){
                return {
                    ...state,
                    currentRecord: action.data,
                    upsertReqStatus: REQUEST_STATUS.SUCCESS,
                };
            }else {
                return {
                    ...state,
                    currentBackendRecord: action.data,
                    upsertReqStatus: REQUEST_STATUS.SUCCESS,
                };
            }
        }

         // RESET
         case EXPERT_ACTIONS.EXPERT_RESET: {
            if(action.expertType === 'expert'){
                return {
                    ...state,
                    currentRecord:{
                        img_path:'',
                        status: false,
                        isDraft: false,
                        Subject: [],
                        SubjectId:'',
                        subSubject: [],
                        subSubjectObj:[],
                        subsArr:[],
                        country: '',
                        languages: [],
                        totalExperience: '',
                        gender: '',
                        aboutDetails: '',
                        aboutJourney: '',
                        profile_img: '',
                        services: [],
                        Livingin: '',
                        expert_name: '',
                        education: [
                            {
                                degrees:'',
                                year:'',
                                percentage:0,
                                subject:[
                                    {
                                        name:'',
                                        id:''
                                    }
                                ]
                            }
                        ],
                        badge: false,
                        approve:false,
                        img_path: '',
                        SubjectExpertise:[
                           { name:'',
                            id:'',
                            sub_subject:[]}
                        ]
                    }
                }
            }else {
                return{
                    ...state,
                    currentBackendRecord:{
                        name:'',
                        email:'',
                        password:'',
                        Contact:'',
                        address:'',
                        country:'',
                        gender:'',
                        profileImg:'',
                        Subject: [],
                        SubjectId:'',
                        subSubject: [],
                        subSubjectObj:[],
                        subsArr:[],
                        SubjectExpertise:[
                            { name:'',
                             id:'',
                             sub_subject:[]}
                         ],
                        education: [
                            {
                                degrees:'',
                                year:'',
                                percentage:0,
                                subject:[
                                    {
                                        name:'',
                                        id:''
                                    }
                                ]
                            }
                        ],
                        totalExperience:0,
                        bankDetails:{},
                        experience:[
                            {
                                companyExp:[
                                    {
                                        companyName:'',
                                        designation:'',
                                        specializationsubject:'',
                                        startDate:'',
                                        endDate:'',
                                        isCurrentlyWorking:false
                                    }
                                ]
                            }
                        ],
                        badge:false,
                        isDraft:false,
                        status:false,
                        approve:false,
                        createdAt:new Date(),
                        updatedAt:new Date()
                    }
                }
            }
            // return {
            //     ...state,
            //     currentRecord: { ...BlogDefaultProps },
            // };
        }
   

        case EXPERT_ACTIONS.EXPERT_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        default:
            return state;
    }
}