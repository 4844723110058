import React from 'react';
import FormikSearchDropdown from 'src/components/formik/FormikSearchDropdown/FormikSearchDropdown';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import PropTypes from 'prop-types';
import { getBlogMaster } from 'src/api/BlogApi';

const CategoryDropDown = ({ value, name, onChange,...rest }) => (
    <FormikSearchDropdown
        name={name}
        placeholder="Select Category"
        displayKeyPath={[
            'name'
        ]}
        valueKeyPath="_id"
        defaultValueFetchByKeyPath="_id"
        whereClauseKeysPaths={[
            'name'
        ]}
        api={getBlogMaster}
        apiCallAddonData={{
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
        }}
        value={value}
        onChange={onChange}
        {...rest}
    />
);

CategoryDropDown.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

CategoryDropDown.defaultProps = {
    value: '',
    onChange: () => { },
};

export default CategoryDropDown;
