import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { announcementEditAction } from './actions/AnnouncementActions'
import Pagination from 'src/components/Pagination'

const Categories = ['Session Reschedule', 'New Course Announcement', 'Course Certificate', 'Admin Under Maintanence']
const AnnouncementList = ({tableData,pageNo,setPageNo,total,handleFilter}) => {

   const dispatch = useDispatch()

const handleAnnouncementEdit = useCallback((id) => {
   window.scrollTo({top:0,behaviour:'smooth'})
      dispatch(announcementEditAction({_id:id}))
}, [
   dispatch,
]);


    return (
        <>
               <div className="card shadow">
                                                      <div className="card-body"> 
                                                         <div className="psttabblog"> 
                                                            <h4 className="card-title">All Announcement Details</h4> 
                                                         <div className="psttabblog1">
                                                            <select className="form-select"
                                                            onChange={(e) => {
                                                                  handleFilter({'category':e.target.value})   
                                                                  setPageNo(0)
                                                               
                                                            }}
                                                            >
                                                            <option value=''>Select Category </option>
                                                            {
                                                               Categories.map((categs) => {
                                                                  return (
                                                                     <option value={categs} key={categs}>{categs}</option>
                                                                  )
                                                               })
                                                            }
                                                            </select>  
                                                          
					  </div>
				 </div>
			
				 <div className="postablnewx"> 
                              <div className="table-responsive">
								   <table className="table datatablefnts datatable">
                                    <thead>
                                       <tr>
                                          <th>S.no </th>
                                          <th>Start Date </th>
                                          <th>End Date </th>
                                          <th>Title </th>  
                                          <th>Description </th> 
                                          <th>Category</th>  
                                          <th>Action</th>
                                          <th>Created At</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData.map((data,idx)=>{
                                                return (
                                                    <tr key={data._id}>
                                                        <td><span className="sNo">{idx+1 + pageNo*10}</span> </td> 
                                                        <td>{data.schedule['startDate']}    </td>
                                                        <td>{data.schedule['endDate']}  </td>
                                                        <td><div className="">{data.announcement_title}</div></td>   
                                          
                                          <td><div className="">{data.announcement_desc}</div> </td> 
										   <td>{data.category} </td>
                                          <td><div className="">
                                             <button className="virenbtn1" onClick={() => handleAnnouncementEdit(data._id)}>Edit </button></div> </td>  
                                          <td>{data.created_at}    </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                       
                                    </tbody>
                                 </table> 
                              </div>
                              <Pagination setPageNo={setPageNo} pageNo={pageNo} total={total}/>
							   </div>
                     </div>
                     </div>
        </>
    )
}

export default AnnouncementList