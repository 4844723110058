import { MakeSlugToTitle } from "src/utils/CommonUtils";

const AssignmentStatus=(props)=>{
    const {
        assignmentOverview = []
    } = props;
    const totalPaidAssignment =(assignmentOverview.length==0)?0:assignmentOverview.find((assignmentOverview)=>assignmentOverview._id=="paid-full")
    console.log('assignmentOverview=>',assignmentOverview)
   return (
        <div className="col-md-6 mtops">
                    <div className="card shadow">
                    <div className="card-body">
                        <div className="books-pan1bg">
                        <div className="hdhomX1">
                            <div className="hdhomX2 d-flex align-items-center">
                            <div className="hdhomX3 yellow-icon me-2"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                            Assignments </div> 
                        </div>
                        <div className="d-flex justify-content-between align-items-center totdetaX">
                            <div className="">
                            <h3>Total Earning</h3>
                            </div>
                            <div className="">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center txnetotal"> <span className="numcount">${totalPaidAssignment?.amount}</span>
                                <div className="pendcp text-green ms-3"><span>{totalPaidAssignment?.totalAssignment} <i className="mdi mdi-plus mdi-24px"></i> </span> </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="assignallTyp">
                            {
                                assignmentOverview && assignmentOverview.map((assignment,index)=>{ 
                                    const classesObject={"paid-full":"asTyp1","cancelled":"asTyp2 textred"}
                                    const statusLabel = {"paid-full":"Total Paid","unpaid":"Total Unpaid"}
                                    const status =(statusLabel[assignment._id])?statusLabel[assignment._id]:assignment._id
                                    if(status=="pending") return

                                    return (
                                            <div className={(classesObject[assignment._id])?classesObject[assignment._id]:"asTyp4"} key={`assignment_${index}`}>
                                                <h4>{`$${assignment.amount}(${assignment.totalAssignment})`}</h4>
                                                <p>{MakeSlugToTitle(status)} </p>
                                        </div>
                                )
                                })
                            }                              
                        </div>
                        </div>
                    </div>
                    </div>
                </div> 
   );
}
export default AssignmentStatus;