import React from 'react';
import PropTypes from 'prop-types';

const MenuBandageComponent = ({
    title,
    faIcon,
}) => (title ? <span className={`badge badge-pill ${faIcon}`}>{title}</span> : null);

MenuBandageComponent.propTypes = {
    faIcon: PropTypes.string,
    title: PropTypes.string,
};

MenuBandageComponent.defaultProps = {
    faIcon: '',
    title: '',
};

export default MenuBandageComponent;
