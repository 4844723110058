/* eslint-disable no-bitwise */
import React from 'react';
import { clamp, get } from 'lodash-es';
import ErrorPageComponent from 'src/components/ErrorPageComponent';
import moment from 'moment';

export const isObject = (target) => Object.prototype.toString.call(target) === '[object Object]';

export const groupBy = function (xs, key) {
    return xs.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const calculateBilling = (
    itemsInCart = [],
    discount = 0,
    deliveryCharges = 0,
    deliveryChargesWaveOff = 0,
) => {
    let total = 0;

    for (const key in itemsInCart) {
        if (Object.prototype.hasOwnProperty.call(itemsInCart, key)) {
            const item = itemsInCart[key];
            if (item.id) {
                const perItemPrice = item.final_selling_price * item.count;
                total += perItemPrice;
            }
        }
    }

    const totalAfterDiscount = total - discount;

    const totalAfterDeliveryAdjustment = totalAfterDiscount + deliveryCharges - deliveryChargesWaveOff;

    return {
        total,
        discount,
        totalAfterDiscount,
        totalAfterDeliveryAdjustment,
    };
};

export const valueAsPercentageOf = (value = 0, percentageOf = 0) => {
    const result = (clamp(value, 0, Infinity) / clamp(percentageOf, 0, Infinity)) * 100;
    if (Number.isNaN(result)) {
        return 0;
    }
    return result;
};
export const percentageToValue = (percentage = 0, valueOf = 0) => {
    const result = (clamp(valueOf, 0, Infinity) * clamp(percentage, 0, Infinity)) / 100;
    if (Number.isNaN(result)) {
        return 0;
    }
    return result;
};

export const clickedOutside = (evt, target) => {
    let targetElement = evt.target; // clicked element

    do {
        if (targetElement === target) {
            return true;
        }
        // Go up the DOM
        targetElement = targetElement.parentNode;
    } while (targetElement);

    return false;
};

export const getLatLng = () => new Promise((res, rej) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(res, rej, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        });
    }
    else {
        // eslint-disable-next-line prefer-promise-reject-errors
        rej('navigator geolocation is not available');
    }
});

// eslint-disable-next-line no-bitwise
export const getRandomLightColor = () => `hsla(${~~(360 * Math.random())},` + '70%,' + '80%,1)';

export function countDecimalDigits(number) {
    const char_array = number.toString().split(''); // split every single char
    const not_decimal = char_array.lastIndexOf('.');
    return not_decimal < 0 ? 0 : char_array.length - not_decimal;
}

export const allPossiblePathOfObject = function (tree) {
    const leaves = [];
    const walk = function (obj, path) {
    // eslint-disable-next-line no-param-reassign
        path = path || '';
        for (const n in obj) {
            if (obj.hasOwnProperty(n)) {
                // key starting from $ are mongoose filter
                if (n[0] === '$') {
                    leaves.push(`${path ? `${path}` : ''}`);
                }
                else if (
                    (typeof obj[n] === 'object' || obj[n] instanceof Array)
          && !(obj[n] instanceof Date)
                ) {
                    walk(obj[n], `${path ? `${path}.` : ''}${n}`);
                }
                else {
                    leaves.push(`${path ? `${path}.` : ''}${n}`);
                }
            }
        }
    };
    walk(tree, '');
    return leaves;
};

export const buildSearchWithNoCase = ({ where, fullTextSearch, caseSensitiveSearchProperties = [] }) => {
    const paths = allPossiblePathOfObject(where);
    const dotNotationWhere = {};
    paths.forEach((path) => {
        const value = get(where, path);
        if (value !== undefined && value !== null) {
            // caseSensitiveSearchProperties: if a property path exist in this array then that will not be full text searched
            if (!fullTextSearch || caseSensitiveSearchProperties.indexOf(path) > -1) {
                dotNotationWhere[path] = value;
            }
            else if (
                value === 'false'
        || value === 'true'
        || value === false
        || value === true
        || typeof value === 'number'
        || typeof value === 'object'
        || Array.isArray(value)
            ) {
                dotNotationWhere[path] = value;
            }
            // this is bad but no way to find if it is object id type... so checking last 2 cart are i and d
            else if (
                path[path.length - 1] === 'd'
        && (path[path.length - 2] === 'i' || path[path.length - 2] === 'I')
        && value.toString().length === 24
            ) {
                dotNotationWhere[path] = value;
            }
            else {
                dotNotationWhere[path] = {
                    $regex: `^${value}`,
                    $options: 'i',
                };
            }
        }
    });

    return dotNotationWhere;
};

export const setEmptyStringToNull = function (tree) {
    const walk = function (target) {
        for (const key in target) {
            if (Object.prototype.hasOwnProperty.call(target, key)) {
                if (typeof target[key] === 'object' || target[key] instanceof Array) {
                    walk(target[key]);
                }
                else if (typeof target[key] === 'string' && target[key] === '') {
                    // eslint-disable-next-line no-param-reassign
                    target[key] = null;
                }
            }
        }
    };
    walk(tree);
    return tree;
};

// convert permission to the structure that tree understands
export function permissionsToTreeStructure(permissions, path = '') {
    if (!permissions) {
        return [];
    }

    const keys = Object.keys(permissions);

    const scopesAtLevel = [];

    keys.forEach((key) => {
        const value = permissions[key];
        let children = null;
        const currentPath = path ? `${path}.${key}` : key;

        if (value.toString() === '[object Object]') {
            children = permissionsToTreeStructure(value, currentPath);
        }

        scopesAtLevel.push({
            value: currentPath,
            label: key,
            children,
        });
    });

    return scopesAtLevel;
}

export function hasPermission({ scope, scopeList }) {
    if (!scopeList.length) {
        return false;
    }

    if (!scope) {
        return true;
    }

    const scopeSplit = scope.split('.');

    const has = scopeList.find((permission) => {
        const permissionSplit = permission.split('.');
        const result = scopeSplit.every(
            (item, index) => item === permissionSplit[index],
        );
        return result;
    });

    return Boolean(has);
}

export const UnAuthorizedErrorPage = () => (
    <ErrorPageComponent
        code="401"
        title="Unauthorized"
    />
);

/**
 *
 * @param {Date} date1
 * @param {Date} date2
 * @returns {String}
 */
export const getDateTimeDiffAsString = (date1, date2) => {
    const date1Moment = moment(new Date(date1 || new Date()));
    const date2Moment = moment(new Date(date2 || new Date()));

    const duration = moment.duration(date2Moment.diff(date1Moment)).asMinutes();

    return `${Math.floor(duration / 60)}H ${Math.floor(duration % 60)}M`;
};

/**
 *
 * @param {Date} date1
 * @param {Date} date2
 * @returns {Number}
 */
export const getDateTimeDiffAsHours = (date1, date2) => {
    const date1Moment = moment(new Date(date1 || new Date()));
    const date2Moment = moment(new Date(date2 || new Date()));

    const duration = moment.duration(date2Moment.diff(date1Moment)).asMinutes();

    return duration / 60;
};

export const formatDate = (date, format) => {
    if (!date) {
        return '';
    }
    return moment(date).format(format || 'YYYY-MMM-DD hh:mm a');
};

export const isValidNumber = (value) => {
    if (value === undefined || value === null || value === '') {
        return false;
    }
    return !Number.isNaN(value);
};
export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  export const  MakeSlug = (str) => {
    return str
      ?.trim()
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  };

  export const MakeSlugToTitle = (slug) => {
    var words = slug.split('-');
  
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
  
    return words.join(' ');
  }

  export const monthsArr = ['Jan','Feb','Mar', 'Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec']
 export const getDateFromTimestamp = (timestamp) => {
    const monthName = new Date(timestamp).getMonth()
    const day = new Date(timestamp).getDate()
    const year = new Date(timestamp).getFullYear()
    const monthNameString = monthsArr[monthName]
    return day+' ' +monthNameString+' '+year
 }

export default {
    isValidNumber,
    isObject,
    groupBy,
    calculateBilling,
    valueAsPercentageOf,
    percentageToValue,
    clickedOutside,
    getLatLng,
    getRandomLightColor,
    countDecimalDigits,
    allPossiblePathOfObject,
    buildSearchWithNoCase,
    setEmptyStringToNull,
    permissionsToTreeStructure,
    hasPermission,
    UnAuthorizedErrorPage,
    getDateTimeDiffAsString,
    getDateTimeDiffAsHours,
    formatDate,
    convertBase64,
    MakeSlug,
    MakeSlugToTitle,
    getDateFromTimestamp
};
