import React from 'react';
import CrudLayoutContainer from 'src/components/crud-layout-component/CrudLayoutContainer';
import RoutingUtil from 'src/utils/RoutingUtil';
import SubSubjectMaster from './SubSubjectMaster';
import SubjectMaster from './SubjectMaster';
import SubjectCreate from './SubjectCreate';
import SubjectSeoUpdate from './SubjectSeoUpdate';
import UpdateSubjectSeoQA from './QA/UpdateSubjectSeoQA';
import UpdateSubjectStudentReviewsQA from './QA/UpdateSubjectStudentReviewsQA';
import UpdateSubjectContentQA from './QA/UpdateSubjectContentQA';
import UpdateSubjectSeoTB from './TB/UpdateSubjectSeoTB';
import UpdateSubjectStudentReviewsTB from './TB/UpdateSubjectStudentReviewsTB';
import UpdateSubjectContentTB from './TB/UpdateSubjectContentTB';
import UpdateSubSubjectSeoQA from './QA/UpdateSubSubjectSeoQA';
import UpdateSubSubjectStudentReviewsQA from './QA/UpdateSubSubjectStudentReviewsQA';
import UpdateSubSubjectContentQA from './QA/UpdateSubSubjectContentQA';
import UpdateSubSubjectRelatedQuestionsTB from './TB/UpdateSubSubjectRelatedQuestionsTB';
import UpdateSubSubjectSeoTB from './TB/UpdateSubSubjectSeoTB';
import UpdateSubSubjectReviewsTB from './TB/UpdateSubSubjectStudentReviewsTB';
import UpdateSubSubjectContentTB from './TB/UpdateSubSubjectContentTB';
import SubSubjectCreate from './SubSubjectCreate';
import AdminMaster from './AdminMaster';
import AdminCreateForm from './AdminCreate';
import RoleMaster from './RoleMaster';
import RoleCreate from './RoleCreate';

const routes = [
    {
        link: RoutingUtil.subSubjectMasterUrl(),
        component: () => (
             <SubSubjectMaster/>
        ),
    },
    {
        link: RoutingUtil.subjectMasterUrl(),
        component: () => (
             <SubjectMaster/>
        ),
    },
    {
        link: RoutingUtil.subjectMasterUpdateUrl(),
        component: () => (
             <SubjectCreate/>
        ),
    },
    {
        link:RoutingUtil.subjectMasterCreateUrl(),
        component: () => (
            <SubjectCreate />
        )
    },
    {
        link:RoutingUtil.subjectSeoQAUpdateUrl(),
        component:() => (
            <UpdateSubjectSeoQA />
        )
    },
    {
        link:RoutingUtil.subjectReviewQAUpdateUrl(),
        component:() => (
            <UpdateSubjectStudentReviewsQA />
        )
    }, 
    {
        link:RoutingUtil.subjectContentQAUpdateUrl(),
        component:() => (
            <UpdateSubjectContentQA />
        )
    }, 
    {
        link:RoutingUtil.subjectSeoTBUpdateUrl(),
        component:() => (
            <UpdateSubjectSeoTB />
        )
    },
    {
        link:RoutingUtil.subjectReviewTBUpdateUrl(),
        component:() => (
            <UpdateSubjectStudentReviewsTB />
        )
    },
    {
        link:RoutingUtil.subjectContentTBUpdateUrl(),
        component:() => (
            <UpdateSubjectContentTB />
        )
    },
    {
        link:RoutingUtil.subSubjectSeoQAUpdateUrl(),
        component:() => (
            <UpdateSubSubjectSeoQA />
        )
    },
    {
        link:RoutingUtil.subSubjectReviewQAUpdateUrl(),
        component:() => (
            <UpdateSubSubjectStudentReviewsQA />
        )
    },
    {
        link:RoutingUtil.subSubjectContentQAUpdateUrl(),
        component:() => (
            <UpdateSubSubjectContentQA />
        )
    },
    {
        link:RoutingUtil.subSubjectRelatedQuestionQAUpdateUrl(),
        component:() => (
            <UpdateSubSubjectRelatedQuestionsTB />
        )
    },
    {
        link:RoutingUtil.subSubjectSeoTBUpdateUrl(),
        component:() => (
            <UpdateSubSubjectSeoTB />
        )
    },
    {
        link:RoutingUtil.subSubjectReviewTBUpdateUrl(),
        component:() => (
            <UpdateSubSubjectReviewsTB />
        )
    },
    {
        link:RoutingUtil.subSubjectContentTBUpdateUrl(),
        component:() => (
            <UpdateSubSubjectContentTB />
        )
    },
    {
        link:RoutingUtil.subSubjectMasterCreateUrl(),
        component:() => (
            <SubSubjectCreate />
        )
    },
    {
        link:RoutingUtil.subSubjectMasterUpdateUrl(),
        component:() => (
            <SubSubjectCreate />
        )
    },
    {
        link:RoutingUtil.adminMasterUrl(),
        component:() => (
            <AdminMaster />
        )
    },
    {
        link:RoutingUtil.adminCreateUrl(),
        component:() => (
            <AdminCreateForm />
        )
    },
    {
        link:RoutingUtil.adminUpdateUrl(),
        component:() => (
            <AdminCreateForm />
        )
    },
    {
        link:RoutingUtil.roleMasterUrl(),
        component:() => (
            <RoleMaster />
        )
    },
    {
        link:RoutingUtil.roleCreateUrl(),
        component:() => (
            <RoleCreate />
        )
    },
    {
        link:RoutingUtil.roleUpdateUrl(),
        component:() => (
            <RoleCreate />
        )
    }

];

const MasterContainer = () => (
    <CrudLayoutContainer
        title="Master Manager"        
        routes={routes}
    />
);

MasterContainer.propTypes = {
};

export default MasterContainer;
