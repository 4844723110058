import React from "react"
 const FooterComponent=()=>{

    return(
        <>
        <footer className="footer">
            <div className="container-fluid d-flex justify-content-between">
              <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright © crazyforstudy.com 2023</span>
              <span className="float-none float-sm-end mt-1 mt-sm-0 text-end"> crazyforstudy.com</span>
            </div>
          </footer>
        </>
    )

 }
 export default FooterComponent;