import CountryWiseRegisterStudent from "./CountryWiseSubscriber";


const RegisterStudent=(props)=>{
    const {
        registerStudent,
        countryWiseUser,
    } = props;
const totalCount = registerStudent[0]?.totalCount[0]?.count||0;
const statusCounts = registerStudent[0]?.statusCounts;

   return (
    <div className="col-md-5 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="hdhomX1">
                  <div className="hdhomX2">Users</div>
                  <div className="hdhomX3 yellow-icon"><span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                </div>
                <div className="hdhomXd1">
                  <div className="hdhomXd2">Registered Users </div>
                  <div className="hdhomXd3">{totalCount}</div>
                </div> 
                <div className="bgsusebg">
                <div className="bgsuse1" key="00">
                                <div className="bgsuse2">
                                <div className="bgsuse3">Total Subscribed Users</div>
                                <div className="bgsuse4">
                                    <div className="d-flex align-items-center"> 
                                    <span className="numcount">
                                        {statusCounts && statusCounts[0]?.total}
                                    </span>                          
                                </div>
                                </div>
                                </div>
                </div>
                    
                {
                  statusCounts && statusCounts[0]?.statusCount.map((subscriber,index)=>{
                        return (
                            <div className="bgsuse1" key={index}>
                                <div className="bgsuse2">
                                <div className="bgsuse3"> {subscriber._id} Users</div>
                                <div className="bgsuse4">
                                    <div className="d-flex align-items-center"> <span className="numcount">{subscriber.count}</span>                          
                                </div>
                                </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                    <CountryWiseRegisterStudent countryWiseUser={countryWiseUser}/>
              </div>
            </div>
          </div>
   )
}
export default RegisterStudent;