import React from 'react'
import { useState, useEffect } from 'react'
import { getSubSubject, getSubjects } from 'src/api/Assignment'

const ServiceType = [
    'Assignment Writing Services',
    'Assignment Proofreading Services',
    'Assignment Editing Services',
    'Assignment Graphic Designing Services'
]
const SessionFilters = ({handleFilterChange}) => {

const [subjects, setSubjects] = useState([])   
const [subSubject, setSubSubject] = useState([])
const [selectedSubject, setSelectedSubject] = useState('')

useEffect(() => {
   getSubjectArrr()
},[])

useEffect(() => {
   if(selectedSubject !== ''){
       getSubSubjectsArr()
   }
},[selectedSubject])

const getSubjectArrr = async() => {
   const res = await getSubjects()
   setSubjects(res.data)
}

const getSubSubjectsArr = async () => {
   const res = await getSubSubject(selectedSubject)
   setSubSubject(res.data.data)
}


    return (
        <div className="row">
        <div className="col-md-12">
           <div className="scscm21 scscm21-step5">
              <div className="">
                 <select className="form-select" onChange={(e) => handleFilterChange({service_type:e.target.value})}>
                    <option>Select Service Type</option>
                    {
                        ServiceType.map((service) => {
                            return (
                                <option value={service} key={service}>{service}</option>
                            )
                        })
                    }
                 </select>
              </div>
               <div className="">
                    <select className="form-select" onChange={(e) => 
                        {
                            if(e.target.value !== ''){
                                setSelectedSubject(e.target.value)
                              handleFilterChange({'subject':e.target.value})
                            }
                        }
                        }>
                        <option value="">Select Subject</option>
                        {
                            subjects.map((sub) => {
                                return (
                                    <option key={sub._id} value={sub.subject}>{sub.subject}</option>
                                )
                            })
                        }
                    </select>
                    </div>
                    <div className="">
                    <select className="form-select" onClick={(e) => 
                        {
                            if(e.target.value !== ''){
                              handleFilterChange({'expertise':e.target.value})
                            }
                        }
                        }>
                        <option value="">Sub Subject</option>
                        {
                            subSubject.map((subs) => {
                                return (
                                    <option key={subs._id} value={subs.sub_subject}>{subs.sub_subject}</option>
                                )
                            })
                        }
                    </select>
                    </div>
           </div>
        </div>
     </div>
    )
}

export default SessionFilters