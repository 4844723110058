import { all } from 'redux-saga/effects'; 
import AppSaga from './containers/app-container/saga/AppSaga';
import DashboardSaga from './containers/dashboard-container/saga/DashboardSaga';
import CitySaga from './containers/city-container/saga/CitySaga'; 
import AuthSaga from './containers/auth-container/saga/AuthSaga';
import AssignmentRequestSaga from './containers/assignment-request-container/saga/AssignmentRequestSaga'; 
import BlogSaga from './containers/blog-container/saga/BlogSaga';
import BookSaga from './containers/book-container/saga/BookSaga';
import QuestionSaga from './containers/question-container/saga/QuestionSaga';
import EnquirySaga from './containers/enquiry-container/saga/EnquirySaga';
import StudentSaga from './containers/student-container/saga/StudentSaga';
import ExpertSaga from './containers/expert-container/saga/ExpertSaga';
import AnnouncementSaga from './containers/announcement-container/saga/AnnouncementSaga';
import TutorSaga from './containers/tutor-container/saga/TutorSaga';
import SOSSaga from './containers/SOS-container/saga/SOSSaga';
import SessionSaga from './containers/session-container/saga/SessionSaga';
import WhatsappSaga from './containers/whatsapp-container/saga/WhatsappSaga';

export default function* sagas() {
    yield all([
        ...AppSaga,
        ...CitySaga,
        ...AssignmentRequestSaga,
        ...BlogSaga,
        ...AuthSaga,
        ...BookSaga,
        ...QuestionSaga,
        ...EnquirySaga,
        ...StudentSaga,
        ...DashboardSaga,
        ...ExpertSaga,
        ...AnnouncementSaga,
        ...TutorSaga,
        ...SOSSaga,
        ...SessionSaga,
        ...WhatsappSaga
    ]);
}
