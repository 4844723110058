import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PermissionHOC from 'src/hoc/PermissionHOC';
import UpsertButton from 'src/components/UpsertButton';
 

const FormCreateUpdate = ({
    isSubmitting,
    isUpdate,
    onSearch,
    onClear,
    resetForm,
    values,
    submitButtonText,
    canClear,
    submitScope,
    searchScope,
    onSubmitClick,
}) => (
    <>
        {/* CREATE UPDATE */}
        {/* <PermissionHOC
            scope={submitScope}
        > */}
            <UpsertButton
                type="submit"
                disabled={isSubmitting}
                isUpdate={isUpdate}
                submitButtonText={submitButtonText}
                onClick={onSubmitClick}
            />
        {/* </PermissionHOC> */}
    </>
);

FormCreateUpdate.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    onClear: PropTypes.func,
    resetForm: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.any,
    isUpdate: PropTypes.bool,
    onSearch: PropTypes.func,
    submitButtonText: PropTypes.string,
    canClear: PropTypes.bool,
    submitScope: PropTypes.string,
    searchScope: PropTypes.string,
};

FormCreateUpdate.defaultProps = {
    values: null,
    isUpdate: false,
    onSearch: null,
    onClear: () => { },
    submitButtonText: 'Submit',
    canClear: true,
    submitScope: '',
    searchScope: '',
};

export default FormCreateUpdate;
