import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikInput from 'src/components/formik/FormikInput';
import FormikCKEditor from 'src/components/formik/FormikCKEditor/FormikCKEditor';
import { useEffect } from 'react';
import { addFaqQuestionAPI, getSingleFaqCategory } from 'src/api/FAQApi';
import { useHistory, useParams } from 'react-router-dom';
import RoutingUtil from 'src/utils/RoutingUtil';


const Render = (props) => {

const [editorLoaded, setEditorLoaded] = useState(false)    

useEffect(() => {
    setEditorLoaded(true)
},[])

    const {
        values,
        touched,
        errors,

        getFieldProps,
        setFieldValue,
      } = props;


    return (
        <Form>
          <div className='row p-2'>
            <div className='col-md-12 mt-2'>
                <label>Question</label>
                <FormikInput 
                  type='text'
                  placeholder="Enter Category Name"
                  name="question"
                  className="form-control  enchilada"
                />
            </div>
            <div className='col-md-12 mt-4 mb-3'>
                <label>Answer</label>
                <FormikCKEditor 
                 id='editor1'
                 name="answer"
                  value={values.answer}
                  onBlur={(data) => {
                      setFieldValue(
                          "answer",
                          data
                        );
                  }}
                  editorLoaded={editorLoaded}
                />
                
            </div>
            <div className='col-md-12 mt-5'>
                <button className='btnbluen1' style={{marginTop:'2rem'}}>Add Question</button>
            </div>
          </div>
        </Form>
    )
}

const AddQuestionForm = ({quesArr}) => {

    const params = useParams()
    const history = useHistory()
    const [initialValues, setInitialValues] = useState({
        question: "",
        answer: "",
      });
    
      const validationSchema = Yup.object({
        question: Yup.string().required("Required"),
        answer: Yup.string(),
      });
    
     useEffect(() => {
      if(params.questionId){
        getSingleCategoryQuestion()
      }
     },[])

     const getSingleCategoryQuestion = async () => {
      const {data} = await getSingleFaqCategory(params.id)
      const faqArr = data.data.faq_content
        const currQues = faqArr.filter((item) => item._id === params.questionId)
        setInitialValues({
          question:currQues?.[0]?.question,
          answer:currQues?.[0]?.answer
        })
     }

      const handleSubmit = async (values) => {
        // console.log('values is', values)
        const addQues = await addFaqQuestionAPI(values,params.id)
        if(addQues){
          history.push(`/faq/add-faq-question/${params.categorySlug}/${params.id}`)
        }
      }

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
        {(formikProps) => (
                    <Render
                        setInitialValues={setInitialValues}
                        onSubmit={handleSubmit}
                        {...formikProps}
                    />
                )}
        </Formik>
    )
}

export default AddQuestionForm