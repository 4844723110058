import React from 'react';
import CrudLayoutContainer from 'src/components/crud-layout-component/CrudLayoutContainer';
import RoutingUtil from 'src/utils/RoutingUtil';
import { questionEditAction, questionSearchAction } from './actions/QuestionActions';
import AllQuestion from './AllQuestion';
import ManageQuestion from './ManageQuestion';
import OtherQuestion from './OtherQuestion';
import MiscellaneousQuestion from './MiscellaneousQuestion';
import AskQuestion from './AskQuestion';



const routes = [
    {
        link: RoutingUtil.questionAllUrl(),
        component: () => (
           <AllQuestion/>
        ),
    },
    {
        link: RoutingUtil.questionManagerUrl(),
        component: () => ( 
             <ManageQuestion/>
        ),
    },
    {
        link: RoutingUtil.questionOtherUrl(),
        component: () => ( 
             <OtherQuestion/>
        ),
    },
    {
        link: RoutingUtil.questionMiscellaneousUrl(),
        component: () => ( 
             <MiscellaneousQuestion/>
        ),
    },
    {
        link: RoutingUtil.questionAskUrl(),
        component: () => ( 
             <AskQuestion/>
        ),
    }
];

const QuestionContainer = () => (
    <CrudLayoutContainer
        title="Question Manager"        
        routes={routes}
    />
);

QuestionContainer.propTypes = {
};

export default QuestionContainer;
