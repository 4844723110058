import React, { useState, useEffect } from "react";
 
import {
  ChartTitle,
  ReportWrapper,
  Subtitle,    
  StyledTable,
} from "./styles";

const PageviewsReport = (props) => {
  const {
    topPages ={}, 
} = props; 
  const [reportData, setReportData] = useState([]); 
  const [totalPages, setTotalPages] = useState(0);

  const displayResults = (response) => {
    const queryResult = response.reports[0].data.rows;
    setTotalPages(queryResult.length);
    const total = response.reports[0].data.totals[0].values[0];
    let newReportData = [];
    queryResult.forEach((row, idx) => {
      if (idx < 10) {
        let tempObj = {
          path: row.dimensions[0],
          views: row.metrics[0].values[0],
          perc: `${parseFloat((row.metrics[0].values[0] / total) * 100).toFixed(
            1
          )}%`,
        };
        newReportData.push(tempObj);
      }
    });
    setReportData(newReportData);
  };

  useEffect(() => {   
    if(topPages?.reports?.length>0)
    displayResults(topPages)
  }, []);

  return (
    <div className="col-md-12 mtops">
    <div className="card shadow">
      <div className="card-body">
          <ReportWrapper>
          <ChartTitle>Top 10 Pages by Views</ChartTitle>
          <Subtitle>{`Total pages - ${totalPages}`}</Subtitle> 
          {reportData.length && (
            <StyledTable>
              <thead>
                <tr>
                  <th>Page</th>
                  <th>Views</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((row, id) => (
                  <tr key={id}>
                    <td className="left-align">{row.path}</td>
                    <td>{row.views}</td>
                    <td>{row.perc}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          )}
        </ReportWrapper>
      </div>
    </div>
  </div> 
  );
};

export default PageviewsReport;
