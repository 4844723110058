import React, { useState, useEffect } from "react";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  ChartWrapper,
  ReportWrapper,
  ChartTitle,
  Subtitle,
} from "./styles"; 
 
import { formatDate } from "./utils"; 
// import React from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.position = 'top';
ChartJS.defaults.plugins.title.display = true;
ChartJS.defaults.plugins.title.text = `Conversions Report`;

const getRandomColor = () => {
  // Define a function to generate a random color
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const ConversionsDataReport = (props) => {
  const {
    conversionsData ={}, 
} = props; 

const INITIAL_STATE = {
  datasets: [],
};
  const [reportData, setReportData] = useState(INITIAL_STATE);  
  const [average, setAverage] = useState(0);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [chartData, setChartData] = useState(
    {
    labels: [],
    datasets: [
      {
        label: `Loading`,
        fill: false,
        lineTension: 0.3,
        borderColor: 'rgb(255, 99, 132)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#375751",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [],
      },
    ],
  }
  );

   
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 7,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  }; 

  useEffect(() => {
    const updatedLabels = [];
    const updatedDatasets = {};
    if (conversionsData.rows) {
     conversionsData.rows.forEach((row) => {
      const label = row.dimensionValues[0].value;
      const date = formatDate(row.dimensionValues[1].value);
      const count = row.metricValues[0].value;
  
      if (!updatedDatasets.hasOwnProperty(label)) {
        updatedDatasets[label] = {
          label,
          data: [],
          borderColor: getRandomColor(),
          backgroundColor: getRandomColor(),
        };
      }
  
      // Fill missing dates with 0 count for each label
      if (!updatedDatasets[label].data.find((data) => data.x === date)) {
        updatedDatasets[label].data.push({ x: date, y: 0 });
      }
  
      updatedDatasets[label].data.find((data) => data.x === date).y += parseInt(count);
  
      if (!updatedLabels.includes(date)) {
        updatedLabels.push(date);
      }
    });
  }
  
    setLabels(updatedLabels);
    setDatasets(updatedDatasets);
    setChartData({
      labels: updatedLabels,
      datasets: (updatedDatasets)?Object.values(updatedDatasets):[],
    });
  }, [conversionsData]);

  return (
    <div className="col-md-12 mtops">
            <div className="card shadow">
              <div className="card-body">
                <div className="books-pan1bg">
                  <div className="hdhomX1">
                    <div className="hdhomX2 d-flex align-items-center">
                      <div className="hdhomX3 yellow-icon me-2">
                        <span><i className="mdi mdi-account-multiple mdi-24px"></i></span></div>
                        {`${props.title} per day`} 
                        </div>
                  </div>
                  <div className="hdh1bg">
                      <ReportWrapper> 
                          {/* <Subtitle>{`${props.title}`}</Subtitle>  */}
                          {reportData && (
                            <ChartWrapper>
                              <Line data={chartData} options={options} width={100} height={250} />
                            </ChartWrapper>
                          )}
                        </ReportWrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
  );
};

export default ConversionsDataReport;
