import { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import VisitorsGeoData from "src/api/VisitorsGeoData";
// import getReport from "src/api/Google"; 
import { titleCase } from "src/libs/common";
 

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

const CountryWiseVisiterGoogle = (props) => { 
  const {
    GoogleAPI = []
} = props;
 const colors = [
  "#fcba03",
  "#fa8c5c",
  "#9fc934",
  "#60d17e",
  "#45afed",
  "#7c5cdb",
  "#ce5cdb",
  "#db5c97",
]; 
  const INITIAL_STATE = {
    labels: [],
    values: [],
    colors: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [totalCoutries, setTotalCountries] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const displayResults = (response) => {
    const queryResult = response.reports[0].data.rows;
    setTotalUsers(response.reports[0].data.totals[0].values[0]);
    setTotalCountries(queryResult.length);
    let labels = [];
    let values = [];
    let bgColors = [];
    queryResult.forEach((row, idx) => {
      if (idx < 5) {
        labels.push(row.dimensions[0]);
        values.push(row.metrics[0].values[0]);
        bgColors.push(colors[idx + 1]);
      }
    });
    setReportData({
      ...reportData,
      labels,
      values,
      colors: bgColors,
    });
  };

  const data = {
    labels: reportData.labels,
    datasets: [
      {
        data: reportData.values,
        backgroundColor: reportData.colors,
      },
    ],
  };

  const options = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return data.labels[tooltipItem["index"]];
        },
      },
    },
    plugins: {
      datalabels: {
        color: "black",
        font: {
          size: 20,
        },
        formatter: function (value, context) {
          const perc = parseInt((value / totalUsers) * 100);
          return perc + "%";
        },
      },
    },
  };

  useEffect(() => {    
    if(GoogleAPI?.reports?.length>0)
     displayResults(GoogleAPI)
  }, [GoogleAPI]);

  return (
    <div className="col-md-12 mtops">
      <div className="card shadow">
        <div className="card-body">
          <div className="books-pan1bg">
            <div className="hdhomX1">
              <div className="hdhomX2 d-flex align-items-center">
                <div className="hdhomX3 yellow-icon me-2">
                  <span>
                    <i className="mdi mdi-account-multiple mdi-24px"></i>
                  </span>
                </div>
                 Country Wise Visiter Google Analytics{" "}
              </div>
              <div className="viebtnv1"> 
                {/* {
                  !isFullView?
                  <a href="javascript:void(0)" onClick={()=>{setIsFullView(true)}}>View More</a>:
                  <a href="javascript:void(0)" onClick={()=>{setIsFullView(false)}}>Minimize</a>
                } */}
                
              </div>
                
            </div>
            {/* <VisitorsGeoData /> */}

            <div className="row"> 
                <div className="col-md-4"  >
                  <div className="uncunrybg">
                  {reportData && ( 
                       <Pie data={data} options={options} />  
                   )} 
                  </div>
                </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountryWiseVisiterGoogle