import Pagination from "src/components/Pagination";
import ListTitle from "./ListTitle"
import { titleCase } from "src/libs/common";
import { useState } from "react";
import config from 'src/config';

const QuestionCard=(props)=>{
    const [isShowAnswer,setIsShowAnswer] = useState(false);
    const {question} = props;
return (
<div className="card shadow marb1">
   <div className="card-body">
      <div className="">
         <div className="d-flex align-items-center justify-content-between topnamsunjt">
            <div className="d-flex align-items-center">
               <span className="img"><img src="/assets/images/faces/face10.jpg" className="img-fluid" alt=""/></span>
               <div className="filTextv1">
                  <h3>{question?.user_Id?.Name}</h3>
                  <div className=""><span>Question ID:</span> {question._id}</div>
               </div>
            </div>
            <div className="d-flex align-items-center justify-content-end topnamsunjt2">
               <div className="d-flex align-items-center">
                  <button className="businessBtn ms-2">{titleCase(question.subject)}</button>
                  {/* <button className="edtiBtn ms-2">Edit </button> */}
               </div>
               <div className=" sunjtTxt"><span>Sub Subject:</span> {titleCase(question.sub_subject)}</div>
            </div>
         </div>
         <div className="alltextQues">
            <div className="only-so-big">
               <span
               dangerouslySetInnerHTML={{
               __html: `${question.question}`,
               }} 
               ></span>
            </div>
         </div>
         <div className="attachntFil">
            <ul>
               <li><span>Attachments </span></li>
               {
               [...Array(2)].map((e,i)=>{
               if(question['image'+i] && question['image'+i]!="")
               return(
               <li><a href={config.imageUrl+question['image'+i]} target="_blank"><img src="/assets/images/all-questions/attechment-img.svg" className="img-fluid" alt={question['image'+i]}/></a></li>
               )
               }
               )
               }
            </ul>
            <div className="attachntFilbg">
               <span>Ask Date: {question.created_at}</span>
            </div>
         </div>
         {/* 
         <div className="">
            <div className="d-flex justify-content-between align-items-center viewAnsDcBg">
               <div className="">
                  <div className="NoanswBtn">Answer Not Available</div>
               </div>
               <div className="d-flex justify-content-between align-items-center">
                  <button className="btnbluen1 bg-red me-2">Reject </button>
                  <button className="btnbluen1 bg-blue">Solve Question</button>
               </div>
            </div>
         </div>
         */}
      </div>
   </div>
</div>
    )
}



const PendingQuestionList=(props)=>{
    const {
        search:{data},
        search,
        activeTab,
        setPageNo,
        pageNo
       } = props;
    return (
        <div className={`tab-pane fade ${(activeTab=="Pending")?"show active":""}`} id="pills-2" role="tabpanel" aria-labelledby="pills-tab2"> 
        <div className="col-12 grid-margin  mt-4"> 
          <ListTitle title="Pending Questions"/>
          {
        data.map((question,index)=>(<QuestionCard question={question} key={index}/>))
       }
     {data && data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>} 
       </div>
    </div>
    )
}

export default PendingQuestionList