import { put, call } from 'redux-saga/effects';
import { bookCreateAPI, booksSearchAPI, bookUpdateAPI, bookSearchAPI,updateBookStatusAPI, bookLogSearchAPI, addBookFaqAPI } from 'src/api/BookApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { BOOK_ACTIONS } from '../actions/BookActions';
import { chapterLogSearchAPI } from 'src/api/ChapterApi';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(booksSearchAPI, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: BOOK_ACTIONS.BOOK_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: BOOK_ACTIONS.BOOK_SEARCH_ERROR,
        });
        return null;
    }
}

function* search_chapter_logs({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(chapterLogSearchAPI, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });
        // console.log('saga log is',data)
        yield put({
            type: BOOK_ACTIONS.CHAPTER_LOG_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: BOOK_ACTIONS.CHAPTER_LOG_SEARCH_ERROR,
        });
        return null;
    }
}

function* search_book_logs({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(bookLogSearchAPI, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });
        // console.log('saga log is',data)
        yield put({
            type: BOOK_ACTIONS.BOOK_LOG_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: BOOK_ACTIONS.BOOK_LOG_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({ _id }) {  
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(booksSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });
 
        if (!data || !data[0]) {
            // DATA NO FOUND
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }

        // add in reducer
        yield put({
            type: BOOK_ACTIONS.BOOK_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(bookCreateAPI, payload);

        yield put(headerShowAction({
            data: 'Created',
        }));

        // add in reducer
        yield put({
            type: BOOK_ACTIONS.BOOK_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: BOOK_ACTIONS.BOOK_UPSERT_ERROR,
        });
    }
}

function* update(bookId, payload) {
    try {
        yield call(updateBookStatusAPI, {
           
            _id:bookId, ...payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));

        yield edit({ _id: bookId });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: BOOK_ACTIONS.BOOK_UPSERT_ERROR,
        });
    }
}

function* addFaq(payload) {
    try {
        yield call(addBookFaqAPI, {
        ...payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));

        // yield edit({ _id: bookId });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: BOOK_ACTIONS.BOOK_UPSERT_ERROR,
        });
    }
}


function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(BOOK_ACTIONS.BOOK_SEARCH, search),
    takeFirstSagaUtil(BOOK_ACTIONS.CHAPTER_LOG_SEARCH, search_chapter_logs),
    takeFirstSagaUtil(BOOK_ACTIONS.BOOK_LOG_SEARCH, search_book_logs),
    takeFirstSagaUtil(BOOK_ACTIONS.BOOK_UPSERT, upsert),
    takeFirstSagaUtil(BOOK_ACTIONS.BOOK_EDIT, edit),
];
