import React, {useState,useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux'
import { expertSearchAction, expertCountAction } from "./actions/ExpertActions"
import ExpertContainerTabPane from "src/components/ExpertContainerTabPane"
import { TabDetails } from "./TabsConfiguration"

const ExpertContainer=()=>{
   const dispatch = useDispatch()
   const {search, expertCounts} = useSelector((state) => state.ExpertReducer)
   const [activeSelectedTab, setActiveSelectedTab] = useState({tabName:'Backend',id:1})
   const [filter, setFilter] = useState({})
   const [tableData, setTableData] = useState([])
   const [pageNo, setPageNo] = useState(0)
   const [totalTableData, setTotalTableData] = useState(0)
   useEffect(()=>{
      getExperts()
      getExpertsCount()
   },[pageNo])

   useEffect(() => {
      if(activeSelectedTab.tabName === 'Backend'){
         dispatch(expertSearchAction({
            where:{...filter,role:'tutor'},
            currentPage: pageNo,
            autopopulate: true,
            expertType:'tutor'
         }))
      }else if(activeSelectedTab.tabName === 'Frontend'){
         dispatch(expertSearchAction({
            where:{...filter},
            currentPage: pageNo,
            autopopulate: true,
            expertType:'expert'
         }))
      }else if(activeSelectedTab.tabName === 'Pending Frontend'){
         dispatch(expertSearchAction({
            where:{...filter},
            currentPage: pageNo,
            autopopulate: true,
            expertType:'pending'
         }))
      }else {
         dispatch(expertSearchAction({
            where:{...filter},
            currentPage: pageNo,
            autopopulate: true,
            expertType:'draft'
         }))
      }
   },[filter,activeSelectedTab,pageNo])


   const getExperts =  () => {
       dispatch(expertSearchAction({
         where:{status:true,approve:true},
         currentPage: pageNo,
         autopopulate: true,
         expertType:'tutor'
      }))
   }

   const getExpertsCount =  () => {
      dispatch(expertCountAction())
  }
  const handelFilterChange = (payload) => {
   setFilter({...filter, ...payload})
  }
  useEffect(()=>{
   const {data} = search
   setTableData(data)
   setTotalTableData(search.total)
  },[search])

   const handleActiveTabClick = (tabHeading,tabId) => {
      const {data} = search
      setActiveSelectedTab({ tabName: tabHeading, id: tabId })
      if(tabHeading === 'Backend'){
         dispatch(expertSearchAction({
            where:{status:true,approve:true,role:'tutor'},
            currentPage: pageNo,
            autopopulate: true,
            expertType:'tutor'
         }))
         setTableData(data)
      }else if(tabHeading === 'Frontend'){
         dispatch(expertSearchAction({
            where:{status:true,approve:true},
            currentPage: pageNo,
            autopopulate: true,
            expertType:'expert'
         }))
         setTableData(data)
      }else if(tabHeading === 'Pending Frontend'){
         dispatch(expertSearchAction({
            where:{status:false,approve:false},
            currentPage: pageNo,
            autopopulate: true,
            expertType:'pending'
         }))
         setTableData(data)
      }else{
         dispatch(expertSearchAction({
            where:{status:false,isDraft:true},
            currentPage: pageNo,
            autopopulate: true,
            expertType:'draft'
         }))
         setTableData(data)
      }
   }


    return(
        <>
        <div className="content-wrapper">
                  <div className="page-header">
                     <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2" >
                        <i className="mdi mdi-home"></i>
                        </span> Experts
                     </h3>
                     <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                           <li className="breadcrumb-item active" aria-current="page">
                              <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="card shadow">
                           <div className="card-body">
                              <div className="tabsnrstl1 tabsnrstl-m4">
                                 <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {
                                       TabDetails.map((tab) => {
                                          return (
                                             <li className="nav-item" role="presentation" key={tab.id} onClick={() => handleActiveTabClick(tab.heading,tab.id)}>
                                             <div className={`nav-link ${activeSelectedTab.tabName === tab.heading && 'active'}`} id={`pills-tab${activeSelectedTab.id}`} data-bs-toggle="pill" data-bs-target={`#pills-${activeSelectedTab.id}`} type="button" role="tab" aria-controls={`pills-${activeSelectedTab.id}`} aria-selected="true">
                                                <div className="stretch-card asigntabnew1">
                                                   <div className="card-img-holder">
                                                      <div className="card-body">
                                                         <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className="asigntabImgX">
                                                             <img src={tab.icon} className="img-fluid img-icon" alt=""/>
                                                             </span> <span className="valutext">{expertCounts[tab.heading]}</span>
                                                         </h4>
                                                         <h2 className="mt-0">{tab.heading} Experts</h2>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </li>
                                          )
                                       })
                                    }
                                 </ul>
                              </div>
                            
                              <ExpertContainerTabPane handelFilterChange={handelFilterChange} tableData={tableData} pageNo={pageNo} setPageNo={setPageNo} activeSelectedTab={activeSelectedTab} total={totalTableData}/>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
        </>
    )
}

export default ExpertContainer;